import React from 'react'
import { useState, useMemo, useEffect } from 'react'
import ROIcon from '../../components/ROIcon'
import RepServiceOverdueItem from './RepServiceOverdueItem'

import { useSelector } from 'react-redux'
import myUUid from '../../features/myUUid'
import { toast } from 'react-toastify'

import { addROErrorLog, getServiceInterval, daysDiff, getFranSettingsJSON, getAnySettingsJSON, getServicesReportArray, EmailTokensReplace, GetEmailTokens, FormatHtmlEmail } from '../../features/globalFunctions'

import { ServiceEmailMsg, RegExPattern } from '../../features/globalVars'
import { formatLongDate, formatShortDate } from '../../features/formatTime'
import { useSearchParams } from 'react-router-dom'

const RepServiceOverdue = ({ searchString, searchFilters }) => {
    console.log('*** DRAW RepServiceOverdue ***', searchFilters)

    let [urlParams, setUrlParams] = useSearchParams()

    let reportType = urlParams.get('reportType') ? urlParams.get('reportType') : ''

    //Search Parts
    const [searchResults, setSearchResults] = useState([])
    //let [searchString, setSearchString] = useState(_searchString)
    const [searchFound, setSearchFound] = useState(false)

    //Get State user from redux
    const { user } = useSelector((state) => state.auth)
    //Get State users / staff from redux
    const { franusers } = useSelector((state) => state.franusers)
    //Get State customers from redux
    const { customers } = useSelector((state) => state.customers)
    //Get State customer locations from redux
    const { loccustomers } = useSelector((state) => state.loccustomers)
    //Get State customer location contacts from redux
    const { custloccontacts } = useSelector((state) => state.loccustcontacts)
    //Get State equipment from redux
    const { equipment } = useSelector((state) => state.equipment)
    //Get State parts from redux
    const { parts } = useSelector((state) => state.parts)
    //Get State location equipment from redux
    const { equipcustloc } = useSelector((state) => state.equipcustloc)
    //#region All Services
    const { services } = useSelector((state) => state.service)

    //Get State fran acc settings from redux
    const { franacc } = useSelector((state) => state.franacc)

    //Deconstruct Search Filters
    let { toggleAllRecentServices, toggleShowAllCust, toggleShowSuspended, toggleShowServices, toggleShowRepairs, toggleMoreFilters, toggleShowUnsucessfull, toggleShowUpcomingServices, advSearch } = searchFilters

    //toggleShowUpcomingServices = reportType === 'upcoming' ? true : toggleShowUpcomingServices

    let arrServOver //{EquipCustLocID: 0, CustID: 0, CustLocID: 0, equipServices: []}

    const todayDate = new Date()
    //console.log('todayDate: ', todayDate)
    const thisMonthEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)
    const nextMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 2, 0)

    //const [updateReportArray, setUpdateReportArray] = useState(false)
    const [emailSendIDs, setEmailSendIDs] = useState([-1])
    try {
        //Sort Services....
        //First Group by EquipCustLocID
        //Then check if there are more than 3 services remove the older services (shift)
        // services &&
        // services.length > 0 &&

        let sortedArray = []

        //console.log('REP SERVICES OVERDUE searchString: ', searchString)

        if (franacc && Object.values(franacc).length > 0 && loccustomers && loccustomers.length > 0 && custloccontacts && equipcustloc && equipcustloc.length > 0 && equipment && equipment.length > 0) {
            arrServOver = getServicesReportArray(services, franacc, loccustomers, equipcustloc, custloccontacts, equipment, toggleShowUnsucessfull, toggleShowServices, toggleShowRepairs, toggleAllRecentServices, toggleShowSuspended, toggleShowAllCust, toggleShowUpcomingServices)

            if (arrServOver && arrServOver.length > 0) {
                // const todayDate = new Date()
                // const nextMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 2, 0)
                //console.log('AFTER equipcustloc MAP arrServOver: ', arrServOver)
                //Filter to show all services overdue by interval amount and current date
                // console.log('arrServOver: ', arrServOver)
            } else {
                //console.log('option3-arrServOver Empty')
                //console.log('arrServOver: ', arrServOver)
                //TO DO REMOVE THIS TESTING FEEDBACK LOGS
                //if (services && loccustomers && equipcustloc && equipment) {
                // console.log('Services: ', services)
                // console.log('loccustomers: ', loccustomers)
                // console.log('equipcustloc: ', equipcustloc)
                // console.log('equipment: ', equipment)
            }

            console.log('arrServOver: ', arrServOver)
            let arrServOverByCustLocID = []

            arrServOver.map((item) => {
                //Add IF for filtering report
                let found = false

                if (searchString !== '') {
                    const _searchstr = searchString.toLowerCase()
                    let searchWords = _searchstr.trim().split(' ')

                    if (!advSearch) {
                        searchWords = [_searchstr]
                    }

                    //console.log('Search Words: ', searchWords)
                    //console.log('Item: ', item)

                    searchWords.forEach((word) => {
                        //console.log('cont.CustLocContactNumber :', String(cont.CustLocContactNumber.replaceAll(' ', '')).toLowerCase())
                        if (
                            String(item.CustPriContactName).toLowerCase().includes(word) ||
                            String(item.CustPriContactNumber).toLowerCase().includes(word) ||
                            String(item.CustPriContactNumber.replaceAll(' ', '')).toLowerCase().includes(word) ||
                            String(item.CustPriContactEmail).toLowerCase().includes(word) ||
                            String(item.CustName).toLowerCase().includes(word) ||
                            String(item.CustLocName).toLowerCase().includes(word) ||
                            String(item.CustLocStreetAddress).toLowerCase().includes(word) ||
                            String(item.CustLocSuburb).toLowerCase().includes(word) ||
                            String(item.CustLocCity).toLowerCase().includes(word) ||
                            String(item.CustLocPostalCode).toLowerCase().includes(word) ||
                            String(item.CustLocCountry).toLowerCase().includes(word) ||
                            String(item.CustLocNotes).toLowerCase().includes(word) ||
                            String(item.EquipInfo.EquipCode).toLowerCase().includes(word) ||
                            String(item.EquipInfo.EquipName).toLowerCase().includes(word) ||
                            String(item.EquipCustLoc.EquipCustLocSerialNR).toLowerCase().includes(word) ||
                            String(item.EquipCustLoc.EquipCustLocNotes).toLowerCase().includes(word)
                        ) {
                            //console.log('Found Item: ', item)
                            found = true
                        }
                    })
                }

                if (searchString !== '' && !found) {
                    return
                }

                let tempItem = arrServOverByCustLocID.find((e) => e.CustLocID === item.CustLocID)

                if (tempItem) {
                    tempItem.Equipment.push(item)
                } else {
                    arrServOverByCustLocID.push({
                        CustLocID: item.CustLocID,
                        EquipCustLocID: item.EquipCustLocID,
                        CustID: item.CustID,
                        EquipID: item.EquipID,
                        ServiceType: item.ServiceType,
                        CustName: item.CustName,
                        CustLocName: item.CustLocName,
                        CustLocStreetAddress: item.CustLocStreetAddress,
                        CustLocSuburb: item.CustLocSuburb,
                        CustLocCity: item.CustLocCity,
                        CustLocPostalCode: item.CustLocPostalCode,
                        CustLocCountry: item.CustLocCountry,
                        CustLocNotes: item.CustLocNotes,
                        CustLocFlags: item.CustLocFlags,
                        CustSuspended: item.CustSuspended,
                        CustPriContactName: item.CustPriContactName,
                        CustPriContactEmail: item.CustPriContactEmail,
                        CustPriContactNumber: item.CustPriContactNumber,
                        LastServiceRecorded: item.LastServiceRecorded,
                        ShowEmail: getAnySettingsJSON(franacc.FranAccFlags, 'Email') === null || getAnySettingsJSON(franacc.FranAccFlags, 'Email') === 'null' || getAnySettingsJSON(franacc.FranAccFlags, 'Email') === '' ? true : false,
                        Contacts: item.Contacts,
                        EquipCustLoc: item.EquipCustLoc,
                        Equipment: [item],
                    })
                }
            })

            console.log('arrServOverByCustLocID: ', arrServOverByCustLocID)

            //Sort Contacts to put Primary on top
            // setReportArray(
            //     arrServOverByCustLocID.sort((a, b) => {
            //         return a.CustID === b.CustID ? 0 : a.CustID < b.CustID ? -1 : 1
            //     })
            // )

            // sortedArray = arrServOverByCustLocID.sort((a, b) => {
            //     return a.CustID === b.CustID ? 0 : a.CustID < b.CustID ? -1 : 1
            // })

            sortedArray = arrServOverByCustLocID.sort((a, b) => {
                return a.CustName === b.CustName ? 0 : a.CustName < b.CustName ? -1 : 1
            })
        }

        const updateEmailSend = (id) => {
            // for (const item of sortedArray) {
            //     if (item.CustLocID === id) {
            //         var today = new Date()
            //         today.setDate(today.getDate() + 30)
            //         const tokenExpire = parseInt(today / 1000).toString()
            //         console.log('tokenExpire: ', tokenExpire, ' id: ', id)
            //         item.CustLocFlags = '{"EToken", "' + tokenExpire.toString() + '"}'
            //         break
            //     }
            // }

            //setEmailSendIDs((prev) => prev.push(id))
            console.log('updateEmailSend emailSendIDs : ', emailSendIDs)
            let IDs = [...emailSendIDs]
            IDs.push(id)
            setEmailSendIDs(IDs)
        }

        // const emailBulkClientService = () => {
        //     try {
        //         let msg = franacc && franacc.FranAccNotes ? franacc.FranAccNotes : ServiceEmailMsg

        //         arrServOverByCustLocID.map((item, i) => {
        //             let serviceDueDate = new Date(item.LastServiceRecorded)

        //             let emailTokens = GetEmailTokens({
        //                 ReplyToEmail: getFranSettingsJSON(franacc, 'ReplyTo'),
        //                 FranCompName: franacc.FranAccName,
        //                 YourName: user && user.name ? user.name : 'YourName',
        //                 FranAccTel: franacc.FranAccTel,
        //                 FranAccEmail: franacc.FranAccEmail,
        //                 FranAccStreet: franacc.FranAccStreetAddress,
        //                 FranAccSuburb: franacc.FranAccSuburb,
        //                 FranAccCity: franacc.FranAccCity,
        //                 FranAccPostal: franacc.FranAccPostalCode,
        //                 FranAccCountry: franacc.FranAccCountry,
        //                 CustomerName: item.CustPriContactName ? item.CustPriContactName : 'Client',
        //                 //Equipment: item.EquipInfo.EquipName ? item.EquipInfo.EquipName : 'Your Equipment',
        //                 // ServiceDue: formatLongDate(
        //                 //     serviceDueDate.setDate(serviceDueDate.getDate() + equipObj.EquipCustLoc.EquipCustLocDaysTillNextService)
        //                 // ),
        //             })

        //             const email = EmailTokensReplace(msg, emailTokens)
        //             const replyTo = getFranSettingsJSON(franacc, 'ReplyTo')
        //             const cc = getFranSettingsJSON(franacc, 'cc')
        //             const fromName = getFranSettingsJSON(franacc, 'FromName')
        //             const emaildays = getFranSettingsJSON(franacc, 'EmailToken')

        //             //Validation Checks
        //             if (!_LocDetails.CustPriContactEmail || !_LocDetails.CustPriContactEmail.match(RegExPattern.Email)) {
        //                 toast.error('Not valid email(To): ' + _LocDetails.CustPriContactEmail.toString())
        //             } else if (!replyTo || !replyTo.match(RegExPattern.Email)) {
        //                 toast.error('Not valid email(ReplyTo): ' + replyTo.toString())
        //             } else {
        //                 let sendEmailTo = _LocDetails.CustPriContactEmail

        //                 const reportDetails = {
        //                     id: id,
        //                     type: 'CustLocID',
        //                     curSymb: curSymb,
        //                     pType: pType,
        //                     days: emaildays,
        //                     cc: cc,
        //                     emailName: fromName ? fromName : franacc.FranAccName,
        //                     emailTo: sendEmailTo,
        //                     emailReply: replyTo,
        //                     emailMsg: email,
        //                     emailHtml: FormatHtmlEmail(email),
        //                 }
        //             }
        //         })

        //         toast.info('Service email sent to: ' + sendEmailTo)

        //         //dispatch(sendClientService(reportDetails))
        //     } catch (error) {
        //         toast.error('ERROR!!!! in RepServiceOverdueItem(emailClientService): ' + error)
        //         addROErrorLog(
        //             'RepServiceOverdueItem(emailClientService) Error:  ' + error.toString(),
        //             _LocDetails.User && _LocDetails.User.name ? _LocDetails.User.name : '',
        //             'emailClientService'
        //         )
        //         console.log('ERROR!!!! in RepServiceOverdueItem(emailClientService): ', error)
        //     }
        // }

        const CollapseAll = () => {}

        return (
            <>
                {/*
                <div className='pageFunctions row bg-white header sticky-top'>
                    <div className='col-auto ms-auto'>
                        <ROIcon icon='ROicoUp' size={24} className='icoSVG' tooltip='Collapse All' onClickCallback={() => CollapseAll()} />
                    </div>
                </div>
                */}
                {/* <div className='row text-primary mb-0 mt-3'>
                    <div className='col-auto me-auto '>
                        <h5>Services Report</h5>
                    </div> */}
                {/* <div className='col-auto'>
                        {' '}
                        <ROIcon
                            icon={toggleMoreFilters ? 'ROicoEmail' : 'ROicoEmail'}
                            className='icoSVG p-0 m-0'
                            size={32}
                            authlvl={15}
                            onClickCallback={() => setMoreFilters((prev) => !prev)}
                            textafter='Send Email to All unsent in Report'
                            tooltip='If not already sent'
                        />
                    </div> */}
                {/* </div> */}

                {sortedArray && sortedArray.length > 0
                    ? sortedArray.map((line, i) => {
                          //   console.log(
                          //       'line: ',
                          //       line,
                          //       ' emailSendIDs: ',
                          //       emailSendIDs,
                          //       ' line.CustLocID:',
                          //       line.CustLocID,
                          //       ' emailSendIDs.includes(line.CustLocID): ',
                          //       emailSendIDs.includes(line.CustLocID),
                          //       'daysDiff: ',
                          //       getAnySettingsJSON(line.CustLocFlags, 'EToken') !== '' &&
                          //           daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0,
                          //       ' getAnySettingsJSON(line.CustLocFlags, EToken): ',
                          //       getAnySettingsJSON(line.CustLocFlags, 'EToken')
                          //   )

                          return (
                              <RepServiceOverdueItem
                                  _LocDetails={line}
                                  key={() => myUUid()}
                                  containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'}
                                  emailSendIDCallback={updateEmailSend}
                                  //   emailSent={
                                  //       emailSendIDs.includes(line.CustLocID) ||
                                  //       daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0
                                  //   }
                                  emailSent={emailSendIDs.includes(line.CustLocID) || (getAnySettingsJSON(line.CustLocFlags, 'EToken') !== '' && daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0)}
                                  //emailSent={daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0}
                                  showEmail={line.ShowEmail}
                              />
                          )
                      })
                    : ''}
            </>
        )
    } catch (error) {
        console.log('ERROR!!!! in RepServicesOverdue: ', error)
        console.log('Services: ', services)
        console.log('loccustomers: ', loccustomers)
        console.log('equipcustloc: ', equipcustloc)
        console.log('equipment: ', equipment)
        toast.error('ERROR!!!! in RepServicesOverdue: ' + error)
        addROErrorLog('RepServicesOverdue Error:  ' + error.toString(), user ? user.name : '', 'Reports')
    }
}
export default RepServiceOverdue
