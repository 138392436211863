
import ROIcon from '../ROIcon'
const ROHelpCustomer = () => {

    return (
        <>
            <div className="row p-0 m-0">
                <div class="contianer ps-2">
                    <a href="#Overview" class="bs-linebreak fw-bold " >Customer Overview|</a>
                    &emsp;
                    <a href="#Add-Customer" class="bs-linebreak fw-bold">Add Customer|</a>
                    &emsp;
                    <a href="#Search" class="bs-linebreak fw-bold">Search|</a>
                    &emsp;
                    <a href="#Customer-Information" class="bs-linebreak fw-bold">Customer Information</a>
                </div>



                <div class="container" id="Overview">
                <br />
                    <h2>Customer Page Overview</h2>
                    <img src="./images/help-imges/customer-img.jpg" height={90} width={600} alt="img" />
                    <p> The customer page consists of various customers and
                        their personal information as well as the services
                        they enquired from Rovantage. Users are able to edit
                        and the information and also add nuwe user and information
                        such as location, cell phone number, email adress, etc.
                        Customers are also able to view equipment, when and what kind
                        of maintenace the received
                        and also add too their maintenance should they need any.

                    </p>
                </div>
                <div class="container" id="Add-Customer">
                    <h2>Add Customer [<ROIcon icon='ROicoPlus' size={26} className='icoSVG ' />]</h2>
                    <p>The plus icon next to the "Your Customer" heading.
                        This icon is used when we want to add a customer and then
                        a form will appear user will be able to fill in needed information.</p>
                </div>
                <div class="container" id="Search">
                    <h2>Search[]</h2>
                    <p>The search box next to the plus icon.
                        The search box is used when we want to search for specific a
                        customer but it does work if you are busy adding a new customer.</p>
                </div>
                <div class="container" id="Customer-Information">
                    <h2>Customer Information []</h2>
                    <p>
                        This page is where we fill in information about Customer, and only after the information is add will the user
                        stay in the customer page where their information is and be able to add more information such as a another user, equipment, and parts.
                        The customer information page has the as following:<br />

                        <h4>Personal Information:</h4>
                        Only one customer can be added at a time, only afterwards can more be added<br />
                        <strong class="ps-3">1. Name and Surname</strong><br />
                        <div class="ps-5">
                            - [] First Name<br />
                            - [] Last Name<br />
                        </div>

                    </p>
                    <p>
                        <strong class="ps-3">2. Tax Information: </strong>
                        <div class="ps-5">
                            - [] Tax Nr
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">3. Customer Notes:</strong><br />
                        Notes is where we add more information about customer if needed<br />
                        <div class="ps-5">
                            - [] Customer Notes
                        </div>

        </p>
        <p>
            <h4><ROIcon icon='ROicoLocation' size={26} className='icoSVG ' />Location:</h4>
            The location can be added one at a time, only afterwards can more be added<br/>
            <strong class="ps-3">4.Address:</strong>
            <br/>
            <div class="ps-5">
                - [] Street<br/>
                - [] Suburb<br/>
                - [] City<br/>
                - [] Postal Code<br/>
                - [] Country<br/>
            </div>
        </p>
        <p>
            <h4> Contact Information:</h4>
            The customer contact information is in this section of the customers page.<br/>
            <strong class="ps-3">5. Contact Name:</strong>
            <div class="ps-5">
            - [] Contact Name<br/>
            </div>
            
        </p>
        <p>
            <strong class="ps-3">6. Phone Number:</strong>
            <div class="ps-5">
                - [] Phone Number
                </div>
        </p>
        <p>
            <strong class="ps-3">7. Email Address:</strong>
            <div class="ps-5">
                - [] Email Address
                </div>
        </p>
        <p>
            <h4>Save Customer or Cancel:</h4>
            This icon is used to save the user information<br/>
            <strong class="ps-3">8. Save Customer:</strong>
            <div class="ps-5">
                - [<ROIcon icon='ROicoCheck' size={26} className='icoSVG ' />] Approve: (Check icon to save information)<br/>
                </div>

                    </p>
                    <p>
                        <strong class="ps-3">9. Cancel:</strong>
                        <div class="ps-5">
                            This icon is used to discard changes of customers information<br />
                            - [<ROIcon icon='ROicoCancel' size={26} className='icoSVG ' />] Cancel: (Times icon to discard changes)<br />
                        </div>
                    </p>

                </div>


            </div>
        </>

    )
}

export default ROHelpCustomer
