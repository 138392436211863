import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import servicesService from './servicesService'

const initialState = {
    services: [],
    isServicesError: false,
    isServicesSuccess: false,
    isServicesLoading: false,
    servicesmessage: '',
}

// Create New Services
export const createService = createAsyncThunk(
    'service/create',
    async (serviceData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            return await servicesService.createService(serviceData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update Service
export const updateService = createAsyncThunk(
    'service/update',
    async (serviceData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            // console.log(
            //     'updateService(servicesSlice) serviceData: ',
            //     serviceData
            // )
            return await servicesService.updateService(serviceData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get fran services
export const getServices = createAsyncThunk(
    'service/getAll',
    async (_, thunkAPI) => {
        try {
            let userCred = ''

            if (thunkAPI.getState() != null) {
                userCred = thunkAPI.getState().auth.user
            }

            if (userCred.token) {
                const returnData = await servicesService.getServices(userCred)
                return returnData
            }

            return thunkAPI.rejectWithValue('No user token found')
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            console.log('thunkAPI GetServices error: ', message, error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete service
export const deleteService = createAsyncThunk(
    'service/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await servicesService.deleteService(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        resetservices: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createService.pending, (state) => {
                state.isServicesSuccess = false
                state.isServicesLoading = true
                state.isServicesError = false
            })
            .addCase(createService.fulfilled, (state, action) => {
                state.isServicesLoading = false
                state.isServicesSuccess = true
                state.isServicesError = false
                if (!state.services) {
                    state.services = []
                }
                state.services.push(action.payload)
            })
            .addCase(createService.rejected, (state, action) => {
                state.isServicesLoading = false
                state.isServicesError = true
                console.log(
                    'createService.rejected action.payload: ',
                    action.payload
                )
                state.servicesmessage = action.payload
            })
            .addCase(updateService.pending, (state) => {
                state.isServicesSuccess = false
                state.isServicesLoading = true
                state.isServicesError = false
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.isServicesLoading = false
                state.isServicesSuccess = true
                state.isServicesError = false
                state.services = state.services.map((service) => {
                    if (service.ServiceID === action.payload.ServiceID) {
                        return action.payload
                    } else {
                        return service
                    }
                })
            })
            .addCase(updateService.rejected, (state, action) => {
                state.isServicesLoading = false
                state.isServicesError = true
                //This is what redux helps to do. Change immutable values
                state.servicesmessage = action.payload
            })
            .addCase(getServices.pending, (state) => {
                state.isServicesLoading = true
                state.isServicesError = false
            })
            .addCase(getServices.fulfilled, (state, action) => {
                state.isServicesLoading = false
                state.isServicesSuccess = true
                state.isServicesError = false
                //This is what redux helps to do. Change immutable values
                state.services = action.payload
            })
            .addCase(getServices.rejected, (state, action) => {
                state.isServicesLoading = false
                state.isServicesError = true
                //This is what redux helps to do. Change immutable values
                state.servicesmessage = action.payload
            })
            .addCase(deleteService.pending, (state) => {
                state.isServicesSuccess = false
                state.isServicesLoading = true
                state.isServicesError = false
            })
            .addCase(deleteService.fulfilled, (state, action) => {
                state.isServicesLoading = false
                state.isServicesSuccess = true
                state.isServicesError = false
                state.services = state.services.filter(
                    (service) => service.ServiceID !== action.payload.id
                )
            })
            .addCase(deleteService.rejected, (state, action) => {
                state.isServicesLoading = false
                state.isServicesError = true
                //This is what redux helps to do. Change immutable values
                state.servicesmessage = action.payload
            })
    },
})

export const { resetservices } = serviceSlice.actions
export default serviceSlice.reducer
