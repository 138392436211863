import React, { useEffect } from 'react'
import RepServiceOverdue from './RepServiceOverdue'
import { useState, useMemo } from 'react'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import RepPartsUsage from './RepPartsUsage'
import RepSearchBar from './RepSearchBar.jsx'
import { formatInputDate } from '../../features/formatTime.js'
import PartsUsageItemCategory from './PartsUsageItemCategory'
import PartsUsageItemCustomer from './PartsUsageItemCustomer'

const AllReports = () => {
    //console.log('*** AllReports.jsx Draw ****')
    const navigate = useNavigate()

    // const fromDate = formatInputDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7))
    // const tillDate = formatInputDate(new Date())

    const fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)
    const tillDate = new Date()

    //console.log('fromDate', fromDate, 'tillDate', tillDate)

    const defaultSearchFilters = {
        toggleAllRecentServices: false,
        toggleShowAllCust: false,
        toggleShowSuspended: false,
        toggleShowServices: true,
        toggleShowRepairs: false,
        toggleMoreFilters: false,
        toggleShowUnsucessfull: false,
        toggleShowUpcomingServices: false,
        advSearch: false,
        togglePartsUsageByCustomer: false,
        togglePartsUsageByCategory: false,
        fromDate: fromDate,
        tillDate: tillDate,
    }

    //console.log('defaultSearchFilters', defaultSearchFilters)

    let [urlParams, setUrlParams] = useSearchParams()

    let reportType = urlParams.get('reportType') ? urlParams.get('reportType') : ''

    let [searchString, setSearchString] = useState('')
    let [searchFilters, setSearchFilters] = useState(defaultSearchFilters)

    const searchCallback = (searchString) => {
        //console.log('Search String: ', searchString)
        setSearchString(searchString)
    }

    //search bar filters callback when search filters are changes in the search bar / filter checkboxes
    const searchFiltersCallback = (filters) => {
        //console.log('searchFiltersCallback: ', filters)
        setSearchFilters(filters)
    }

    let _searchfilters = searchFilters ? searchFilters : defaultSearchFilters
    //console.log('reportType', reportType, searchFilters)

    switch (reportType) {
        case 'partsusage':
            _searchfilters = { ...searchFilters, toggleShowAllCust: true, toggleShowRepairs: true, toggleAllRecentServices: true, toggleShowUpcomingServices: false }
            if (!searchFilters.togglePartsUsageByCategory && !searchFilters.togglePartsUsageByCustomer) {
                _searchfilters = { ...defaultSearchFilters, togglePartsUsageByCategory: true, toggleMoreFilters: true, toggleShowAllCust: true, toggleShowRepairs: true, toggleAllRecentServices: true, fromDate: fromDate, tillDate: tillDate }
            }

            break
        case 'allcustomers':
            _searchfilters = { ...searchFilters, toggleShowAllCust: true }
            break

        case 'upcoming':
            _searchfilters = { ...searchFilters, toggleShowUpcomingServices: true, toggleShowAllCust: false }
            break
        case 'overdue':
            _searchfilters = { ...searchFilters, toggleShowUpcomingServices: false, toggleShowAllCust: false }
            break
        default:
            _searchfilters = { ...searchFilters, toggleShowAllCust: true }
            break
    }

    //console.log('All Reports Search String: ', searchString)

    //reportType === 'upcoming' ? <RepServiceOverdue searchString={searchString} searchFilters={searchFilters} /> :

    return (
        <>
            <RepSearchBar searchCallback={searchCallback} searchFiltersCallback={searchFiltersCallback} searchFilters={_searchfilters} />
            <div className='pageContent container  pt-2'>{reportType === 'partsusage' ? <RepPartsUsage searchString={searchString} searchFilters={_searchfilters} /> : <RepServiceOverdue searchString={searchString} searchFilters={_searchfilters} />}</div>
        </>
    )
}
export default AllReports
