import myUUid from '../features/myUUid'
import { useEffect, useState, useRef, useMemo } from 'react'
import ROIcon from './ROIcon'
import ROHelpParts from './help/ROHelpParts.jsx'
import ROHelpEquipment from './help/ROHelpEquipment.jsx'
import ROHelpUser from './help/ROHelpUser.jsx'
import ROHelpCustomer from './help/ROHelpCustomer.jsx'
import ROHelpReports from './help/ROHelpReports.jsx'
import ROHelpDashboard from './help/ROHelpDashboard.jsx'

const ROHelpBox = ({
    helpTopic,
}) => {

    const [toggleState, setToggleState] = useState(false)

    const helpContent = (type) => {

        switch (type) {
            case "parts":
                return <ROHelpParts />
                break;
            case "equipment":
                return <ROHelpEquipment />
                break;
            case "user":
                return <ROHelpUser />
                break;
            case "customer":
                return <ROHelpCustomer />
                break;
            case "reports":
                return <ROHelpReports />
                break;
            case "dashboard":
                return <ROHelpDashboard />
                break;
            default:
                break;
        }

    }


    return (
        <>
        <ROIcon
            icon={'ROicoHelp'}
            size={24}
            authlvl={99}
            className='icoSVG'
            tooltip={'Help'}
            onClickCallback={() => {setToggleState((prev) => !prev)}}
        />
            {toggleState ? 
            <div className="modal">
                
                <div className="row p-0 m-0 sticky-top">
                    <div className="col-auto ms-auto p-0 m-0">
                    <ROIcon
                    icon={'ROicoCancel'}
                    size={24}
                    authlvl={99}
                    className='icoSVG'
                    tooltip={'Help'}
                    onClickCallback={() => {setToggleState((prev) => !prev)}}        />
                    </div>
                    </div>

                    {helpContent(helpTopic)}

            </div> : ''}

            
        </>

    )
}

export default ROHelpBox
