import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset, isError, message, sendServiceToken } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { addROErrorLog } from '../features/globalFunctions'
import { useSearchParams } from 'react-router-dom'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ClientServiceItem from '../components/ClientServiceItem'

const ServiceToken = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let [searchParams, setSearchParams] = useSearchParams()

    let token = searchParams.get('token') ? searchParams.get('token') : ''

    const { user, isError, message } = useSelector((state) => state.auth)

    //console.log('Service Token User: ', user)

    let arrServiceList = []

    if (user && user.ServiceDetails && user.ServiceDetails.length > 0) {
        user.ServiceDetails.map((item) => {
            let tempItem = arrServiceList.find((e) => e.CustLocID === item.CustLocID)

            if (item.ServiceID && item.ServiceID !== null && item.ServiceID >= 0) {
                let Service = {
                    ServiceID: item.ServiceID,
                    ServiceWorkRef: item.ServiceWorkRef,
                    ServiceDate: item.ServiceDate,
                    ServiceTDSSystem: item.ServiceTDSSystem,
                    ServiceTDSFeed: item.ServiceTDSFeed,
                    ServiceFeedPressure: item.ServiceFeedPressure,
                    ServiceTankPressure: item.ServiceTankPressure,
                    ServiceType: item.ServiceType,
                    ServiceSuccessful: item.ServiceSuccessful,
                    Username: item.Username,
                    servicejson: item.servicejson,
                }

                if (tempItem) {
                    let tempEquipItem = tempItem.Equipment.find((e) => e.EquipCustLocID === item.EquipCustLocID)

                    if (tempEquipItem) {
                        tempItem.Equipment.map((e) => {
                            if (e.EquipCustLocID === item.EquipCustLocID) {
                                e.Services.push(Service)
                            }
                        })
                    } else {
                        let Equipment = {
                            EquipCustLocID: item.EquipCustLocID,
                            EquipID: item.EquipID,
                            EquipCode: item.EquipCode,
                            EquipName: item.EquipName,
                            EquipFlags: item.EquipFlags,
                            EquipCustLocSerialNR: item.EquipCustLocSerialNR,
                            EquipCustLocDaysTillNextService: item.EquipCustLocDaysTillNextService,
                            EquipCustLocServiceIntervalType: item.EquipCustLocServiceIntervalType,
                            EquipCustLocLastServiceDate: item.EquipCustLocLastServiceDate,
                            EquipCustLocRoomPlace: item.EquipCustLocRoomPlace,
                            Services: [Service],
                        }

                        tempItem.Equipment.push(Equipment)
                    }
                } else {
                    let Equipment = {
                        EquipCustLocID: item.EquipCustLocID,
                        EquipID: item.EquipID,
                        EquipCode: item.EquipCode,
                        EquipName: item.EquipName,
                        EquipFlags: item.EquipFlags,
                        EquipCustLocSerialNR: item.EquipCustLocSerialNR,
                        EquipCustLocDaysTillNextService: item.EquipCustLocDaysTillNextService,
                        EquipCustLocServiceIntervalType: item.EquipCustLocServiceIntervalType,
                        EquipCustLocLastServiceDate: item.EquipCustLocLastServiceDate,
                        EquipCustLocRoomPlace: item.EquipCustLocRoomPlace,
                        Services: [Service],
                    }

                    arrServiceList.push({
                        CustName: item.CustName,
                        CustCompanyReg: item.CustCompanyReg,
                        CustCompanyTax: item.CustCompanyTax,
                        CustID: item.CustID,
                        CustLocID: item.CustLocID,
                        CustLocName: item.CustLocName,
                        CustLocStreetAddress: item.CustLocStreetAddress,
                        CustLocSuburb: item.CustLocSuburb,
                        CustLocCity: item.CustLocCity,
                        CustLocPostalCode: item.CustLocPostalCode,
                        CustLocCountry: item.CustLocCountry,
                        Contact: item.Contact,
                        Equipment: [Equipment],
                    })
                }
            } else {
                let Equipment = {
                    EquipCustLocID: item.EquipCustLocID,
                    EquipID: item.EquipID,
                    EquipCode: item.EquipCode,
                    EquipName: item.EquipName,
                    EquipFlags: item.EquipFlags,
                    EquipCustLocSerialNR: item.EquipCustLocSerialNR,
                    EquipCustLocDaysTillNextService: item.EquipCustLocDaysTillNextService,
                    EquipCustLocServiceIntervalType: item.EquipCustLocServiceIntervalType,
                    EquipCustLocLastServiceDate: item.EquipCustLocLastServiceDate,
                    EquipCustLocRoomPlace: item.EquipCustLocRoomPlace,
                    Services: [],
                }

                arrServiceList.push({
                    CustName: item.CustName,
                    CustCompanyReg: item.CustCompanyReg,
                    CustCompanyTax: item.CustCompanyTax,
                    CustID: item.CustID,
                    CustLocID: item.CustLocID,
                    CustLocName: item.CustLocName,
                    CustLocStreetAddress: item.CustLocStreetAddress,
                    CustLocSuburb: item.CustLocSuburb,
                    CustLocCity: item.CustLocCity,
                    CustLocPostalCode: item.CustLocPostalCode,
                    CustLocCountry: item.CustLocCountry,
                    Contact: item.Contact,
                    Equipment: [Equipment],
                })
            }
        })
    }

    // if (arrServiceList && arrServiceList.length >= 1) {
    //     console.log('arrServiceList: ', arrServiceList)
    // }

    /*
CustName
CustCompanyReg
CustCompanyTax
CustID
CustLocID
CustLocName
CustLocStreetAddress
CustLocSuburb
CustLocCity
CustLocPostalCode
CustLocCountry
Contact
EquipCustLocID
EquipID
EquipCode
EquipName
EquipCustLocSerialNR
EquipCustLocDaysTillNextService
EquipCustLocServiceIntervalType
EquipCustLocLastServiceDate
EquipCustLocRoomPlace
ServiceID
ServiceWorkRef
ServiceDate
ServiceTDSSystem
ServiceTDSFeed
ServiceFeedPressure
ServiceTankPressure
ServiceType
PartsObj
*/

    useEffect(() => {
        if (isError) {
            let msg = message
            console.log('ServiceToken useEffect error: ', msg)
            toast.error(msg)
            addROErrorLog('ServiceToken Error:  ' + msg, user ? user.name : '', 'ServiceToken')
            dispatch(reset())
            navigate('/?pagemessage=' + msg)

            return
        }

        // if (!user || !user._id || !user.token) {
        //     console.log('User error!!! log out: ', user)
        //     addROErrorLog('ServiceToken User Error:  ' + message, user ? user.name : '', 'ServiceToken')
        //     dispatch(logout())
        //     dispatch(reset())
        //     navigate('/')
        // }

        try {
            //console.log('Send service token to server')
            if (token && token !== '') {
                dispatch(sendServiceToken(token))
            }
        } catch (error) {
            console.log('ServiceToken.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }

        return () => dispatch(reset())
    }, [token, isError]) //token, isError, isFUsersSuccess

    return (
        <>
            <section className='pageHead container mt-3'>
                <h5>Services</h5>
                {user ? (
                    <>
                        {/* <h6> {user ? user.name : ''}</h6> */}
                        <div className='row'>
                            {arrServiceList
                                ? arrServiceList.map((line, i) => {
                                      return (
                                          <ClientServiceItem
                                              _LocDetails={line}
                                              key={myUUid()}
                                              containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'}
                                          />
                                      )
                                  })
                                : 'Nope'}
                        </div>
                    </>
                ) : (
                    ''
                )}

                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container'></div>
        </>
    )
}

export default ServiceToken
