import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { reset, newAccountEmail } from '../features/auth/authSlice'
import { VaildateForm } from '../features/globalFunctions'
import { addROErrorLog } from '../features/globalFunctions'
import { RegExPattern } from '../features/globalVars'

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
    })

    const [boolTerms, setboolTerms] = useState(false)

    const { name, email } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {
        console.log('Register useEffect Start')
        if (isError) {
            console.log('Register USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            addROErrorLog('Register USERS useEffect Error:  ' + message, user ? user.name : '', 'Register')
            return
        }

        console.log('Register useEffect after error check')

        // Hide register for pilot stage
        if (isSuccess) {
            console.log('Register useEffect isSuccess ')
            toast.success('Email Sent')
            dispatch(reset())
            navigate('/?pagemessage=Please click the email link we emailed.')

            //navigate('/')
        }

        // if (!user) {
        //     navigate('/')
        // }

        console.log('Register useEffect after success and user check')

        // return () => {
        //     console.log('Register Deconstruction call')
        //     //dispatch(reset())
        // }
    }, [isError, isSuccess, message])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    // const onSubmit = (e) => {
    //     e.preventDefault()

    //     if (email !== '') {
    //         toast.error('Please enter email')
    //     } else {
    //         const userData = {
    //             name,
    //             email,
    //         }

    //         //Sends userData to authSlice -> import { register, reset } from '../features/auth/authSlice'
    //         dispatch(registernewaccount(userData))
    //         toast('User ' + name + ' registered!!! Email: ', email)
    //     }
    // }

    //#region Edit User Form Validation
    const [boolRegisterUserFormValidated, setRegisterUserFormValidated] = useState(false)

    const valUserNameRef = useRef()
    const valUserEmailRef = useRef()
    const valUserTermsRef = useRef()

    const vaildateRegisterNewUserForm = (clicked = false) => {
        setRegisterUserFormValidated(
            VaildateForm(
                [
                    {
                        ref: valUserNameRef,
                        text: 'No Special Characters',
                        form: 'Register New Fran valUserNameRef',
                    },
                    {
                        ref: valUserEmailRef,
                        text: 'Enter Valid Email',
                        form: 'Register New Fran valUserEmailRef',
                    },
                    {
                        ref: valUserTermsRef,
                        text: 'Please Accept the Terms and Conditions',
                        form: 'Register New Fran valUserTermsRef',
                    },
                ],
                clicked
            )
        )
    }

    const sendRegistrationForm = (e) => {
        e.preventDefault()

        const sendRegistration = {
            name,
            email,
        }

        console.log('sendRegistration: ', sendRegistration)

        dispatch(newAccountEmail(sendRegistration))
    }

    return (
        <>
            <div className='loginContainer content border border-primary bg-light mt-3 p-2 rounded-2'>
                <section className='heading'>
                    <h1>Register</h1>
                    <p>Please create a account</p>
                </section>

                <section className='form'>
                    <form className='p-0' onSubmit={(e) => sendRegistrationForm(e)}>
                        <div className='form-group'>
                            <input
                                type='text'
                                className='form-control'
                                id='name'
                                name='name'
                                value={name}
                                placeholder='Enter your name'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateRegisterNewUserForm()
                                }}
                                ref={valUserNameRef}
                                title='Your Name'
                                pattern={RegExPattern.Text}
                                required
                            />
                        </div>
                        <div className='form-group mt-2'>
                            <input
                                type='email'
                                className='form-control'
                                id='email'
                                name='email'
                                value={email}
                                placeholder='Enter your email'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateRegisterNewUserForm()
                                }}
                                ref={valUserEmailRef}
                                title='User Email'
                                required
                            />
                        </div>
                        <div className='row small'>
                            <div className='col-md-12 p-3 m-1'>
                                <b>
                                    <strong>Terms and Conditions:</strong>
                                </b>
                                <br />
                                By using this website and its services, you agree to follow these terms and conditions. <br />
                                The website and services must only be used for lawful purposes. <br />
                                The company has the right to change or stop the website and services at any time. <br />
                                The company will collect and use your personal information only to provide its services. Your information will not be
                                shared with others, except if required by law. <br /> The company will take reasonable steps to protect the security
                                of your information. The company may use cookies and other technology to improve its website and services. <br />
                                The company is not responsible for any harm that might happen while using the website or services.
                            </div>
                        </div>
                        <div className='form-group ms-3'>
                            <input
                                type='checkbox'
                                id='terms'
                                name='terms'
                                value={boolTerms}
                                onChange={(e) => {
                                    setboolTerms(e.checked)
                                    vaildateRegisterNewUserForm()
                                }}
                                title='Accept Terms and Conditions'
                                ref={valUserTermsRef}
                                required
                            />
                            &nbsp;I Accept the Terms and Conditions
                        </div>
                        <div className='form-group m-3'>
                            {boolRegisterUserFormValidated ? (
                                <button type='submit' className='btn btn-primary btn-block' onClick={() => vaildateRegisterNewUserForm(true)}>
                                    Submit
                                </button>
                            ) : (
                                <button type='button' className='btn btn-secondary btn-block' onClick={() => vaildateRegisterNewUserForm(true)}>
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </section>
            </div>
        </>
    )
}

export default Register
