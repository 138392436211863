
import ROIcon from '../ROIcon'
const ROHelpReports = () => {

    return (
        <>
            <div className="row p-0 m-0">

                <div class="contianer ps-2">
                    <a href="#report" class="bs-linebreak fw-bold">Report Filters Section|</a>
                    &emsp;
                    <a href="#Search" class="bs-linebreak fw-bold">Search Section|</a>
                    &emsp;
                    <a href="#Customer-Information" class="bs-linebreak fw-bold">Customer Information Section|</a>
                    &emsp;
                    <a href="#Equipment&Parts" class="bs-linebreak fw-bold">Equipment and Parts Section</a>
                </div>

                <div class="container" id="report">
                <br />
                    <h2>Report Filters Section</h2>
                    <img src="./images/help-imges/reports.jpg" height={300} alt="img" />
                    <p>
                        <h4>[<ROIcon icon='ROicoBox' size={26} className='icoSVG ' />]Filter Options:</h4>
                        The filter option is set of different thick boxes used to filter the reports based on these
                        following filtering option:
                        <p class="ps-5">
                            <strong>1. Services:</strong><br />
                            [ ] Include Services<br />

                            <strong>2. Repairs:</strong><br />
                            [ ] Include Repairs<br />

                            <strong>3. Upcoming Services:</strong><br />
                            [ ] Include Upcoming Services<br />

                            <strong>4. All Customers:</strong><br />
                            [ ] Include All Customers<br />

                            <strong>5. Older Maintenance:</strong><br />
                            [ ] Include Older Maintenance<br />

                            <strong>6. Unsuccessful Maintenance:</strong><br />
                            [ ] Include Unsuccessful Maintenance<br />

                            <strong>7. Suspended Customers:</strong><br />
                            [ ] Include Suspended Customers<br />
                        </p>


                        <h4>[ <ROIcon icon='ROicoXDown' size={26} className='icoSVG ' /> ]Expand Filter:</h4>
                        The expand filter is used to expand the and show more filtering options
                        or collapsed to show less filtering options.
                        <p class="ps-5"><strong>8. Greater-than:</strong><br />
                            [] Expand icon<br /></p>


                    </p>
                </div>
                <div class="container" id="Search">
                    <h2>Search Section []</h2>
                    <p class="ps-5">
                        <strong>1. Search Box:</strong><br />
                        The search box is used to search for a specific report. <br />
                        [ ] Enter Keywords:<br />
                    </p>
                    <p class="ps-5">
                        <strong>2. Exact Match:</strong><br />
                        The tick box is used to find the exact match of the report searched for in search box. <br />
                        [ <ROIcon icon='ROicoBox' size={26} className='icoSVG ' /> ] Exact Match:  (Check icon for precise search)
                    </p>
                </div>
                <div class="container" id="Customer-Information">
                    <h2>Customer Information Section []</h2>
                    The customer section information is colection personal information of the customers.
                    <p class="ps-5">
                        <strong>
                            1. Customer Name
                        </strong><br />
                        [ <ROIcon icon='ROicoUser' size={26} className='icoSVG ' /> ] Name
                    </p>
                    <p class="ps-5">
                        <strong>
                            2. Phone Number:
                        </strong><br />
                        [ <ROIcon icon='ROicoPhone' size={26} className='icoSVG ' /> ] Number
                    </p>
                    <p class="ps-5">
                        <strong>
                            3. Location:
                        </strong><br />
                        [ <ROIcon icon='ROicoLocation' size={26} className='icoSVG ' /> ] Location
                    </p>
                    <p class="ps-5">
                        <strong>
                            4. Expand details
                        </strong><br />
                        [ <ROIcon icon='ROicoTdown' size={26} className='icoSVG ' /> ] Expand icon<br /></p>
                
            </div>
            <div class="container" id="Equipment&Parts">
                <h2>Equipment and Parts Section </h2>
                The parts and equipment section of the report contains information of the
                service provided to the customer including the cost and the various icons
                used to navigate ths section as shown below.
                <h5>Equipment Information:</h5>
                Equipment contains the folling icon used to perform different operation, it also
                contains the text that informs if customer service is overdue.
                <p class="ps-5">
                    <strong>1. Share Report:</strong><br />
                    [ <ROIcon icon='ROicoGoto' size={26} className='icoSVG ' /> ] Share (Share icon to send report)
                </p>
                <p class="ps-5">
                    <strong>2. Message Customer:</strong><br />
                    [ <ROIcon icon='ROicoEmail' size={26} className='icoSVG ' /> ] Message (Message icon for communication)
                </p>
                <p class="ps-5">
                    <strong>3. Overdue Service:</strong><br />
                    [  <ROIcon icon='ROicoService' size={26} className='icoSVG ' /> ] Overdue
                </p>
                <h5>Card Section:</h5>
                The section contains information such as the service provided to the customer and the by whom it was done. It makes use
                of the Greater-than icon to expand the card so user may be able view the costs.
                <p class="ps-5">
                    <strong>4. Equipment Card:</strong><br />
                    [ <ROIcon icon='ROicoXDown' size={26} className='icoSVG ' /> ] Expand icon <br />
                    [ ] Price <br />
                    [<ROIcon icon='ROicoUser' size={26} className='icoSVG ' /> ] Service Provided <br />

                </p>
                <h5>Parts Information:</h5>
                Parts contains the folling icon used to perform different operation, it also
                contains the text that informs if customer service is overdue.
                <p class="ps-5">
                    <strong>5. Share Report:</strong><br />
                    [ <ROIcon icon='ROicoGoto' size={26} className='icoSVG ' /> ] Share (Share icon to send report)
                </p>
                <p class="ps-5">
                    <strong>6. Message Customer:</strong><br />
                    [ <ROIcon icon='ROicoEmail' size={26} className='icoSVG ' /> ] Message (Message icon for communication)
                </p>
                <p class="ps-5">
                    <strong>7. Overdue Service:</strong><br />
                    [ <ROIcon icon='ROicoService' size={26} className='icoSVG ' /> ] Overdue
                </p>
                <h5>Card Section:</h5>
                The section contains information such as the service provided to the customer and the by whom it was done. It makes use
                of the Greater-than icon to expand the card so user may be able view the costs.
                <p class="ps-5">
                    <strong>8. Parts Card:</strong><br />
                    [ <ROIcon icon='ROicoXDown' size={26} className='icoSVG ' /> ] Expand icon <br />
                    [ ] Price <br />
                    [<ROIcon icon='ROicoUser' size={26} className='icoSVG ' /> ] Service Provided <br />
                </p>

            </div>


        </div >
        </>

    )
}

export default ROHelpReports
