import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import { VaildateForm, getFranSettingsJSON, addROErrorLog } from '../features/globalFunctions'
import { toast } from 'react-toastify'

//Import Redux State/Global Var and Axiox database calls
import { updateEquipment, deleteEquipment } from '../features/equip/equipSlice.js'

import ROComboBox from './ROComboBox'
import { RegExPattern } from '../features/globalVars'

const EquipItem = ({ equip, arrCategories, containerBackColor, collapse, _expanedState, _editState }) => {
    const dispatch = useDispatch()

    // if (equip) {
    //     console.log(`*** Equip  ${equip.EquipName}  Draw ***`)
    // }

    let EquipFlags = {}

    try {
        //console.log('FranAccSettings: ', FranAccSettings)
        EquipFlags = equip.EquipFlags ? JSON.parse(equip.EquipFlags) : {}
    } catch (error) {
        console.log('EquipItem JSON Flags Parsing Error: ', error)
        toast.error('EquipItem JSON Flags Parsing Error: ' + error.toString())
        addROErrorLog('EquipItem JSON Flags Parsing Error:  ' + error.toString(), '', 'EquipItem ID:', equip.EquipID ? equip.EquipID : '-1')
    }

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    const curSymb = getFranSettingsJSON(franacc, 'CurrencySymb') ? getFranSettingsJSON(franacc, 'CurrencySymb') : 'R'
    const pType = getFranSettingsJSON(franacc, 'Bar') ? getFranSettingsJSON(franacc, 'Bar') : 'Bar'

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)

    //Edit Equipment
    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtEquipName, setTxtEquipName] = useState(equip ? equip.EquipName : '')
    const [txtEquipCode, setTxtEquipCode] = useState(equip ? equip.EquipCode : '')
    const [txtEquipPrice, setTxtEquipPrice] = useState(equip ? equip.EquipLastPrice : '')
    const [txtEquipCategory, setTxtEquipCategory] = useState(equip ? equip.EquipCategory : '')

    const [defs, setDefs] = useState(equip && equip.EquipFlags ? JSON.parse(equip.EquipFlags) : { DefS: [1, 1, 1, 1, 1], DefP: {} })
    //const [defs, setDefs] = useState({ DefS: [1, 1, 1, 1, 1], DefP: {} })
    //JSON.parse(equip.EquipFlags)

    //const defs = useRef({ DefS: [1, 1, 1, 1, 1], DefP: {} })

    const editEquipment = (edit = true) => {
        if (!edit) {
            setEditState((prev) => !prev)
            setTxtEquipCode(equip.EquipCode ? equip.EquipCode : '')
            setTxtEquipName(equip.EquipName ? equip.EquipName : '')
            setTxtEquipPrice(equip.EquipLastPrice ? equip.EquipLastPrice : '')
            setTxtEquipCategory(equip.EquipCategory ? equip.EquipCategory : '')
            setDefs(equip && equip.EquipFlags ? JSON.parse(equip.EquipFlags) : { DefS: [1, 1, 1, 1, 1], DefP: {} })
            setArrDefaultParts([])
            LoadDefaultParts()
            return
        }

        if (editState) {
            //console.log('Save Edit EquipName: ', txtEquipName)
            setTxtEquipCode(equip.EquipCode ? equip.EquipCode : '')
            setTxtEquipName(equip.EquipName ? equip.EquipName : '')
            setTxtEquipPrice(equip.EquipLastPrice ? equip.EquipLastPrice : '')
            setTxtEquipCategory(equip.EquipCategory ? equip.EquipCategory : '')

            //console.log('JSON.stringify(defaultParts): ', JSON.stringify(defaultParts))

            let eFlags = defs //{ DefS: [1, 1, 1, 1, 1], DefP: {} }

            //console.log('BEFORE eFlags: ', eFlags)

            eFlags.DefP = {}

            eFlags.DefP = defaultParts.map((dpart) => {
                return { ...eFlags.DefP, [dpart.DefaultPartID]: dpart.DefaultPartQty.toString() }
            })

            //console.log('editEquipment defaultParts: ', defaultParts)
            //console.log('eFlags: ', eFlags)

            const updEquip = {
                equipID: equip.EquipID,
                equipcode: txtEquipCode,
                equipname: txtEquipName,
                equipserialnr: '',
                equiplastprice: txtEquipPrice,
                equipcategory: txtEquipCategory,
                equipimageurl: '',
                equipnotes: '',
                equipflags: JSON.stringify(eFlags),
            }

            //console.log('updEquip: ', updEquip)

            dispatch(updateEquipment(updEquip))

            //updateCustomerDetails(CustID)
            //refreshCustCallback()
        } else {
            //console.log('Edit EquipName: ', txtEquipName)
        }
        setEditState((prev) => !prev)
    }

    //Delete Equipment
    const confirmDeleteEquip = (setState) => {
        if (confirmDelete && setState) {
            console.log('Delete!!!! EquipID: ', equip.EquipID)
            deleteEquipById(equip.EquipID)
        }
        setDeleteState(setState)
    }

    const deleteEquipById = (_id) => {
        console.log('Delete!!!! Equip_ID: ', _id)
        dispatch(deleteEquipment(_id))
        confirmDeleteEquip(false)
        //refreshCustCallback()
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    //#region Edit Location Equipment Form Validation
    const [boolEquipmentFormValidated, setEquipmentFormValidated] = useState(false)

    const valEquipCodeRef = useRef()
    const valEquipNameRef = useRef()
    const valEquipPriceRef = useRef()
    const valEquipCategoryRef = useRef()

    const vaildateEquipmentEditEquipForm = (clicked = false) => {
        if (!editState) return

        if (editState) {
            setEquipmentFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valEquipCodeRef,
                            text: 'No Special Characters',
                            form: 'Edit Equipment valEquipCodeRef',
                        },
                        {
                            ref: valEquipNameRef,
                            text: 'No Special Characters',
                            form: 'Edit Equipment valEquipNameRef',
                        },
                        {
                            ref: valEquipPriceRef,
                            text: 'Enter valid price',
                            form: 'Edit Equipment valEquipPriceRef',
                        },
                        {
                            ref: valEquipCategoryRef,
                            text: 'Equiment Category Required',
                            form: 'Edit Equipment valEquipCategoryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // PARTS
    //Get State parts from redux
    const { parts } = useSelector((state) => state.parts)
    const [arrDefaultParts, setArrDefaultParts] = useState([])

    //convert Price value to float
    const defaultParts = arrDefaultParts.map((item) => {
        return { ...item, DefaultPartPrice: parseFloat(item.DefaultPartPrice) }
    })

    //p_json: JSON.stringify(serviceParts),

    const addPart = (partID, partQty = 1) => {
        if (!parts || parts.length <= 0) {
            return
        }
        //console.log('EquipItem addPart PartID: ', partID, '  partQty: ', partQty)

        let defaultParts = [...arrDefaultParts]

        let newPart = parts.find((part) => part.PartID === partID)

        //console.log('EquipItem addPart newPart: ', newPart)

        if (!newPart || newPart.length <= 0) {
            return
        }

        let newDefaultItem = {
            DefaultPartID: newPart.PartID,
            DefaultPartCode: newPart.PartCode.replace(/"/g, '\\"'),
            //ServicePartName: newPart.PartName.replace('"', '\\"'),
            DefaultPartName: newPart.PartName.replace(/"/g, '\\"'),
            DefaultPartQty: partQty,
            //DefaultPartPrice: newPart.PartLastPrice.toFixed(2),
            //ServicePartCategory: newPart.PartCategory.replace('"', '\\"'),
            //ServicePartCategory: newPart.PartCategory.replace(/"/g, '\\"'),
            //ServicePartNotes: '',
            //ServicePartFlags: '',
        }

        // console.log(
        //     'newPart.PartCategory: ',
        //     newPart.PartCategory.replace('"', '\\"')
        // )

        defaultParts.push(newDefaultItem)
        setArrDefaultParts(defaultParts)

        //console.log('defaultParts: ', defaultParts)

        // console.log('call total')
        // calcTotalService(serviceParts)
    }

    const updateDefaultPart = (i, newObjVal) => {
        let defaultParts = [...arrDefaultParts]

        defaultParts[i] = { ...defaultParts[i], ...newObjVal }
        setArrDefaultParts(defaultParts)
        //console.log('arrServiceParts: ', arrServiceParts)
    }

    const removeDefaultPart = (i) => {
        let defaultParts = [...arrDefaultParts]
        defaultParts.splice(i, 1)
        setArrDefaultParts(defaultParts)
        //console.log('arrServiceParts: ', arrServiceParts)
    }

    let arrParts = []
    if (parts) {
        arrParts = parts.map((part) => {
            return {
                Text: '(' + part.PartCode + ') ' + part.PartName + ' - ' + part.PartCategory,
                ID: part.PartID,
            }
        })

        //console.log('arrParts: ', arrParts)
    }

    //Load Default Parts
    //arrDefaultParts

    const LoadDefaultParts = () => {
        if (equip.EquipFlags && equip.EquipFlags.length > 0) {
            let defaultParts = []
            let defs = JSON.parse(equip.EquipFlags)
            //dParts = Object.entries(defs.DefP)

            //console.log('useEffect!!!! Default equip.EquipFlags: ', equip.EquipFlags)
            //console.log('useEffect!!!! defaultParts: ', defaultParts)
            //console.log('Default equip.EquipFlags JSON.Parse: ', defs.DefP)

            defs.DefP.map((part, i) => {
                //addPart(part[0].toString(), parseInt(part[1]))
                //console.log('part entries: ', Object.entries(part))
                let valPair = Object.entries(part)
                let newPart = parts.find((part) => part.PartID === parseInt(valPair[0][0]))
                //console.log(i.toString() + '(', part, '): ', valPair[0][0], valPair[0][1])
                //addPart(parseInt(valPair[0][0]), valPair[0][1])

                if (newPart) {
                    //console.log('newPart: ', newPart)
                    let newDefaultItem = {
                        DefaultPartID: newPart.PartID,
                        DefaultPartCode: newPart.PartCode.replace(/"/g, '\\"'),
                        DefaultPartName: newPart.PartName.replace(/"/g, '\\"'),
                        DefaultPartQty: parseInt(valPair[0][1]),
                    }

                    defaultParts.push(newDefaultItem)
                }
            })

            setArrDefaultParts(defaultParts)
        }
    }

    useEffect(() => {
        LoadDefaultParts()
    }, [])

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-primary p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded

                <div className='container p-2 pt-0 bg-light rounded-2'>
                    <div className='row' /*EquipInfo Expanded*/>
                        {/*Equipment Code*/}
                        <div className='ROmaintxt col-auto me-auto'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtEquipCode}
                                    placeholder={equip ? equip.EquipCode : ''}
                                    onChange={(e) => {
                                        setTxtEquipCode(e.target.value)
                                        vaildateEquipmentEditEquipForm()
                                    }}
                                    ref={valEquipCodeRef}
                                    title='Equipment Code'
                                    pattern={RegExPattern.Text}
                                    required
                                />
                            ) : equip ? (
                                equip.EquipCode
                            ) : (
                                ''
                            )}
                        </div>
                        {/*Interface Buttons*/}
                        <div className='col-auto px-2'>
                            {editState ? (
                                <>
                                    {boolEquipmentFormValidated ? (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Update Equipment'
                                            authlvl={10}
                                            onClickCallback={() => editEquipment()}
                                        />
                                    ) : (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Update Equipment'
                                            authlvl={10}
                                            onClickCallback={() => vaildateEquipmentEditEquipForm(true)}
                                            usefill={true}
                                            color1='#ff0000'
                                        />
                                    )}

                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG borderlp'
                                        tooltip='Cancel'
                                        onClickCallback={() => editEquipment(false)}
                                    />
                                </>
                            ) : (
                                <ROIcon
                                    icon='ROicoEdit'
                                    size={24}
                                    className='icoSVG borderlp'
                                    tooltip='Edit Equipment'
                                    authlvl={10}
                                    onClickCallback={() => editEquipment()}
                                />
                            )}

                            {!editState ? (
                                <>
                                    {confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG danger borderlp'
                                            tooltip='Delete Equipment'
                                            authlvl={5}
                                            onClickCallback={() => confirmDeleteEquip(true)}
                                        />
                                    ) : (
                                        ' '
                                    )}
                                    {!confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Delete Equipment'
                                            authlvl={5}
                                            onClickCallback={() => confirmDeleteEquip(true)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            {!editState ? (
                                confirmDelete ? (
                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG borderlp'
                                        tooltip='Cancel Delete'
                                        onClickCallback={() => confirmDeleteEquip(false)}
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}
                            {/*Expande/Collapse Icon*/}
                            {editState ? (
                                ''
                            ) : (
                                <ROIcon icon='ROicoXUp' className='icoSVG borderlp' onClickCallback={() => setToggleState((prev) => !prev)} />
                            )}
                        </div>
                    </div>
                    <div className='row' /*EquipInfo Expanded*/>
                        <div className='ROnormaltxt col-md-6 fs-5'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtEquipName}
                                    placeholder={equip ? equip.EquipName : ''}
                                    onChange={(e) => {
                                        setTxtEquipName(e.target.value)
                                        vaildateEquipmentEditEquipForm()
                                    }}
                                    ref={valEquipNameRef}
                                    title='Equipment Name'
                                    pattern={RegExPattern.Text}
                                    required
                                />
                            ) : equip ? (
                                equip.EquipName
                            ) : (
                                ''
                            )}
                        </div>
                        <div className='ROnormaltxt col-md-2 fs-5'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtEquipPrice}
                                    placeholder={equip ? equip.EquipLastPrice : ''}
                                    onChange={(e) => {
                                        setTxtEquipPrice(e.target.value.replace(/[^0-9\.]+/g, ''))
                                        vaildateEquipmentEditEquipForm()
                                    }}
                                    ref={valEquipPriceRef}
                                    title='Equipment Price'
                                    pattern={RegExPattern.Dec}
                                    required
                                />
                            ) : equip ? (
                                <>
                                    <sup>{curSymb}</sup>
                                    {equip.EquipLastPrice.toFixed(2)}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className='ROnormaltxt col-md-4 fs-5 me-auto '>
                            {editState ? (
                                <ROComboBox
                                    dataArray={arrCategories}
                                    placeholdertext={'Category'}
                                    textVal={txtEquipCategory}
                                    myValueCallback={setTxtEquipCategory}
                                    ref={valEquipCategoryRef}
                                    required={true}
                                    validationCallBack={() => vaildateEquipmentEditEquipForm()}
                                />
                            ) : // <input
                            //     className='txtBox'
                            //     value={txtEquipCategory}
                            //     placeholder={equip.EquipCategory}
                            //     onChange={(e) =>
                            //         setTxtEquipPrice(e.target.value)
                            //     }
                            // />
                            equip ? (
                                equip.EquipCategory
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <div className='row' /*Default Parts / Items*/>
                        {/* Default Parts / Items List */}
                        {(arrDefaultParts && arrDefaultParts.length > 0) || (defs.DefS && defs.DefS.length > 0) || editState ? (
                            <>
                                <div className='row text-white m-0 p-3'>
                                    <nav>
                                        <div className='nav nav-tabs fw-normal' id='nav-tab' role='tablist'>
                                            <button
                                                className='nav-link active mx-1 '
                                                id={'nav-' + equip.EquipID + 'service-parts-tab'}
                                                data-bs-toggle='tab'
                                                data-bs-target={'#nav-' + equip.EquipID + 'service-parts'}
                                                type='button'
                                                role='tab'
                                                aria-controls={'nav-' + equip.EquipID + 'service-parts'}
                                                aria-selected='false'
                                            >
                                                Default Service Parts
                                            </button>
                                            <button
                                                className='nav-link mx-1'
                                                id={'nav-' + equip.EquipID + 'default-settings-tab'}
                                                data-bs-toggle='tab'
                                                data-bs-target={'#nav-' + equip.EquipID + 'default-settings'}
                                                type='button'
                                                role='tab'
                                                aria-controls={'nav-' + equip.EquipID + 'default-settings'}
                                                aria-selected='true'
                                            >
                                                Default Settings
                                            </button>
                                        </div>
                                    </nav>
                                    <div class='tab-content bg-primary rounded-2 p-2' id='nav-tabContent'>
                                        <div
                                            className='tab-pane fade show active'
                                            id={'nav-' + equip.EquipID + 'service-parts'}
                                            role='tabpanel'
                                            aria-labelledby={'nav-' + equip.EquipID + 'service-parts-tab'}
                                        >
                                            {arrDefaultParts && arrDefaultParts.length <= 0 ? (
                                                <div className='row m-0 px-3 ms-1'>No Default Parts</div>
                                            ) : (
                                                ''
                                            )}

                                            {arrDefaultParts.map((part, i) => (
                                                <>
                                                    <div className='row m-0 px-3 ms-1'>
                                                        <div className='col-md-2 p-0 m-0'>{part.DefaultPartCode.replace(/\\/g, '')}</div>
                                                        <div className='col-md-4 p-0 m-0'>{part.DefaultPartName.replace(/\\/g, '')}</div>
                                                        <div className='col-md-1 p-0 m-0 ms-1'>
                                                            {' '}
                                                            {/* QTY */}
                                                            {editState ? (
                                                                <>
                                                                    <input
                                                                        className='txtBox'
                                                                        value={part.DefaultPartQty || ''}
                                                                        placeholder='Qty'
                                                                        onChange={(e) =>
                                                                            updateDefaultPart(i, {
                                                                                DefaultPartQty: parseInt(e.target.value),
                                                                            })
                                                                        }
                                                                        onBlur={(e) =>
                                                                            e.target.value === null || e.target.value === ''
                                                                                ? updateDefaultPart(i, {
                                                                                      DefaultPartQty: 1,
                                                                                  })
                                                                                : true
                                                                        }
                                                                        required
                                                                    />
                                                                </>
                                                            ) : (
                                                                part.DefaultPartQty
                                                            )}
                                                        </div>
                                                        <div className='col-auto p-0 m-0 ms-auto pt-1'>
                                                            {editState ? (
                                                                <ROIcon
                                                                    icon='ROicoDel'
                                                                    size={24}
                                                                    className='icoSVG'
                                                                    tooltip='Remove'
                                                                    onClickCallback={() => removeDefaultPart(i)}
                                                                    usefill='true'
                                                                    color1='#f00'
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            ))}

                                            <div className='row'>
                                                {parts && editState ? (
                                                    <div className='col-md-12 p-0 px-3 m-0 fs-6'>
                                                        <ROComboBox
                                                            dataArray={arrParts}
                                                            classname='ROdropdownFull'
                                                            placeholdertext='Search Parts to Add'
                                                            myIDCallback={addPart}
                                                            clearTextOnSelect={true}
                                                            allowCustomValue={false}
                                                        />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className='tab-pane fade'
                                            id={'nav-' + equip.EquipID + 'default-settings'}
                                            role='tabpanel'
                                            aria-labelledby={'nav-' + equip.EquipID + 'default-settings-tab'}
                                        >
                                            <div className='row'>
                                                <div className='ROnormaltxt col-md-2 fs-5'>
                                                    {editState ? (
                                                        <>
                                                            <ROIcon
                                                                icon={defs.DefS[0] === 1 ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Hide TDS Feed'
                                                                onClickCallback={() =>
                                                                    setDefs({
                                                                        ...defs,
                                                                        DefS:
                                                                            defs.DefS[0] === 1
                                                                                ? [0, defs.DefS[1], defs.DefS[2], defs.DefS[3], defs.DefS[4]]
                                                                                : [1, defs.DefS[1], defs.DefS[2], defs.DefS[3], defs.DefS[4]],
                                                                    })
                                                                }
                                                                usefill='true'
                                                                color1='#ffffff'
                                                            />
                                                        </>
                                                    ) : defs.DefS[0] === 1 ? (
                                                        <ROIcon
                                                            icon='ROicoCheck'
                                                            className='icoSVG'
                                                            tooltip='TDS Feed'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoClose'
                                                            className='icoSVG'
                                                            tooltip='TDS Feed'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    )}
                                                    <ROIcon icon='ROicoFeed' className='icoSVG' tooltip='TDS Feed' usefill='true' color1='#ffffff' />
                                                    tds
                                                </div>
                                                <div className='ROnormaltxt col-md-2 fs-5'>
                                                    {editState ? (
                                                        <>
                                                            <ROIcon
                                                                icon={defs.DefS[1] === 1 ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Hide TDS System'
                                                                onClickCallback={() =>
                                                                    setDefs({
                                                                        ...defs,
                                                                        DefS:
                                                                            defs.DefS[1] === 1
                                                                                ? [defs.DefS[0], 0, defs.DefS[2], defs.DefS[3], defs.DefS[4]]
                                                                                : [defs.DefS[0], 1, defs.DefS[2], defs.DefS[3], defs.DefS[4]],
                                                                    })
                                                                }
                                                                usefill='true'
                                                                color1='#ffffff'
                                                            />
                                                        </>
                                                    ) : defs.DefS[1] === 1 ? (
                                                        <ROIcon
                                                            icon='ROicoCheck'
                                                            className='icoSVG'
                                                            tooltip='TDS System'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoClose'
                                                            className='icoSVG'
                                                            tooltip='TDS System'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    )}
                                                    <ROIcon icon='ROicoRO' className='icoSVG' tooltip='TDS System' usefill='true' color1='#ffffff' />
                                                    tds
                                                </div>
                                                <div className='ROnormaltxt col-md-2 fs-5'>
                                                    {editState ? (
                                                        <>
                                                            <ROIcon
                                                                icon={defs.DefS[2] === 1 ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Hide Feed Pressure'
                                                                onClickCallback={() =>
                                                                    setDefs({
                                                                        ...defs,
                                                                        DefS:
                                                                            defs.DefS[2] === 1
                                                                                ? [defs.DefS[0], defs.DefS[1], 0, defs.DefS[3], defs.DefS[4]]
                                                                                : [defs.DefS[0], defs.DefS[1], 1, defs.DefS[3], defs.DefS[4]],
                                                                    })
                                                                }
                                                                usefill='true'
                                                                color1='#ffffff'
                                                            />
                                                        </>
                                                    ) : defs.DefS[2] === 1 ? (
                                                        <ROIcon
                                                            icon='ROicoCheck'
                                                            className='icoSVG'
                                                            tooltip='Feed Pressure'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoClose'
                                                            className='icoSVG'
                                                            tooltip='Feed Pressure'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    )}

                                                    <ROIcon
                                                        icon='ROicoFPres'
                                                        className='icoSVG'
                                                        tooltip='Feed Pressure'
                                                        usefill='true'
                                                        color1='#ffffff'
                                                    />
                                                    {pType === 'PSI' ? 'psi' : 'bar'}
                                                </div>
                                                <div className='ROnormaltxt col-md-2 fs-5'>
                                                    {editState ? (
                                                        <>
                                                            <ROIcon
                                                                icon={defs.DefS[3] === 1 ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Hide Tank Pressure'
                                                                onClickCallback={() =>
                                                                    setDefs({
                                                                        ...defs,
                                                                        DefS:
                                                                            defs.DefS[3] === 1
                                                                                ? [defs.DefS[0], defs.DefS[1], defs.DefS[2], 0, defs.DefS[4]]
                                                                                : [defs.DefS[0], defs.DefS[1], defs.DefS[2], 1, defs.DefS[4]],
                                                                    })
                                                                }
                                                                usefill='true'
                                                                color1='#ffffff'
                                                            />
                                                        </>
                                                    ) : defs.DefS[3] === 1 ? (
                                                        <ROIcon
                                                            icon='ROicoCheck'
                                                            className='icoSVG'
                                                            tooltip='Tank Pressure'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoClose'
                                                            className='icoSVG'
                                                            tooltip='Tank Pressure'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    )}
                                                    <ROIcon
                                                        icon='ROicoTpres'
                                                        className='icoSVG'
                                                        tooltip='Tank Pressure'
                                                        usefill='true'
                                                        color1='#ffffff'
                                                    />
                                                    {pType === 'PSI' ? 'psi' : 'bar'}
                                                </div>
                                                <div className='ROnormaltxt col-md-2 fs-5'>
                                                    {editState ? (
                                                        <>
                                                            <ROIcon
                                                                icon={defs.DefS[4] === 1 ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Hide Controls'
                                                                onClickCallback={() =>
                                                                    setDefs({
                                                                        ...defs,
                                                                        DefS:
                                                                            defs.DefS[4] === 1
                                                                                ? [defs.DefS[0], defs.DefS[1], defs.DefS[2], defs.DefS[3], 0]
                                                                                : [defs.DefS[0], defs.DefS[1], defs.DefS[2], defs.DefS[3], 1],
                                                                    })
                                                                }
                                                                usefill='true'
                                                                color1='#ffffff'
                                                            />
                                                        </>
                                                    ) : defs.DefS[4] === 1 ? (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            className='icoSVG'
                                                            tooltip='Controls'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoClose'
                                                            className='icoSVG'
                                                            tooltip='Controls'
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    )}
                                                    Controls
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <div className='container p-2 pt-0' onClick={() => setToggleState((prev) => !prev)}>
                    <div className='row' /*EquipInfo Compact*/>
                        <div className='ROmaintxt col-md-2 pl-2 fs-3'>{equip ? equip.EquipCode : ''}</div>
                        <div className='ROnormaltxt col-md-4 fs-5'>{equip ? equip.EquipName : ''}</div>
                        <div className='ROnormaltxt col-md-2 fs-5'>
                            {equip ? (
                                <>
                                    <sup>{curSymb}</sup>
                                </>
                            ) : (
                                ''
                            )}
                            {equip ? equip.EquipLastPrice.toFixed(2) : ''}
                        </div>
                        <div className='ROnormaltxt col-md-3 fs-5 me-auto '>{equip ? equip.EquipCategory : ''}</div>
                        {/*Interface Buttons*/}
                        <div className='col-auto me-auto px-0'></div>
                        {/*Toggle Icon*/}
                        <div className='col-auto px-2'>
                            {/*Expande/Collapse Icon*/}
                            {editState ? '' : <ROIcon icon='ROicoXDown' className='icoSVG' />}
                        </div>
                    </div>
                </div>
            )}
        </ContentBox>
    )
}

export default EquipItem
