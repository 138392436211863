import axios from 'axios'

const API_URL = '/api/users/'

//Register User
// const register = async (userData) => {
//     const response = await axios.post(API_URL, userData)

//     console.log('Login User API response: ', response)

//     return response.data
// }

//Login User
const login = async (userLoginData) => {
    const response = await axios.post(API_URL + 'login', userLoginData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    console.log('User logged in: ', response.data)

    return response.data
}

// Logout user by removing token from localStorage
const logout = async () => {
    //console.log('authService logout remove user')
    localStorage.removeItem('user')
    return true
}

//Forgot Password
const forgotpassword = async (email) => {
    //console.log('authService userForgotPassword Email: ' + email)
    const response = await axios.get('/api/reset-password/email/' + email)
    return response.data
}

const sendforgotpasswordtoken = async (token) => {
    //console.log('authService sendforgotpasswordtoken token: ' + token)
    const response = await axios.get('/api/reset-password/reset-token/' + token)
    return response.data
}

const sendservicetoken = async (token) => {
    //console.log('authService sendservicetoken token: ' + token)
    const response = await axios.get('/api/client/service-token/' + token)
    return response.data
}

//Register New Account Password
const registernewaccount = async (newAccountData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    console.log('authService registernewaccount newAccountData: ', newAccountData)
    const response = await axios.post('/api/register-new/createnewaccount', newAccountData, headersConfig)
    //const response = await axios.get('/reset-password/email/' + email)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}

const registernewaccountemail = async (userData) => {
    // Get user from localStorage
    // const user = JSON.parse(localStorage.getItem('user'))

    // userData = { ...userData, user: { ...user } }

    const response = await axios.post('/api/register-new/newaccountemail', userData)

    console.log('registernewaccountemail response: ', response)

    return response.data
}

const sendregisternewaccounttoken = async (token) => {
    console.log('authService sendregisternewaccounttoken token: ' + token)

    const response = await axios.get('/api/register-new/newacc-token/' + token)
    return response.data
}

const authService = {
    registernewaccount,
    logout,
    login,
    forgotpassword,
    sendforgotpasswordtoken,
    registernewaccountemail,
    sendregisternewaccounttoken,
    sendservicetoken,
}

export default authService
