import axios from 'axios'

const API_URL = '/api/parts/'

//Add new customer
const createPart = async (partData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    partData = { ...partData, user: { ...user } }

    const response = await axios.post(API_URL, partData, headersConfig)
    return response.data
}

const updatePart = async (partData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    partData = { ...partData, user: { ...user } }

    const response = await axios.put(API_URL + partData.partID, partData, headersConfig)

    return response.data
}

//Get Parts
const getParts = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig)

        return response.data
    } catch (error) {
        console.log('partsService getParts response: ', error.response)
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }
        throw new Error('partsService(getParts): ' + error.response.data.message)
    }
}

//Delete Part
const deletePart = async (partID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + partID, headersConfig)

    return response.data
}

const partsService = {
    createPart,
    getParts,
    deletePart,
    updatePart,
}

export default partsService
