import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset, isError, message } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { addROErrorLog } from '../features/globalFunctions'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'

const Support = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, isError, message } = useSelector((state) => state.auth)

    useEffect(() => {
        if (isError) {
            console.log('useEffect error: ', message)
            toast.error(message)
            addROErrorLog('Support Error:  ' + message, user ? user.name : '', 'Support')
            dispatch(reset())
            navigate('/')
            return
        }

        // if (!user || !user._id || !user.token) {
        //     console.log('User error!!! log out: ', user)
        //     addROErrorLog('Support User Error:  ' + message, user ? user.name : '', 'Support')
        //     dispatch(logout())
        //     dispatch(reset())
        //     navigate('/')
        // }

        try {
            console.log('Get All Data from servers')
            //dispatch(getAll())
        } catch (error) {
            console.log('Support.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }
    }, [user, isError])
    //[user, isError, isCustError, isLocError, isErrorLocContacts]) //dispatch

    return (
        <>
            <section className='pageHead container'>
                <div className='pageFunctions row header sticky-top py-3'>
                    {/* <h6>Welcome {user && user.name}</h6> */}
                    <div className='col-md-4'>
                        <h4>Support &nbsp;</h4>
                    </div>
                </div>
                <div className='row'>
                    <p>
                        Please email us with any questions or help you need. <br />
                        <a href='mailto:info@rovantage.com' className='m-0 p-0 text-primary'>
                            info@rovantage.com
                        </a>
                        <br />
                        <br />
                        {user && user.type >= 0 ? (
                            <>
                                For any Bugs, Problems or Technical support please email us at: <br />{' '}
                                <a href='mailto:support@rovantage.com' className='m-0 p-0 text-primary'>
                                    support@rovantage.com
                                </a>
                            </>
                        ) : (
                            ''
                        )}
                    </p>
                </div>

                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container'></div>
        </>
    )
}

export default Support
