export const UserRoles = [
    { authlvl: 0, title: 'Owner' },
    { authlvl: 5, title: 'Full Access-1' },
    { authlvl: 10, title: 'Power User-2' },
    { authlvl: 20, title: 'Basic User-3' },
    { authlvl: 21, title: 'Read Only-4' },
    { authlvl: 99, title: 'Public' },
]

export const MaintenanceTypes = [
    { id: 0, title: 'Service Successful', titleFail: 'Service Failed', icon: 'ROicoService', type: 'Service' },
    { id: 1, title: 'Repair Successful', titleFail: 'Repair Failed', icon: 'ROicoRepair', type: 'Repair' },
]

export const RegExPattern = {
    Text: '[a-zA-Z0-9@ \\(\\),.!&*\\/_"\'-\\[\\]]+',
    Phone: '[0-9 +()-]+',
    Int: '[0-9]+',
    Dec: '^[0-9]\\d*(.\\d+)?$',
    Email: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
}

export const ServiceEmailMsg = `Dear [CustomerName],
We wanted to notify you of equipment due for a scheduled service soon.

Service Details:

Equipment: [Equipment]
Service Due Date: [ServiceDate]

[Link]

Our team will contact you soon to schedule a convenient service appointment. Ensuring your equipment receives regular maintenance is essential to its longevity and performance. We appreciate your business and trust in our services.

If you have any questions or need to reschedule the service, please don't hesitate to contact our customer support team at [ReplyToEmail].

Thank you for choosing [YourCompanyName] for your equipment servicing needs.

Sincerely,

[YourName]
[YourCompanyName]
[YourTelephone]
[YourEmail]`

// const textPattern = '[a-zA-Z0-9@ (),.!&*/_"\'-\\[\\]]+'
// const phonePattern = '[0-9 +()-]+'
// const datePattern = 'd{4}-d{2}-d{2}'
// const datePattern = /\d{4}\-\d{2}\-\d{2}/
// const intPattern = '[0-9]+'
// const decPattern = '^[0-9]\\d*(.\\d+)?$'
