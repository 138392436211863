import ROIcon from '../ROIcon'
const ROHelpParts = () => {

    return (
        <>
            <div className="row p-0 m-0">

                <div class="contianer ps-2 ">
                    <a href="#Overview" class="bs-linebreak fw-bold">Parts Overview|</a>
                    &emsp;
                    <a href="#Add-Customer" class="bs-linebreak fw-bold">Add Parts|</a>
                    &emsp;
                    <a href="#Search" class="bs-linebreak fw-bold">Search|</a>
                    &emsp;
                    <a href="#Product-Details" class="bs-linebreak fw-bold ">Product Details</a>
                </div>

                <div class="container" id="DOverview">
                    <br />
                    <h2>Parts Page Overview</h2>
                    <img src="./images/help-imges/parts-img.jpg" height={80} alt="img" />
                    <p> The parts page consists of various parts and
                        their information from Rovantage. Users are able to edit
                        and delete the information and also add nuwe parts.


                    </p>
                </div>
                <div class="container" id="Add-Customer">
                    <h3>Add New Parts [<ROIcon icon='ROicoAdd' size={26} className='icoSVG ' />]</h3>
                    <p>The plus icon next to the "Your Parts" heading.
                        This icon is used when we want to add a parts, when click
                        under the heading is where they will
                        be able to fill in needed information.</p>
                </div>
                <div class="container" id="Search">
                    <h2>Search[<i class="fas fa-search"></i>]</h2>
                    <p>The search box next to the plus icon.
                        The search box is used when we want to search for specific a part in the list of existing parts,
                        you can use the abbreviation or name to search for parts.</p>
                </div>
                <div class="container" id="Product-Details">
                    <h2> Product Details:</h2>
                    <p>
                        This area is where we fill in information about Parts, as following:<br />
                        <strong class="ps-3">1. Product Code</strong><br />
                        Product code is an abbreviation of the parts name and is a way of uniquely identifying the the parts.
                        <div class="ps-5">
                            - [] Product Code
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">2. Product Name:</strong><br />
                        This is were the name of the part will be enter<br />
                        <div class="ps-5">
                            - [] Product Name
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">3. Latest Price:</strong><br />
                        The latest price give user the exact price of a part.<br />
                        <div class="ps-5">
                            - [] Price
                        </div>
                    </p>
                    
                        <strong class="ps-3">4. Category:</strong>
                        The category is where user are ale to under which category the part fall under.<br />
                        <div class="ps-5">
                            - [] Category<br />
                        </div>
                        
                            <p>
                                <h4>Actions:</h4>
                                <strong class="ps-3">5. Save Information:</strong><br />
                                The check icon, when click the information that was filled in will be saved.<br />
                                <div class="ps-5">
                                    - [<ROIcon icon='ROicoAccept' size={26} className='icoSVG ' />] Save <i class="fas fa-check"></i> <br />
                                </div>
                            </p>
                            <p>
                                <strong class="ps-3">6. Delete/Cancel:</strong><br />
                                The times icon, when click the information that was filled in will be Delete/Cancel.<br />
                                <div class="ps-5">
                                    - [<ROIcon icon='ROicoCancel' size={26} className='icoSVG ' />] Delete/Cancel <i class="fas fa-times"></i>
                                </div>
                            </p>
                        </div>

                </div>
            
            
        </>

    )
}

export default ROHelpParts
