import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { RegExPattern, UserRoles } from '../features/globalVars'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROHelpBox from '../components/ROHelpBox.jsx'
import ROComboBox from '../components/ROComboBox'
import { VaildateForm } from '../features/globalFunctions'
import { addROErrorLog } from '../features/globalFunctions'
import ROPassword from '../components/ROPassword'

//Import Franchise User Redux State/Global Var and Axiox database calls
import { createUser, getUsers, deleteUser, resetusers } from '../features/users/usersSlice'
import UserItem from '../components/UserItem'

const Users = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tel: '',
        usertype: 99,
    })

    const [toggleShowSuspended, setShowSuspended] = useState(false)

    const { name, email, tel, usertype } = formData

    const { user } = useSelector((state) => state.auth)

    const [showNewUser, setShowNewUser] = useState(false)
    const [boolUserPassValidated, setUserPassValidated] = useState(false)
    const [txtUserPass, setTxtUserPass] = useState('')

    //Get State users from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } = useSelector((state) => state.franusers)

    useEffect(() => {
        //Error Handeling Check 22 Nov 2022
        if (isFUsersError) {
            console.log('Users useEffect error: ', franusersmessage)
            toast.error(franusersmessage)
            addROErrorLog('Users useEffect Error:  ' + franusersmessage, user ? user.name : '', 'Users')
            dispatch(resetusers())
            navigate('/')
            return
        }

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }

        try {
            console.log('Get All Data from servers')
            dispatch(getUsers())
        } catch (error) {
            console.log('Template.jsx Dispatch(all) Error: ', error)
            addROErrorLog('Users Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'Users')
            toast.error(error)
            return () => dispatch(reset())
        }
    }, [isFUsersError])
    //Error Handeling Check 22 Nov 2022 added isFUsersError use effect check

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const comboBoxSetRole = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            usertype: value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (!boolUserPassValidated) {
            toast.error('Passwords invalid')
        } else {
            const userData = {
                name,
                email,
                tel,
                franid: user.franid,
                usertype,
                password: txtUserPass,
            }

            //Sends userData to authSlice -> import { register, reset } from '../features/auth/authSlice'
            dispatch(createUser(userData))

            clearForm()
        }
    }

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            tel: '',
            usertype: 99,
        })

        setTxtUserPass('')

        setShowNewUser(false)
    }

    //Account Password

    const getUserPassword = (newPass) => {
        if (newPass && newPass !== '') {
            console.log('New Pass: ', newPass)
            setTxtUserPass(newPass)
            setUserPassValidated(true)
        } else {
            setUserPassValidated(false)
        }
    }

    //Delete User
    const [confirmDelete, setDeleteState] = useState(false)
    const [deleteUserObj, setDeleteUserObj] = useState({})

    const confirmDeleteUser = (setState) => {
        if (confirmDelete && setState && deleteUserObj.usertype > 1 && deleteUserObj._id !== user._id) {
            //console.log('Delete!!!! UserID: ', deleteUserObj._id)
            deleteUserById(deleteUserObj._id)
        } else if (deleteUserObj._id === user._id) {
            //console.log('Cannot Delete Self')
        } else if (deleteUserObj.usertype <= 1) {
            //console.log('Cannot Delete Owner')
        }
        setDeleteState(setState)
    }

    const deleteUserById = (_id) => {
        //console.log('Delete!!!! User_ID: ', _id)
        dispatch(deleteUser(_id))
        setDeleteUserObj({})
        confirmDeleteUser(false)
    }

    const deleteUserCall = (userToDelete) => {
        console.log('deleteUserCall: ', userToDelete)
        setDeleteUserObj(userToDelete)
        confirmDeleteUser(true)
    }

    //User Roles
    let arrRoles = []
    if (UserRoles) {
        try {
            arrRoles = Array.from(UserRoles)
            //console.log('arrRoles: ', arrRoles)
            arrRoles = arrRoles.map((item, i) => {
                return { Text: item.title, ID: item.authlvl }
            })

            arrRoles.shift()
            arrRoles.pop()

            //console.log('arrCategories: ', arrRoles)
        } catch (error) {
            console.log('Users arrRoles Error: ', error)

            toast.error('Users arrRoles Error: ' + error.toString())
            addROErrorLog('Users arrRoles Error:  ' + error.toString(), user ? user.name : '', 'Users')
        }
    }

    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //#region Edit User Form Validation
    const [boolUserFormValidated, setUserFormValidated] = useState(false)

    const valUserNameRef = useRef()
    const valUserEmailRef = useRef()
    const valUserContactNumRef = useRef()
    const valUserRoleRef = useRef()

    const vaildateNewUserForm = (clicked = false) => {
        if (!showNewUser) return

        if (!boolUserPassValidated) {
            if (clicked) {
                toast.error('Invalid Password')
            }
            console.log('Invalid Password')
            setUserFormValidated(false)
            return
        }

        if (showNewUser) {
            setUserFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valUserNameRef,
                            text: 'No Special Characters',
                            form: 'New User valUserNameRef',
                        },
                        {
                            ref: valUserEmailRef,
                            text: 'Enter Valid Email',
                            form: 'New User valUserEmailRef',
                        },
                        {
                            ref: valUserContactNumRef,
                            text: 'No Special Characters',
                            form: 'New User valUserContactNumRef',
                        },
                        {
                            ref: valUserRoleRef,
                            text: 'User Role Required',
                            form: 'New User valUserRoleNumRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    let suspendedCount = 0

    return (
        <>
            <div className='bg-welcome'>
            <section className='pageHead container clearfix pb-2 '>
            <div className='pageFunctions row'>
                    <div className='col-md-3 pt-2 text-center'>
                        <h4>
                            Your Users&nbsp;
                            <ROIcon
                                icon={showNewUser ? 'ROicoCancel' : 'ROicoPlus'}
                                size={24}
                                className='icoSVG'
                                textbefore={showNewUser ? 'Cancel' : ' '}
                                tooltip={showNewUser ? 'Cancel' : 'Add User'}
                                onClickCallback={showNewUser ? () => clearForm() : () => setShowNewUser((prev) => !prev)}
                                textafter={franusers === null || (!showNewUser && franusers.length <= 1) ? '<-Click' : ''}
                                authlvl={5}
                                style={{
                                    animation: 'pulse 4s infinite',
                                }}
                            />
                        </h4>
                    </div>
                    <div className='interfSearch align-middle col-md-4 me-auto p-0'></div>
                    <div className='align-middle col-auto pt-2 pe-4'>
                        <ROHelpBox helpTopic={'user'} />
                        {/* <ROIcon icon='ROicoUp' size={24} className='icoSVG' tooltip='Collapse All' onClickCallback={() => CollapseAll()} /> */}
                        {/* <ROIcon icon='ROicoHelp' size={24} className='icoSVG' tooltip='Help' /> */}
                    </div>

                    {showNewUser ? (
                        <div className='content border border-primary bg-light'>
                            <section className='form py-2'>
                                <form onSubmit={onSubmit}>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='name'
                                            name='name'
                                            value={name}
                                            placeholder='Enter user name'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserNameRef}
                                            title='User Name'
                                            pattern={RegExPattern.Text}
                                            required
                                        />
                                    </div>
                                    <div className='form-group  pt-1'>
                                        <input
                                            type='email'
                                            className='form-control'
                                            id='email'
                                            name='email'
                                            value={email}
                                            placeholder='Enter user email'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserEmailRef}
                                            title='User Email'
                                            required
                                        />
                                    </div>
                                    {/* <div className='form-group'>
                                        <input
                                            type='password'
                                            className='form-control'
                                            id='password'
                                            name='password'
                                            value={password}
                                            placeholder='Enter user password'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserPasswordRef}
                                            title='User Password'
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input
                                            type='password'
                                            className='form-control'
                                            id='password2'
                                            name='password2'
                                            value={password2}
                                            placeholder='Confirm password'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserConfirmPasswordRef}
                                            title='User Confirm Password'
                                            required
                                        />
                                    </div> */}
                                    <div className='form-group  pt-1'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='tel'
                                            name='tel'
                                            value={tel}
                                            placeholder='Enter contact number'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserContactNumRef}
                                            title='Contact Number'
                                            pattern={RegExPattern.Phone}
                                            required
                                        />
                                    </div>
                                    <div className='form-group m-3'>
                                        <ROPassword validationCallBack={getUserPassword} password1={txtUserPass} password2={txtUserPass} />
                                    </div>

                                    <div className='form-group p-1'>
                                        <ROComboBox dataArray={arrRoles} classname='ROdropdownFull p-0 m-0' placeholdertext='User Role' myIDCallback={comboBoxSetRole} ref={valUserRoleRef} required={true} validationCallBack={() => vaildateNewUserForm()} allowCustomValue={false} allowFilter={false} />
                                    </div>
                                    <div className='form-group'>
                                        {boolUserFormValidated ? (
                                            <button type='submit' className='btn btn-success btn-block'>
                                                Submit
                                            </button>
                                        ) : (
                                            <button type='button' className='btn btn-danger btn-block' onClick={() => vaildateNewUserForm(true)}>
                                                Submit
                                            </button>
                                        )}
                                        <button type='button' className='btn btn-danger btn-block ms-2' onClick={() => clearForm()}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </section>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </section>
            </div>
                
            
            <div className='pageContent container pt-2'>
                <div className={!showNewUser ? 'pageFunctions bg-light py-3' : 'pageFunctions bg-light py-3'}>
                    <ROIcon
                        icon={toggleShowSuspended ? 'ROicoCheckbox' : 'ROicoBox'}
                        className='icoSVG p-0 m-0'
                        size={32}
                        authlvl={15}
                        onClickCallback={() => setShowSuspended((prev) => !prev)}
                    />
                    Show Suspended &nbsp;
                </div>
                {franusers.map((userObj, i) => {
                    if ((user.type > 0 && userObj.usertype > 0 && !toggleShowSuspended && userObj.usersuspended !== 1) || (user.type > 0 && userObj.usertype > 0 && toggleShowSuspended && userObj.usersuspended === 1) || (user.type === 0 && !toggleShowSuspended && userObj.usersuspended !== 1) || (user.type === 0 && toggleShowSuspended && userObj.usersuspended === 1)) {
                        return (
                            <div>
                                <UserItem userData={userObj} arrRoles={arrRoles} containerBackColor={(i - suspendedCount + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'} collapse={getCollapseAll} />
                            </div>
                        )
                    } else {
                        suspendedCount++
                    }
                })}
            </div>
        </>
    )
}

export default Users
