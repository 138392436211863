import { toast } from 'react-toastify'
import { formatTime, formatShortDate } from './formatTime'

export const getServiceInterval = (data, equipcustlocitem) => {
    let txtInterval = ''
    if (data.EquipCustLocServiceIntervalType > 30) {
        txtInterval = 'Year'
    } else if (data.EquipCustLocServiceIntervalType > 7) {
        txtInterval = 'Months'
    } else if (data.EquipCustLocServiceIntervalType > 1) {
        txtInterval = 'Weeks'
    } else {
        txtInterval = 'Days'
    }

    return data.EquipCustLocDaysTillNextService / data.EquipCustLocServiceIntervalType + ' ' + txtInterval + ' Interval'
}

export const daysDiff = (date_1, date_2) => {
    let date1 = new Date(date_1)
    let date2 = new Date(date_2)

    // let difference = date1.getTime() - date2.getTime()
    // let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
    // return TotalDays

    let date1mil = date1.getTime()
    let date2mil = date2.getTime()

    let difference = date1mil - date2mil

    let TotalDays = difference / 86400000

    return Math.ceil(TotalDays)
}

export const addROErrorLog = (msg, user, pagesrc) => {
    let errorLog = JSON.parse(localStorage.getItem('ROerrorLog'))

    if (errorLog && errorLog.length > 100) {
        console.log('Error log to long removing first entry: ', errorLog[0])
        errorLog.shift()
    }

    let foundItem = []

    if (errorLog && errorLog.length > 0) {
        foundItem = errorLog.find((e) => e.msg === msg && e.user === user && e.src === pagesrc)
    } else {
        errorLog = []
    }

    if (foundItem && foundItem.count >= 1) {
        //console.log('foundItem: ', foundItem)
        foundItem.count++
        foundItem.DateTime = formatTime(Date.now())
    } else {
        let newLogEntry = {
            msg: msg,
            DateTime: formatTime(Date.now()),
            user: user,
            src: pagesrc,
            count: 1,
        }

        console.log('before push errorLog: ', errorLog)
        errorLog.push(newLogEntry)
    }

    console.log('errorLog: ', errorLog)
    localStorage.setItem('ROerrorLog', JSON.stringify(errorLog))
}

//#region Validation

export const VaildateForm = (refArray, clicked = false) => {
    try {
        let validForm = true

        //console.log('refArray: ', refArray)

        if (refArray && refArray.length > 0) {
            refArray.forEach((item) => {
                if (item.ref.current) {
                    //console.log('Validating item: ', item)
                    validForm = ValidateFormObj(item.ref, clicked, item.text) ? validForm : false
                } else if (!item.ref.current && item.text !== '') {
                    // console.log(
                    //     'Incorrect element passed for validation refArray: ',
                    //     item,
                    //     refArray
                    // )
                    toast.error('VaildateForm(globalFunction): ' + item.form + ' Error: Incorrect element passed for validation')
                    addROErrorLog('VaildateForm: ' + item.form + ' Error: Incorrect element passed for validation', '', 'GlobalFunctions')
                    validForm = false
                }
            })
        }

        //console.log('VaildateForm return: ', validForm)

        return validForm
    } catch (error) {
        console.log('globalFunctions.js(vaildateServiceForm): ', error)
        toast.error('globalFunctions.js(vaildateServiceForm): ' + error)
    }
}

const ValidateFormObj = (formObj, clicked, customMsg = '') => {
    //console.log('validateObj: ', formObj.current.readOnly)

    const isReadOnly = formObj.current.readOnly

    formObj.current.readOnly = false

    let titleTxt = formObj.current.title ? formObj.current.title + ': ' : ''

    if (!formObj.current.validity.valid) {
        if (clicked) {
            if (formObj.current.validity.badInput) {
                toast.error(titleTxt + formObj.current.validationMessage)
                addROErrorLog('ValidateFormObj: ' + titleTxt + formObj.current.validationMessage, '', 'GlobalFunctions')
            } else if (formObj.current.validity.valueMissing) {
                toast.error(titleTxt + 'Required')
                addROErrorLog('ValidateFormObj: ' + titleTxt + 'Required', '', 'GlobalFunctions')
            } else {
                if (customMsg !== '') {
                    toast.error(titleTxt + customMsg)
                    addROErrorLog('ValidateFormObj: ' + titleTxt + titleTxt + customMsg, '', 'GlobalFunctions')
                } else {
                    toast.error(titleTxt + formObj.current.validationMessage)
                    addROErrorLog('ValidateFormObj: ' + titleTxt + formObj.current.validationMessage, '', 'GlobalFunctions')
                }
            }
        }
        formObj.current.readOnly = isReadOnly
        return false
    }
    formObj.current.readOnly = isReadOnly
    return true
}

export const JSONtoHtml = (data) => {
    try {
        console.log('JSONtoHtml data: ', data)

        let jsonObj = data

        try {
            jsonObj = JSON.parse(data)
            console.log('JSONtoHtml JSON.parse(data): ', JSON.parse(data))
        } catch (error) {}

        // console.log('JSONtoHtml jsonObj: ', jsonObj)
        // console.log('JSONtoHtml keys: ', Object.keys(jsonObj))
        // console.log('JSONtoHtml values: ', Object.values(jsonObj))
        // console.log('JSONtoHtml entries: ', Object.entries(jsonObj))

        const mainUL = document.createElement('ul')

        Object.keys(jsonObj).forEach((key) => {
            console.log('*************************')
            console.log('START ITEM')
            console.log('key: ', key)
            //console.log(key, ':', jsonObj[key])
            let itemLI = document.createElement('li')

            itemLI = JSONtoHtmlAddSubKeys(itemLI, key, jsonObj[key])

            mainUL.append(itemLI)
        })

        return mainUL
    } catch (error) {
        console.log('globalFunctions.js(JSONtoHtml): ', error)
        toast.error('globalFunctions.js(JSONtoHtml): ' + error)
    }
}

const JSONtoHtmlAddSubKeys = (item, key, subItemData) => {
    console.log('JSONtoHtmlAddSubKeys item: ', item)
    console.log('JSONtoHtmlAddSubKeys key: ', key)
    console.log('JSONtoHtmlAddSubKeys subItemData: ', subItemData)

    const subItemUL = document.createElement('ul')
    if (!subItemData[0]) {
        item.innerHTML = key

        Object.keys(subItemData).forEach((subKey) => {
            // console.log(
            //     'JSONtoHtmlAddSubKeys subItemData[subKey]: ',
            //     subItemData[subKey]
            // )
            let subItemLI = document.createElement('li')
            if (!subItemData[subKey][0]) {
                subItemLI = JSONtoHtmlAddSubKeys(subItemLI, subKey, subItemData[subKey])
            } else {
                subItemLI.innerHTML = subKey + ': ' + subItemData[subKey]
            }
            subItemUL.append(subItemLI)
        })
        item.append(subItemUL)
    } else {
        //console.log('ELSE subItemData[0]: ', subItemData[0])
        if (!subItemData[0][0]) {
            item.innerHTML = key
            //item.innerHTML = key + ': ' + JSON.stringify(subItemData)
            Object.keys(subItemData).forEach((subKey) => {
                let subItemLI = document.createElement('li')
                if (!subItemData[subKey][0]) {
                    subItemLI = JSONtoHtmlAddSubKeys(subItemLI, subKey, subItemData[subKey])
                } else {
                    subItemLI.innerHTML = subKey + ': ' + subItemData[subKey]
                }
                subItemUL.append(subItemLI)
            })
            item.append(subItemUL)
        } else {
            console.log('ELSE subItemData[0][0]: ', subItemData[0][0])
            item.innerHTML = key + ': ' + subItemData
        }
    }

    return item
}

export const getServicesReportArray = (
    services = null,
    franacc = null,
    loccustomers = null,
    equipcustloc = null,
    custloccontacts = null,
    equipment = null,
    toggleShowUnsucessfull = false,
    toggleShowServices = true,
    toggleShowRepairs = false,
    toggleAllRecentServices = true, //Show only first 3 items
    toggleShowSuspended = false,
    toggleShowAllCust = false, //Show all customers even with no overdue services
    toggleShowUpcomingServices = false
) => {
    let arrReport = []
    const todayDate = new Date()
    const thisMonthEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)
    const nextMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 2, 0)

    try {
        if (services && franacc && loccustomers && equipcustloc && custloccontacts && equipment && services.length > 0 && Object.values(franacc).length > 0 && loccustomers.length > 0 && equipcustloc.length > 0 && custloccontacts.length > 0 && equipment.length > 0) {
            services.map((item, i) => {
                // if (!toggleAllRecentServices && item.ServiceSuccessful !== 1) {
                //     return null
                // }
                if (!toggleShowUnsucessfull && item.ServiceSuccessful !== 1) {
                    return null
                }

                if (!toggleShowServices && item.ServiceType === 0) {
                    return null
                }

                if (!toggleShowRepairs && item.ServiceType === 1) {
                    return null
                }

                const pType = getFranSettingsJSON(franacc, 'Bar') ? getFranSettingsJSON(franacc, 'Bar') : 'Bar'

                let serItem = { ...item }

                if (pType === 'PSI') {
                    serItem.ServiceFeedPressure = (serItem.ServiceFeedPressure * 14.5).toFixed(1)
                    serItem.ServiceTankPressure = (serItem.ServiceTankPressure * 14.5).toFixed(1)
                }

                //Check if a service is already 'logged' added to the report for this location equimpent
                let tempItem = arrReport.find((e) => e.EquipCustLocID === item.EquipCustLocID)

                let lastServiceDate = item.ServiceSuccessful === 1 && item.ServiceType === 0 ? item.ServiceDate : 0

                if (tempItem) {
                    //A Equipment on Location with a service is already added to the array
                    //Just update the array with new service info

                    // console.log(
                    //     'Already added to array: ',
                    //     arrServOver,
                    //     '  tempItem: ',
                    //     tempItem
                    // )

                    if (tempItem.equipServices.length < 3 || toggleAllRecentServices) {
                        tempItem.equipServices.push(item)
                        if (tempItem.equipServices.length === 1 && (item.ServiceSuccessful === 1 || tempItem.LastServiceRecorded === 0)) {
                            tempItem.LastServiceRecorded = lastServiceDate
                        }

                        //shifted when the services was ascending by date but we changes it to descending by date in die backend
                        //tempItem.equipServices.shift()
                    }

                    //Double check last service date for in case a new service was deleted and the EquipCustLoc Last Service date didn't update correctly... (need to fix)
                } else {
                    let locItem = loccustomers.find((e) => e.CustLocID === item.CustLocID)
                    //console.log('tempItem: ', tempItem, '  locItem: ', locItem)

                    //locItem Customers Joined CustLocations
                    if (locItem) {
                        if ((locItem.CustSuspended !== 1 && !toggleShowSuspended) || (locItem.CustSuspended === 1 && toggleShowSuspended) || (toggleShowSuspended && toggleShowAllCust)) {
                            let equipItem = equipcustloc.find((e) => e.EquipCustLocID === item.EquipCustLocID)
                            let contacts = custloccontacts.filter((e) => {
                                if (e.CustLocID === item.CustLocID) {
                                    return e
                                } else {
                                    return null
                                }
                            })

                            if (!equipItem.EquipID) {
                                console.log('***********  Error equipItem.EquipID is undefined/null equipItem: ', equipItem)
                            }

                            let equipInfo = equipment.find((e) => e.EquipID === equipItem.EquipID)

                            //Parse Equipment Flags

                            const flags = equipInfo.EquipFlags && equipInfo.EquipFlags !== '' ? JSON.parse(equipInfo.EquipFlags) : ''

                            equipInfo = { ...equipInfo, EquipFlags: { ...flags } }

                            //console.log('equipInfo: ', equipInfo)

                            // let equipInfo = equipment.find(
                            //     (e) =>
                            //         e.EquipID ===
                            //         (equipItem.EquipID ? equipItem.EquipID : -1)
                            // )

                            //console.log('contacts: ', contacts)

                            arrReport.push({
                                EquipCustLocID: item.EquipCustLocID,
                                CustID: item.CustID,
                                CustLocID: item.CustLocID,
                                EquipID: item.EquipID,
                                ServiceType: item.ServiceType,
                                CustName: locItem.CustName,
                                CustLocName: locItem.CustLocName,
                                CustLocStreetAddress: locItem.CustLocStreetAddress,
                                CustLocSuburb: locItem.CustLocSuburb,
                                CustLocCity: locItem.CustLocCity,
                                CustLocPostalCode: locItem.CustLocPostalCode,
                                CustLocCountry: locItem.CustLocCountry,
                                CustLocNotes: locItem.CustNotes,
                                CustLocFlags: locItem.CustLocFlags,
                                CustSuspended: locItem.CustSuspended,
                                CustPriContactName: contacts && contacts.length > 0 ? (contacts.find((cont) => cont.CustLocContactPrimary === 1) ? contacts.find((cont) => cont.CustLocContactPrimary === 1).CustLocContactName : contacts.find((cont) => cont.CustLocContactPrimary !== 1).CustLocContactName) : 'None',
                                // : console.log(
                                //       'contacts empty: ',
                                //       custloccontacts,
                                //       contacts
                                //   ),
                                CustPriContactEmail: contacts && contacts.length > 0 ? (contacts.find((cont) => cont.CustLocContactPrimary === 1) ? contacts.find((cont) => cont.CustLocContactPrimary === 1).CustLocContactEmail : contacts.find((cont) => cont.CustLocContactPrimary !== 1).CustLocContactEmail) : 'None',
                                CustPriContactNumber: contacts && contacts.length > 0 ? (contacts.find((cont) => cont.CustLocContactPrimary === 1) ? contacts.find((cont) => cont.CustLocContactPrimary === 1).CustLocContactNumber : contacts.find((cont) => cont.CustLocContactPrimary !== 1).CustLocContactNumber) : 'None',
                                LastServiceRecorded: lastServiceDate,
                                EquipCustLoc: equipItem,
                                Contacts: contacts,
                                EquipInfo: equipInfo,
                                equipServices: [serItem],
                            })
                        }
                    }
                }
                //return null
            })

            //console.log('Before equipcustloc MAP arrServOver: ', arrServOver)

            //Check Location Equipment that has a last service date but no service
            equipcustloc.map((locequip) => {
                if (!arrReport.some((e) => e.EquipCustLocID === locequip.EquipCustLocID)) {
                    if (daysDiff(todayDate, locequip.EquipCustLocLastServiceDate) > locequip.EquipCustLocDaysTillNextService || toggleShowUpcomingServices || toggleShowAllCust) {
                        //console.log('Item Not In List NEED SERVICE: ', locequip)
                        let locItem = loccustomers.find((e) => e.CustLocID === locequip.CustLocID)

                        //If not suspended!!!
                        if ((locItem.CustSuspended !== 1 && !toggleShowSuspended) || (locItem.CustSuspended === 1 && toggleShowSuspended)) {
                            //let equipItem = equipcustloc.find((e) => e.EquipCustLocID === item.EquipCustLocID)
                            let contacts = custloccontacts.filter((e) => {
                                if (e.CustLocID === locequip.CustLocID) {
                                    return e
                                } else {
                                    return null
                                }
                            })

                            let equipInfo = equipment.find((e) => e.EquipID === locequip.EquipID)
                            // let equipInfo = equipment.find(
                            //     (e) =>
                            //         e.EquipID ===
                            //         (equipItem.EquipID ? equipItem.EquipID : -1)
                            // )

                            //console.log('contacts: ', contacts)

                            arrReport.push({
                                EquipCustLocID: locequip.EquipCustLocID,
                                CustID: locItem.CustID,
                                ServiceType: 0,
                                CustLocID: locequip.CustLocID,
                                EquipID: locequip.EquipID,
                                CustName: locItem.CustName,
                                CustLocName: locItem.CustLocName,
                                CustLocStreetAddress: locItem.CustLocStreetAddress,
                                CustLocSuburb: locItem.CustLocSuburb,
                                CustLocCity: locItem.CustLocCity,
                                CustLocPostalCode: locItem.CustLocPostalCode,
                                CustLocCountry: locItem.CustLocCountry,
                                CustLocNotes: locItem.CustNotes,
                                CustLocFlags: locItem.CustLocFlags,
                                CustSuspended: locItem.CustSuspended,
                                CustPriContactName: contacts && contacts.length > 0 ? (contacts.find((e) => e.CustLocContactPrimary === 1) ? contacts.find((e) => e.CustLocContactPrimary === 1).CustLocContactName : contacts[0].CustLocContactName) : 'None',
                                // : console.log(
                                //       'contacts empty: ',
                                //       custloccontacts,
                                //       contacts
                                //   ),
                                CustPriContactEmail: contacts && contacts.length > 0 ? (contacts.find((e) => e.CustLocContactPrimary === 1) ? contacts.find((e) => e.CustLocContactPrimary === 1).CustLocContactEmail : contacts[0].CustLocContactEmail) : 'None',
                                CustPriContactNumber: contacts && contacts.length > 0 ? (contacts.find((e) => e.CustLocContactPrimary === 1) ? contacts.find((e) => e.CustLocContactPrimary === 1).CustLocContactNumber : contacts[0].CustLocContactNumber) : 'None',
                                LastServiceRecorded: locequip.EquipCustLocLastServiceDate,
                                EquipCustLoc: locequip,
                                Contacts: contacts,
                                EquipInfo: equipInfo,
                                equipServices: [],
                            })
                        }
                    } else {
                        //console.log('Item Not In List: ', locequip)
                    }
                } else {
                    //console.log('IN List: ', locequip)
                }
            })
        }

        //Filter if upcoming services are needed
        if (!toggleShowAllCust && !toggleShowUpcomingServices) {
            //console.log('option1')
            arrReport = arrReport.filter((item) => {
                if (daysDiff(todayDate, item.LastServiceRecorded) > item.EquipCustLoc.EquipCustLocDaysTillNextService && item.ServiceType === 0) {
                    return item
                }
            })
        } else if (toggleShowUpcomingServices) {
            console.log('toggleShowUpcomingServices: ', toggleShowUpcomingServices)
            //console.log('option2')
            // daysDiff(nextMonthDate, item.EquipCustLocLastServiceDate) > item.EquipCustLocDaysTillNextService &&
            // daysDiff(todayDate, item.EquipCustLocLastServiceDate) < item.EquipCustLocDaysTillNextService

            console.log('arrServOver: ', arrReport)
            arrReport = arrReport.filter((item) => {
                // console.log(
                //     'daysDiff(nextMonthDate, item.LastServiceRecorded): ',
                //     daysDiff(nextMonthDate, item.LastServiceRecorded) + ' > ' + item.EquipCustLoc.EquipCustLocDaysTillNextService
                // )
                // console.log(
                //     'daysDiff(todayDate, item.LastServiceRecorded):',
                //     daysDiff(todayDate, item.LastServiceRecorded) + ' > ' + item.EquipCustLoc.EquipCustLocDaysTillNextService
                // )
                if (daysDiff(nextMonthDate, item.LastServiceRecorded) > item.EquipCustLoc.EquipCustLocDaysTillNextService && daysDiff(todayDate, item.LastServiceRecorded) < item.EquipCustLoc.EquipCustLocDaysTillNextService) {
                    return item
                }
            })
        }
    } catch (error) {
        console.log('globalFunctions.js(getServicesReportArray): ', error)
        toast.error('globalFunctions.js(getServicesReportArray): ' + error)
    }
    //console.log('globalFunctions getServicesReportArray: ', arrReport)
    return arrReport
}

//partListStr the quick string to show which parts are in the service on compact view
export const getServicePartsStr = (jsonDataArr) => {
    try {
        let jsonparts = []
        if (jsonDataArr) {
            jsonparts = JSON.parse(jsonDataArr)
        }

        let partsListStr = ''

        //partListStr the quick string to show which parts are in the service on compact view
        jsonparts.map((part, i) => {
            part.ServicePartCode = part.ServicePartCode.replace(/"/g, '\\"')
            part.ServicePartCategory = part.ServicePartCategory.replace(/"/g, '\\"')
            part.ServicePartName = part.ServicePartName.replace(/"/g, '\\"')

            part.ServicePartPrice = parseFloat(part.ServicePartPrice).toFixed(2)

            // part.ServicePartCategory = part.ServicePartCategory.replace(/"/g, '\\"')
            // part.ServicePartName = part.ServicePartName.replace(/"/g, '\\"')

            if (i < jsonparts.length - 1) {
                partsListStr += part.ServicePartCode.replace(/\\/g, '') + ', '
            } else {
                partsListStr += part.ServicePartCode.replace(/\\/g, '')
            }
        })

        return partsListStr
    } catch (error) {
        console.log('globalFunctions.jsx(getServiceParts) Parse JSON data error: ', error, ' jsonDataArr: ', jsonDataArr)

        toast.error('globalFunctions.jsx(getServiceParts) Parse JSON data error: ' + error)

        addROErrorLog('globalFunctions.jsx(getServiceParts) JSON data Error:  ' + error.toString(), '', 'globalFunctions.jsx(getServiceParts)')
    }
}

export const getFranSettingsJSON = (dataArr, setting) => {
    //console.log('getFranSettingsJSON setting: ', setting, ' Ret: ', JSON.parse(dataArr.FranAccSettings)[setting])

    if (dataArr && dataArr.FranAccSettings && setting) {
        return JSON.parse(dataArr.FranAccSettings)[setting]
    }

    //console.log('getFranSettingsJSON return empty string')
    return ''
}

export const getAnySettingsJSON = (dataArr, setting) => {
    //console.log('getAnySettingsJSON setting: ', setting, ' Ret: ', dataArr ? JSON.parse(dataArr)[setting] : '')

    if (dataArr && setting) {
        return JSON.parse(dataArr)[setting]
    }

    //console.log('getAnySettingsJSON return empty string')
    return ''
}

export const GetEmailTokens = ({ ReplyToEmail = 'noreply@rovantage.com', FranCompName = 'CompanyName', YourName = 'YourName', FranAccTel = '', FranAccEmail = 'noreply@rovantage.com', FranAccStreet = '', FranAccSuburb = '', FranAccCity = '', FranAccPostal = '', FranAccCountry = '', CustomerName = '[Customer]', Equipment = '[Equipment]', ServiceDue = formatShortDate(Date.now()), Link = '[Link]' }) => {
    return [
        ['[CustomerName]', CustomerName],
        ['[Equipment]', Equipment],
        ['[Link]', Link],
        ['[ServiceDate]', ServiceDue],
        ['[ReplyToEmail]', ReplyToEmail],
        ['[YourCompanyName]', FranCompName],
        ['[YourName]', YourName],
        ['[YourTelephone]', FranAccTel],
        ['[YourEmail]', FranAccEmail],
        ['[YourStreet]', FranAccStreet],
        ['[YourSuburb]', FranAccSuburb],
        ['[YourCity]', FranAccCity],
        ['[YourPostalCode]', FranAccPostal],
        ['[YourCountry]', FranAccCountry],
    ]
}

export const EmailTokensReplace = (str, emailTokens = []) => {
    //console.log('str before: ', str)
    emailTokens.find((e) => {
        if (str.indexOf(e[0]) >= 0) {
            //console.log('EmailTokensReplace: ', str, ' value: ', e)
            str = str.replaceAll(e[0], e[1])
        }
    })
    //console.log('str after: ', str)
    return str
}

export const FormatHtmlEmail = (str, tokenLink = '') => {
    //console.log('FormatPlainEmail str before: ', str)
    //console.log('FormatPlainEmail str before: ', str.split('\n'))
    /*
    text: '
    url: ' + serverAddress + '/token/?token=' + token,
           
    html:
                "<a href='" +
                serverAddress +
                '/token/?token=' +
                token +
                '\'><button type="button">More Info</button></a>' +
    
*/

    //const tokenHtml = `<a href="${tokenLink}"><button type="button">More Info</button></a>`

    str = str.replaceAll('\n', '<br />')
    //str = str.replaceAll('[Link]', tokenHtml)

    //console.log('FormatPlainEmail str after: ', str)
    return str
}

export const GetCurrency = (curCode = 'list') => {
    //#region curJSON Currency JSON

    const curJSON = {
        USD: {
            symbol: '$',
            name: 'US Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'USD',
            name_plural: 'US dollars',
        },
        CAD: {
            symbol: 'CA$',
            name: 'Canadian Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'CAD',
            name_plural: 'Canadian dollars',
        },
        EUR: {
            symbol: '€',
            name: 'Euro',
            symbol_native: '€',
            decimal_digits: 2,
            rounding: 0,
            code: 'EUR',
            name_plural: 'euros',
        },
        AED: {
            symbol: 'AED',
            name: 'United Arab Emirates Dirham',
            symbol_native: 'د.إ.‏',
            decimal_digits: 2,
            rounding: 0,
            code: 'AED',
            name_plural: 'UAE dirhams',
        },
        AFN: {
            symbol: 'Af',
            name: 'Afghan Afghani',
            symbol_native: '؋',
            decimal_digits: 0,
            rounding: 0,
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
        },
        ALL: {
            symbol: 'ALL',
            name: 'Albanian Lek',
            symbol_native: 'Lek',
            decimal_digits: 0,
            rounding: 0,
            code: 'ALL',
            name_plural: 'Albanian lekë',
        },
        AMD: {
            symbol: 'AMD',
            name: 'Armenian Dram',
            symbol_native: 'դր.',
            decimal_digits: 0,
            rounding: 0,
            code: 'AMD',
            name_plural: 'Armenian drams',
        },
        ARS: {
            symbol: 'AR$',
            name: 'Argentine Peso',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'ARS',
            name_plural: 'Argentine pesos',
        },
        AUD: {
            symbol: 'AU$',
            name: 'Australian Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'AUD',
            name_plural: 'Australian dollars',
        },
        AZN: {
            symbol: 'man.',
            name: 'Azerbaijani Manat',
            symbol_native: 'ман.',
            decimal_digits: 2,
            rounding: 0,
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
        },
        BAM: {
            symbol: 'KM',
            name: 'Bosnia-Herzegovina Convertible Mark',
            symbol_native: 'KM',
            decimal_digits: 2,
            rounding: 0,
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
        },
        BDT: {
            symbol: 'Tk',
            name: 'Bangladeshi Taka',
            symbol_native: '৳',
            decimal_digits: 2,
            rounding: 0,
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
        },
        BGN: {
            symbol: 'BGN',
            name: 'Bulgarian Lev',
            symbol_native: 'лв.',
            decimal_digits: 2,
            rounding: 0,
            code: 'BGN',
            name_plural: 'Bulgarian leva',
        },
        BHD: {
            symbol: 'BD',
            name: 'Bahraini Dinar',
            symbol_native: 'د.ب.‏',
            decimal_digits: 3,
            rounding: 0,
            code: 'BHD',
            name_plural: 'Bahraini dinars',
        },
        BIF: {
            symbol: 'FBu',
            name: 'Burundian Franc',
            symbol_native: 'FBu',
            decimal_digits: 0,
            rounding: 0,
            code: 'BIF',
            name_plural: 'Burundian francs',
        },
        BND: {
            symbol: 'BN$',
            name: 'Brunei Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'BND',
            name_plural: 'Brunei dollars',
        },
        BOB: {
            symbol: 'Bs',
            name: 'Bolivian Boliviano',
            symbol_native: 'Bs',
            decimal_digits: 2,
            rounding: 0,
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
        },
        BRL: {
            symbol: 'R$',
            name: 'Brazilian Real',
            symbol_native: 'R$',
            decimal_digits: 2,
            rounding: 0,
            code: 'BRL',
            name_plural: 'Brazilian reals',
        },
        BWP: {
            symbol: 'BWP',
            name: 'Botswanan Pula',
            symbol_native: 'P',
            decimal_digits: 2,
            rounding: 0,
            code: 'BWP',
            name_plural: 'Botswanan pulas',
        },
        BYN: {
            symbol: 'Br',
            name: 'Belarusian Ruble',
            symbol_native: 'руб.',
            decimal_digits: 2,
            rounding: 0,
            code: 'BYN',
            name_plural: 'Belarusian rubles',
        },
        BZD: {
            symbol: 'BZ$',
            name: 'Belize Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'BZD',
            name_plural: 'Belize dollars',
        },
        CDF: {
            symbol: 'CDF',
            name: 'Congolese Franc',
            symbol_native: 'FrCD',
            decimal_digits: 2,
            rounding: 0,
            code: 'CDF',
            name_plural: 'Congolese francs',
        },
        CHF: {
            symbol: 'CHF',
            name: 'Swiss Franc',
            symbol_native: 'CHF',
            decimal_digits: 2,
            rounding: 0.05,
            code: 'CHF',
            name_plural: 'Swiss francs',
        },
        CLP: {
            symbol: 'CL$',
            name: 'Chilean Peso',
            symbol_native: '$',
            decimal_digits: 0,
            rounding: 0,
            code: 'CLP',
            name_plural: 'Chilean pesos',
        },
        CNY: {
            symbol: 'CN¥',
            name: 'Chinese Yuan',
            symbol_native: 'CN¥',
            decimal_digits: 2,
            rounding: 0,
            code: 'CNY',
            name_plural: 'Chinese yuan',
        },
        COP: {
            symbol: 'CO$',
            name: 'Colombian Peso',
            symbol_native: '$',
            decimal_digits: 0,
            rounding: 0,
            code: 'COP',
            name_plural: 'Colombian pesos',
        },
        CRC: {
            symbol: '₡',
            name: 'Costa Rican Colón',
            symbol_native: '₡',
            decimal_digits: 0,
            rounding: 0,
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
        },
        CVE: {
            symbol: 'CV$',
            name: 'Cape Verdean Escudo',
            symbol_native: 'CV$',
            decimal_digits: 2,
            rounding: 0,
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
        },
        CZK: {
            symbol: 'Kč',
            name: 'Czech Republic Koruna',
            symbol_native: 'Kč',
            decimal_digits: 2,
            rounding: 0,
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
        },
        DJF: {
            symbol: 'Fdj',
            name: 'Djiboutian Franc',
            symbol_native: 'Fdj',
            decimal_digits: 0,
            rounding: 0,
            code: 'DJF',
            name_plural: 'Djiboutian francs',
        },
        DKK: {
            symbol: 'Dkr',
            name: 'Danish Krone',
            symbol_native: 'kr',
            decimal_digits: 2,
            rounding: 0,
            code: 'DKK',
            name_plural: 'Danish kroner',
        },
        DOP: {
            symbol: 'RD$',
            name: 'Dominican Peso',
            symbol_native: 'RD$',
            decimal_digits: 2,
            rounding: 0,
            code: 'DOP',
            name_plural: 'Dominican pesos',
        },
        DZD: {
            symbol: 'DA',
            name: 'Algerian Dinar',
            symbol_native: 'د.ج.‏',
            decimal_digits: 2,
            rounding: 0,
            code: 'DZD',
            name_plural: 'Algerian dinars',
        },
        EEK: {
            symbol: 'Ekr',
            name: 'Estonian Kroon',
            symbol_native: 'kr',
            decimal_digits: 2,
            rounding: 0,
            code: 'EEK',
            name_plural: 'Estonian kroons',
        },
        EGP: {
            symbol: 'EGP',
            name: 'Egyptian Pound',
            symbol_native: 'ج.م.‏',
            decimal_digits: 2,
            rounding: 0,
            code: 'EGP',
            name_plural: 'Egyptian pounds',
        },
        ERN: {
            symbol: 'Nfk',
            name: 'Eritrean Nakfa',
            symbol_native: 'Nfk',
            decimal_digits: 2,
            rounding: 0,
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
        },
        ETB: {
            symbol: 'Br',
            name: 'Ethiopian Birr',
            symbol_native: 'Br',
            decimal_digits: 2,
            rounding: 0,
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
        },
        GBP: {
            symbol: '£',
            name: 'British Pound Sterling',
            symbol_native: '£',
            decimal_digits: 2,
            rounding: 0,
            code: 'GBP',
            name_plural: 'British pounds sterling',
        },
        GEL: {
            symbol: 'GEL',
            name: 'Georgian Lari',
            symbol_native: 'GEL',
            decimal_digits: 2,
            rounding: 0,
            code: 'GEL',
            name_plural: 'Georgian laris',
        },
        GHS: {
            symbol: 'GH₵',
            name: 'Ghanaian Cedi',
            symbol_native: 'GH₵',
            decimal_digits: 2,
            rounding: 0,
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
        },
        GNF: {
            symbol: 'FG',
            name: 'Guinean Franc',
            symbol_native: 'FG',
            decimal_digits: 0,
            rounding: 0,
            code: 'GNF',
            name_plural: 'Guinean francs',
        },
        GTQ: {
            symbol: 'GTQ',
            name: 'Guatemalan Quetzal',
            symbol_native: 'Q',
            decimal_digits: 2,
            rounding: 0,
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
        },
        HKD: {
            symbol: 'HK$',
            name: 'Hong Kong Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
        },
        HNL: {
            symbol: 'HNL',
            name: 'Honduran Lempira',
            symbol_native: 'L',
            decimal_digits: 2,
            rounding: 0,
            code: 'HNL',
            name_plural: 'Honduran lempiras',
        },
        HRK: {
            symbol: 'kn',
            name: 'Croatian Kuna',
            symbol_native: 'kn',
            decimal_digits: 2,
            rounding: 0,
            code: 'HRK',
            name_plural: 'Croatian kunas',
        },
        HUF: {
            symbol: 'Ft',
            name: 'Hungarian Forint',
            symbol_native: 'Ft',
            decimal_digits: 0,
            rounding: 0,
            code: 'HUF',
            name_plural: 'Hungarian forints',
        },
        IDR: {
            symbol: 'Rp',
            name: 'Indonesian Rupiah',
            symbol_native: 'Rp',
            decimal_digits: 0,
            rounding: 0,
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
        },
        ILS: {
            symbol: '₪',
            name: 'Israeli New Sheqel',
            symbol_native: '₪',
            decimal_digits: 2,
            rounding: 0,
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
        },
        INR: {
            symbol: 'Rs',
            name: 'Indian Rupee',
            symbol_native: 'টকা',
            decimal_digits: 2,
            rounding: 0,
            code: 'INR',
            name_plural: 'Indian rupees',
        },
        IQD: {
            symbol: 'IQD',
            name: 'Iraqi Dinar',
            symbol_native: 'د.ع.‏',
            decimal_digits: 0,
            rounding: 0,
            code: 'IQD',
            name_plural: 'Iraqi dinars',
        },
        IRR: {
            symbol: 'IRR',
            name: 'Iranian Rial',
            symbol_native: '﷼',
            decimal_digits: 0,
            rounding: 0,
            code: 'IRR',
            name_plural: 'Iranian rials',
        },
        ISK: {
            symbol: 'Ikr',
            name: 'Icelandic Króna',
            symbol_native: 'kr',
            decimal_digits: 0,
            rounding: 0,
            code: 'ISK',
            name_plural: 'Icelandic krónur',
        },
        JMD: {
            symbol: 'J$',
            name: 'Jamaican Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'JMD',
            name_plural: 'Jamaican dollars',
        },
        JOD: {
            symbol: 'JD',
            name: 'Jordanian Dinar',
            symbol_native: 'د.أ.‏',
            decimal_digits: 3,
            rounding: 0,
            code: 'JOD',
            name_plural: 'Jordanian dinars',
        },
        JPY: {
            symbol: '¥',
            name: 'Japanese Yen',
            symbol_native: '￥',
            decimal_digits: 0,
            rounding: 0,
            code: 'JPY',
            name_plural: 'Japanese yen',
        },
        KES: {
            symbol: 'Ksh',
            name: 'Kenyan Shilling',
            symbol_native: 'Ksh',
            decimal_digits: 2,
            rounding: 0,
            code: 'KES',
            name_plural: 'Kenyan shillings',
        },
        KHR: {
            symbol: 'KHR',
            name: 'Cambodian Riel',
            symbol_native: '៛',
            decimal_digits: 2,
            rounding: 0,
            code: 'KHR',
            name_plural: 'Cambodian riels',
        },
        KMF: {
            symbol: 'CF',
            name: 'Comorian Franc',
            symbol_native: 'FC',
            decimal_digits: 0,
            rounding: 0,
            code: 'KMF',
            name_plural: 'Comorian francs',
        },
        KRW: {
            symbol: '₩',
            name: 'South Korean Won',
            symbol_native: '₩',
            decimal_digits: 0,
            rounding: 0,
            code: 'KRW',
            name_plural: 'South Korean won',
        },
        KWD: {
            symbol: 'KD',
            name: 'Kuwaiti Dinar',
            symbol_native: 'د.ك.‏',
            decimal_digits: 3,
            rounding: 0,
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
        },
        KZT: {
            symbol: 'KZT',
            name: 'Kazakhstani Tenge',
            symbol_native: 'тңг.',
            decimal_digits: 2,
            rounding: 0,
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
        },
        LBP: {
            symbol: 'L.L.',
            name: 'Lebanese Pound',
            symbol_native: 'ل.ل.‏',
            decimal_digits: 0,
            rounding: 0,
            code: 'LBP',
            name_plural: 'Lebanese pounds',
        },
        LKR: {
            symbol: 'SLRs',
            name: 'Sri Lankan Rupee',
            symbol_native: 'SL Re',
            decimal_digits: 2,
            rounding: 0,
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
        },
        LTL: {
            symbol: 'Lt',
            name: 'Lithuanian Litas',
            symbol_native: 'Lt',
            decimal_digits: 2,
            rounding: 0,
            code: 'LTL',
            name_plural: 'Lithuanian litai',
        },
        LVL: {
            symbol: 'Ls',
            name: 'Latvian Lats',
            symbol_native: 'Ls',
            decimal_digits: 2,
            rounding: 0,
            code: 'LVL',
            name_plural: 'Latvian lati',
        },
        LYD: {
            symbol: 'LD',
            name: 'Libyan Dinar',
            symbol_native: 'د.ل.‏',
            decimal_digits: 3,
            rounding: 0,
            code: 'LYD',
            name_plural: 'Libyan dinars',
        },
        MAD: {
            symbol: 'MAD',
            name: 'Moroccan Dirham',
            symbol_native: 'د.م.‏',
            decimal_digits: 2,
            rounding: 0,
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
        },
        MDL: {
            symbol: 'MDL',
            name: 'Moldovan Leu',
            symbol_native: 'MDL',
            decimal_digits: 2,
            rounding: 0,
            code: 'MDL',
            name_plural: 'Moldovan lei',
        },
        MGA: {
            symbol: 'MGA',
            name: 'Malagasy Ariary',
            symbol_native: 'MGA',
            decimal_digits: 0,
            rounding: 0,
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
        },
        MKD: {
            symbol: 'MKD',
            name: 'Macedonian Denar',
            symbol_native: 'MKD',
            decimal_digits: 2,
            rounding: 0,
            code: 'MKD',
            name_plural: 'Macedonian denari',
        },
        MMK: {
            symbol: 'MMK',
            name: 'Myanma Kyat',
            symbol_native: 'K',
            decimal_digits: 0,
            rounding: 0,
            code: 'MMK',
            name_plural: 'Myanma kyats',
        },
        MOP: {
            symbol: 'MOP$',
            name: 'Macanese Pataca',
            symbol_native: 'MOP$',
            decimal_digits: 2,
            rounding: 0,
            code: 'MOP',
            name_plural: 'Macanese patacas',
        },
        MUR: {
            symbol: 'MURs',
            name: 'Mauritian Rupee',
            symbol_native: 'MURs',
            decimal_digits: 0,
            rounding: 0,
            code: 'MUR',
            name_plural: 'Mauritian rupees',
        },
        MXN: {
            symbol: 'MX$',
            name: 'Mexican Peso',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'MXN',
            name_plural: 'Mexican pesos',
        },
        MYR: {
            symbol: 'RM',
            name: 'Malaysian Ringgit',
            symbol_native: 'RM',
            decimal_digits: 2,
            rounding: 0,
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
        },
        MZN: {
            symbol: 'MTn',
            name: 'Mozambican Metical',
            symbol_native: 'MTn',
            decimal_digits: 2,
            rounding: 0,
            code: 'MZN',
            name_plural: 'Mozambican meticals',
        },
        NAD: {
            symbol: 'N$',
            name: 'Namibian Dollar',
            symbol_native: 'N$',
            decimal_digits: 2,
            rounding: 0,
            code: 'NAD',
            name_plural: 'Namibian dollars',
        },
        NGN: {
            symbol: '₦',
            name: 'Nigerian Naira',
            symbol_native: '₦',
            decimal_digits: 2,
            rounding: 0,
            code: 'NGN',
            name_plural: 'Nigerian nairas',
        },
        NIO: {
            symbol: 'C$',
            name: 'Nicaraguan Córdoba',
            symbol_native: 'C$',
            decimal_digits: 2,
            rounding: 0,
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
        },
        NOK: {
            symbol: 'Nkr',
            name: 'Norwegian Krone',
            symbol_native: 'kr',
            decimal_digits: 2,
            rounding: 0,
            code: 'NOK',
            name_plural: 'Norwegian kroner',
        },
        NPR: {
            symbol: 'NPRs',
            name: 'Nepalese Rupee',
            symbol_native: 'नेरू',
            decimal_digits: 2,
            rounding: 0,
            code: 'NPR',
            name_plural: 'Nepalese rupees',
        },
        NZD: {
            symbol: 'NZ$',
            name: 'New Zealand Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'NZD',
            name_plural: 'New Zealand dollars',
        },
        OMR: {
            symbol: 'OMR',
            name: 'Omani Rial',
            symbol_native: 'ر.ع.‏',
            decimal_digits: 3,
            rounding: 0,
            code: 'OMR',
            name_plural: 'Omani rials',
        },
        PAB: {
            symbol: 'B/.',
            name: 'Panamanian Balboa',
            symbol_native: 'B/.',
            decimal_digits: 2,
            rounding: 0,
            code: 'PAB',
            name_plural: 'Panamanian balboas',
        },
        PEN: {
            symbol: 'S/.',
            name: 'Peruvian Nuevo Sol',
            symbol_native: 'S/.',
            decimal_digits: 2,
            rounding: 0,
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
        },
        PHP: {
            symbol: '₱',
            name: 'Philippine Peso',
            symbol_native: '₱',
            decimal_digits: 2,
            rounding: 0,
            code: 'PHP',
            name_plural: 'Philippine pesos',
        },
        PKR: {
            symbol: 'PKRs',
            name: 'Pakistani Rupee',
            symbol_native: '₨',
            decimal_digits: 0,
            rounding: 0,
            code: 'PKR',
            name_plural: 'Pakistani rupees',
        },
        PLN: {
            symbol: 'zł',
            name: 'Polish Zloty',
            symbol_native: 'zł',
            decimal_digits: 2,
            rounding: 0,
            code: 'PLN',
            name_plural: 'Polish zlotys',
        },
        PYG: {
            symbol: '₲',
            name: 'Paraguayan Guarani',
            symbol_native: '₲',
            decimal_digits: 0,
            rounding: 0,
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
        },
        QAR: {
            symbol: 'QR',
            name: 'Qatari Rial',
            symbol_native: 'ر.ق.‏',
            decimal_digits: 2,
            rounding: 0,
            code: 'QAR',
            name_plural: 'Qatari rials',
        },
        RON: {
            symbol: 'RON',
            name: 'Romanian Leu',
            symbol_native: 'RON',
            decimal_digits: 2,
            rounding: 0,
            code: 'RON',
            name_plural: 'Romanian lei',
        },
        RSD: {
            symbol: 'din.',
            name: 'Serbian Dinar',
            symbol_native: 'дин.',
            decimal_digits: 0,
            rounding: 0,
            code: 'RSD',
            name_plural: 'Serbian dinars',
        },
        RUB: {
            symbol: 'RUB',
            name: 'Russian Ruble',
            symbol_native: '₽.',
            decimal_digits: 2,
            rounding: 0,
            code: 'RUB',
            name_plural: 'Russian rubles',
        },
        RWF: {
            symbol: 'RWF',
            name: 'Rwandan Franc',
            symbol_native: 'FR',
            decimal_digits: 0,
            rounding: 0,
            code: 'RWF',
            name_plural: 'Rwandan francs',
        },
        SAR: {
            symbol: 'SR',
            name: 'Saudi Riyal',
            symbol_native: 'ر.س.‏',
            decimal_digits: 2,
            rounding: 0,
            code: 'SAR',
            name_plural: 'Saudi riyals',
        },
        SDG: {
            symbol: 'SDG',
            name: 'Sudanese Pound',
            symbol_native: 'SDG',
            decimal_digits: 2,
            rounding: 0,
            code: 'SDG',
            name_plural: 'Sudanese pounds',
        },
        SEK: {
            symbol: 'Skr',
            name: 'Swedish Krona',
            symbol_native: 'kr',
            decimal_digits: 2,
            rounding: 0,
            code: 'SEK',
            name_plural: 'Swedish kronor',
        },
        SGD: {
            symbol: 'S$',
            name: 'Singapore Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'SGD',
            name_plural: 'Singapore dollars',
        },
        SOS: {
            symbol: 'Ssh',
            name: 'Somali Shilling',
            symbol_native: 'Ssh',
            decimal_digits: 0,
            rounding: 0,
            code: 'SOS',
            name_plural: 'Somali shillings',
        },
        SYP: {
            symbol: 'SY£',
            name: 'Syrian Pound',
            symbol_native: 'ل.س.‏',
            decimal_digits: 0,
            rounding: 0,
            code: 'SYP',
            name_plural: 'Syrian pounds',
        },
        THB: {
            symbol: '฿',
            name: 'Thai Baht',
            symbol_native: '฿',
            decimal_digits: 2,
            rounding: 0,
            code: 'THB',
            name_plural: 'Thai baht',
        },
        TND: {
            symbol: 'DT',
            name: 'Tunisian Dinar',
            symbol_native: 'د.ت.‏',
            decimal_digits: 3,
            rounding: 0,
            code: 'TND',
            name_plural: 'Tunisian dinars',
        },
        TOP: {
            symbol: 'T$',
            name: 'Tongan Paʻanga',
            symbol_native: 'T$',
            decimal_digits: 2,
            rounding: 0,
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
        },
        TRY: {
            symbol: 'TL',
            name: 'Turkish Lira',
            symbol_native: 'TL',
            decimal_digits: 2,
            rounding: 0,
            code: 'TRY',
            name_plural: 'Turkish Lira',
        },
        TTD: {
            symbol: 'TT$',
            name: 'Trinidad and Tobago Dollar',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
        },
        TWD: {
            symbol: 'NT$',
            name: 'New Taiwan Dollar',
            symbol_native: 'NT$',
            decimal_digits: 2,
            rounding: 0,
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
        },
        TZS: {
            symbol: 'TSh',
            name: 'Tanzanian Shilling',
            symbol_native: 'TSh',
            decimal_digits: 0,
            rounding: 0,
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
        },
        UAH: {
            symbol: '₴',
            name: 'Ukrainian Hryvnia',
            symbol_native: '₴',
            decimal_digits: 2,
            rounding: 0,
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
        },
        UGX: {
            symbol: 'USh',
            name: 'Ugandan Shilling',
            symbol_native: 'USh',
            decimal_digits: 0,
            rounding: 0,
            code: 'UGX',
            name_plural: 'Ugandan shillings',
        },
        UYU: {
            symbol: '$U',
            name: 'Uruguayan Peso',
            symbol_native: '$',
            decimal_digits: 2,
            rounding: 0,
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
        },
        UZS: {
            symbol: 'UZS',
            name: 'Uzbekistan Som',
            symbol_native: 'UZS',
            decimal_digits: 0,
            rounding: 0,
            code: 'UZS',
            name_plural: 'Uzbekistan som',
        },
        VEF: {
            symbol: 'Bs.F.',
            name: 'Venezuelan Bolívar',
            symbol_native: 'Bs.F.',
            decimal_digits: 2,
            rounding: 0,
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
        },
        VND: {
            symbol: '₫',
            name: 'Vietnamese Dong',
            symbol_native: '₫',
            decimal_digits: 0,
            rounding: 0,
            code: 'VND',
            name_plural: 'Vietnamese dong',
        },
        XAF: {
            symbol: 'FCFA',
            name: 'CFA Franc BEAC',
            symbol_native: 'FCFA',
            decimal_digits: 0,
            rounding: 0,
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
        },
        XOF: {
            symbol: 'CFA',
            name: 'CFA Franc BCEAO',
            symbol_native: 'CFA',
            decimal_digits: 0,
            rounding: 0,
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
        },
        YER: {
            symbol: 'YR',
            name: 'Yemeni Rial',
            symbol_native: 'ر.ي.‏',
            decimal_digits: 0,
            rounding: 0,
            code: 'YER',
            name_plural: 'Yemeni rials',
        },
        ZAR: {
            symbol: 'R',
            name: 'South African Rand',
            symbol_native: 'R',
            decimal_digits: 2,
            rounding: 0,
            code: 'ZAR',
            name_plural: 'South African rand',
        },
        ZMK: {
            symbol: 'ZK',
            name: 'Zambian Kwacha',
            symbol_native: 'ZK',
            decimal_digits: 0,
            rounding: 0,
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
        },
        ZWL: {
            symbol: 'ZWL$',
            name: 'Zimbabwean Dollar',
            symbol_native: 'ZWL$',
            decimal_digits: 0,
            rounding: 0,
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
        },
    }
    //#endregion

    //console.log('globalFunction GetCurrency curCode: ', curCode)

    if (curCode === 'list') {
        return Object.entries(curJSON).map((cur) => {
            return { Text: cur[0] }
        })

        //return Object.keys(curJSON)
    } else {
        //console.log('return curJSON[curCode]: ', curJSON[curCode])
        return curJSON[curCode]
    }
}

// console.log(
//     Object.values(jsonObj[key][0]),
//     'Object.values(jsonObj[key]) lenght',
//     Object.values(jsonObj[key][0]).length
// )

// console.log('(Value)jsonObj[key]: ', jsonObj[key])
// console.log('(Index0)jsonObj[key][0]: ', jsonObj[key][0])
// console.log(
//     '(Lenght)jsonObj[key][0] lenght: ',
//     jsonObj[key][0].length
// )
//#endregion
