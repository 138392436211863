import ROIcon from '../ROIcon'
const ROHelpDashboard = () => {
    return (
        <>
            <div className='row p-0 m-0'>
                <div class='contianer ps-2 '>
                    <a href='#Search' class='bs-linebreak fw-bold'>
                        Dashboard|
                    </a>
                    &emsp;
                    <a href='#overdue' class='bs-linebreak fw-bold'>
                        Overdue Customers List
                    </a>
                    <br />
                </div>

                <div class='container' id='Search'>
                    <br />
                    <h2>Dashboard:</h2>
                </div>
                <div class='container' id='Customer-Information'>
                    <img src='./images/help-imges/dash-img1.jpg' height={150} alt='img' />
                    <p>
                        <strong class='ps-3'>
                            <ROIcon icon='ROicoCust' size={26} className='icoSVG ' />
                            Total Customers:
                        </strong>
                        <br />
                        <p>The customer card displays the total amount of customers and is also a link when clicked takes the user to the customer page. The button beneath the card `View All the customers` has the same functionality.</p>
                        <div class='ps-5'>
                            [] [Number]
                            <br />
                            [] View All Customers: [Link to Customer Page]
                            <br />
                        </div>
                    </p>
                    <p>
                        <strong class='ps-3'>
                            <ROIcon icon='ROicoEquip' size={26} className='icoSVG ' />
                            Equipment:
                        </strong>
                        <br />
                        <p>The Equipment card displays the total amount of Equipment and is also a link when clicked takes the user to the Equipment page. The button beneath the card `View All the Equipment` has the same functionality.</p>
                        <div class='ps-5'>
                            [ ] [Number]
                            <br />
                            [] View All Equipment: [Link to Equipment Page]
                        </div>
                    </p>
                    <p>
                        <strong class='ps-3'>
                            <ROIcon icon='ROicoService' size={26} className='icoSVG ' />
                            Services Completed:
                        </strong>
                        <br />
                        <p>The Services Completed card displays the total amount of Services Completed and is also a link when clicked takes the user to the report page. The button beneath the card `Services Due Report` has the same functionality.</p>
                        <div class='ps-5'>
                            [] [Number]
                            <br />
                            [] Services Due Report: [Link to Completed Services Page]
                        </div>
                    </p>

                    <strong class='ps-3'>
                        <ROIcon icon='ROicoService' size={26} className='icoSVG ' />
                        Services Next Month:
                    </strong>
                    <br />
                    <p>The Services Next Month card displays the total amount of Services Next Month and is also a link when clicked takes the user to the report page. The button beneath the card `Upcoming Services` has the same functionality.</p>
                    <div class='ps-5'>
                        [ ] [Number]
                        <br />
                        [] Upcmming Services: [Link to Next Month Services Page]
                    </div>
                    <p />
                </div>
                <div class='container' id='overdue'>
                    <h2>Overdue Customers List:</h2>
                </div>
                <div class='container'>
                    <img src='./images/help-imges/dash-img2.jpg' height={50} alt='img' />
                    <p>
                        <strong class='ps-3'>Customer Name:</strong>
                        <p>The longest overdue list is a list of customer whose services are over due, and each customer has share icon whose function is to take the user to the customer page and see the customers information and edit it as needed.</p>
                        <div class='ps-5'>
                            [] Overdue: [Number of Days Overdue]
                            <br />
                            [<ROIcon icon='ROicoGoto' size={26} className='icoSVG ' />] View Details: [Link to Customer Details Page]
                        </div>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ROHelpDashboard
