import ContentBox from './ContentBox'
import CustContactItem from './CustContactItem'
import CustChatBox from './CustChatBox'

import { useEffect, useState, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { addROErrorLog, VaildateForm, getAnySettingsJSON } from '../features/globalFunctions'
import { formatInputDate } from '../features/formatTime'

import myUUid from '../features/myUUid'

import ROIcon from '../components/ROIcon'
import EquipCustLocItem from '../components/EquipCustLocItem'

import { createCustomerLoc, updateCustomerLoc, deleteCustomerLoc } from '../features/custloc/custlocSlice.js'

//Import Customer Contacts Redux State/Global Var and Axiox database calls
import {
    createCustomerLocContact,
    deleteCustomerLocContact,
    updateCustomerLocContact,
    resetLocContacts,
} from '../features/custloccontacts/custloccontactsSlice'
import ROComboBox from './ROComboBox'

//import equipcustlocService from '../features/equipcustloc/equipcustlocService'
import { createEquipCustLoc, resetequipcustloc } from '../features/equipcustloc/equipcustlocSlice'
import { RegExPattern } from '../features/globalVars'

const CustLocItem = ({
    _CustLocDetails,
    CustID,
    ChildOjb,
    classTag,
    borderParms,
    backColor,
    containerBackColor,
    expanedState,
    focusEquipLocID = -1,
    newItem,
    showChatOfContactID,
    custlocAddedCallback = -1,
    addLocationCallback,
}) => {
    const dispatch = useDispatch()

    //console.log('CustLocITem showChatOfContactID: ', showChatOfContactID)
    console.log('*** CustLocItem DRAW ***')

    ChildOjb = null ? '' : ChildOjb
    classTag = null ? 'content' : classTag
    borderParms = null ? '' : ''
    backColor = null ? '' : ''
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    expanedState = null ? false : expanedState //Expanded or not
    newItem = null ? false : newItem

    if (!_CustLocDetails) {
        _CustLocDetails = {}
    }

    let {
        CustLocID = '',
        CustLocName = 'Enter Name',
        CustLocEmail = 'Enter Email',
        CustLocStreet = 'Street',
        CustLocSub = 'Suburb',
        CustLocCity = 'City',
        CustLocPostal = 'Code',
        CustLocCountry = 'Country',
        CustLocFlags = '',
        CustPriContactName = '',
        CustPriContactEmail = '',
    } = _CustLocDetails

    // try {
    //     CustLocContacts = JSON.parse(CustLocContacts)
    // } catch (error) {
    //     CustLocContacts = []
    // }

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)
    const editState = newItem

    const [toggleState, setToggleState] = useState(expanedState)
    const [toggleEditState, setEditState] = useState(editState)
    const [confirmDelete, setDeleteState] = useState(false)
    const [showLocContactChatID, setLocContactChatID] = useState(showChatOfContactID)
    const [allLocChatState, setAllLocChatState] = useState(false)
    const [addLocContactState, setLocContactState] = useState(false)
    const [showChatDeletedState, setShowChatDeletedState] = useState(false)
    const [showEquipment, setShowEquipment] = useState(focusEquipLocID)

    //console.log('CustLocItem REDRAW')
    if (CustLocFlags) {
        console.log('*******************CustLocFlags******************88: ', CustLocFlags)
    }

    const changeToggleState = () => {
        setToggleState((prev) => !prev)
    }
    const toggleAllLocChatState = () => {
        setAllLocChatState((prev) => !prev)
        setLocContactChatID(-1)
    }

    const toggleChatDeletedState = () => {
        setShowChatDeletedState((prev) => !prev)
    }

    const cancelNewEdit = () => {
        setEditState((prev) => !prev)

        const cancelID = CustLocID !== '' ? CustLocID : -2

        //console.log('cancelNewEdit CustLocID:', CustLocID)
        //console.log('cancelNewEdit cancelID:', cancelID)

        custlocAddedCallback(cancelID, false)

        //custlocAddedCallback(-2)
    }

    //#region Locations Contacts
    const { custloccontacts } = useSelector((state) => state.loccustcontacts)

    //New Customer Contacts Input boxes
    let [txtContactName, setTxtContactName] = useState('')
    let [txtContactTel, setTxtContactTel] = useState('')
    let [txtContactEmail, setTxtContactEmail] = useState('')

    //Add or Update Customer Contact
    const updateCustomerLocContactCallback = ({
        _contid,
        _name,
        _number = '',
        _email = '',
        _chats = [],
        _primary = false,
        _flags = '',
        PrimaryID = -1,
        AddedChat = false,
    }) => {
        console.log('updateCustomerLocContact id: ', _contid, _name)

        if (AddedChat) {
            setLocContactChatID(_contid)
        } else {
            setLocContactChatID(-1)
        }

        //If this is not a primary and there are no other make this one the new primary
        if (!_primary) {
            console.log('Not primary contact')
            let anyPrimaryContact = custloccontacts.find((i) => {
                if (i.CustLocID === CustLocID && i.CustLocContactPrimary === 1) {
                    return i
                }
            })

            if (!anyPrimaryContact || anyPrimaryContact.length <= 0) {
                console.log('No primary set so default this one created to primary.')
                _primary = true
            } else {
                //console.log('Primary contact: ', anyPrimaryContact)
            }
        }

        // else {
        //     //If this is a primary make sure other contacts in redux are update to not be the primary

        //     let tempContacts = []
        //     tempContacts = custloccontacts.map((custloc) => {
        //         if (custloc.CustLocID === CustLocID) {
        //             if (custloc.CustLocContactID === _contid) {
        //                 return { ...custloc, CustLocContactPrimary: 1 }
        //             } else return { ...custloc, CustLocContactPrimary: 0 }
        //         } else {
        //             return custloc
        //         }
        //     })

        //     console.log(
        //         'tempContacts: ',
        //         tempContacts,
        //         ' of CustLoctID: ',
        //         CustLocID
        //     )

        //     custloccontacts = tempContacts
        // }

        //-1 New Customer location contact
        if (_contid === -1) {
            const newCustLocCont = {
                custlocID: CustLocID,
                custlocContactName: _name,
                custlocContactNumber: _number,
                custlocContactEmail: _email,
                custlocContactChats: JSON.stringify(_chats),
                custlocContactPrimary: _primary,
                custlocContactFlags: _flags,
            }
            // console.log(
            //     'updateCustomerLocContact newCustLocCont: ',
            //     newCustLocCont
            // )
            dispatch(createCustomerLocContact(newCustLocCont))
        } else {
            const updCustLocCont = {
                _id: _contid,
                custlocID: CustLocID,
                custlocContactName: _name,
                custlocContactNumber: _number,
                custlocContactEmail: _email,
                custlocContactChats: JSON.stringify(_chats),
                custlocContactPrimary: _primary,
                custlocContactFlags: _flags,
            }
            // console.log(
            //     'updateCustomerLocContact updCustLocCont: ',
            //     updCustLocCont
            // )
            dispatch(updateCustomerLocContact(updCustLocCont))
            custlocAddedCallback(CustLocID)
        }

        if (!AddedChat) {
            console.log('Not chat added')
            custlocAddedCallback(CustLocID, false)
        } else {
            console.log('Chat added', _contid)
            custlocAddedCallback(CustLocID, false, _contid)
        }
    }

    const DisableAddLocContactState = () => {
        console.log('DisableAddLocContactState:', addLocContactState)
        setLocContactChatID(-1)
        setLocContactState(false)
    }

    const DeleteLocContactByID = (id) => {
        //CustContacts = CustContacts.filter((cust) => cust.ContactID !== id)
        //updateCustomerDetails(CustID)
        dispatch(deleteCustomerLocContact(id))
        custlocAddedCallback()
    }

    //Store primary contact to show info on cust compact state
    const myPrimaryLocContact = custloccontacts.filter((custloc) => custloc.CustLocContactPrimary === true)

    //Create copy of custcontacts to sort and display
    let CustLocContactsSorted = custloccontacts.filter((i) => i.CustLocID === CustLocID)

    //CustContacts.filter((cust) => cust.ContactID === Id).length <= 0

    //console.log('Sorted by custID: ' + CustID, CustContactsSorted)

    //Sort Contacts to put Primary on top
    CustLocContactsSorted.sort((a, b) => {
        return a.CustLocContactPrimary === b.CustLocContactPrimary ? 0 : a.CustLocContactPrimary ? -1 : 1
    })

    //#endregion

    //Form edit values needed so react UI can show user typing
    const [txtCustLocName, setTxtCustLocName] = useState('')
    const [txtCustLocEmail, setTxtCustLocEmail] = useState('')
    const [txtCustLocStreet, setTxtCustLocStreet] = useState('')
    const [txtCustLocSuburb, setTxtCustLocSuburb] = useState('')
    const [txtCustLocCity, setTxtCustLocCity] = useState('')
    const [txtCustLocPostal, setTxtCustLocPostal] = useState('')
    const [txtCustLocCountry, setTxtCustLocCountry] = useState(franacc.FranAccCountry)

    const toggleCustLocEdit = () => {
        setEditState((prev) => !prev)
    }

    const editCustomerLoc = () => {
        if (newItem) {
            CustLocName = txtCustLocName
            CustLocEmail = txtCustLocEmail
            CustLocStreet = txtCustLocStreet
            CustLocSub = txtCustLocSuburb
            CustLocCity = txtCustLocCity
            CustLocPostal = txtCustLocPostal
            CustLocCountry = txtCustLocCountry

            createNewCustomerLoc()
            newItem = false
        } else if (toggleEditState) {
            CustLocName = txtCustLocName
            CustLocEmail = txtCustLocEmail
            CustLocStreet = txtCustLocStreet
            CustLocSub = txtCustLocSuburb
            CustLocCity = txtCustLocCity
            CustLocPostal = txtCustLocPostal
            CustLocCountry = txtCustLocCountry

            updateCustomerLocDetails(CustLocID)
            custlocAddedCallback(CustLocID)
        } else {
            setTxtCustLocName(CustLocName)
            setTxtCustLocEmail(CustLocEmail)
            setTxtCustLocStreet(CustLocStreet)
            setTxtCustLocSuburb(CustLocSub)
            setTxtCustLocCity(CustLocCity)
            setTxtCustLocPostal(CustLocPostal)
            setTxtCustLocCountry(CustLocCountry)
        }

        setEditState((prev) => !prev)
    }

    const createNewCustomerLoc = () => {
        console.log('create new location customer on custID: ', CustID)
        const newCustLoc = {
            custID: CustID,
            busname: CustLocName,
            firstname: '',
            lastname: '',
            email: CustLocEmail,
            locationtype: '',
            streetaddress: CustLocStreet,
            suburb: CustLocSub,
            city: CustLocCity,
            postalcode: CustLocPostal,
            country: CustLocCountry,
            loccontactname: txtContactName,
            loccontacttel: txtContactTel,
            loccontactemail: txtContactName,
            gpscoords: '',
            directions: '',
            notes: '',
        }

        console.log('Call createCustomerLoc with newCustLoc: ', newCustLoc)

        dispatch(createCustomerLoc(newCustLoc)).then((e) => {
            custlocAddedCallback(e.payload.CustLocID)
        })
    }

    const updateCustomerLocDetails = (_id) => {
        const updCustLoc = {
            custID: CustID,
            _id,
            busname: CustLocName,
            firstname: '',
            lastname: '',
            email: CustLocEmail,
            locationtype: '',
            streetaddress: CustLocStreet,
            suburb: CustLocSub,
            city: CustLocCity,
            postalcode: CustLocPostal,
            country: CustLocCountry,
            gpscoords: '',
            directions: '',
            notes: '',
            //notes: JSON.stringify(CustContacts),
        }

        dispatch(updateCustomerLoc(updCustLoc))
    }

    const deleteCustomerLocById = (_id) => {
        dispatch(deleteCustomerLoc(_id))
    }

    const confirmDeleteCustLoc = (setState) => {
        if (CustLocContactsSorted && CustLocContactsSorted.length > 0) {
            setDeleteState(false)
            toast('Location Delete Error: Contains contact')
            return
        }

        if (equipcustloc && equipcustloc.length > 0 && equipcustloc.some((e) => e.CustLocID === CustLocID)) {
            setDeleteState(false)
            toast('Location Delete Error: Contains equipment')
            return
        }

        if (confirmDelete && setState) {
            console.log('Delete!!!!')
            deleteCustomerLocById(CustLocID)
        }
        setDeleteState(setState)
    }

    /* Equipment on Location */

    const [showNewEquip, setShowNewEquip] = useState(false)
    const [NewEquipSelected, setNewEquipSelected] = useState(false)
    const [NewEquipItem, setNewEquipItem] = useState('')
    const [txtNewEquipRepID, setTxtNewEquipRepID] = useState(-1)
    const [txtNewEquipSerialNR, setTxtNewEquipSerialNR] = useState('')
    const [txtNewEquipNotes, setTxtNewEquipNotes] = useState('')
    const [txtNewEquipRoomPlace, setTxtNewEquipRoomPlace] = useState('')
    const [txtNewEquipServiceInterval, setTxtNewEquipServiceInterval] = useState('')
    const [txtNewEquipIntervalType, setTxtNewEquipIntervalType] = useState('')
    const [txtNewEquipServiceDate, setTxtNewEquipServiceDate] = useState(formatInputDate(Date()))
    const newEquipIntervalTypeRef = useRef()

    //Get State equipment from redux
    const { equipment, isEquipError, isEquipSuccess, equipmessage } = useSelector((state) => state.equipment)

    //Get State location equipment from redux
    const { equipcustloc, isEquipCustLocError, isEquipEquipCustLocSuccess, equipcustlocmessage } = useSelector((state) => state.equipcustloc)

    //Move it up in the call hierarchy to make less calls
    // useMemo(() => {
    //     //return () => SearchCustomers()
    //     console.log('Before Call Get CustLoc Equipment')
    //     dispatch(getEquipCustLoc())
    //     console.log('After Call Get CustLoc Equipment')
    // }, [equipment])

    useEffect(() => {
        //isEquipCustLocError
        if (isEquipCustLocError) {
            console.log('CustLocItem useEffect isEquipCustLocError error: ', equipcustlocmessage)
            toast.error(equipcustlocmessage)
            addROErrorLog('CustLocItem Error:  ' + equipcustlocmessage, '', 'CustLocItem')
            dispatch(resetequipcustloc())
            return
        }
    }, [isEquipCustLocError])

    let arrEquipment = []
    if (equipment) {
        try {
            arrEquipment = equipment.map((equip) => {
                return {
                    Text: '(' + equip.EquipCode + ') ' + equip.EquipName + ' - ' + equip.EquipCategory,
                    ID: equip.EquipID,
                }
            })
        } catch (error) {
            console.log('CustLocItem arrEquipment Error: ', error)

            toast.error('CustLocItem arrEquipment Error: ' + error.toString())
            addROErrorLog('CustLocItem arrEquipment Error:  ' + error.toString(), '', 'CustLocItem')
        }

        //console.log('arrEquipment: ', arrEquipment)
    }

    let arrRoomPlace = []
    if (equipcustloc) {
        try {
            arrRoomPlace = new Set(equipcustloc.map((equipcustloc) => equipcustloc.EquipCustLocRoomPlace))
            arrRoomPlace = Array.from(arrRoomPlace)
            //console.log('Equipment setCategories: ', arrRoomPlace)
            arrRoomPlace = arrRoomPlace.map((equipcustloc) => {
                return {
                    Text: equipcustloc,
                }
            })
        } catch (error) {
            console.log('CustLocItem arrRoomPlace Error: ', error)

            toast.error('CustLocItem arrRoomPlace Error: ' + error.toString())
            addROErrorLog('CustLocItem arrRoomPlace Error:  ' + error.toString(), '', 'CustLocItem')
        }
    }

    const SelectEquipmentItem = (id) => {
        if (id === -1) {
            setShowNewEquip(false)
            setNewEquipSelected(false)
            setNewEquipItem('')
            return
        }

        const item = equipment.filter((item) => item.EquipID === id)

        if (item && item[0].EquipID === id) {
            console.log('Item Added', item)

            setNewEquipItem(item[0])
            setNewEquipSelected(true)
            setShowEquipment(id)
        } else {
            setShowNewEquip(false)
        }
    }

    const AddEquipmentItem = () => {
        //console.log('CustLocID: ', CustLocID)

        const newEquipItem = {
            custlocID: CustLocID,
            equipID: NewEquipItem.EquipID,
            equipcustlocRepID: txtNewEquipRepID,
            equipcustlocserialnr: txtNewEquipSerialNR,
            equipcustlocdays: txtNewEquipServiceInterval * newEquipIntervalTypeRef.current.value,
            equipcustlastservicedate: txtNewEquipServiceDate,
            equipcustlocintervaltype: parseInt(newEquipIntervalTypeRef.current.value),
            equipcustlocroomplace: txtNewEquipRoomPlace,
            equipcustlocnotes: txtNewEquipNotes,
            equipcustlocflags: '',
        }

        console.log('newEquipItem: ', newEquipItem)

        setTxtNewEquipRoomPlace('')
        setTxtNewEquipSerialNR('')
        setTxtNewEquipNotes('')
        setTxtNewEquipServiceInterval('')
        setTxtNewEquipServiceDate(formatInputDate(Date()))
        setTxtNewEquipRepID(-1)
        setShowNewEquip(false)

        dispatch(createEquipCustLoc(newEquipItem)).then((e) => {
            console.log('CustLocItem New Cust Loction Item retVal(then) payload: ', e.payload.CustLocID)
            console.log('CustLocItem New Cust Loction Item retVal(then) payload.EquipCustLocID: ', e.payload.EquipCustLocID)
            setShowEquipment(e.payload.EquipCustLocID)
            //custlocAddedCallback(e.payload.CustLocID)
        })
    }

    const selectSalesRep = (repID) => {
        setTxtNewEquipRepID(repID)
    }

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } = useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        try {
            arrSalesReps = new Set(
                franusers.map((reps) => {
                    return { name: reps.name, ID: reps._id }
                })
            )
            //console.log('Equipment reps: ', arrSalesReps)
            arrSalesReps = Array.from(arrSalesReps)
            arrSalesReps = arrSalesReps.map((reps) => {
                return {
                    Text: reps.name,
                    ID: reps.ID,
                }
            })
        } catch (error) {
            console.log('CustLocItem arrSalesReps Error: ', error)

            toast.error('CustLocItem arrSalesReps Error: ' + error.toString())
            addROErrorLog('CustLocItem arrSalesReps Error:  ' + error.toString(), '', 'CustLocItem')
        }

        //console.log('Equipment rep Categories: ', arrSalesReps)
    }

    //#region New Customer Form Validation
    const [boolCustomerLocationFormValidated, setCustomerLocationFormValidated] = useState(false)

    const valCustLocNameRef = useRef()
    const valCustLocStreetRef = useRef()
    const valCustLocSuburbRef = useRef()
    const valCustLocCityRef = useRef()
    const valCustLocPostalCodeRef = useRef()
    const valCustLocCountryRef = useRef()
    const valCustLocContactNameRef = useRef()
    const valCustLocContactTelRef = useRef()
    const valCustLocContactEmailRef = useRef()

    const vaildateCustomerLocForm = (clicked = false) => {
        //console.log('newItem: ', newItem, ' toggleEditState: ', toggleEditState)

        if (!newItem && !toggleEditState) return

        if (toggleEditState) {
            setCustomerLocationFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valCustLocNameRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location valCustLocNameRef',
                        },
                        {
                            ref: valCustLocStreetRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location valCustStreetRef',
                        },
                        {
                            ref: valCustLocSuburbRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location valCustSuburbRef',
                        },
                        {
                            ref: valCustLocCityRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location valCustCityRef',
                        },
                        {
                            ref: valCustLocPostalCodeRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location valCustPostalCodeRef',
                        },
                        {
                            ref: valCustLocCountryRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location valCustCountryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }

        if (newItem) {
            setCustomerLocationFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valCustLocNameRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustLocNameRef',
                        },
                        {
                            ref: valCustLocStreetRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustStreetRef',
                        },
                        {
                            ref: valCustLocSuburbRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustSuburbRef',
                        },
                        {
                            ref: valCustLocCityRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustCityRef',
                        },
                        {
                            ref: valCustLocPostalCodeRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustPostalCodeRef',
                        },
                        {
                            ref: valCustLocCountryRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustCountryRef',
                        },
                        {
                            ref: valCustLocContactNameRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustContactNameRef',
                        },
                        {
                            ref: valCustLocContactTelRef,
                            text: 'No Special Characters',
                            form: 'New Customer Location valCustContactTelRef',
                        },
                        {
                            ref: valCustLocContactEmailRef,
                            text: 'Please enter valid email',
                            form: 'New Customer Location valCustContactEmailRef',
                        },
                    ],
                    clicked
                )
            )
        }
    }

    //#region New Location Equipment Form Validation
    const [boolCustLocNewEquipFormValidated, setCustLocNewEquipFormValidated] = useState(false)

    const valEquipSalesRef = useRef()
    const valEquipSerialNrRef = useRef()
    const valEquipNotes = useRef()
    const valEquipRoomPlaceRef = useRef()
    const valEquipServiceIntervalValRef = useRef()
    const valEquipServiceDate = useRef()

    const vaildateCustLocNewEquipForm = (clicked = false) => {
        console.log('NewEquipSelected: ', !NewEquipSelected)
        if (!NewEquipSelected) return

        if (NewEquipSelected) {
            console.log('vaildateCustLocNewEquipForm: CALLLED!!!!!!!!')
            setCustLocNewEquipFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valEquipServiceDate,
                            text: 'Invalid Date',
                            form: 'New Location Equipment valEquipServiceDate',
                        },
                        {
                            ref: valEquipSalesRef,
                            text: 'Sales Rep Required',
                            form: 'New Location Equipment valEquipSalesRef',
                        },
                        {
                            ref: valEquipSerialNrRef,
                            text: 'No Special Characters',
                            form: 'New Location Equipment valEquipSerialNrRef',
                        },
                        {
                            ref: valEquipNotes,
                            text: 'No Special Characters',
                            form: 'New Location Equipment valEquipNotes',
                        },
                        {
                            ref: valEquipRoomPlaceRef,
                            text: 'Room/Place Required',
                            form: 'New Location Equipment valEquipRoomPlaceRef',
                        },
                        {
                            ref: valEquipServiceIntervalValRef,
                            text: 'Only Integer Values',
                            form: 'New Location Equipment valEquipServiceIntervalValRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-light bg-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `custBox content border border-light bg-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded
                <>
                    {/*Container Div*/}
                    <div className='container'>
                        {/*1st ROW Header*/}
                        <div className='row '>
                            {/*Customer Name*/}
                            <div className='custName col-auto me-auto '>
                                {toggleEditState ? (
                                    <input
                                        className='txtCustName txtBox'
                                        value={txtCustLocName}
                                        placeholder={CustLocName}
                                        onChange={(e) => {
                                            setTxtCustLocName(e.target.value)
                                            vaildateCustomerLocForm()
                                        }}
                                        ref={valCustLocNameRef}
                                        title='Location Name'
                                        pattern={RegExPattern.Text}
                                        required
                                    />
                                ) : (
                                    <>
                                        <ROIcon icon='ROicoLocation' size={24} className='icoSVG' />
                                        {CustLocName}
                                    </>
                                )}
                                {/* <div className='col-auto me-auto'>
                                        {toggleEditState ? (
                                            <input
                                                className='txtCustEmail txtBox'
                                                value={txtCustLocEmail}
                                                placeholder={CustLocEmail}
                                                onChange={(e) =>
                                                    setTxtCustLocEmail(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div> */}
                            </div>
                            {/*Interface Buttons*/}
                            <div className='col-auto px-0'>
                                {toggleEditState ? (
                                    newItem ? (
                                        boolCustomerLocationFormValidated ? (
                                            <ROIcon
                                                icon='ROicoAccept'
                                                size={24}
                                                className='icoSVG borderlp'
                                                tooltip='Add Location'
                                                onClickCallback={() => editCustomerLoc()}
                                                style={{
                                                    animation: 'icofocus 4s infinite',
                                                }}
                                            />
                                        ) : (
                                            <ROIcon
                                                icon='ROicoAccept'
                                                size={24}
                                                className='icoSVG borderlp'
                                                onClickCallback={() => vaildateCustomerLocForm(true)}
                                                style={{
                                                    animation: 'pulse 4s infinite',
                                                }}
                                                usefill='true'
                                                color1='#ff0000'
                                            />
                                        )
                                    ) : boolCustomerLocationFormValidated ? (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Update Location'
                                            authlvl={10}
                                            onClickCallback={() => editCustomerLoc()}
                                            style={{
                                                animation: 'pulse 4s infinite',
                                            }}
                                        />
                                    ) : (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderlp'
                                            onClickCallback={() => vaildateCustomerLocForm(true)}
                                            style={{
                                                animation: 'pulse 4s infinite',
                                            }}
                                            usefill='true'
                                            color1='#ff0000'
                                        />
                                    )
                                ) : (
                                    <ROIcon
                                        icon='ROicoEdit'
                                        size={24}
                                        className='icoSVG borderlp '
                                        tooltip='Edit Location'
                                        authlvl={10}
                                        onClickCallback={() => {
                                            setCustomerLocationFormValidated(true)
                                            editCustomerLoc()
                                        }}
                                    />
                                )}
                                {!toggleEditState ? (
                                    <>
                                        {newItem ? (
                                            ''
                                        ) : toggleEditState ? (
                                            ''
                                        ) : confirmDelete ? (
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                authlvl={10}
                                                className='icoSVG borderlp danger'
                                                tooltip='Delete Location'
                                                onClickCallback={() => confirmDeleteCustLoc(true)}
                                            />
                                        ) : (
                                            ' '
                                        )}

                                        {!confirmDelete ? (
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                className='icoSVG borderlp'
                                                tooltip='Delete Location'
                                                authlvl={10}
                                                onClickCallback={() => confirmDeleteCustLoc(true)}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                                {!toggleEditState ? (
                                    confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoCancel'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Cancel Delete'
                                            onClickCallback={() => confirmDeleteCustLoc(false)}
                                        />
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG'
                                        tooltip='Cancel'
                                        onClickCallback={() => cancelNewEdit()}
                                    />
                                )}
                                {!newItem ? <ROIcon icon='ROicoXUp' className='icoSVG borderlp' onClickCallback={changeToggleState} /> : ''}
                            </div>
                        </div>

                        {/* Cust Adres */}
                        {/* Cust Adres + Contacts */}
                        <div className='row'>
                            {/* Cust Adres */}
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-sm-2'>
                                        <ROIcon icon='ROicoSign' size={24} className='icoSVG' />
                                    </div>
                                    <div className='divCustDetails col-auto'>
                                        <span className='mainCustomerAddress'>
                                            {toggleEditState ? (
                                                <input
                                                    className='txtCustStreet txtBox'
                                                    value={txtCustLocStreet}
                                                    placeholder={CustLocStreet}
                                                    onChange={(e) => {
                                                        setTxtCustLocStreet(e.target.value)
                                                        vaildateCustomerLocForm()
                                                    }}
                                                    ref={valCustLocStreetRef}
                                                    title='Address Street'
                                                    pattern={RegExPattern.Text}
                                                    required
                                                />
                                            ) : (
                                                CustLocStreet
                                            )}
                                            <br />
                                            {toggleEditState ? (
                                                <input
                                                    className='txtCustSuburb txtBox'
                                                    value={txtCustLocSuburb}
                                                    placeholder={CustLocSub}
                                                    onChange={(e) => {
                                                        setTxtCustLocSuburb(e.target.value)
                                                        vaildateCustomerLocForm()
                                                    }}
                                                    ref={valCustLocSuburbRef}
                                                    title='Address Suburb'
                                                    pattern={RegExPattern.Text}
                                                />
                                            ) : (
                                                CustLocSub
                                            )}
                                            <br />
                                            {toggleEditState ? (
                                                <input
                                                    className='txtCustCity txtBox'
                                                    value={txtCustLocCity}
                                                    placeholder={CustLocCity}
                                                    onChange={(e) => {
                                                        setTxtCustLocCity(e.target.value)
                                                        vaildateCustomerLocForm()
                                                    }}
                                                    ref={valCustLocCityRef}
                                                    title='Address City'
                                                    pattern={RegExPattern.Text}
                                                    required
                                                />
                                            ) : (
                                                CustLocCity
                                            )}
                                            ,&nbsp;
                                            {toggleEditState ? (
                                                <input
                                                    className='txtCustPostal txtBox'
                                                    value={txtCustLocPostal}
                                                    placeholder={CustLocPostal}
                                                    onChange={(e) => {
                                                        setTxtCustLocPostal(e.target.value)
                                                        vaildateCustomerLocForm()
                                                    }}
                                                    ref={valCustLocPostalCodeRef}
                                                    title='Address Postal Code'
                                                    pattern={RegExPattern.Text}
                                                />
                                            ) : (
                                                CustLocPostal
                                            )}
                                            <br />
                                            {toggleEditState ? (
                                                <input
                                                    className='txtCustCountry txtBox'
                                                    value={txtCustLocCountry}
                                                    placeholder={CustLocCountry}
                                                    onChange={(e) => {
                                                        setTxtCustLocCountry(e.target.value)
                                                        vaildateCustomerLocForm()
                                                    }}
                                                    ref={valCustLocCountryRef}
                                                    title='Address Country'
                                                    pattern={RegExPattern.Text}
                                                />
                                            ) : (
                                                CustLocCountry
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <ROIcon
                                            icon='ROicoPlus'
                                            tooltip='Add Location'
                                            className='icoSVG'
                                            onClickCallback={addLocationCallback}
                                            style={{
                                                animation: 'pulse 4s infinite',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Customer Contacts */}
                            <div className='col-lg-8'>
                                {newItem ? (
                                    <div className='row'>
                                        <div className='col-lg-1'>
                                            <ROIcon icon='ROicoUser' size={24} className='icoSVG' />
                                        </div>
                                        <div className='col-lg-4 px-1 fs-6'>
                                            <input
                                                id='txtContactName'
                                                value={txtContactName}
                                                placeholder='Contact Name'
                                                onChange={(e) => {
                                                    setTxtContactName(e.target.value)
                                                    vaildateCustomerLocForm()
                                                }}
                                                ref={valCustLocContactNameRef}
                                                title='Contact Name'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='col-lg-3 px-1 fs-6'>
                                            <input
                                                id='txtContactTel'
                                                value={txtContactTel}
                                                placeholder='Number'
                                                onChange={(e) => {
                                                    setTxtContactTel(e.target.value)
                                                    vaildateCustomerLocForm()
                                                }}
                                                ref={valCustLocContactTelRef}
                                                title='Contact Number'
                                                pattern={RegExPattern.Phone}
                                                required
                                            />
                                        </div>
                                        <div className='col-lg-4 me-auto px-1 fs-6'>
                                            <input
                                                id='txtContactEmail'
                                                value={txtContactEmail}
                                                placeholder='Email'
                                                type='email'
                                                onChange={(e) => {
                                                    setTxtContactEmail(e.target.value)
                                                    vaildateCustomerLocForm()
                                                }}
                                                ref={valCustLocContactEmailRef}
                                                title='Contact Email'
                                                required
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : CustLocContactsSorted !== '' && CustLocContactsSorted.length > 0 ? (
                                    <>
                                        <div key={myUUid()} className={allLocChatState ? 'row border borderChat' : 'row'}>
                                            {allLocChatState ? (
                                                <div>
                                                    <ROIcon
                                                        icon='ROicoCancel'
                                                        className='icoSVG'
                                                        tooltip='Close All Chat'
                                                        onClickCallback={toggleAllLocChatState}
                                                    />
                                                    {showChatDeletedState ? (
                                                        <ROIcon
                                                            icon='ROicoCheckbox'
                                                            className='icoSVG'
                                                            tooltip='Hide Deleted'
                                                            onClickCallback={toggleChatDeletedState}
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoBox'
                                                            className='icoSVG'
                                                            tooltip='Show Deleted'
                                                            onClickCallback={toggleChatDeletedState}
                                                        />
                                                    )}
                                                    <CustChatBox
                                                        chatdata={CustLocContactsSorted.map((contact) => {
                                                            return {
                                                                Chats: contact.CustLocContactChats,
                                                                Name: contact.CustLocContactName,
                                                            }
                                                        })}
                                                        isAllChat={true}
                                                        showDeleted={showChatDeletedState}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <ROIcon
                                                        icon='ROicoAllchat'
                                                        className='icoSVG'
                                                        tooltip='All Chat'
                                                        onClickCallback={toggleAllLocChatState}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {/* {console.log(
                                                'CustLocContactsSorted: ',
                                                CustLocContactsSorted
                                            )} */}
                                        {CustLocContactsSorted
                                            ? CustLocContactsSorted.map((contact, i) => {
                                                  return (
                                                      <div key={myUUid()} className='container'>
                                                          <CustContactItem
                                                              id={contact.CustLocContactID}
                                                              showChat={
                                                                  showLocContactChatID !== -1 && showLocContactChatID === contact.CustLocContactID
                                                              }
                                                              data={{
                                                                  contactname: contact.CustLocContactName,
                                                                  tel: contact.CustLocContactNumber,
                                                                  email: contact.CustLocContactEmail,
                                                                  primary: contact.CustLocContactPrimary,
                                                                  chat: contact.CustLocContactChats,
                                                              }}
                                                              updateCallback={updateCustomerLocContactCallback}
                                                              deleteCallback={DeleteLocContactByID}
                                                              resetCallback={DisableAddLocContactState}
                                                          />
                                                      </div>
                                                  )
                                              })
                                            : ''}
                                        <div></div>
                                    </>
                                ) : (
                                    //If there are no contacts
                                    ''
                                )}
                                {!newItem ? (
                                    //New contact form
                                    <>
                                        {!addLocContactState ? (
                                            <ROIcon
                                                icon='ROicoPlus'
                                                className='icoSVG'
                                                tooltip='Add Contact'
                                                onClickCallback={() => setLocContactState(true)}
                                                style={{
                                                    animation: 'pulse 4s infinite',
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <CustContactItem
                                                    id={-1}
                                                    data={{
                                                        editing: true,
                                                    }}
                                                    updateCallback={updateCustomerLocContactCallback}
                                                    resetCallback={DisableAddLocContactState}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        {/*Equipment - Header*/}
                        <div className='row text-primary mb-0 mt-3'>
                            <div className='col-auto me-auto '>
                                <h5>
                                    Equipment
                                    <ROIcon
                                        icon='ROicoPlus'
                                        tooltip='Add Equipment'
                                        className='icoSVG'
                                        onClickCallback={() => setShowNewEquip((prev) => !prev)}
                                        style={{
                                            animation: 'pulse 4s infinite',
                                        }}
                                    />
                                </h5>
                            </div>
                        </div>
                        {/*Equipment - Items*/}
                        <div className='container bg-light m-0 px-3 fs-6'>
                            <div className='row'>
                                <div className='col me-auto'>
                                    {/* Add New Equipment */}
                                    {showNewEquip ? (
                                        <>
                                            <div className='row'>
                                                <div className='ROmaintxt col-md-10'>
                                                    <ROComboBox
                                                        dataArray={arrEquipment}
                                                        placeholdertext='Search Equipment to Add'
                                                        myIDCallback={SelectEquipmentItem}
                                                        clearTextOnSelect={true}
                                                    />
                                                    <ROIcon
                                                        icon='ROicoCancel'
                                                        size={24}
                                                        className='icoSVG ms-2'
                                                        tooltip='Cancel'
                                                        onClickCallback={() => SelectEquipmentItem(-1)}
                                                        style={{
                                                            animation: 'pulse 3s infinite',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {NewEquipSelected ? 'Add Equipment:' : ''}
                                            {NewEquipSelected ? (
                                                <div className='row'>
                                                    <div className='row'>
                                                        <div className='ROmaintxt col-md-10'>{NewEquipItem ? NewEquipItem.EquipName : ''}</div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='ROnormaltxt col-md-6'>
                                                            <input
                                                                className='txtBox'
                                                                value={txtNewEquipSerialNR}
                                                                placeholder='Serial Nr'
                                                                onChange={(e) => {
                                                                    setTxtNewEquipSerialNR(e.target.value)
                                                                    vaildateCustLocNewEquipForm()
                                                                }}
                                                                ref={valEquipSerialNrRef}
                                                                title='Equipment Serial Number'
                                                                pattern={RegExPattern.Text}
                                                            />
                                                        </div>
                                                        <div className='ROnormaltxt col-md-6 fs-6'>
                                                            <ROComboBox
                                                                dataArray={arrRoomPlace}
                                                                placeholdertext='Room / Place'
                                                                classname='ROdropdownFull'
                                                                textVal={txtNewEquipRoomPlace}
                                                                myValueCallback={setTxtNewEquipRoomPlace}
                                                                ref={valEquipRoomPlaceRef}
                                                                required={true}
                                                                validationCallBack={() => vaildateCustLocNewEquipForm()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='ROnormaltxt col-md-6 fs-6  mt-1'>
                                                            <ROComboBox
                                                                dataArray={arrSalesReps}
                                                                placeholdertext='Search Sales Rep'
                                                                classname='ROdropdownFull'
                                                                myIDCallback={selectSalesRep}
                                                                ref={valEquipSalesRef}
                                                                required={true}
                                                                validationCallBack={() => vaildateCustLocNewEquipForm()}
                                                                allowCustomValue={false}
                                                            />
                                                        </div>
                                                        <div className='ROnormaltxt col-md-6'>
                                                            <input
                                                                className='txtBox'
                                                                value={txtNewEquipNotes}
                                                                placeholder='Notes'
                                                                onChange={(e) => {
                                                                    setTxtNewEquipNotes(e.target.value)
                                                                    vaildateCustLocNewEquipForm()
                                                                }}
                                                                ref={valEquipNotes}
                                                                title='Equipment Notes'
                                                                pattern={RegExPattern.Text}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row col-md-12 mt-1'>
                                                        <div className='row'>
                                                            <div className='col-md-4'>
                                                                <div className='ROnormaltxt ms-1'> Install/Last Service Date: </div>

                                                                <input
                                                                    className='txtBox m-1 p-1'
                                                                    value={txtNewEquipServiceDate}
                                                                    type='date'
                                                                    onChange={(e) => {
                                                                        setTxtNewEquipServiceDate(e.target.value)
                                                                        vaildateCustLocNewEquipForm()
                                                                    }}
                                                                    ref={valEquipServiceDate}
                                                                    title='Last Service Date'
                                                                    //pattern={datePattern}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='ROnormaltxt col-md-3  ms-2'>
                                                                <div className='ROnormaltxt ms-1'> Service Interval</div>
                                                                <input
                                                                    className='txtBox'
                                                                    value={txtNewEquipServiceInterval}
                                                                    placeholder='Service Interval'
                                                                    onChange={(e) => {
                                                                        setTxtNewEquipServiceInterval(e.target.value.replace(/\D/g, '').slice(0, 3))
                                                                        vaildateCustLocNewEquipForm()
                                                                    }}
                                                                    ref={valEquipServiceIntervalValRef}
                                                                    title='Service Interval'
                                                                    pattern={RegExPattern.Int}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='ROnormaltxt col-md-2 px-1 fs-6'>
                                                                <div className='ROnormaltxt ms-1'> Interval Type</div>
                                                                <select
                                                                    id='IntervalType'
                                                                    className='txtBox'
                                                                    name='Interval Type'
                                                                    ref={newEquipIntervalTypeRef}
                                                                    onChange={(e) => setTxtNewEquipIntervalType(e.target.value)}
                                                                >
                                                                    <option value='365'>Years</option>
                                                                    <option value='30'>Months</option>
                                                                    <option value='7'>Weeks</option>
                                                                    <option value='1'>Days</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-auto me-auto'>
                                                                <>
                                                                    {boolCustLocNewEquipFormValidated ? (
                                                                        <ROIcon
                                                                            icon='ROicoAccept'
                                                                            size={24}
                                                                            className='icoSVG'
                                                                            tooltip='Add Equipment'
                                                                            onClickCallback={() => AddEquipmentItem()}
                                                                            style={{
                                                                                animation: 'icofocus 4s infinite',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <ROIcon
                                                                            icon='ROicoAccept'
                                                                            size={24}
                                                                            className='icoSVG'
                                                                            tooltip='Add Equipment'
                                                                            onClickCallback={() => vaildateCustLocNewEquipForm(true)}
                                                                            style={{
                                                                                animation: 'pulse 3s infinite',
                                                                            }}
                                                                            usefill={true}
                                                                            color1='#ff0000'
                                                                        />
                                                                    )}
                                                                    <ROIcon
                                                                        icon='ROicoCancel'
                                                                        size={24}
                                                                        className='icoSVG ms-2'
                                                                        tooltip='Cancel'
                                                                        onClickCallback={() => SelectEquipmentItem(-1)}
                                                                        style={{
                                                                            animation: 'pulse 3s infinite',
                                                                        }}
                                                                    />
                                                                    {/* <ROIcon
                                                                icon='ROicoClose'
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Cancel'
                                                                onClickCallback={() => SelectEquipmentItem(-1)}
                                                            /> */}
                                                                </>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br />
                                                    <hr />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* Display Location Equipment */}
                                {equipcustloc
                                    ? equipcustloc.map((item, i) => {
                                          if (item.CustLocID === CustLocID) {
                                              return (
                                                  <>
                                                      <EquipCustLocItem
                                                          key={myUUid()}
                                                          equipcustlocitem={item}
                                                          equipitem={equipment.find((equip) => equip.EquipID === item.EquipID)}
                                                          // arrCategories={arrCategories}
                                                          // collapse={getCollapseAll}
                                                          _expanedState={item.EquipCustLocID === showEquipment}
                                                          containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-light'}
                                                          equipfocusCallback={setShowEquipment}
                                                          CustPriContactName={CustPriContactName}
                                                          CustPriContactEmail={CustPriContactEmail}
                                                          tokenExpiryDate={new Date(getAnySettingsJSON(CustLocFlags, 'EToken') * 1000)}
                                                      />
                                                  </>
                                              )
                                          }
                                      })
                                    : ''}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <>
                    {/*Container Div*/}
                    <div className={classTag + ' ' + borderParms + ' ' + backColor} onClick={() => changeToggleState()}>
                        {/*1st ROW Header*/}
                        <div className='container'>
                            <div className='row'>
                                {/*Customer Name*/}
                                {/* <div className='custName col-3 pl-2 fs-3'>
                                        {CustLocName}
                                    </div> */}
                                {/*Customer Primary Number and Contact*/}
                                {/*Customer Email*/}
                                {/* <div className='custEmail col-4 fs-5 me-auto '>
                                        {CustLocEmail}
                                    </div> */}
                                {/*Customer Expande/Collapse Icon*/}

                                {/*Customer Name*/}
                                <div className='custName col-md-3'>
                                    <ROIcon icon='ROicoLocation' size={24} className='icoSVG' />
                                    {CustLocName}
                                </div>
                                {/*Customer Primary Number and Contact*/}
                                <div className='custCont col-auto me-auto'>
                                    {/* {myPrimaryContact.ContactName} */}
                                    {CustLocStreet + ', ' + CustLocSub + ', ' + CustLocCity}
                                </div>
                                <div className='col-auto px-1 fs-6'>
                                    <ROIcon
                                        icon='ROicoXDown'
                                        className='icoSVG'
                                        //onClickCallback={changeToggleState}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Unused*/}
                        <> {ChildOjb}</>
                    </div>
                </>
                //#endregion
            )}
        </ContentBox>
    )
}

export default CustLocItem
