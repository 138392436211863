//Format time to our standard dd/mm/yyyy - hh:mm:ss
export const formatTime = (timeval) => {
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = ('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    const hours = ('0' + t.getHours()).slice(-2)
    const minutes = ('0' + t.getMinutes()).slice(-2)
    const seconds = ('0' + t.getSeconds()).slice(-2)
    return `${date}/${month}/${year}, ${hours}:${minutes}:${seconds}`
}

export const formatShortDate = (timeval) => {
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = t.toLocaleString('default', { month: 'short' }) //('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    const hours = ('0' + t.getHours()).slice(-2)
    const minutes = ('0' + t.getMinutes()).slice(-2)
    const seconds = ('0' + t.getSeconds()).slice(-2)
    return `${year} ${month} ${date}`
}

export const formatGetMonthName = (timeval) => {
    const t = new Date(timeval)
    const month = t.toLocaleString('default', { month: 'short' }) //('0' + (t.getMonth() + 1)).slice(-2)
    return `${month}`
}

export const formatLongDate = (timeval) => {
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = t.toLocaleString('default', { month: 'long' }) //('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    const hours = ('0' + t.getHours()).slice(-2)
    const minutes = ('0' + t.getMinutes()).slice(-2)
    const seconds = ('0' + t.getSeconds()).slice(-2)
    return `${year} ${month} ${date}`
}

export const formatInputDate = (timeval) => {
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = ('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    const hours = ('0' + t.getHours()).slice(-2)
    const minutes = ('0' + t.getMinutes()).slice(-2)
    const seconds = ('0' + t.getSeconds()).slice(-2)
    //console.log('formatTime.js formatInputDate: ', `${year}-${month}-${date}`)
    return `${year}-${month}-${date}`
}
