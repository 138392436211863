import { useState } from 'react'

const ROPassword = ({ password1 = '', password2 = '', classname = 'ROpassword', validationCallBack }) => {
    const [txtPassword, setTxtPassword] = useState(password1)
    const [txtPassword2, setTxtPassword2] = useState(password2)

    const lettersRegExp = /[a-zA-Z]/g
    const numbersRegExp = /[0-9]/g
    const specialRegExp = /[#?!@$%^&*-]/
    const specialCountRegExp = /[#?!@$%^&*-]/g
    //const specialRegExp = /(?=.*?[#?!@$%^&*-])/
    const whitespaceRegExp = /^$|\s+/

    let lettersPassword = false
    let numbersPassword = false
    let specialPassword = false
    let whiteSpace = false

    lettersPassword = lettersRegExp.test(txtPassword ? txtPassword : false)
    numbersPassword = numbersRegExp.test(txtPassword ? txtPassword : false)
    specialPassword = specialRegExp.test(txtPassword ? txtPassword : false)
    whiteSpace = whitespaceRegExp.test(txtPassword ? txtPassword : false)

    let passwordLength = txtPassword ? txtPassword.length : 0

    //console.log('passLenght: ', txtPassword ? txtPassword.length : 0)

    let passwordError = ''
    let passwordNotMatch = false

    let passwordInput = txtPassword ? txtPassword : ''
    let passwordConfirmInput = txtPassword2 ? txtPassword2 : ''

    let passwordScore = 0

    const letterCount = (passwordInput.match(lettersRegExp) || []).length
    const numberCount = (passwordInput.match(numbersRegExp) || []).length
    const specialCount = (passwordInput.match(specialCountRegExp) || []).length

    passwordError = ''
    if (passwordLength < 6) {
        passwordScore = -1
        passwordError = 'Password is to short'
        if (validationCallBack) validationCallBack('')
    } else if (whiteSpace) {
        passwordScore = -1
        passwordError = 'Whitespaces are not allowed'
        if (validationCallBack) validationCallBack('')
    } else if (passwordInput === '') {
        passwordScore = -1
        passwordError = 'Password is Empty'
        if (validationCallBack) validationCallBack('')
    } else if (!lettersPassword) {
        passwordScore = -1
        passwordError = 'Password require a letter'
        if (validationCallBack) validationCallBack('')
    } else if (!numbersPassword) {
        passwordScore = -1
        passwordError = 'Password require a number'
        if (validationCallBack) validationCallBack('')
    } else if (!specialPassword) {
        passwordScore = -1
        passwordError = 'Password require a special char'
        if (validationCallBack) validationCallBack('')
    } else {
        passwordScore = passwordLength
        passwordScore += letterCount
        passwordScore += numberCount * 3
        passwordScore += specialCount * 5

        // console.log(
        //     'Score: ',
        //     passwordScore,
        //     ' - Letters: ',
        //     letterCount,
        //     lettersPassword,
        //     'Numbers: ',
        //     numberCount,
        //     numbersPassword,
        //     'Special: ',
        //     specialCount,
        //     specialPassword
        // )

        if (passwordInput === '' || passwordConfirmInput === '' || passwordInput !== passwordConfirmInput) {
            passwordNotMatch = true
            if (validationCallBack) validationCallBack('')
        } else {
            passwordNotMatch = false
            if (validationCallBack) {
                validationCallBack(txtPassword)
                console.log('ROPassword is accepted!!!')
            }
        }
    }

    return (
        <>
            <div className={classname}>
                <div className='row'>
                    <input
                        type='password'
                        className='txtCustTax txtBox m-0 p-1'
                        id='password'
                        name='password'
                        value={txtPassword}
                        placeholder='Enter password'
                        onChange={(e) => {
                            setTxtPassword(e.target.value)
                        }}
                        title='User Password'
                        required
                    />
                </div>
                {txtPassword && txtPassword !== '' ? (
                    <div className='row'>
                        <input
                            type='password'
                            className='txtCustTax txtBox m-0 p-1'
                            id='password2'
                            name='password2'
                            value={txtPassword2}
                            placeholder='Confirm password'
                            onChange={(e) => {
                                setTxtPassword2(e.target.value)
                            }}
                            title='User Confirm Password'
                            required
                        />
                    </div>
                ) : (
                    ''
                )}
                <div className='row text-danger fs-6'>
                    <div className='col-md-12 m-0 p-0'>
                        {passwordScore < 18 ? (
                            <span className={'text-danger p-0 m-0'}>Password Strength</span>
                        ) : passwordScore >= 18 && passwordScore < 24 ? (
                            <span className={'text-warning p-0 m-0'}>Password Strength</span>
                        ) : (
                            <span className={'text-success p-0 m-0'}>Password Strength</span>
                        )}
                        <ul className='list-group list-group-horizontal m-0 p-0'>
                            <li className='list-group-item bg-danger col-md-4' style={{ padding: '1px 0px' }}></li>
                            {passwordScore >= 18 ? <li className='list-group-item bg-warning col-md-4' style={{ padding: '1px 0px' }}></li> : ''}
                            {passwordScore >= 24 ? <li className='list-group-item bg-success col-md-4' style={{ padding: '1px 0px' }}></li> : ''}
                        </ul>
                        <p className='m-0 p-0'> {passwordError}</p>
                        <p className='m-0 p-0'> {passwordNotMatch ? 'Does not match' : ''}</p>
                        {passwordScore >= 18 && passwordScore < 24 && passwordLength < 9 ? (
                            <div className='m-0 p-0 text-warning'>Increase Password Length</div>
                        ) : (
                            ''
                        )}
                        {passwordScore >= 18 && passwordScore < 24 && (passwordInput.match(numbersRegExp) || []).length <= 2 ? (
                            <div className='m-0 p-0 text-warning'>Use More Numbers</div>
                        ) : (
                            ''
                        )}
                        {passwordScore >= 18 && passwordScore < 24 && (passwordInput.match(specialCountRegExp) || []).length <= 2 ? (
                            <div className='m-0 p-0 text-warning'>Use More Special Characters</div>
                        ) : (
                            ''
                        )}
                        {/*passwordScore + ' Number: ' + (passwordInput.match(numbersRegExp) || []).length*/}
                        {/* passwordScore += letterCount passwordScore += numberCount * 3 passwordScore += specialCount * 5 */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ROPassword
