import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import { toast } from 'react-toastify'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import { formatInputDate } from '../features/formatTime'
import { addROErrorLog, VaildateForm, getFranSettingsJSON, getServicePartsStr } from '../features/globalFunctions'
import { MaintenanceTypes, RegExPattern } from '../features/globalVars'

//Import Redux State/Global Var and Axiox database calls
import { updateService, deleteService } from '../features/services/servicesSlice.js'

//Used to refresh equipment if services was changed. Mainly to update last service date.
import { getEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice'

import ROComboBox from './ROComboBox'
import { formatTime, formatShortDate } from '../features/formatTime'
import { getFranSettings } from '../features/franacc/franaccSlice'

const ServiceItem = ({ service, arrCategories, small = false, containerBackColor, collapse, _expanedState, _editState, serviceChangeCallback, displayOnly = false, hideNotes = false, equipSettings = [1, 1, 1, 1, 1] }) => {
    //    const [txtMaintenanceObj, setTxtMaintenanceObj] = useState({ ID: service.ServiceType, Success: service.ServiceSuccessful })
    const dispatch = useDispatch()

    // if (service) {
    //     console.log(`*** Service  ${service.ServiceWorkRef}  Draw ***`, service)
    // }

    //console.log(`*** Service Draw ***`)

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    const curSymb = getFranSettingsJSON(franacc, 'CurrencySymb') ? getFranSettingsJSON(franacc, 'CurrencySymb') : 'R'
    const pType = getFranSettingsJSON(franacc, 'Bar') ? getFranSettingsJSON(franacc, 'Bar') : 'Bar'

    /* JSON Service Items / Parts */
    const [serviceTotal, setServiceTotal] = useState(0)

    //console.log('service.servicejson from allServices: ', service.servicejson)

    let jsonparts = []
    let partsListStr = ''

    //parse the serviceJSON to js obj and also call global(getServicePartsStr) to get parts to display as text.
    if (service && service != null && service.servicejson && service.servicejson != null) {
        jsonparts = service.servicejson ? JSON.parse(service.servicejson) : []

        //partListStr the quick string to show which parts are in the service on compact view
        partsListStr = service.servicejson ? getServicePartsStr(service.servicejson) : []
    }

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)
    //const [txtMaintenanceTypeID, setTxtMaintenanceTypeID] = useState(service ? service.ServiceType : 0)

    //#region All Services
    const { services, isServicesError, servicesmessage } = useSelector((state) => state.service)

    //Edit Service
    const [txtServiceDate, setTxtServiceDate] = useState(service ? formatInputDate(service.ServiceDate) : formatInputDate(Date()))

    const [txtServiceTechID, setTxtServiceTechID] = useState(service ? service.UserID : -1)
    const [txtMaintenanceObj, setTxtMaintenanceObj] = useState({ ID: service.ServiceType, Success: service.ServiceSuccessful })
    const [txtServiceRef, setTxtServiceRef] = useState(service ? service.ServiceWorkRef : '')

    const [txtTDSSystem, setTxtTDSSystem] = useState(service ? service.ServiceTDSSystem : '')
    const [txtTDSFeed, setTxtTDSFeed] = useState(service ? service.ServiceTDSFeed : '')
    const [txtFeedPressure, setTxtFeedPressure] = useState(service ? service.ServiceFeedPressure : '')
    const [txtTankPressure, setTxtTankPressure] = useState(service ? service.ServiceTankPressure : '')
    const [boolControls, setBoolControls] = useState(service ? service.ServiceControls : false)
    //const [boolSuccessful, setBoolSuccessful] = useState(service ? service.ServiceSuccessful : false)
    const [txtComments, setTxtComments] = useState(service ? service.ServiceNotes : '')

    const [arrServiceParts, setArrServiceParts] = useState(service ? jsonparts : [])

    const editService = async (edit = true) => {
        if (!edit) {
            //Reset values to prev if edit is canceled
            setEditState((prev) => !prev)
            setTxtServiceDate(service.ServiceDate ? formatInputDate(service.ServiceDate) : formatInputDate(Date()))

            console.log('editService setTxtServiceDate: ', txtServiceDate)

            setTxtServiceTechID(service.UserID ? service.UserID : -1)

            setTxtServiceRef(service.ServiceWorkRef ? service.ServiceWorkRef : '')

            setTxtTDSSystem(service.ServiceTDSSystem ? service.ServiceTDSSystem : 0)
            setTxtTDSFeed(service.ServiceTDSFeed ? service.ServiceTDSFeed : 0)
            setTxtFeedPressure(service.ServiceFeedPressure ? service.ServiceFeedPressure : 0)
            setTxtTankPressure(service.ServiceTankPressure ? service.ServiceTankPressure : 0)
            setBoolControls(service.ServiceControls ? service.ServiceControls : false)
            //setBoolSuccessful(service.ServiceSuccessful ? service.ServiceSuccessful : false)
            setTxtMaintenanceObj({ ID: 0, Success: true })
            setTxtMaintenanceObj({
                ID: service.ServiceType ? service.ServiceType : 0,
                Success: service.ServiceSuccessful ? service.ServiceSuccessful : true,
            })
            setTxtComments(service.ServiceNotes ? service.ServiceNotes : '')

            console.log(txtServiceRef + ' - ServiceItem On Edit Before Assign - arrServiceParts: ', arrServiceParts)

            setArrServiceParts(jsonparts ? jsonparts : [])

            return
        }

        setArrServiceParts(jsonparts ? jsonparts : [])

        const serviceParts = arrServiceParts.map((item) => {
            return { ...item, ServicePartPrice: parseFloat(item.ServicePartPrice) }
        })

        if (editState) {
            const updService = {
                serviceID: service.ServiceID,
                p_EquipCustLocID: service.EquipCustLocID,
                p_UserID: txtServiceTechID,
                p_ServiceWorkRef: txtServiceRef,
                p_ServiceDate: txtServiceDate,
                p_ServiceTDSSystem: txtTDSSystem,
                p_ServiceTDSFeed: txtTDSFeed,
                p_ServiceFeedPressure: pType === 'PSI' ? txtFeedPressure / 14.5 : txtFeedPressure,
                p_ServiceTankPressure: pType === 'PSI' ? txtTankPressure / 14.5 : txtTankPressure,
                p_ServiceControls: boolControls,
                p_ServiceNotes: txtComments,
                p_ServiceSuccessful: txtMaintenanceObj.Success,
                p_ServiceType: txtMaintenanceObj.ID,
                p_ServiceFlags: '',
                p_ServiceCustom1: '',
                p_ServiceCustom2: '',
                p_ServiceCustom3: '',
                p_json: JSON.stringify(serviceParts),
            }

            console.log('updateService(updService): ', updService)

            await dispatch(updateService(updService))
            dispatch(getEquipCustLoc())

            //editService(false)
            //updateCustomerDetails(CustID)
            //refreshCustCallback()

            if (serviceChangeCallback) {
                console.log('serviceChangeCallback serviceChangeCallback')
                serviceChangeCallback()
            }
        } else {
            //console.log('Edit EquipName: ', txtEquipName)
        }
        setEditState((prev) => !prev)
    }

    //Delete Service
    const confirmDeleteService = (setState) => {
        if (confirmDelete && setState) {
            //console.log('Delete!!!! ServiceID: ', service.ServiceID)
            deleteServiceById(service.ServiceID)
        }
        setDeleteState(setState)
    }

    const deleteServiceById = async (_id) => {
        console.log('Delete!!!! Service_ID: ', _id)
        await dispatch(deleteService(_id))
        dispatch(getEquipCustLoc())
        confirmDeleteService(false)

        if (serviceChangeCallback) {
            console.log('serviceChangeCallback serviceChangeCallback')
            serviceChangeCallback()
        }
    }

    /*Parts*/
    //Get State parts from redux
    const { parts, isPartsError, isPartsSuccess, partsmessage } = useSelector((state) => state.parts)

    const addPart = (partID) => {
        let serviceParts = [...arrServiceParts]

        let newPart = parts.find((part) => part.PartID === partID)

        let newServiceItem = {
            ServicePartID: newPart.partID,
            ServicePartCode: newPart.PartCode.replace(/"/g, '\\"'),
            //ServicePartName: newPart.PartName.replace('"', '\\"'),
            ServicePartName: newPart.PartName.replace(/"/g, '\\"'),
            ServicePartSerialNR: '',
            ServicePartQty: 1,
            ServicePartPrice: newPart.PartLastPrice.toFixed(2),
            //ServicePartCategory: newPart.PartCategory.replace('"', '\\"'),
            ServicePartCategory: newPart.PartCategory.replace(/"/g, '\\"'),
            //(/"/g, '\\"')
            ServicePartNotes: '',
            ServicePartFlags: '',
        }

        console.log('newServiceItem: ', newServiceItem)

        serviceParts.push(newServiceItem)
        setArrServiceParts(serviceParts)

        console.log('arrServiceParts: ', arrServiceParts)

        console.log('call total')
        calcTotalService(serviceParts)
    }

    // const updateServicePart = (i, newpriceobj) => {
    //     let serviceParts = [...arrServiceParts]

    //     serviceParts[i] = { ...serviceParts[i], ...newpriceobj }

    //     //console.log('updateServicePart: ', newpriceobj, ' arrServiceParts: ', arrServiceParts)

    //     setArrServiceParts(serviceParts)
    //     //console.log('after arrServiceParts: ', arrServiceParts)
    //     calcTotalService(serviceParts)
    // }

    const updateServicePart = (i, newpriceobj) => {
        let serviceParts = [...arrServiceParts]

        serviceParts[i] = { ...serviceParts[i], ...newpriceobj }
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const updateServicePartPrice = (i, newprice, forceValidation = false) => {
        if (!newprice || newprice === null || newprice === '') {
            newprice = ''
        }

        if (forceValidation) {
            if (newprice === '') {
                newprice = 0
            }

            newprice = parseFloat(newprice).toFixed(2)
        }

        let newpriceobj = { ServicePartPrice: newprice.toString().replace(/[^0-9\.]+/g, '') }

        let serviceParts = [...arrServiceParts]

        serviceParts[i] = { ...serviceParts[i], ...newpriceobj }
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const removeServicePart = (i) => {
        let serviceParts = [...arrServiceParts]
        serviceParts.splice(i, 1)
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const calcTotalService = (partslist) => {
        let total = 0

        //console.log('partslist: ', partslist)

        partslist.map((part) => (total += part.ServicePartPrice * part.ServicePartQty))

        //console.log('total: ', total)
        setServiceTotal(parseFloat(total.toFixed(2)))
    }

    //#region  Form Validation
    const [boolServiceFormValidated, setServiceFormValidated] = useState(false)

    const valServiceRef = useRef()
    const valComments = useRef()
    const valServiceDate = useRef()
    const valServiceTechnician = useRef()
    const valMaintenanceType = useRef()
    const valServiceTDSFeed = useRef()
    const valServiceTDSSystem = useRef()
    const valServiceFeedPressure = useRef()
    const valServiceTankPressure = useRef()

    const vaildateServiceForm = (clicked = false) => {
        //console.log('editState: ', editState)
        if (!editState) return

        //Validate service ref or invoice num for doubles
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        // if (
        //     services &&
        //     services.length > 0 &&
        //     services.some(
        //         (e) =>
        //             e.ServiceWorkRef === valServiceRef.current.value &&
        //             e.ServiceID !== service.ServiceID
        //     )
        // ) {
        //     const existService = services.find(
        //         (e) => e.ServiceWorkRef === valServiceRef.current.value
        //     )

        //     setServiceFormValidated(false)

        //     if (clicked) {
        //         toast.error('Service Ref already exist!')
        //         console.log('Service Ref already exist: ', existService)
        //     }
        //     return
        // }
        valMaintenanceType.current.readonly = false
        setServiceFormValidated(
            VaildateForm(
                [
                    {
                        ref: valServiceRef,
                        text: 'No Special Characters',
                        form: 'Edit Service valServiceRef',
                    },
                    {
                        ref: valComments,
                        text: 'No Special Characters',
                        form: 'Edit Service valComments',
                    },
                    {
                        ref: valServiceDate,
                        text: 'Invalid Date',
                        form: 'Edit Service valServiceDate',
                    },
                    {
                        ref: valServiceTechnician,
                        text: 'Invalid Technician',
                        form: 'Edit Service valServiceTechnician',
                    },
                    {
                        ref: valMaintenanceType,
                        text: 'Invalid Maintenance Type',
                        form: 'New Service valMaintenanceType',
                    },
                    {
                        ref: valServiceTDSFeed,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceTDSFeed',
                    },
                    {
                        ref: valServiceTDSSystem,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceTDSSystem',
                    },
                    {
                        ref: valServiceFeedPressure,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceFeedPressure',
                    },
                    {
                        ref: valServiceTankPressure,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceTankPressure',
                    },
                ],
                clicked
            )
        )
    }

    useEffect(() => {
        //REdraw on validation change
        vaildateServiceForm()
        //Do validation on ROCombobox changes Service Rep ID
    }, [txtServiceTechID])
    //#endregion

    useEffect(() => {
        calcTotalService(jsonparts)

        return () => {
            //second
        }
    }, [])

    let arrParts = []
    if (parts) {
        arrParts = parts.map((part) => {
            return {
                Text: '(' + part.PartCode + ') ' + part.PartName + ' - ' + part.PartCategory,
                ID: part.PartID,
            }
        })
    }

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } = useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        try {
            arrSalesReps = new Set(
                franusers.map((reps) => {
                    return { name: reps.name, ID: reps._id }
                })
            )
            arrSalesReps = Array.from(arrSalesReps)
            arrSalesReps = arrSalesReps.map((reps) => {
                return {
                    Text: reps.name,
                    ID: reps.ID,
                }
            })
        } catch (error) {
            console.log('ServicesItem arrSalesReps Error: ', error)

            toast.error('ServicesItem arrSalesReps Error: ' + error.toString())
            addROErrorLog('ServicesItem arrSalesReps Error:  ' + error.toString(), '', 'ServicesItem')
        }
    }

    const getRepName = (id) => {
        let retVal = arrSalesReps.find((rep) => {
            //console.log('Rep id: ', rep, id)
            if (rep.ID === id) {
                //console.log('Choosen Rep id: ', rep)
                return rep.Text
            }
        })

        //console.log('getRepName: ', retVal)

        if (retVal) {
            return retVal.Text
        } else {
            return ''
        }
    }

    //Maintenance Types ROCombobox Array
    let arrMaintTypes = []
    if (MaintenanceTypes) {
        try {
            const MaintTypes = Array.from(MaintenanceTypes)
            //console.log('arrMaintTypes: ', arrMaintTypes)
            // arrMaintTypes = arrMaintTypes.map((item, i) => {
            //     return { Text: item.title, ID: item.id }, { Text: item.titleFail, ID: item.id }
            // })
            MaintTypes.forEach((item, i) => {
                arrMaintTypes.push({ Text: item.title, ID: item.id + i })
                arrMaintTypes.push({ Text: item.titleFail, ID: item.id + 1 + i })
            })

            //console.log('arrMaintTypes: ', arrMaintTypes)
        } catch (error) {
            console.log('Users arrRoles Error: ', error)

            toast.error('Users arrRoles Error: ' + error.toString())
            addROErrorLog('ServiceItem arrMaintTypes Error:  ' + error.toString(), 'ServiceItem')
        }
    }

    const setMaintenaceIDCallback = (id) => {
        const successBool = !((id + 1) % 2 === 0)

        //setBoolSuccessful(successBool)

        //console.log('successBool: ', successBool, ' txtMaintenanceTypeID : ', id)

        //console.log('before boolSuccessful.current: ', successBool);
        if (successBool) {
            //setTxtMaintenanceTypeID((prev) => id / 2 )
            setTxtMaintenanceObj({ ID: id / 2, Success: true })

            //console.log('(id / 2 - id / 2): ' , id / 2 )
        } else {
            //setTxtMaintenanceTypeID((prev) => (id - 1) / 2)
            setTxtMaintenanceObj({ ID: (id - 1) / 2, Success: false })
            //console.log('(id + 1) / 2 - (id + 1) / 2 :', (id - 1) / 2 )
        }

        //console.log('after boolSuccessful.current: ', successBool);
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    return (
        //bg-primary to "simulate" or create space between services child container.
        <ContentBox classTag={toggleState ? `serviceBox content p-0 pt-1 m-0 col-md-12 rounded-2  ${containerBackColor}` : `serviceBox content p-1 m-0 ` + (small ? 'col-md-4' : 'col-md-6') + ` rounded-2 ${containerBackColor}`}>
            <div className='content'>
                {toggleState ? (
                    /*Toggled On/True EXPANDED State*/
                    /*Toggled On/True EXPANDED State*/
                    //#region Expanded

                    !editState ? (
                        <div className={`container p-0 m-0 rounded-2 bg-servicewhite`}>
                            <div className='row p-0 m-0' /*Service First Line Expanded*/>
                                <div className='ROmaintxt col-md-12 m-0 p-0  fs-5'>
                                    <div className='row p-0 m-0'>
                                        <div className='col-md-6 p-0 m-0'>
                                            <ROIcon
                                                icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'}
                                                size={48}
                                                className='icoSVG'
                                                tooltip={MaintenanceTypes ? (txtMaintenanceObj.Success ? MaintenanceTypes[txtMaintenanceObj.ID].title : MaintenanceTypes[txtMaintenanceObj.ID].titleFail) : 'marknew'}
                                                //onClickCallback={() => AddNewService()}
                                                // style={{
                                                //     animation: 'icofocus 4s infinite',
                                                // }}
                                                usefill='true'
                                                //color1={(txtMaintenanceTypeID + 1) % 2 === 0 ? '#ff0000' : '#32bebe'}
                                                color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'}
                                                authlvl={99}
                                            />
                                            <span className='fw-bold fs-6'>{formatShortDate(Date.parse(service.ServiceDate))}</span>
                                        </div>
                                        {/*Interface Buttons*/}
                                        <div className='col-auto ms-auto m-0 p-0'>
                                            <div>
                                                {editState ? (
                                                    <>
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className={!displayOnly ? 'icoSVG borderlp' : 'icoSVG borderlp invisible'}
                                                            tooltip='Update Service'
                                                            onClickCallback={() => editService()}
                                                            style={{
                                                                animation: 'pulse 4s infinite',
                                                            }}
                                                            authlvl={10}
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                        <ROIcon icon='ROicoCancel' size={24} className={!displayOnly ? 'icoSVG borderlp' : 'icoSVG borderlp invisible'} tooltip='Cancel' onClickCallback={() => editService(false)} />
                                                    </>
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoEdit'
                                                        size={24}
                                                        className={!displayOnly ? 'icoSVG borderlp' : 'icoSVG borderlp invisible'}
                                                        tooltip='Edit Service'
                                                        authlvl={10}
                                                        onClickCallback={() => {
                                                            setServiceFormValidated(true)
                                                            editService()
                                                        }}
                                                    />
                                                )}

                                                {!editState ? (
                                                    <>
                                                        {confirmDelete ? <ROIcon icon='ROicoDel' size={24} className={!displayOnly ? 'icoSVG borderlp danger' : 'icoSVG borderlp danger invisible'} tooltip='Delete Service' authlvl={10} onClickCallback={() => confirmDeleteService(true)} /> : ''}
                                                        {!confirmDelete ? <ROIcon icon='ROicoDel' size={24} className={!displayOnly ? 'icoSVG borderlp' : 'icoSVG borderlp invisible'} tooltip='Delete Service' authlvl={10} onClickCallback={() => confirmDeleteService(true)} /> : ''}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {!editState ? confirmDelete ? <ROIcon icon='ROicoClose' size={24} className={!displayOnly ? 'icoSVG ' : 'icoSVG  invisible'} tooltip='Cancel Delete' onClickCallback={() => confirmDeleteService(false)} /> : '' : ''}
                                                {/*Toggle Icon*/}
                                                {/*Expande/Collapse Icon*/}
                                                {editState ? '' : <ROIcon icon='ROicoXUp' className='icoSVG borderlp' onClickCallback={() => setToggleState((prev) => !prev)} authlvl={99} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row p-0 m-0 ms-3' /*Service First Line Expanded*/>
                                <div className='ROnormaltxt col-md-2 fs-5'>
                                    <ROIcon icon='ROicoReport' className='icoSVG' tooltip='Service Reference' authlvl={99} />
                                    <span className='fw-normal fs-6'>{service.ServiceWorkRef}</span>
                                </div>
                                <div className='ROnormaltxt col-md-2 fs-5'>
                                    <ROIcon icon='ROicoUser' className='icoSVG' tooltip='Technician' authlvl={99} />
                                    <span className='fw-normal fs-6'>{service.Username}</span>
                                </div>

                                <div className='ROnormaltxt col-md-7 fs-5'>
                                    <ROIcon
                                        icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'}
                                        size={24}
                                        className='icoSVG'
                                        tooltip={MaintenanceTypes ? (txtMaintenanceObj.Success ? MaintenanceTypes[txtMaintenanceObj.ID].title : MaintenanceTypes[txtMaintenanceObj.ID].titleFail) : 'marknew'}
                                        textafter={txtMaintenanceObj.Success ? MaintenanceTypes[txtMaintenanceObj.ID].title : MaintenanceTypes[txtMaintenanceObj.ID].titleFail}
                                        usefill='true'
                                        color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'}
                                        authlvl={99}
                                    />
                                </div>
                            </div>

                            <div className='row p-0 m-0 ms-3' /*Service Second Line Expanded*/>
                                <div className={equipSettings[0] === 1 ? 'ROnormaltxt col-md-2 fs-5' : 'ROnormaltxt col-md-2 fs-5 invisible'}>
                                    <ROIcon icon='ROicoFeed' className='icoSVG' tooltip='TDS Feed' authlvl={99} />
                                    {service.ServiceTDSFeed} tds
                                </div>
                                <div className={equipSettings[1] === 1 ? 'ROnormaltxt col-md-2 fs-5' : 'ROnormaltxt col-md-2 fs-5 invisible'}>
                                    <ROIcon icon='ROicoRO' className='icoSVG' tooltip='Feed Pressure' authlvl={99} />
                                    {service.ServiceTDSSystem} tds
                                </div>

                                <div className={equipSettings[2] === 1 ? 'ROnormaltxt col-md-2 fs-5' : 'ROnormaltxt col-md-2 fs-5 invisible'}>
                                    <ROIcon icon='ROicoFPres' className='icoSVG' tooltip='Feed Pressure' authlvl={99} />
                                    {service.ServiceFeedPressure} {pType === 'PSI' ? 'psi' : 'bar'}
                                </div>
                                <div className={equipSettings[3] === 1 ? 'ROnormaltxt col-md-2 fs-5' : 'ROnormaltxt col-md-2 fs-5 invisible'}>
                                    <ROIcon icon='ROicoTpres' className='icoSVG' tooltip='Tank Pressure' authlvl={99} />
                                    {service.ServiceTankPressure} {pType === 'PSI' ? 'psi' : 'bar'}
                                </div>
                                <div className={equipSettings[4] === 1 ? 'ROnormaltxt col-md-2 fs-5' : 'ROnormaltxt col-md-2 fs-5 invisible'}>
                                    {service.ServiceControls === 1 ? <ROIcon icon='ROicoCheck' className='icoSVG' tooltip='Controls' authlvl={99} /> : <ROIcon icon='ROicoClose' className='icoSVG' tooltip='Controls' authlvl={99} />}
                                    Controls
                                </div>
                                {/* <div className='ROnormaltxt col-2 fs-5'>
                                    {service.ServiceSuccessful === 1 ? (
                                        <>
                                            <ROIcon icon='ROicoCheck' className='icoSVG' tooltip='Successful' />
                                            Successful
                                        </>
                                    ) : (
                                        <>
                                            <ROIcon icon='ROicoClose' className='icoSVG' tooltip='Unsuccessful' usefill='true' color1='#ff0000' />
                                            Unsuccessful
                                        </>
                                    )}
                                </div> */}
                            </div>

                            {!hideNotes ? (
                                <div className='row p-0 m-0 ms-3' /*Service Third Line Expanded*/>
                                    <div className='font-italic col-md-9 fs-5'>
                                        <ROIcon icon='ROicoComments' className='icoSVG' tooltip='Service Note' authlvl={99} />
                                        <span className='fs-6'>
                                            <i>{service.ServiceNotes}</i>
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            <hr className='servicesThick px-4 mx-4' />

                            <div className='row p-0 m-0 mx-4' /*Service Parts / Items*/>
                                {jsonparts.map((part, i) => {
                                    return (
                                        <div className={(i + 1) % 2 === 0 ? 'row bg-serviceswhite p-0 m-0' : 'row bg-secondary p-0 m-0'}>
                                            <div className='col-md-2 small text-end fw-bold p-0 pe-2 m-0 pt-1'>{part.ServicePartCode.replace(/\\/g, '')}</div>
                                            <div className='col-md-5 fs-6'>{part.ServicePartName.replace(/\\/g, '')}</div>
                                            <div className='col-md-1 fs-6'>{part.ServicePartQty}</div>
                                            <div className='col-md-2 fs-6 text-end'>
                                                {curSymb}
                                                {part.ServicePartPrice}
                                            </div>
                                            <div className='col-md-2 fs-6 text-end'>
                                                {curSymb}
                                                {(part.ServicePartPrice * part.ServicePartQty).toFixed(2)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='row ' /*Service Parts List Total NOT EDIT*/>
                                <div className='ROmaintxt col-md-10 fs-5 '></div>
                                <div className='ROmaintxt col-auto fs-5 '>
                                    Total: {curSymb}
                                    {serviceTotal.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        /*EDIT Service */
                        /*EDIT Service */
                        /*EDIT Service */
                        <div className='serviceBox col-md-12 m-0 p-0 m-0 fs-5 bg-servicewhite rounded-2'>
                            <div className='row p-0 m-0'>
                                <div className='col-md-6 p-0 m-0'>
                                    <ROIcon
                                        icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'}
                                        size={48}
                                        className='icoSVG'
                                        tooltip='Edit Maintance'
                                        //onClickCallback={() => AddNewService()}
                                        // style={{
                                        //     animation: 'icofocus 4s infinite',
                                        // }}
                                        usefill='true'
                                        //color1={(txtMaintenanceTypeID + 1) % 2 === 0 ? '#ff0000' : '#32bebe'}
                                        color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'}
                                        authlvl={99}
                                    />
                                    <span className='text-primary fw-bold fs-6'>Edit Maintenance</span>
                                </div>
                                <div className='col-auto ms-auto m-0 p-0'>
                                    <>
                                        {boolServiceFormValidated ? (
                                            <ROIcon
                                                icon='ROicoAccept'
                                                size={24}
                                                className='icoSVG borderlp'
                                                tooltip='Update Service'
                                                onClickCallback={() => editService()}
                                                style={{
                                                    animation: 'icofocus 4s infinite',
                                                }}
                                                usefill='true'
                                                color1='#32bebe'
                                            />
                                        ) : (
                                            <ROIcon
                                                icon='ROicoAccept'
                                                size={24}
                                                className='icoSVG borderlp'
                                                onClickCallback={() => vaildateServiceForm(true)}
                                                style={{
                                                    animation: 'pulse 4s infinite',
                                                }}
                                                usefill='true'
                                                color1='#ff0000'
                                            />
                                        )}
                                        <ROIcon
                                            icon='ROicoCancel'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Cancel'
                                            onClickCallback={() => editService(false)}
                                            //usefill='true'
                                            //color1='#ffffff'
                                        />
                                    </>
                                </div>
                            </div>
                            <div className='row m-0 p-0 my-0 py-0' /*Service Date:*/>
                                <div className='small fw-bold col-lg-2'>
                                    Service Date:
                                    <input
                                        className='txtBox px-0 mx-0'
                                        value={txtServiceDate}
                                        type='date'
                                        onChange={(e) => {
                                            setTxtServiceDate(e.target.value)
                                            vaildateServiceForm()
                                        }}
                                        ref={valServiceDate}
                                        title='Service Date'
                                        //pattern={datePattern}
                                        required
                                    />
                                </div>
                                <div className='small fw-bold col-lg-3'>
                                    Service Ref:
                                    <input
                                        className='txtBox'
                                        value={txtServiceRef}
                                        placeholder='Service Reference'
                                        onChange={(e) => {
                                            setTxtServiceRef(e.target.value)
                                            vaildateServiceForm()
                                        }}
                                        ref={valServiceRef}
                                        title='Service Reference'
                                        pattern={RegExPattern.Text}
                                        required
                                    />
                                </div>
                                <div className='small fw-bold col-lg-3'>
                                    Technician:
                                    <div className='ROnormaltxt fs-5'>
                                        <ROComboBox dataArray={arrSalesReps} placeholdertext={'Search Technician'} classname='ROdropdownFull small fw-bold ' textVal={getRepName(txtServiceTechID)} myIDCallback={setTxtServiceTechID} ref={valServiceTechnician} required={true} validationCallBack={() => vaildateServiceForm()} allowCustomValue={false} />
                                    </div>
                                </div>
                                <div className='small fw-bold col-lg-3'>
                                    Type:
                                    <div className='ROnormaltxt fs-5'>
                                        <div className='row p-0 m-0'>
                                            <div className='col-md-1 p-0 m-0'>
                                                {' '}
                                                <ROIcon
                                                    icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'}
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Add New Maintenance'
                                                    //onClickCallback={() => AddNewService()}
                                                    // style={{
                                                    //     animation: 'icofocus 4s infinite',
                                                    // }}
                                                    usefill='true'
                                                    //color1={(txtMaintenanceTypeID + 1) % 2 === 0 ? '#ff0000' : '#32bebe'}
                                                    color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'}
                                                    authlvl={99}
                                                />
                                            </div>
                                            <div className='col-md-11 p-0 m-0'>
                                                <ROComboBox
                                                    dataArray={arrMaintTypes}
                                                    placeholdertext={'Maintenance Type'}
                                                    classname='ROdropdown small fw-bold '
                                                    textVal={MaintenanceTypes ? (txtMaintenanceObj.Success ? MaintenanceTypes[txtMaintenanceObj.ID].title : MaintenanceTypes[txtMaintenanceObj.ID].titleFail) : ''}
                                                    myIDCallback={setMaintenaceIDCallback}
                                                    ref={valMaintenanceType}
                                                    required={true}
                                                    validationCallBack={() => vaildateServiceForm()}
                                                    allowCustomValue={false}
                                                    allowFilter={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-0 p-0 my-0 py-0 m-3' /*Service Equip Info:*/>
                                <div className={equipSettings[0] === 1 ? 'small fw-bold col-lg-2 p-0 ' : 'col-lg-2 p-0 m-0 fs-6 invisible'}>
                                    TDS Feed
                                    <input
                                        className='txtBox'
                                        value={txtTDSFeed !== '' ? txtTDSFeed : 0}
                                        placeholder='TDS Feed'
                                        ref={valServiceTDSFeed}
                                        title='TDS Feed'
                                        pattern={RegExPattern.Int}
                                        onChange={(e) => {
                                            setTxtTDSFeed(e.target.value.replace(/[^0-9]+/g, ''))
                                            vaildateServiceForm()
                                        }}
                                        required
                                    />
                                </div>
                                <div className={equipSettings[1] === 1 ? 'small fw-bold col-lg-2 p-0 ps-1 m-0' : 'col-lg-2 p-0 ps-1 m-0 fs-6 invisible'}>
                                    TDS System
                                    <input
                                        className='txtBox'
                                        value={txtTDSSystem !== '' ? txtTDSSystem : 0}
                                        placeholder='TDS System'
                                        ref={valServiceTDSSystem}
                                        title='TDS System'
                                        pattern={RegExPattern.Int}
                                        onChange={(e) => {
                                            setTxtTDSSystem(e.target.value.replace(/[^0-9]+/g, ''))
                                            vaildateServiceForm()
                                        }}
                                        required
                                    />
                                </div>
                                <div className={equipSettings[2] === 1 ? 'small fw-bold col-lg-2 p-0 ps-1 m-0' : 'col-lg-2 p-0 ps-1 m-0 fs-6 invisible'}>
                                    Feed Pressure
                                    <input
                                        className='txtBox'
                                        value={txtFeedPressure !== '' ? txtFeedPressure : 0}
                                        placeholder='Feed Pressure'
                                        ref={valServiceFeedPressure}
                                        title='Feed Pressure'
                                        pattern={RegExPattern.Dec}
                                        onChange={(e) => {
                                            setTxtFeedPressure(e.target.value.replace(/[^0-9\.]+/g, ''))
                                            vaildateServiceForm()
                                        }}
                                        required
                                    />
                                </div>
                                <div className={equipSettings[3] === 1 ? 'small fw-bold col-lg-2 p-0 ps-1 m-0' : 'col-lg-2 p-0 ps-1 m-0 fs-6 invisible'}>
                                    Tank Pressure
                                    <input
                                        className='txtBox'
                                        value={txtTankPressure !== '' ? txtTankPressure : 0}
                                        placeholder='Tank Pressure'
                                        ref={valServiceTankPressure}
                                        title='Tank Pressure'
                                        pattern={RegExPattern.Dec}
                                        onChange={(e) => {
                                            setTxtTankPressure(e.target.value.replace(/[^0-9\.]+/g, ''))
                                            vaildateServiceForm()
                                        }}
                                        required
                                    />
                                </div>

                                {/* <ROIcon CheckBox /> */}
                                <div className={equipSettings[4] === 1 ? 'small fw-bold col-lg-2 ' : 'col-lg-2 fs-5 pt-1 invisible'}>
                                    <ROIcon
                                        icon={boolControls ? 'ROicoCheckbox' : 'ROicoBox'}
                                        size={24}
                                        className='icoSVG fw-bold p-0 m-0'
                                        tooltip='Controls Tested'
                                        //usefill='true'
                                        //color1='#ffffff'
                                        textbefore='Controls'
                                        onClickCallback={() => setBoolControls((prev) => !prev)}
                                        authlvl={99}
                                    />
                                </div>
                                {/* <div className='col-2 fs-5 pt-1'>
                                    { <ROIcon
                                        icon={boolSuccessful ? 'ROicoCheckbox' : 'ROicoBox'}
                                        size={24}
                                        className='icoSVG text-white fw-bold'
                                        tooltip='Service Successful'
                                        usefill='true'
                                        color1={boolSuccessful ? '#ffffff' : '#ff0000'}
                                        textbefore='Successful'
                                        onClickCallback={() => setBoolSuccessful((prev) => !prev)}
                                        style={{
                                            animation: 'pulse 4s infinite',
                                        }}
                                    /> }
                                </div> */}
                            </div>
                            <div className='row p-0 m-0' /*Service User + Comments*/>
                                <div className='col-lg-12 p-0 ps-3 pe-4 m-0 fs-6'>
                                    <input
                                        className='txtBox'
                                        value={txtComments}
                                        placeholder='Service Comments'
                                        onChange={(e) => {
                                            setTxtComments(e.target.value)
                                            vaildateServiceForm()
                                        }}
                                        ref={valComments}
                                        title='Service Comments'
                                        pattern={RegExPattern.Text}
                                    />
                                </div>
                            </div>
                            <hr className='servicesThick px-4 mx-4' />
                            <div className='row p-0 m-0 mx-4' /*Service Parts / Items*/>
                                {/* New Service Parts / Items List */}
                                {arrServiceParts.map((part, i) => (
                                    <>
                                        <div className={(i + 1) % 2 === 0 ? 'row bg-serviceswhite p-0 m-0' : 'row bg-secondary p-0 m-0'}>
                                            <div className='col-md-2 small text-end fw-bold p-0 pe-2 m-0 pt-1'>
                                                {part.ServicePartCode.replace(/\\/g, '')}
                                                {/* + '(' +
                                                            part.ServicePartID +
                                                            ')' */}
                                            </div>
                                            <div className='col-md-4 small p-0 m-0 pt-1'>{part.ServicePartName.replace(/\\/g, '')}</div>
                                            <div className='col-md-2 p-0 m-0'>
                                                {' '}
                                                {/* SERIAL NR */}
                                                <input
                                                    className='txtBox small'
                                                    value={part.ServicePartSerialNR || ''}
                                                    placeholder='Serial Nr'
                                                    onChange={(e) =>
                                                        updateServicePart(i, {
                                                            ServicePartSerialNR: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className='col-md-2 p-0 m-0'>
                                                {/* QTY */}
                                                <div className='row p-0 m-0'>
                                                    <div className='col-md-3 ps-2 p-0 m-0'>
                                                        <input
                                                            className='inputBox small text-center'
                                                            value={part.ServicePartQty || ''}
                                                            placeholder='Qty'
                                                            onChange={(e) =>
                                                                updateServicePart(i, {
                                                                    ServicePartQty: parseInt(e.target.value),
                                                                })
                                                            }
                                                            onBlur={(e) =>
                                                                e.target.value === null || e.target.value === ''
                                                                    ? updateServicePart(i, {
                                                                          ServicePartQty: 1,
                                                                      })
                                                                    : true
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-md-9  px-2 m-0'>
                                                        {' '}
                                                        {/* Price */}
                                                        <input className='inputBox small' value={part.ServicePartPrice} placeholder='Price' onChange={(e) => updateServicePartPrice(i, e.target.value)} onBlur={(e) => updateServicePartPrice(i, e.target.value, true)} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-1 small fw-bold p-0 m-0 ms-2 pt-1'>
                                                {curSymb}
                                                {(part.ServicePartPrice * part.ServicePartQty).toFixed(2) || 0}
                                            </div>
                                            <div className='col-auto p-0 m-0 ms-auto pt-1'>
                                                <ROIcon icon='ROicoDel' size={24} className='icoSVG' tooltip='Remove' onClickCallback={() => removeServicePart(i)} usefill='true' color1='#f00' />
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className='row'>
                                {parts ? (
                                    <div className='col-md-12 p-0 px-5 m-0 fs-6'>
                                        <ROComboBox dataArray={arrParts} classname='ROdropdownFull' placeholdertext='Search Parts to Add' myIDCallback={addPart} clearTextOnSelect={true} allowCustomValue={false} />
                                    </div>
                                ) : (
                                    'Loading Parts'
                                )}
                                {arrServiceParts && arrServiceParts.length > 0 ? (
                                    <>
                                        <div className='col-md-10 p-0 m-0'>&nbsp;</div>
                                        <div className='col-md-2 p-0 m-0'>
                                            Total: {curSymb}
                                            {serviceTotal.toFixed(2)}
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    )
                ) : (
                    //#endregion
                    /*Toggled Off/False COMPACT State */
                    /*Toggled Off/False COMPACT State*/
                    //#region Compact
                    <div className={`container p-2 pt-0 rounded-2 bg-servicewhite`} onClick={() => setToggleState((prev) => !prev)}>
                        <div className='row' /*Compact*/>
                            <div className={(small ? '' : 'ROmaintxt') + ' col-md-5  fs-6'}>
                                <ROIcon icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'} className='icoSVG' usefill='true' color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'} tooltip={MaintenanceTypes ? (txtMaintenanceObj.Success ? MaintenanceTypes[txtMaintenanceObj.ID].title : MaintenanceTypes[txtMaintenanceObj.ID].titleFail) : 'marknew'} authlvl={99} />
                                {formatShortDate(Date.parse(service.ServiceDate))}
                                {/* {service.ServiceSuccessful === 1 ? (
                                    <ROIcon icon='ROicoCheck' className='icoSVG' tooltip='Successful' />
                                ) : (
                                    <ROIcon icon='ROicoClose' className='icoSVG' tooltip='Unsuccessful' usefill='true' color1='#ff0000' />
                                )} */}
                            </div>
                            <div className={equipSettings[0] === 1 ? 'ROnormaltxt col-md-3 fs-5' : 'ROnormaltxt col-md-3 fs-5 invisible'}>
                                <ROIcon icon='ROicoFeed' className='icoSVG' tooltip='TDS Feed' authlvl={99} />
                                {service.ServiceTDSFeed}
                            </div>
                            <div className={equipSettings[1] === 1 ? 'ROnormaltxt col-md-3 fs-5' : 'ROnormaltxt col-md-3 fs-5 invisible'}>
                                <ROIcon icon='ROicoRO' className='icoSVG' tooltip='TDS System' authlvl={99} />
                                {service.ServiceTDSSystem}
                            </div>
                            {/*Toggle Icon*/}
                            <div className='col-auto px-0'>
                                {/*Expande/Collapse Icon*/}
                                <ROIcon icon='ROicoXDown' className='icoSVG borderlp' onClick={() => setToggleState(true)} authlvl={99} />
                            </div>
                        </div>
                        <div className='row small' /*Compact*/>
                            <div className='col-auto'>
                                {partsListStr}
                                <span className='fw-bold'>
                                    &nbsp;[<sup>{curSymb}</sup> {serviceTotal + ']'}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ContentBox>
    )
}
export default ServiceItem
