import axios from 'axios'

const API_URL = '/api/franacc/'
const PAYPAL_API_URL = '/api/paypal/'

// //Add new customer
// const createPart = async (partData, token) => {
//     const headersConfig = {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//     }

//     partData = { ...partData, user: { ...user } }

//     const response = await axios.post(API_URL, partData, headersConfig)
//     return response.data
// }

const updatePayPalSub = async (franAccSub, token) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        // Get user from localStorage
        const user = JSON.parse(localStorage.getItem('user'))

        franAccSub = { ...franAccSub, user: { ...user } }

        //console.log('calling updatePayPalSub')

        const response = await axios.put(API_URL + '/paypalsub', franAccSub, headersConfig)

        //console.log('updatePayPalSub response: ', response)
        //console.log('updatePayPalSub response.data: ', response.data)

        return response.data
    } catch (error) {
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        console.log('Error franaccService updatePayPalSub response: ', error.response)

        if (error.response.status === 401) {
            if (error.response.data.message) {
                //Check if server sent a message and create error with message
                throw new Error(error.response.data.message)
            } else {
                //Else create error with statusText
                throw new Error(error.response.statusText + ' Please Log In')
            }
        }

        throw new Error('franaccService(updatePayPalSub): ' + error.response.data.message)
    }
}

//Get Franchise paypal subscription details
const getFranPayPalSubDetails = async (subID, token) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        const response = await axios.get(PAYPAL_API_URL + subID, headersConfig)

        return response.data
    } catch (error) {
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        console.log('franaccService getFranSettings response: ', error.response)

        if (error.response.status === 401) {
            if (error.response.data.message) {
                //Check if server sent a message and create error with message
                throw new Error(error.response.data.message)
            } else {
                //Else create error with statusText
                throw new Error(error.response.statusText + ' Please Log In')
            }
        }

        throw new Error('franaccService(getFranSettings): ' + error.response.data.message)
    }
}

//Get Franchise settings
const getFranSettings = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig)

        return response.data
    } catch (error) {
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        console.log('franaccService getFranSettings response: ', error.response)

        if (error.response.status === 401) {
            if (error.response.data.message) {
                //Check if server sent a message and create error with message
                throw new Error(error.response.data.message)
            } else {
                //Else create error with statusText
                throw new Error(error.response.statusText + ' Please Log In')
            }
        }

        throw new Error('franaccService(getFranSettings): ' + error.response.data.message)
    }
}

const updateFranSettings = async (franAccData, token) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        // Get user from localStorage
        const user = JSON.parse(localStorage.getItem('user'))

        franAccData = { ...franAccData, user: { ...user } }

        //console.log('calling updateFranSettings')

        const response = await axios.put(API_URL + '/', franAccData, headersConfig)

        //console.log('updateFranSettings response: ', response)
        //console.log('updateFranSettings response.data: ', response.data)

        return response.data
    } catch (error) {
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        console.log('Error franaccService updateFranSettings response: ', error.response)

        if (error.response.status === 401) {
            if (error.response.data.message) {
                //Check if server sent a message and create error with message
                throw new Error(error.response.data.message)
            } else {
                //Else create error with statusText
                throw new Error(error.response.statusText + ' Please Log In')
            }
        }

        throw new Error('franaccService(updateFranSettings): ' + error.response.data.message)
    }
}

//Cancel Subscription
const cancelSub = async (subID, token) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        console.log('cancelSub subID: ', subID)

        console.log('cancelSub PAYPAL_API_URL + subID: ', PAYPAL_API_URL + subID)
        const response = await axios.delete(PAYPAL_API_URL + subID, headersConfig)

        console.log(console.log('cancelSub response: ', response))

        return response.data
    } catch (error) {
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        console.log('franaccService cancelSub response: ', error.response)

        if (error.response.status === 401) {
            if (error.response.data.message) {
                //Check if server sent a message and create error with message
                throw new Error(error.response.data.message)
            } else {
                //Else create error with statusText
                throw new Error(error.response.statusText + ' Please Log In')
            }
        }

        throw new Error('franaccService(cancelSub): ' + error.response.data.message)
    }
}

const franaccService = {
    getFranSettings,
    updatePayPalSub,
    updateFranSettings,
    getFranPayPalSubDetails,
    cancelSub,
}

export default franaccService
