import ContentBox from './ContentBox'
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import CustLocItem from '../components/CustLocItem'
import { VaildateForm } from '../features/globalFunctions'
import { RegExPattern } from '../features/globalVars'

//Import Customer Redux State/Global Var and Axiox database calls
import { createCustomer, updateCustomer, deleteCustomer } from '../features/cust/custSlice.js'

//import { createCustomerLoc } from '../features/custloc/custlocSlice.js'

//Component Contructor / Init Parameters
const CustItem = ({ _CustDetails, ChildOjb, classTag, borderParms, backColor, containerBackColor, expanedState, focused = false, focusLocationID = -1, focusEquipLocID = -1, newItem, refreshCustCallback, collapse }) => {
    const dispatch = useDispatch()

    //console.log(`*** CustItem  ${_CustDetails}  Draw ***`)
    //console.log('*** CustItem ' + (CustName ? CustName : '') + ' REDRAW ***')
    //console.log('*** CustItem REDRAW ***')

    ChildOjb = null ? '' : ChildOjb
    classTag = null ? 'content' : classTag
    borderParms = null ? '' : ''
    backColor = null ? '' : ''
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    expanedState = null ? false : expanedState //Expanded or not
    newItem = null ? false : newItem

    if (!_CustDetails) {
        _CustDetails = {}
    }

    let { CustID = '', CustName = 'Enter Name', CustEmail = 'Enter Email', CustReg = 'Company Registration', CustTax = 'Company Tax', CustStreet = 'Street', CustSub = 'Suburb', CustCity = 'City', CustPostal = 'Code', CustCountry = 'Country', CustNotes = '', CustSuspended = 0 } = _CustDetails

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    const editState = newItem

    const [toggleState, setToggleState] = useState(expanedState)
    const [toggleEditState, setEditState] = useState(editState)
    const [confirmDelete, setDeleteState] = useState(false)
    const [addContactState, setContactState] = useState(false)
    const [showLocationContactChatID, setLocationContactChatID] = useState(-1)
    const [showLocationID, setLocationID] = useState(focusLocationID)
    const [allChatState, setAllChatState] = useState(false)
    const [showChatDeletedState, setShowChatDeletedState] = useState(false)

    //New Customer Contacts Input boxes
    let [txtContactName, setTxtContactName] = useState('')
    let [txtContactTel, setTxtContactTel] = useState('')
    let [txtContactEmail, setTxtContactEmail] = useState('')

    const [curCustLocations, setcurCustLocations] = useState([])

    const changeToggleState = () => {
        //console.log('CHANGE TOGGLE STATE!!!!!')
        setToggleState((prev) => !prev)
    }
    const toggleAllChatState = () => {
        setAllChatState((prev) => !prev)
    }
    const toggleChatDeletedState = () => {
        setShowChatDeletedState((prev) => !prev)
    }

    const confirmDeleteCust = (setState) => {
        if (curCustLocations && curCustLocations.length > 0 && curCustLocations.some((e) => e.CustID === CustID)) {
            setDeleteState(false)
            toast('Customer Delete Error: Contains location')
            return
        }

        if (confirmDelete && setState) {
            console.log('Delete!!!!')
            deleteCustomerById(CustID)
        }
        setDeleteState(setState)
    }

    const DisableAddContactState = () => {
        console.log('DisableAddContactState:', addContactState)
        setContactState(false)
    }

    //#region Customers Main

    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtCustName, setTxtCustName] = useState('')
    const [txtCustEmail, setTxtCustEmail] = useState('')
    const [txtCustReg, setTxtCustReg] = useState('')
    const [txtCustTax, setTxtCustTax] = useState('')
    const [txtCustStreet, setTxtCustStreet] = useState('')
    const [txtCustSuburb, setTxtCustSuburb] = useState('')
    const [txtCustCity, setTxtCustCity] = useState('')
    const [txtCustPostal, setTxtCustPostal] = useState('')
    const [txtCustCountry, setTxtCustCountry] = useState(franacc.FranAccCountry)
    const [txtCustNotes, setTxtCustNotes] = useState('')
    const [boolCustSuspended, setBoolCustSuspended] = useState(CustSuspended === 1 ? true : false)

    const switchtoggleEditState = () => {
        console.log('switchtoggleEditState')
        setEditState((prev) => !prev)
    }

    const editCustomer = () => {
        console.log('edit cust')
        if (newItem) {
            CustName = txtCustName
            CustEmail = txtCustEmail
            CustReg = txtCustReg
            CustTax = txtCustTax
            CustStreet = txtCustStreet
            CustSub = txtCustSuburb
            CustCity = txtCustCity
            CustPostal = txtCustPostal
            CustCountry = txtCustCountry
            CustNotes = txtCustNotes
            CustSuspended = boolCustSuspended

            createNewCustomer()
            newItem = false
        } else if (toggleEditState) {
            //console.log('Save Edit CustName: ', CustName)
            CustName = txtCustName
            CustEmail = txtCustEmail
            CustReg = txtCustReg
            CustTax = txtCustTax
            CustStreet = txtCustStreet
            CustSub = txtCustSuburb
            CustCity = txtCustCity
            CustPostal = txtCustPostal
            CustCountry = txtCustCountry
            CustNotes = txtCustNotes
            CustSuspended = boolCustSuspended

            updateCustomerDetails(CustID)
            refreshCustCallback(true, CustID)
        } else {
            //console.log('Edit CustName: ', CustName)
            setTxtCustName(CustName)
            setTxtCustEmail(CustEmail)
            setTxtCustReg(CustReg)
            setTxtCustTax(CustTax)
            setTxtCustStreet(CustStreet)
            setTxtCustSuburb(CustSub)
            setTxtCustCity(CustCity)
            setTxtCustPostal(CustPostal)
            setTxtCustCountry(CustCountry)
            setTxtCustNotes(CustNotes)
            setBoolCustSuspended(CustSuspended)
        }

        setEditState((prev) => !prev)
    }

    const createNewCustomer = () => {
        const newCust = {
            //franID: FranID,
            busname: CustName,
            companyreg: CustReg,
            companytax: CustTax,
            locationtype: '',
            streetaddress: CustStreet,
            suburb: CustSub,
            city: CustCity,
            postalcode: CustPostal,
            country: CustCountry,
            gpscoords: '',
            directions: '',
            notes: CustNotes,
            contactname: txtContactName,
            contactnumber: txtContactTel,
            contactemail: txtContactEmail,
            contactprimary: true,
            suspended: CustSuspended,
        }

        const retVal = dispatch(createCustomer(newCust)).then((e) => {
            console.log('CustItem New Cust Item retVal(then) payload: ', e.payload.CustID)
            refreshCustCallback(true, e.payload.CustID)
        })
        console.log('CustItem New Cust Item retVal: ', retVal)
    }

    const updateCustomerDetails = (_id) => {
        const updCust = {
            //franID: FranID,
            _id,
            busname: CustName,
            firstname: '',
            lastname: '',
            email: CustEmail,
            companyreg: CustReg,
            companytax: CustTax,
            locationtype: '',
            streetaddress: CustStreet,
            suburb: CustSub,
            city: CustCity,
            postalcode: CustPostal,
            country: CustCountry,
            gpscoords: '',
            directions: '',
            notes: CustNotes,
            suspended: CustSuspended,
        }

        dispatch(updateCustomer(updCust))
    }

    const deleteCustomerById = (_id) => {
        dispatch(deleteCustomer(_id))
        refreshCustCallback()
    }

    //#endregion

    //#region Customer locations
    const { loccustomers, isLocError, locmessage } = useSelector((state) => state.loccustomers)

    useEffect(() => {
        setcurCustLocations(loccustomers.filter((loc) => loc.CustID === CustID))
        //console.log('USE EFFECT!!! curCustLocs: ', curCustLocations)
    }, [loccustomers]) // [isError, dispatch, message])

    const [newCustLocToggle, setNewCustLocToggle] = useState(false)

    const NewCustLocAdded = (locID, refreshDB = true, chatID = -1) => {
        setLocationID(locID)
        setNewCustLocToggle(false)
        setLocationContactChatID(chatID)

        console.log('CustItem setLocationContactChatID:', chatID)

        console.log('New CustLoc Added - RESET FORM', locID)
        //no need for getCustomers seeing as redux is updated when new is created????
        //dispatch(getCustomers())

        //-2 cancel new location add and don't query DB
        if (locID !== -2 && refreshDB) {
            console.log('New CustLoc Added - refreshCustCallback CustID: ', CustID)
            refreshCustCallback(true, CustID)
        } else if (locID !== -2) {
            refreshCustCallback(false, CustID)
        }
    }

    const toggleNewCustLoc = () => {
        setNewCustLocToggle((prev) => !prev)
    }

    //#endregion

    //#region Customer locations Contacts

    const { custloccontacts, isErrorLocContacts, custloccontmessage } = useSelector((state) => state.loccustcontacts)

    //let myPrimaryContact = []
    let curCustLocId = 0

    if (curCustLocations && curCustLocations.length > 0) {
        curCustLocId = curCustLocations[0].CustLocID
    }

    //if (curCustLocations && curCustLocations.length > 0) {
    let CustLocContactsSorted = custloccontacts.filter((i) => i.CustLocID === curCustLocId)

    //Store primary contact to show info on cust compact state
    const myPrimaryContact = CustLocContactsSorted.filter((cust) => cust.CustLocContactPrimary === 1)

    // if (curCustLocId) {
    //     console.log(
    //         'Cust Loc ID: ' + curCustLocId + ' Primary Contact: ',
    //         custloccontacts
    //     )
    // }

    // //If location data not loaded query server data refresh
    // if (!queriedDB && !loccustomers.some((loc) => loc.CustID === CustID)) {
    //     console.log('Location NOT FOUND!!!!!', queriedDB)
    //     setqueriedDB(true)
    //     //refreshCustCallback(true, CustID)
    // }

    //CustContacts.filter((cust) => cust.ContactID === Id).length <= 0

    //console.log('Sorted by CustLocID: ', CustLocContactsSorted)

    //Sort Contacts to put Primary on top
    CustLocContactsSorted.sort((a, b) => {
        return a.CustLocContactPrimary === b.CustLocContactPrimary ? 0 : a.CustLocContactPrimary ? -1 : 1
    })
    //}

    //#endregion

    //Not used to load customers anymore
    // useEffect(() => {
    //     if (isError) {
    //         console.log('Dashboard useEffect error: ', message)
    //         return () => dispatch(reset())
    //     }

    //     if (message) {
    //         console.log('REDUX MESSAGE: ', message)
    //     }

    //     dispatch(getCustomers())

    //     return () => dispatch(reset())
    // }, []) // [isError, dispatch, message])

    // const changeToggleState = () => {
    //     setToggleState((prev) => !prev)
    // }

    //#endregion

    //#region New Customer Form Validation
    // const RegExPattern.Text = '[a-zA-Z0-9@ (),.!&*/_"\'-\\[\\]]+'
    // const phonePattern = '[0-9 +()-]+'
    // // const datePattern = 'd{4}-d{2}-d{2}'
    // // const datePattern = /\d{4}\-\d{2}\-\d{2}/
    // const intPattern = '[0-9]+'
    // const decPattern = '^[0-9]\\d*(.\\d+)?$'

    const [boolCustomerFormValidated, setCustomerFormValidated] = useState(false)

    const valCustNameRef = useRef()
    const valCustTaxRef = useRef()
    const valCustRegRef = useRef()
    const valCustStreetRef = useRef()
    const valCustSuburbRef = useRef()
    const valCustCityRef = useRef()
    const valCustPostalCodeRef = useRef()
    const valCustCountryRef = useRef()
    const valCustContactNameRef = useRef()
    const valCustContactTelRef = useRef()
    const valCustContactEmailRef = useRef()
    const valCustNotesRef = useRef()

    const vaildateCustomerForm = (clicked = false) => {
        if (!newItem && !toggleEditState) return

        if (toggleEditState && !newItem) {
            setCustomerFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valCustNameRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer valCustNameRef',
                        },
                        {
                            ref: valCustTaxRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer valCustTaxRef',
                        },
                        {
                            ref: valCustRegRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer valCustRegRef',
                        },
                        {
                            ref: valCustNotesRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer valCustNotesRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }

        if (newItem) {
            setCustomerFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valCustNameRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustNameRef',
                        },
                        {
                            ref: valCustTaxRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustTaxRef',
                        },
                        {
                            ref: valCustRegRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustRegRef',
                        },
                        {
                            ref: valCustNotesRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer valCustNotesRef',
                        },
                        {
                            ref: valCustStreetRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustStreetRef',
                        },
                        {
                            ref: valCustSuburbRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustSuburbRef',
                        },
                        {
                            ref: valCustCityRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustCityRef',
                        },
                        {
                            ref: valCustPostalCodeRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustPostalCodeRef',
                        },
                        {
                            ref: valCustCountryRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustCountryRef',
                        },
                        {
                            ref: valCustContactNameRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustContactNameRef',
                        },
                        {
                            ref: valCustContactTelRef,
                            text: 'No Special Characters',
                            form: 'New Customer valCustContactTelRef',
                        },
                        {
                            ref: valCustContactEmailRef,
                            text: 'Please enter valid email',
                            form: 'New Customer valCustContactEmailRef',
                        },
                    ],
                    clicked
                )
            )
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    if (collapse && toggleState) {
        setToggleState(false)
        setLocationContactChatID(-1)
        collapse = false
    }

    return (
        <ContentBox classTag={toggleState ? `donotPrint content border border-primary rounded-2 mb-1 p-0 bg-servicewhite` : `donotPrint content border border-light rounded-2 mb-1 p-0 ${containerBackColor}`}>
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded
                <>
                    {/*Container Div*/}
                    <div className='container m-0 bg-servicewhite rounded-2  pb-2'>
                        <div
                            className={boolCustSuspended ? 'row danger' : 'row'}
                            /*CustInfo*/
                        >
                            {/*Customer Name*/}
                            <div className='custName col-auto pt-2'>
                                {toggleEditState ? (
                                    <input
                                        className='txtCustName txtBox'
                                        value={txtCustName}
                                        placeholder={CustName}
                                        onChange={(e) => {
                                            setTxtCustName(e.target.value)
                                            vaildateCustomerForm()
                                        }}
                                        ref={valCustNameRef}
                                        title='Customer Name'
                                        //pattern={'[a-zA-Z0-9@ (),.!&_"]+'}
                                        pattern={RegExPattern.Text}
                                        required
                                        //[a-zA-Z0-9_-]
                                    />
                                ) : (
                                    CustName
                                )}
                                {/* <div className='col-auto me-auto'>
                                        {toggleEditState ? (
                                            <input
                                                className='txtCustEmail txtBox'
                                                value={txtCustEmail}
                                                placeholder={CustEmail}
                                                onChange={(e) =>
                                                    setTxtCustEmail(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div> */}
                            </div>
                            {/*Interface Buttons*/}
                            <div className='col-auto me-auto px-0'>
                                {/* <div>
                                    {toggleEditState ? (
                                        newItem ? (
                                            <>
                                                {boolCustomerFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Add Customer'
                                                        onClickCallback={() => editCustomer()}
                                                        style={{
                                                            animation: 'icofocus 4s infinite',
                                                        }}
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        onClickCallback={() => vaildateCustomerForm(true)}
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                        usefill='true'
                                                        color1='#ff0000'
                                                    />
                                                )}
                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Cancel'
                                                    onClickCallback={() => refreshCustCallback(false)}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {boolCustomerFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Update Customer'
                                                        onClickCallback={() => editCustomer()}
                                                        authlvl={10}
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        onClickCallback={() => vaildateCustomerForm(true)}
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                        usefill='true'
                                                        color1='#ff0000'
                                                    />
                                                )}
                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Cancel'
                                                    onClickCallback={() => switchtoggleEditState()}
                                                />
                                            </>
                                        )
                                    ) : (
                                        //switchtoggleEditState
                                        <ROIcon
                                            icon='ROicoEdit'
                                            size={24}
                                            className='icoSVG'
                                            tooltip='Edit Customer'
                                            authlvl={10}
                                            onClickCallback={() => {
                                                setCustomerFormValidated(true)
                                                editCustomer()
                                            }}
                                        />
                                    )}

                                    {!toggleEditState ? (
                                        <>
                                            {newItem ? (
                                                ''
                                            ) : toggleEditState ? (
                                                ''
                                            ) : confirmDelete ? (
                                                <ROIcon
                                                    icon='ROicoDel'
                                                    size={24}
                                                    className='icoSVG danger'
                                                    tooltip='Delete Customer'
                                                    authlvl={10}
                                                    onClickCallback={() => confirmDeleteCust(true)}
                                                />
                                            ) : (
                                                ' '
                                            )}
                                            {!confirmDelete ? (
                                                <ROIcon
                                                    icon='ROicoDel'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Delete Customer'
                                                    authlvl={10}
                                                    onClickCallback={() => confirmDeleteCust(true)}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {!toggleEditState ? (
                                        confirmDelete ? (
                                            <ROIcon
                                                icon='ROicoClose'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Cancel Delete'
                                                onClickCallback={() => confirmDeleteCust(false)}
                                            />
                                        ) : (
                                            ''
                                        )
                                    ) : (
                                        ''
                                    )}
                                </div> */}
                            </div>
                            {/*Toggle Icon*/}
                            <div className='col-auto px-0'>
                                {toggleEditState ? (
                                    newItem ? (
                                        <>
                                            {boolCustomerFormValidated ? (
                                                <ROIcon
                                                    icon='ROicoAccept'
                                                    size={24}
                                                    className='icoSVG borderlp'
                                                    tooltip='Add Customer'
                                                    onClickCallback={() => editCustomer()}
                                                    style={{
                                                        animation: 'icofocus 4s infinite',
                                                    }}
                                                />
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoAccept'
                                                    size={24}
                                                    className='icoSVG borderlp'
                                                    onClickCallback={() => vaildateCustomerForm(true)}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                    usefill='true'
                                                    color1='#ff0000'
                                                />
                                            )}
                                            <ROIcon icon='ROicoCancel' size={24} className='icoSVG' tooltip='Cancel' onClickCallback={() => refreshCustCallback(false)} />
                                        </>
                                    ) : (
                                        <>
                                            {boolCustomerFormValidated ? (
                                                <ROIcon
                                                    icon='ROicoAccept'
                                                    size={24}
                                                    className='icoSVG borderlp'
                                                    tooltip='Update Customer'
                                                    onClickCallback={() => editCustomer()}
                                                    authlvl={10}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                />
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoAccept'
                                                    size={24}
                                                    className='icoSVG borderlp'
                                                    onClickCallback={() => vaildateCustomerForm(true)}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                    usefill='true'
                                                    color1='#ff0000'
                                                />
                                            )}
                                            <ROIcon icon='ROicoCancel' size={24} className='icoSVG borderlp' tooltip='Cancel' onClickCallback={() => switchtoggleEditState()} />
                                        </>
                                    )
                                ) : (
                                    //switchtoggleEditState
                                    <ROIcon
                                        icon='ROicoEdit'
                                        size={24}
                                        className='icoSVG borderlp'
                                        tooltip='Edit Customer'
                                        authlvl={10}
                                        onClickCallback={() => {
                                            setCustomerFormValidated(true)
                                            editCustomer()
                                        }}
                                    />
                                )}

                                {!toggleEditState ? (
                                    <>
                                        {newItem ? '' : toggleEditState ? '' : confirmDelete ? <ROIcon icon='ROicoDel' size={24} className='icoSVG danger borderlp' tooltip='Delete Customer' authlvl={10} onClickCallback={() => confirmDeleteCust(true)} /> : ' '}
                                        {!confirmDelete ? <ROIcon icon='ROicoDel' size={24} className='icoSVG borderlp' tooltip='Delete Customer' authlvl={10} onClickCallback={() => confirmDeleteCust(true)} /> : ''}
                                    </>
                                ) : (
                                    ''
                                )}
                                {!toggleEditState ? confirmDelete ? <ROIcon icon='ROicoClose' size={24} className='icoSVG borderlp' tooltip='Cancel Delete' onClickCallback={() => confirmDeleteCust(false)} /> : '' : ''}

                                {/*Customer Expande/Collapse Icon*/}
                                {newItem ? (
                                    ''
                                ) : (
                                    <>
                                        {toggleEditState ? (
                                            <>
                                                <ROIcon icon={boolCustSuspended ? 'ROicoSuspended' : 'ROicoUser'} size={24} className='icoSVG borderlp' tooltip='Suspend/Unsuspend' onClickCallback={() => setBoolCustSuspended((prev) => !prev)} />
                                            </>
                                        ) : boolCustSuspended ? (
                                            <ROIcon icon='ROicoSuspended' size={24} className='icoSVG borderlp' tooltip='Customer Suspended' />
                                        ) : (
                                            ''
                                        )}

                                        {focused ? (
                                            <ROIcon
                                                icon='ROicoUnpin'
                                                className='icoSVG borderlp'
                                                onClickCallback={() => refreshCustCallback(false, -1)}
                                                style={{
                                                    animation: 'icofocus 4s infinite',
                                                }}
                                            />
                                        ) : (
                                            <ROIcon icon='ROicoPin' className='icoSVG borderlp' onClickCallback={() => refreshCustCallback(false, CustID)} />
                                        )}

                                        <ROIcon icon='ROicoXUp' className='icoSVG borderlp' onClickCallback={changeToggleState} />
                                    </>
                                )}
                            </div>
                            {/*Tax + Reg*/}
                            <div className='row ms-1 pb-0'>
                                <div className='col-md-4'>
                                    Tax Nr:
                                    {toggleEditState ? (
                                        <input
                                            className='txtCustTax txtBox'
                                            value={txtCustTax}
                                            placeholder={CustTax}
                                            onChange={(e) => {
                                                setTxtCustTax(e.target.value)
                                                vaildateCustomerForm()
                                            }}
                                            ref={valCustTaxRef}
                                            title='Company Tax'
                                            pattern={RegExPattern.Text}
                                        />
                                    ) : (
                                        CustTax
                                    )}
                                </div>
                                <div className='col-md-4'>
                                    Company Reg:
                                    {toggleEditState ? (
                                        <input
                                            className='txtCustReg txtBox'
                                            value={txtCustReg}
                                            placeholder={CustReg}
                                            onChange={(e) => {
                                                setTxtCustReg(e.target.value)
                                                vaildateCustomerForm()
                                            }}
                                            ref={valCustRegRef}
                                            title='Company Reg'
                                            pattern={RegExPattern.Text}
                                        />
                                    ) : (
                                        CustReg
                                    )}
                                </div>
                            </div>
                            <div className='row ms-1 pb-0' /*Customer Notes*/>
                                {/* <div className='col-11 p-0 ps-0 ms-4 fs-6'> */}
                                <div className='col-md-11 fw-normal mb-3'>
                                    {!toggleEditState && CustNotes && CustNotes.length > 0 ? <ROIcon icon='ROicoComments' size={24} className='icoSVG' tooltip='Notes' /> : ''}
                                    {toggleEditState ? (
                                        <input
                                            className='txtBox'
                                            value={txtCustNotes}
                                            placeholder='Customer Notes'
                                            onChange={(e) => {
                                                setTxtCustNotes(e.target.value)
                                                vaildateCustomerForm()
                                            }}
                                            ref={valCustNotesRef}
                                            title='Customer Notes'
                                            pattern={RegExPattern.Text}
                                        />
                                    ) : CustNotes && CustNotes.length > 0 ? (
                                        'Note: ' + CustNotes
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Cust Adres + Contacts */}
                        {/* ONLY SHOW ON NEW CUSTOMER */}

                        {newItem ? (
                            <div className='row'>
                                <div className='col-md-5 mt-3'>
                                    <div className='row'>
                                        <div className='col-sm-1'>
                                            <ROIcon icon='ROicoSign' size={24} className='icoSVG' />
                                        </div>
                                        <div className='divCustDetails col-lg-11 p-0'>
                                            <span className='mainCustomerAddress m-0'>
                                                <input
                                                    className='txtCustStreet txtBox'
                                                    value={txtCustStreet}
                                                    placeholder={CustStreet}
                                                    onChange={(e) => {
                                                        setTxtCustStreet(e.target.value)
                                                        vaildateCustomerForm()
                                                    }}
                                                    ref={valCustStreetRef}
                                                    title='Address Street'
                                                    pattern={RegExPattern.Text}
                                                    required
                                                />
                                                <br />
                                                <input
                                                    className='txtCustSuburb txtBox'
                                                    value={txtCustSuburb}
                                                    placeholder={CustSub}
                                                    onChange={(e) => {
                                                        setTxtCustSuburb(e.target.value)
                                                        vaildateCustomerForm()
                                                    }}
                                                    ref={valCustSuburbRef}
                                                    title='Address Suburb'
                                                    pattern={RegExPattern.Text}
                                                />
                                                <br />
                                                <input
                                                    className='txtCustCity txtBox'
                                                    value={txtCustCity}
                                                    placeholder={CustCity}
                                                    onChange={(e) => {
                                                        setTxtCustCity(e.target.value)
                                                        vaildateCustomerForm()
                                                    }}
                                                    ref={valCustCityRef}
                                                    title='Address City'
                                                    pattern={RegExPattern.Text}
                                                    required
                                                />
                                                <input
                                                    className='txtCustPostal txtBox'
                                                    value={txtCustPostal}
                                                    placeholder={CustPostal}
                                                    onChange={(e) => {
                                                        setTxtCustPostal(e.target.value)
                                                        vaildateCustomerForm()
                                                    }}
                                                    ref={valCustPostalCodeRef}
                                                    title='Address Postal Code'
                                                    pattern={RegExPattern.Text}
                                                />
                                                <input
                                                    className='txtCustCountry txtBox'
                                                    value={txtCustCountry}
                                                    placeholder={CustCountry}
                                                    onChange={(e) => {
                                                        setTxtCustCountry(e.target.value)
                                                        vaildateCustomerForm()
                                                    }}
                                                    ref={valCustCountryRef}
                                                    title='Address Country'
                                                    pattern={RegExPattern.Text}
                                                    required
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3 ms-2'>
                                    <div className='row'>
                                        <div className='col-sm-1'>
                                            <ROIcon icon='ROicoUser' size={24} className='icoSVG' />
                                        </div>
                                        <div className='col-lg-4  px-1  fs-6'>
                                            <input
                                                id='txtContactName'
                                                className=' txtBox'
                                                value={txtContactName}
                                                placeholder='Contact Name'
                                                onChange={(e) => {
                                                    setTxtContactName(e.target.value)
                                                    vaildateCustomerForm()
                                                }}
                                                ref={valCustContactNameRef}
                                                title='Contact Name'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='col-lg-3  px-1  fs-6'>
                                            <input
                                                id='txtContactTel'
                                                className='txtBox'
                                                value={txtContactTel}
                                                placeholder='Number'
                                                onChange={(e) => {
                                                    setTxtContactTel(e.target.value)
                                                    vaildateCustomerForm()
                                                }}
                                                ref={valCustContactTelRef}
                                                title='Contact Number'
                                                pattern={RegExPattern.Phone}
                                                required
                                            />
                                        </div>
                                        <div className='col-lg-4 px-1  fs-6'>
                                            <input
                                                id='txtContactEmail'
                                                className='txtBox'
                                                value={txtContactEmail}
                                                placeholder='Email'
                                                type='email'
                                                onChange={(e) => {
                                                    setTxtContactEmail(e.target.value)
                                                    vaildateCustomerForm()
                                                }}
                                                ref={valCustContactEmailRef}
                                                title='Contact Email'
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* Moved to use location components even for first and only location of customer */}

                        {/* Customer Locations */}
                        <div className='container bg-serviceswhite rounded-2 m-0 p-0 fs-6'>
                            {curCustLocations.length > 1 ? (
                                <div className='row m-0 mt-3 p-0'>
                                    <div className='text-primary col-auto me-auto '>
                                        <h5>
                                            Locations
                                            <ROIcon
                                                icon='ROicoPlus'
                                                tooltip='Add Location'
                                                className='icoSVG'
                                                onClickCallback={toggleNewCustLoc}
                                                style={{
                                                    animation: 'pulse 4s infinite',
                                                }}
                                            />
                                        </h5>
                                    </div>
                                    <div className='col-auto'></div>
                                </div>
                            ) : (
                                ''
                            )}

                            <div className='expandedLocationsDiv row m-0 my-0 p-0'>
                                {newCustLocToggle ? (
                                    <>
                                        <div className='custLocation ms-2 mb-1 rounded-2 '>
                                            {curCustLocations.map((loc) => {
                                                return (
                                                    <div key={myUUid()} className='custLocation ROTooltip' data-tooltip={loc.CustLocStreetAddress + ', ' + loc.CustLocSuburb + ', ' + loc.CustLocCity + ', ' + loc.CustLocPostalCode}>
                                                        <ROIcon icon='ROicoLocation' size={24} className='icoSVG' />
                                                        <span className='custLocadd col' key={myUUid()}>
                                                            {loc.CustLocName}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <CustLocItem key={myUUid()} newItem={true} CustID={CustID} expanedState={true} custlocAddedCallback={NewCustLocAdded} />
                                    </>
                                ) : (
                                    curCustLocations.map((loc, i) => {
                                        return (
                                            <div key={myUUid()} className='col-md-12 custLocation'>
                                                <span className='custLocadd col' key={myUUid()}>
                                                    <CustLocItem
                                                        key={myUUid()}
                                                        newItem={false}
                                                        CustID={CustID}
                                                        expanedState={curCustLocations.length === 1 ? true : loc.CustLocID === showLocationID}
                                                        focusEquipLocID={focusEquipLocID}
                                                        showChatOfContactID={showLocationContactChatID}
                                                        custlocAddedCallback={NewCustLocAdded}
                                                        _CustLocDetails={{
                                                            CustLocID: loc.CustLocID,
                                                            CustLocName: loc.CustLocName,
                                                            CustLocEmail: loc.CustLocEmail,
                                                            CustLocStreet: loc.CustLocStreetAddress,
                                                            CustLocSub: loc.CustLocSuburb,
                                                            CustLocCity: loc.CustLocCity,
                                                            CustLocPostal: loc.CustLocPostalCode,
                                                            CustLocCountry: loc.CustLocCountry,
                                                            CustPriContactName: myPrimaryContact && myPrimaryContact.length > 0 ? myPrimaryContact[0].CustLocContactName : '',
                                                            CustPriContactEmail: myPrimaryContact && myPrimaryContact.length > 0 ? myPrimaryContact[0].CustLocContactEmail : '',
                                                            CustLocFlags: loc.CustLocFlags,
                                                        }}
                                                        addLocationCallback={toggleNewCustLoc}
                                                    />
                                                </span>
                                            </div>
                                        )
                                    })
                                )}
                                {/* {!toggleEditState && !newItem && !newCustLocToggle ? (
                                    <div>
                                        <ROIcon
                                            icon='ROicoPlus'
                                            tooltip='Add Location'
                                            className='icoSVG'
                                            onClickCallback={toggleNewCustLoc}
                                            style={{
                                                animation: 'pulse 4s infinite',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )} */}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <>
                    {/*Container Div*/}
                    <div className={classTag + ' ' + borderParms + ' ' + backColor}>
                        {/*1st ROW Header*/}
                        <div className='container p-2 pt-0 m-0 ' onClick={() => changeToggleState()}>
                            <div className={boolCustSuspended ? 'row danger' : 'row'}>
                                {/*Customer Name*/}
                                <div className='custName col-md-4 pl-2 fs-3 '>{CustName}</div>
                                {/*Customer Primary Number and Contact*/}
                                <div className='custCont col-md-3 fs-5'>
                                    {/* {myPrimaryContact.ContactName} */}
                                    {/* {console.log(
                                            'myPrimaryContact',
                                            myPrimaryContact
                                        )} */}
                                    <span className='fs-5 fw-normal'>{myPrimaryContact.length > 0 ? myPrimaryContact[0].CustLocContactNumber : ''}</span>
                                    <span className='fs-5'>{myPrimaryContact.length > 0 ? ' (' + myPrimaryContact[0].CustLocContactName + ')' : ''}</span>
                                </div>
                                {/*Customer Email*/}
                                <div className='custEmail col-md-3 fs-5 me-auto '>
                                    {/*CustEmail*/}
                                    {/* {console.log(
                                            'curCustLocations: ' +
                                                curCustLocations.length,
                                            curCustLocations
                                        )} */}
                                    {myPrimaryContact.length > 0 ? ' ' + myPrimaryContact[0].CustLocContactEmail + ' ' : ''}
                                    {/* {curCustLocations.map((loc, i) => {
                                            if (i === 0) {
                                                return loc.CustLocEmail
                                            }
                                        })} */}
                                </div>
                                {/*Customer Expande/Collapse Icon*/}
                                <div className='col-auto px-2'>
                                    {boolCustSuspended ? <span className='danger'>Suspended</span> : ''}
                                    <ROIcon
                                        icon='ROicoXDown'
                                        className='icoSVG'
                                        //onClickCallback={changeToggleState}
                                    />
                                </div>
                            </div>

                            <div className='custLocation ms-2'>
                                {loccustomers.map((loc) => {
                                    if (loc.CustID === CustID) {
                                        return (
                                            <div key={myUUid()} className='custLocation ROTooltip' data-tooltip={loc.CustLocStreetAddress + ', ' + loc.CustLocSuburb + ', ' + loc.CustLocCity + ', ' + loc.CustLocPostalCode}>
                                                <ROIcon icon='ROicoLocation' size={24} className='icoSVG' />
                                                <span className='custLocadd' key={myUUid()}>
                                                    {loc.CustLocName} ({loc.CustLocStreetAddress})
                                                </span>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        {/*Unused*/}
                        <> {ChildOjb}</>
                    </div>
                </>
                //#endregion
            )}
        </ContentBox>
    )
}

export default CustItem
