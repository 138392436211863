import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ROIcon from '../components/ROIcon'

const Footer = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)

    const topFunction = () => {
        console.log('GO TO TOP!!!!!!!!')
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div className='pt-5'>
                <div className='footer donotPrint container-fluid pt-2'>
                    <ROIcon
                        icon='ROicoUp'
                        size={24}
                        className='icoSVG rcorners'
                        tooltip='Back To Top'
                        authlvl={99}
                        onClickCallback={() => topFunction()}
                        usefill={true}
                        color1='#ffffff'
                        //to='/customers?custID=all'
                    />
                    <div className='container '>
                        <div className='row'>
                            {/* <div className='subHeader sticky-top donotPrint'>
                        <div className='container'>
                            <nav className='navbar navbar-expand-lg py-1 whitetxt'></nav> */}

                            <div className='donotPrint'>
                                <nav className='navbar-expand-lg py-1 whitetxt fs-6'>
                                    <div className='row p-0 m-0'>
                                        <div className='col-md-11 p-0 m-0'>
                                            <Link to='/'>Rovantage.com Copyright Reserved</Link> | <Link to='/terms'>Terms of Use</Link> |{' '}
                                            <Link to='/login'>Log In</Link> |{' '}
                                            {user ? (
                                                <>
                                                    {' '}
                                                    <Link to='/support'>Support</Link> |{' '}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            <Link to='/register'>Sign Up</Link>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
