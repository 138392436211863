import { useEffect, useMemo, useState, useRef } from 'react'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset, isError, message } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { addROErrorLog, JSONtoHtml, VaildateForm, GetCurrency, daysDiff, GetEmailTokens, EmailTokensReplace } from '../features/globalFunctions'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROComboBox from '../components/ROComboBox'
import ROPassword from '../components/ROPassword'

import { PayPalButtons } from '@paypal/react-paypal-js'

//Import Redux Functions and Axiox database calls
import { updateUserPassword } from '../features/users/usersSlice'

import {
    getFranSettings,
    updateFranSettings,
    updatePayPalSub,
    getFranPayPalSubDetails,
    cancelSub,
    resetfranacc,
} from '../features/franacc/franaccSlice'
import { formatLongDate, formatShortDate } from '../features/formatTime'
import { RegExPattern, ServiceEmailMsg } from '../features/globalVars'

const Account = () => {
    let [urlParams, setUrlParams] = useSearchParams()

    let settingsType = urlParams.get('settingsType') ? urlParams.get('settingsType') : 'Account'

    //console.log('******** DRAW ACCOUNT *************', settingsType)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, isError, isSuccess, message } = useSelector((state) => state.auth)

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    //console.log('GetCurrency List: ', GetCurrency())

    let {
        FranAccID = '',
        FranID = '',
        FranAccName = 'Enter Name',
        FranAccEmail = 'Enter Email',
        FranAccTel = 'Enter Tel',
        FranAccStreetAddress = 'Enter Street',
        FranAccSuburb = 'Enter Suburb',
        FranAccCity = 'Enter City',
        FranAccPostalCode = 'Enter Code',
        FranAccCountry = 'Enter Country',
        FranAccNotes = ServiceEmailMsg,
        FranAccSettings = '{"Bar":"BAR","Currency":"ZAR","CurrencySymb":"R", "EmailToken":30, "ReplyTo":"noreply@rovantage.com"}',
        FranAccFlags = '{ "Suspended" : ""}',
    } = franacc

    //console.log('FranAccNotes: ', FranAccNotes)

    try {
        //console.log('FranAccSettings: ', FranAccSettings)
        //Check Settings is Correct
        if (!FranAccNotes || FranAccNotes === null || FranAccNotes.length <= 1) {
            FranAccNotes = ServiceEmailMsg
        }

        FranAccSettings = JSON.parse(FranAccSettings)
        //FranAccFlags = FranAccFlags && FranAccFlags !== '' ? JSON.parse(FranAccFlags) : {}
        FranAccFlags = JSON.parse(FranAccFlags)
        //console.log('FranAccSettings: ', FranAccSettings)
        //console.log('Object.keys(ReplyTo)', Object.keys(FranAccSettings), Object.keys(FranAccSettings).includes('ReplyTo'))

        if (!Object.keys(FranAccSettings).includes('ReplyTo') || FranAccSettings.ReplyTo === null || FranAccSettings.ReplyTo === '') {
            FranAccSettings = {
                ...FranAccSettings,
                ReplyTo: 'noreply@rovantage.com',
            }
        }
        if (!Object.keys(FranAccSettings).includes('FromName') || FranAccSettings.FromName === null || FranAccSettings.FromName === '') {
            FranAccSettings = {
                ...FranAccSettings,
                FromName: FranAccName,
            }
        }

        if (
            !Object.keys(FranAccSettings).includes('EmailToken') ||
            FranAccSettings.EmailToken ||
            FranAccSettings.EmailToken === null ||
            FranAccSettings.EmailToken === ''
        ) {
            FranAccSettings = {
                ...FranAccSettings,
                EmailToken: 30,
            }
        }

        //console.log('FranAccSettings: ', FranAccSettings)
    } catch (error) {
        console.log('Account JSON Parsing Error: ', error)
        toast.error('Account JSON Parsing Error: ' + error.toString())
        addROErrorLog('Account JSON Parsing Error:  ' + error.toString(), '', 'Account')
    }

    const [editState, setEditState] = useState(false)
    const [editUserAccPasswordState, setEditUserAccPasswordState] = useState(false)
    const [editSettingsState, setEditSettingsState] = useState(false)
    const [deleteSub, setdeleteSubState] = useState(false)
    const [objAccountSettings, setObjAccountSettings] = useState(FranAccSettings)
    const [settingsCategory, setSettingsCategory] = useState(settingsType ? settingsType : 'Account')

    useMemo(() => {
        //console.log('FRAN ACCOUNT DETAILS UPDATED !!!!!')
        ;({
            FranAccID = '',
            FranID = '',
            FranAccName = 'Enter Name',
            FranAccEmail = 'Enter Email',
            FranAccTel = 'Enter Tel',
            FranAccStreetAddress = 'Enter Street',
            FranAccSuburb = 'Enter Suburb',
            FranAccCity = 'Enter City',
            FranAccPostalCode = 'Enter Code',
            FranAccCountry = 'Enter Country',
            FranAccNotes = ServiceEmailMsg,
            FranAccSettings = '{"Bar":"BAR","Currency":"ZAR","CurrencySymb":"R", "EmailToken":30, "ReplyTo":"noreply@rovantage.com"}',
            FranAccFlags = '{ "Suspended" : ""}',
        } = franacc)
        setObjAccountSettings(JSON.parse(FranAccSettings))
        //console.log('FRAN ACCOUNT DETAILS UPDATED !!!!! FranAccSettings: ', FranAccSettings)
        //console.log('FRAN ACCOUNT DETAILS UPDATED !!!!! franacc:', franacc)
    }, [franacc])

    // console.log('POST useState objAccountSettings: ', objAccountSettings)
    // console.log('POST useState FranAccSettings2: ', FranAccSettings)
    // console.log('settingsCategory: ', settingsCategory)

    //#region Email Settings
    //Email Settings
    const [txtEmailMsg, setTxtEmailMsg] = useState(FranAccNotes && FranAccNotes.length > 1 ? FranAccNotes : ServiceEmailMsg)
    const [editEmailState, setEditEmailState] = useState(false)

    const valEmailMsgRef = useRef()

    //updateSetting({ Currency: curCode })

    const emailTokens = GetEmailTokens({
        ReplyToEmail: objAccountSettings.ReplyTo,
        FranCompName: FranAccName,
        YourName: user && user.name ? user.name : '',
        FranAccTel: FranAccTel,
        FranAccEmail: FranAccEmail,
        FranAccStreet: FranAccStreetAddress,
        FranAccSuburb: FranAccSuburb,
        FranAccCity: FranAccCity,
        FranAccPostal: FranAccPostalCode,
        FranAccCountry: FranAccCountry,
    })

    const PreviewEmail = (str) => {
        return EmailTokensReplace(str, emailTokens)
    }

    useEffect(() => {
        if (valEmailMsgRef.current) {
            valEmailMsgRef.current.parentNode.dataset.replicatedvalue = valEmailMsgRef.current.value
        }
    }, [editEmailState])

    const AddTokenToEmail = (tokenStr) => {
        const pos = valEmailMsgRef.current.selectionStart

        if (valEmailMsgRef.current) {
            valEmailMsgRef.current.value = valEmailMsgRef.current.value.slice(0, pos) + tokenStr + valEmailMsgRef.current.value.slice(pos)

            valEmailMsgRef.current.setSelectionRange(pos + tokenStr.length, pos + tokenStr.length)

            valEmailMsgRef.current.parentNode.dataset.replicatedvalue = valEmailMsgRef.current.value
            setTxtEmailMsg(valEmailMsgRef.current.value)
        }
    }

    //Email Settings Validation
    const valFranAccEmailReplyTo = useRef()
    const valFranAccEmailExpire = useRef()
    const valFranAccEmailCC = useRef()
    const valEmailFromName = useRef()

    const [boolFranAccEmailFormValidated, setFranAccEmailFormValidated] = useState(false)

    const UpdateEmailLength = () => {
        if (valEmailMsgRef.current.value.length >= 1000) {
            toast.warning('Max Email Length')
        }
    }

    const vaildateFranAccEmailForm = (clicked = false) => {
        if (!editEmailState) return

        if (editEmailState) {
            setFranAccEmailFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valFranAccEmailReplyTo,
                            text: 'Enter valid Reply To email',
                            form: 'Email Settings valFranAccEmailReplyTo',
                        },
                        {
                            ref: valEmailMsgRef,
                            text: 'No Special Characters',
                            form: 'Email Settings valEmailMsgRef',
                        },
                        {
                            ref: valEmailMsgRef,
                            text: 'No Special Characters',
                            form: 'Email Settings valEmailMsgRef',
                        },
                        {
                            ref: valFranAccEmailExpire,
                            text: 'Only Numbers',
                            form: 'Email Settings valFranAccEmailExpire',
                        },
                        {
                            ref: valFranAccEmailCC,
                            text: 'Enter valid CC email',
                            form: 'Email Settings valFranAccEmailCC',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    //#endregion Email Settings

    //Subcriptions Delete Sub
    const confirmDeleteSub = (setState) => {
        if (deleteSub && setState) {
            //console.log('Cancel Subscription!!!!')
            if (franacc.FranAccPayPalSubID && franacc.FranAccPayPalSubID !== null && franacc.FranAccPayPalSubID !== '') {
                dispatch(cancelSub(franacc.FranAccPayPalSubID))
            }
            setdeleteSubState(false)
            dispatch(getFranSettings())
            return
        }
        setdeleteSubState(setState)
    }

    useEffect(() => {
        console.log('Account useEffect Start')
        if (isError) {
            console.log('useEffect error: ', message)
            toast.error(message)
            addROErrorLog('Account useEffect Error:  ' + message, user ? user.name : '', 'Account')
            dispatch(reset())
            navigate('/')
            return
        }

        if (isFranAccError) {
            console.log('***************************Fran Account error: ', franaccmessage)

            toast.error(franaccmessage)
            addROErrorLog('Fran Account Error:  ' + franaccmessage, user ? user.name : '', 'Fran Account')
            dispatch(resetfranacc())
            navigate('/')
            return
        }

        console.log('Before Account page check user creds')

        if (!user || !user._id || !user.token) {
            console.log('User error!!! log out: ', user)
            addROErrorLog('Account User Error:  ' + message, user ? user.name : '', 'Account')
            dispatch(logout())
            dispatch(reset())
            navigate('/')
        }

        console.log('After Account page check user creds')

        try {
            console.log('Get All Data from servers')
            dispatch(getFranSettings())
        } catch (error) {
            console.log('Template.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }

        return () => dispatch(reset())
    }, [user, isError, isFranAccError])

    //Account Password

    useEffect(() => {
        console.log('Account useEffect')
        if (isError) {
            console.log('Account USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            addROErrorLog('Account USERS useEffect Error:  ' + message, user ? user.name : '', 'Register')
            return
        }

        if (!user) {
            navigate('/')
        }

        //console.log('Register useEffect after success and user check')
    }, [user, isError])

    //Request Subsription Details from paypal
    useEffect(() => {
        if (franacc.FranAccPayPalSubID && franacc.FranAccPayPalSubID !== null && franacc.FranAccPayPalSubID !== '') {
            dispatch(getFranPayPalSubDetails(franacc.FranAccPayPalSubID))
        }
    }, [franacc])

    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtAccountName, setTxtAccountName] = useState('')
    const [txtAccountTel, setTxtAccountTel] = useState('')
    const [txtAccountEmail, setTxtAccountEmail] = useState('')
    const [txtAccountStreet, setTxtAccountStreet] = useState('')
    const [txtAccountSuburb, setTxtAccountSuburb] = useState('')
    const [txtAccountCity, setTxtAccountCity] = useState('')
    const [txtAccountPostalCode, setTxtAccountPostalCode] = useState('')
    const [txtAccountCountry, setTxtAccountCountry] = useState('')

    // const [txtAccountName, setTxtAccountName] = useState((FranAccName ||= 'Business Name'))
    // const [txtAccountTel, setTxtAccountTel] = useState((FranAccTel ||= 'Business Tel'))
    // const [txtAccountEmail, setTxtAccountEmail] = useState((FranAccEmail ||= 'Business Email'))
    // const [txtAccountStreet, setTxtAccountStreet] = useState((FranAccStreetAddress ||= 'Business Street'))
    // const [txtAccountSuburb, setTxtAccountSuburb] = useState(FranAccSuburb)
    // const [txtAccountCity, setTxtAccountCity] = useState((FranAccStreetAddress ||= 'Business City'))
    // const [txtAccountPostalCode, setTxtAccountPostalCode] = useState((FranAccPostalCode ||= 'Business Postal Code'))
    // const [txtAccountCountry, setTxtAccountCountry] = useState((FranAccCountry ||= 'Business Country'))

    //#region New Customer Form Validation

    const valFranAccNameRef = useRef()
    const valFranAccTelRef = useRef()
    const valFranAccEmailRef = useRef()
    const valFranAccStreetAddressRef = useRef()
    const valFranAccSuburbRef = useRef()
    const valFranAccCityRef = useRef()
    const valFranAccPostalCodeRef = useRef()
    const valFranAccCountryRef = useRef()

    const [boolFranAccFormValidated, setFranAccFormValidated] = useState(false)

    const vaildateFranAccForm = (clicked = false) => {
        if (!editState) return

        if (editState) {
            setFranAccFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valFranAccNameRef,
                            text: 'No Special Characters',
                            form: 'Business valFranAccNameRef',
                        },
                        {
                            ref: valFranAccTelRef,
                            text: 'No Special Characters',
                            form: 'Business valFranAccTelRef',
                        },
                        {
                            ref: valFranAccEmailRef,
                            text: 'Enter valid email',
                            form: 'Business valFranAccEmailRef',
                        },
                        {
                            ref: valFranAccStreetAddressRef,
                            text: 'No Special Characters',
                            form: 'Business valFranAccStreetAddressRef',
                        },
                        {
                            ref: valFranAccSuburbRef,
                            text: 'No Special Characters',
                            form: 'Business valFranAccSuburb',
                        },
                        {
                            ref: valFranAccCityRef,
                            text: 'No Special Characters',
                            form: 'Business valFranAccCityRef',
                        },
                        {
                            ref: valFranAccCityRef,
                            text: 'No Special Characters',
                            form: 'Business valFranAccCityRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    const editFranAcc = (edit = false) => {
        //console.log('editFranAcc clicked franacc.settings: ', FranAccSettings)

        if (!edit) {
            setEditState((prev) => !prev)
            setTxtAccountName(FranAccName)
            setTxtAccountTel(FranAccTel)
            setTxtAccountEmail(FranAccEmail)
            setTxtAccountStreet(FranAccStreetAddress)
            setTxtAccountSuburb(FranAccSuburb)
            setTxtAccountCity(FranAccCity)
            setTxtAccountPostalCode(FranAccPostalCode)
            setTxtAccountCountry(FranAccCountry)
            return
        }

        if (editState) {
            FranAccName = txtAccountName
            FranAccTel = txtAccountTel
            FranAccEmail = txtAccountEmail
            FranAccStreetAddress = txtAccountStreet
            FranAccSuburb = txtAccountSuburb
            FranAccCity = txtAccountCity
            FranAccPostalCode = txtAccountPostalCode
            FranAccCountry = txtAccountCountry

            const updAcc = {
                franAccID: FranAccID,
                franID: FranID,
                franAccName: FranAccName,
                franAccEmail: FranAccEmail,
                franAccTel: FranAccTel,
                franAccStreetAddress: FranAccStreetAddress,
                franAccSuburb: FranAccSuburb,
                franAccCity: FranAccCity,
                franAccPostalCode: FranAccPostalCode,
                franAccCountry: FranAccCountry,
                franAccNotes: FranAccNotes,
                franAccSettings: JSON.stringify(FranAccSettings),
                franAccFlags: JSON.stringify(FranAccFlags),
            }

            console.log('Business details updAcc: ', updAcc)

            dispatch(updateFranSettings(updAcc))
        }

        setEditState((prev) => !prev)
    }

    //Form edit values needed so react UI can show user typing and used as global vars for db updates

    const valFranAccSettingCurrencyRef = useRef()
    const valFranAccSettingCurrencySymbRef = useRef()

    const [boolFranAccSettingsFormValidated, setFranAccSettingsFormValidated] = useState(false)

    const vaildateFranAccSettingsForm = (clicked = false) => {
        if (!editSettingsState) return

        if (editSettingsState) {
            setFranAccSettingsFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valFranAccSettingCurrencyRef,
                            text: 'Invalid Currency',
                            form: 'Business Settings valFranAccSettingCurrencyRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    //Account Password
    const [boolUserPassValidated, setUserPassValidated] = useState(false)
    const [txtUserPass, setTxtUserPass] = useState('')

    const getNewUserPassword = (newPass) => {
        if (newPass && newPass !== '') {
            setTxtUserPass(newPass)
            setUserPassValidated(true)
        } else {
            setUserPassValidated(false)
        }
    }

    const editUserAccPassword = (edit = false) => {
        //console.log('editFranAcc clicked franacc.settings: ', FranAccSettings)

        if (!edit) {
            setTxtUserPass('')
            setUserPassValidated(false)
            setEditUserAccPasswordState((prev) => !prev)
            return
        }

        if (editUserAccPasswordState) {
            //FranAccName = txtAccountName

            const updAccPassword = {
                UserID: user._id,
                Password: txtUserPass,
            }

            console.log('updAccPassword: ', updAccPassword)

            dispatch(updateUserPassword(updAccPassword))

            //dispatch(updateFranSettings(updAcc))
        }

        setEditUserAccPasswordState((prev) => !prev)
    }

    const updateSetting = (setting) => {
        //console.log('call updateSetting setting: ', setting)
        //console.log('before updateSetting: ', objAccountSettings)

        //console.log('updateSetting setting: ', Object.values(setting)[0], Object.keys(setting))
        //console.log('updateSetting setting[Object.keys(setting)[0]: ', setting[Object.keys(setting)[0]])

        if (Object.values(setting)[0]) {
        } else {
            setting[Object.keys(setting)[0]] = ''
        }

        // console.log('updateSetting setting: ', setting)

        if (setting) {
            //console.log('updateSetting setting: ', setting)

            setObjAccountSettings((prevState) => ({
                ...prevState,
                ...setting,
            }))
            //console.log('after updateSetting: ', objAccountSettings)
        }
    }

    const updateCurrency = (curCode) => {
        //console.log('updateCurrency curCode: ', curCode)

        let currency = GetCurrency(curCode)

        //console.log('updateCurrency currency: ', curCode, currency)

        if (currency) {
            //objAccountSettings.Currency = curCode
            //objAccountSettings.CurrencySymb = currency.symbol
            updateSetting({ Currency: curCode })
            updateSetting({ CurrencySymb: currency.symbol })
            //updateSetting((prev) => ({ ...prev, CurrencySymb: currency.symbol }))
        }
    }

    const editFranAccSettings = (edit = false) => {
        //console.log('editFranAccSettings clicked objAccountSettings: ', objAccountSettings)

        if (!edit) {
            setEditSettingsState(false)
            setEditEmailState(false)
            setTxtEmailMsg(FranAccNotes)
            setObjAccountSettings(FranAccSettings)
            return
        }

        if (editSettingsState || editEmailState) {
            // FranAccName = txtAccountName
            // FranAccTel = txtAccountTel
            // FranAccEmail = txtAccountEmail
            // FranAccStreetAddress = txtAccountStreet
            // FranAccSuburb = txtAccountSuburb
            // FranAccCity = txtAccountCity
            // FranAccPostalCode = txtAccountPostalCode
            // FranAccCountry = txtAccountCountry

            if (editEmailState) {
                //console.log('FranAccoNotes is = ', txtEmailMsg)
                FranAccNotes = txtEmailMsg
            }

            const updAcc = {
                franAccID: FranAccID,
                franID: FranID,
                franAccName: FranAccName,
                franAccEmail: FranAccEmail,
                franAccTel: FranAccTel,
                franAccStreetAddress: FranAccStreetAddress,
                franAccSuburb: FranAccSuburb,
                franAccCity: FranAccCity,
                franAccPostalCode: FranAccPostalCode,
                franAccCountry: FranAccCountry,
                franAccNotes: FranAccNotes,
                franAccSettings: JSON.stringify(objAccountSettings),
                franAccFlags: JSON.stringify(FranAccFlags),
            }

            //console.log('settings updAcc: ', updAcc)

            dispatch(updateFranSettings(updAcc))
        }

        //setEditSettingsState((prev) => !prev)
        setEditSettingsState(false)
        setEditEmailState(false)
    }

    /* Old testing and feedback code 

    const dangerShowSettings = () => {
        try {
            console.log('showSettings: ', franacc.FranAccSettings)

            if (franacc.FranAccSettings) {
                const retHtml = JSONtoHtml(fransub)
                console.log('showSettings retHtml: ', retHtml)

                return (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: retHtml.outerHTML,
                        }}
                    />
                )
            }
        } catch (error) {}
        //console.log(JSONtoHtml(franacc.FranAccSettings))
    }

    const quickPayPalTest = () => {
        if (fransub.id && fransub.id !== null) {
            const updPayPalSub = {
                franID: user.franid,
                franaccPayPalSubID: fransub.id,
            }
            console.log('quickPayPalTest: ', updPayPalSub)
            dispatch(updatePayPalSub(updPayPalSub))
        } else {
            toast.error('Error with quickPayPalTest fransub.id: ' + fransub.id)
        }
    }

    Old testing and feedback code*/

    // useMemo(() => {
    //     setObjAccountSettings(FranAccSettings)
    // }, [FranAccSettings])

    return (
        <>
            <section className='pageHead container bg-light'>
                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container bg-light'>
                <div className='row p-0 m-0 pt-2'>
                    <div className='col-auto'>
                        {/* <h3>Account Settings</h3> */}
                        <h3>{user.name}</h3>
                    </div>
                </div>
                <div className='row p-0 m-0 pt-3 pb-3'>
                    {/* Settings Menu */}
                    <div className='col-2 p-0 m-0 pt-2'>
                        <div className={settingsCategory === 'Account' ? 'row text-primary p-0 m-0' : 'row p-0 m-0'}>
                            {/* <h5 className='showClickable' onClick={() => setSettingsCategory('Account')}>
                                Account Settings
                            </h5> */}
                            <Link to='/account?settingsType=Account' onClick={() => setSettingsCategory('Account')}>
                                <h5 className={settingsCategory === 'Account' ? 'text-primary' : ''}>Account Settings</h5>
                            </Link>
                        </div>
                        <div className={settingsCategory === 'Business' ? 'row text-primary p-0 m-0 pt-3' : 'row p-0 m-0 pt-3'}>
                            {/* <h5 className='showClickable' onClick={() => setSettingsCategory('Business')}>
                                Business Settings
                            </h5> */}
                            <Link to='/account?settingsType=Business' onClick={() => setSettingsCategory('Business')}>
                                <h5 className={settingsCategory === 'Business' ? 'text-primary' : ''}>Business Settings</h5>
                            </Link>
                        </div>
                        <div className={settingsCategory === 'Email' ? 'row text-primary p-0 m-0 pt-3' : 'row p-0 m-0 pt-3'}>
                            {/* <h5 className='showClickable' onClick={() => setSettingsCategory('Email')}>
                                Email Settings
                            </h5> */}
                            <Link to='/account?settingsType=Email' onClick={() => setSettingsCategory('Email')}>
                                <h5 className={settingsCategory === 'Email' ? 'text-primary' : ''}>Email Settings</h5>
                            </Link>
                        </div>
                    </div>
                    {/* Main Settings Block or body */}
                    <div className='col-10 p-0 m-0 px-2 pt-1 rounded-2 bg-servicewhite'>
                        {
                            //#region Account Settings
                        }
                        {settingsCategory === 'Account' ? (
                            <>
                                {/*Change Main Account Password*/}
                                <div className='row'>
                                    <div className='text-primary col-auto pt-2'>
                                        <h5>Account Password</h5>
                                    </div>
                                    <div className='col-auto me-auto px-0'>
                                        <div>
                                            {editUserAccPasswordState ? (
                                                <>
                                                    {boolUserPassValidated ? (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Password'
                                                            authlvl={10}
                                                            onClickCallback={() => editUserAccPassword(true)}
                                                            style={{
                                                                animation: 'icofocus 4s infinite',
                                                            }}
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Password'
                                                            authlvl={10}
                                                            onClickCallback={() => {
                                                                toast.error('Invalid Password')
                                                            }}
                                                            usefill={true}
                                                            color1='#ff0000'
                                                            style={{
                                                                animation: 'pulse 4s infinite',
                                                            }}
                                                        />
                                                    )}

                                                    <ROIcon
                                                        icon='ROicoCancel'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Cancel'
                                                        onClickCallback={() => editUserAccPassword(false)}
                                                    />
                                                </>
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoEdit'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Edit Password'
                                                    authlvl={10}
                                                    onClickCallback={() => editUserAccPassword()}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row p-0 m-0'>
                                    <div className='custName col-auto p-0 m-0 fs-3 fw-normal'>
                                        {editUserAccPasswordState ? (
                                            <ROPassword
                                                classname='ps-2'
                                                validationCallBack={getNewUserPassword}
                                                password1={txtUserPass}
                                                password2={txtUserPass}
                                            />
                                        ) : (
                                            'Change Main Account Password'
                                        )}
                                    </div>
                                </div>

                                {/*PayPal Subscription*/}
                                <div className='text-primary col-auto pt-4'>
                                    <h5>Subscription Details</h5>
                                </div>
                                {/* If NOT CANCELLED show subscription info */}
                                {fransub === null || fransub.length <= 0 || fransub.status === 'CANCELLED' ? (
                                    <p>
                                        End Date:&nbsp;
                                        <b>{formatLongDate(Date.parse(franacc.FranAccSubEndDate))}</b>
                                    </p>
                                ) : (
                                    <p>
                                        Status:&nbsp;
                                        <b>{fransub.status}</b>
                                        <br />
                                        Subscription ID:&nbsp;
                                        <b> {fransub.id} </b>
                                        <br />
                                        Next Billing:&nbsp;
                                        <b>{formatLongDate(Date.parse(fransub.billing_info.next_billing_time))}</b>
                                    </p>
                                )}
                                {/* If CANCELLED show OLD subscription info */}
                                {(fransub === null || fransub.length <= 0 || fransub.status === 'CANCELLED') &&
                                franacc &&
                                franacc.FranAccPayPalSubID != null ? (
                                    <p>
                                        Status:&nbsp;
                                        <b>{fransub.status}</b>
                                        <br />
                                        Old Subscription ID:&nbsp;
                                        <b> {fransub.id} </b>
                                        <br />
                                        End Date:&nbsp;
                                        <b>{formatLongDate(Date.parse(franacc.FranAccSubEndDate))}</b>
                                    </p>
                                ) : (
                                    ''
                                )}
                                {/* If franacc info populated show payment options */}
                                {/*console.log('daysDiff(new Date(), franacc.FranAccSubEndDate): ', daysDiff(new Date(), franacc.FranAccSubEndDate))*/}
                                {franacc === null || franacc.length <= 0 ? (
                                    ''
                                ) : (franacc.FranAccPayPalSubID === null || franacc.FranAccSuspended === 1 || franacc.FranAccPayPalSubID === '') &&
                                  daysDiff(new Date(), franacc.FranAccSubEndDate) >= 1 ? (
                                    /* If no OLD or CURRENT subscription show paypal button */
                                    FranAccFlags && FranAccFlags.DEVPAYPAL && FranAccFlags.DEVPAYPAL === 'DEVSPECIAL' ? (
                                        <PayPalButtons
                                            style={{ layout: 'vertical', shape: 'rect', color: 'silver', label: 'subscribe' }}
                                            createSubscription={(data, actions) => {
                                                return actions.subscription.create({
                                                    /* Creates the subscription */
                                                    plan_id: 'P-53P89967VK307015DMQGE2FQ',
                                                })
                                            }}
                                            onApprove={(data, actions) => {
                                                addROErrorLog(
                                                    'Subcription onApprove SubID: ' + data.subscriptionID,
                                                    user ? user.name : '',
                                                    'Subcription'
                                                )
                                                const updPayPalSub = {
                                                    franID: user.franid,
                                                    franaccPayPalSubID: data.subscriptionID,
                                                }
                                                dispatch(updatePayPalSub(updPayPalSub))
                                                console.log('Successfully Subscribed with id: ', data.subscriptionID, data)
                                            }}
                                            onError={(err) => {
                                                console.error('An error prevented the buyer from checking out with PayPal', err)
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <div className='row'>
                                                <div className='col-6 fs-3 fw-normal'>Monthly $12</div>
                                                <div className='col-6 fs-3 fw-normal'>Yearly $120 ($10 / Month)</div>
                                                <div className='col-6'>
                                                    {' '}
                                                    <PayPalButtons
                                                        style={{ layout: 'vertical', shape: 'rect', color: 'blue', label: 'subscribe' }}
                                                        createSubscription={(data, actions) => {
                                                            return actions.subscription.create({
                                                                /* Creates the subscription */
                                                                plan_id: 'P-2J315270HB9752308MQGEMMA',
                                                            })
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            addROErrorLog(
                                                                'Subcription onApprove SubID: ' + data.subscriptionID,
                                                                user ? user.name : '',
                                                                'Subcription'
                                                            )
                                                            const updPayPalSub = {
                                                                franID: user.franid,
                                                                franaccPayPalSubID: data.subscriptionID,
                                                            }
                                                            dispatch(updatePayPalSub(updPayPalSub))
                                                            console.log('Successfully Subscribed with id: ', data.subscriptionID, data)
                                                        }}
                                                        onError={(err) => {
                                                            console.error('An error prevented the buyer from checking out with PayPal', err)
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-6'>
                                                    {' '}
                                                    <PayPalButtons
                                                        style={{ layout: 'vertical', shape: 'rect', color: 'gold', label: 'subscribe' }}
                                                        createSubscription={(data, actions) => {
                                                            return actions.subscription.create({
                                                                /* Creates the subscription */
                                                                plan_id: 'P-302826352K1125909MQQXEYI',
                                                            })
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            addROErrorLog(
                                                                'Subcription onApprove SubID: ' + data.subscriptionID,
                                                                user ? user.name : '',
                                                                'Subcription'
                                                            )
                                                            const updPayPalSub = {
                                                                franID: user.franid,
                                                                franaccPayPalSubID: data.subscriptionID,
                                                            }
                                                            dispatch(updatePayPalSub(updPayPalSub))
                                                            console.log('Successfully Subscribed with id: ', data.subscriptionID, data)
                                                        }}
                                                        onError={(err) => {
                                                            console.error('An error prevented the buyer from checking out with PayPal', err)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                ) : fransub === null || fransub.length <= 0 ? (
                                    ''
                                ) : fransub.status !== 'CANCELLED' ? (
                                    !deleteSub /* Show Cancel Sub Button */ ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG'
                                            textafter='Cancel Subscription'
                                            tooltip='Cancel Subscription'
                                            authlvl={0}
                                            onClickCallback={() => confirmDeleteSub(true)}
                                        />
                                    ) : (
                                        /* Show Cancel sub CONFIRM / Do not cancel Button */ <>
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                className='icoSVG danger'
                                                textafter='Confirm Cancel Subscription?'
                                                //tooltip='Cancel Subscription'
                                                authlvl={0}
                                                onClickCallback={() => confirmDeleteSub(true)}
                                            />
                                            <br />
                                            <ROIcon
                                                icon='ROicoCancel'
                                                size={24}
                                                className='icoSVG'
                                                textafter='Do not Cancel Subscription'
                                                //tooltip='Do not Cancel Subscription'
                                                onClickCallback={() => confirmDeleteSub(false)}
                                            />
                                        </>
                                    )
                                ) : fransub === null || fransub.length <= 0 || fransub.status === 'CANCELLED' ? (
                                    'Waiting for Subscription run out.'
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}
                        {
                            //#endregion Account Settings
                        }

                        {
                            //#region Business Settings
                        }
                        {settingsCategory === 'Business' ? (
                            <>
                                {/*Business Details*/}
                                <div className='row pt-2'>
                                    <div className='text-primary col-auto'>
                                        <h5>Business Details</h5>
                                    </div>
                                    {/*Interface Buttons*/}
                                    <div className='col-auto me-auto px-0'>
                                        <div>
                                            {editState ? (
                                                <>
                                                    {boolFranAccFormValidated ? (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Business'
                                                            authlvl={10}
                                                            onClickCallback={() => editFranAcc(true)}
                                                            style={{
                                                                animation: 'icofocus 4s infinite',
                                                            }}
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Business'
                                                            authlvl={10}
                                                            onClickCallback={() => vaildateFranAccForm(true)}
                                                            usefill={true}
                                                            color1='#ff0000'
                                                            style={{
                                                                animation: 'pulse 4s infinite',
                                                            }}
                                                        />
                                                    )}

                                                    <ROIcon
                                                        icon='ROicoCancel'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Cancel'
                                                        onClickCallback={() => editFranAcc(false)}
                                                    />
                                                </>
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoEdit'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Edit Business'
                                                    authlvl={10}
                                                    onClickCallback={() => editFranAcc()}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!editState ? (
                                    <>
                                        {/*Name, tel, email*/}
                                        <div className='container p-0 pt-0 m-0'>
                                            <div className='row'>
                                                {/*Fran Account Name*/}
                                                <div className='custName col-4 pl-2 fs-3'>
                                                    {FranAccName && FranAccName !== '' ? FranAccName : 'Please update Business Name'}
                                                </div>
                                                {/*Tel Number*/}
                                                <div className='custCont col-3 fs-5'>
                                                    {/*Tel*/}
                                                    <span className='fs-5 fw-normal'>
                                                        {FranAccTel && FranAccTel !== '' ? FranAccTel : '(Please update telephone)'}
                                                    </span>
                                                </div>
                                                {/*Franchise Email*/}
                                                <div className='custEmail col-4 fs-5 fw-normal me-auto '>
                                                    {/*CustEmail*/}
                                                    {FranAccEmail && FranAccEmail !== '' ? FranAccEmail : '(Please update email)'}
                                                </div>
                                            </div>
                                        </div>
                                        {/*Address*/}
                                        <div className='row'>
                                            {/* Fran Adres */}
                                            <div className='col-5'>
                                                <div className='row'>
                                                    <div className='col-sm-1'>
                                                        <ROIcon icon='ROicoSign' size={24} className='icoSVG' />
                                                    </div>
                                                    <div className='divCustDetails col-sm-11 p-0'>
                                                        <span className='mainCustomerAddress m-0'>
                                                            {franacc.FranAccStreetAddress
                                                                ? franacc.FranAccStreetAddress
                                                                : '(Please update street address)'}
                                                            <br />
                                                            {franacc.FranAccSuburb ? franacc.FranAccSuburb : ''}
                                                            {franacc.FranAccSuburb ? <br /> : ''}
                                                            {franacc.FranAccCity ? franacc.FranAccCity : '(Please update city)'}
                                                            ,&nbsp;
                                                            {franacc.FranAccPostalCode ? franacc.FranAccPostalCode : '(Please update postal code)'}
                                                            <br />
                                                            {franacc.FranAccCountry ? franacc.FranAccCountry : '(Please update country)'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='container p-0 pt-0 m-0'>
                                            <div className='row'>
                                                {/*Fran Account Name*/}
                                                <div className='custName col-4 pl-2 fs-3'>
                                                    <input
                                                        className='txtCustName txtBox'
                                                        value={txtAccountName}
                                                        placeholder={'Business Name'}
                                                        onChange={(e) => {
                                                            setTxtAccountName(e.target.value)
                                                            vaildateFranAccForm()
                                                        }}
                                                        ref={valFranAccNameRef}
                                                        title='Business Name'
                                                        pattern={RegExPattern.Text}
                                                        required
                                                    />
                                                </div>
                                                {/*Tel Number*/}
                                                <div className='custCont col-3 fs-5'>
                                                    {/*Tel*/}
                                                    <span className='fs-5 fw-normal'>
                                                        {' '}
                                                        <input
                                                            className='txtCustName txtBox'
                                                            value={txtAccountTel}
                                                            placeholder={'Phone Number'}
                                                            onChange={(e) => {
                                                                setTxtAccountTel(e.target.value)
                                                                vaildateFranAccForm()
                                                            }}
                                                            ref={valFranAccTelRef}
                                                            title='Business Phone'
                                                            pattern={RegExPattern.Phone}
                                                            required
                                                        />
                                                    </span>
                                                </div>
                                                {/*Franchise Email*/}
                                                <div className='custEmail col-4 fs-5 fw-normal me-auto '>
                                                    {/*CustEmail*/}
                                                    <input
                                                        className='txtCustName txtBox'
                                                        value={txtAccountEmail}
                                                        placeholder={'Email'}
                                                        type='email'
                                                        onChange={(e) => {
                                                            setTxtAccountEmail(e.target.value)
                                                            vaildateFranAccForm()
                                                        }}
                                                        ref={valFranAccEmailRef}
                                                        title='Business Email'
                                                        required
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Address*/}
                                        <div className='row'>
                                            {/* Fran Adres */}
                                            <div className='col-5'>
                                                <div className='row'>
                                                    <div className='col-1'>
                                                        <ROIcon icon='ROicoSign' size={24} className='icoSVG' />
                                                    </div>
                                                    <div className='divCustDetails col-8 p-0'>
                                                        <span className='mainCustomerAddress m-0'>
                                                            <input
                                                                className='txtCustName txtBox'
                                                                value={txtAccountStreet}
                                                                placeholder={'Street'}
                                                                onChange={(e) => {
                                                                    setTxtAccountStreet(e.target.value)
                                                                    vaildateFranAccForm()
                                                                }}
                                                                ref={valFranAccStreetAddressRef}
                                                                title='Business Street'
                                                                pattern={RegExPattern.Text}
                                                                required
                                                            />
                                                            <br />
                                                            <input
                                                                className='txtCustName txtBox'
                                                                value={txtAccountSuburb}
                                                                placeholder={'Suburb'}
                                                                onChange={(e) => {
                                                                    setTxtAccountSuburb(e.target.value)
                                                                    vaildateFranAccForm()
                                                                }}
                                                                ref={valFranAccSuburbRef}
                                                                title='Business Suburb'
                                                                pattern={RegExPattern.Text}
                                                            />
                                                            <input
                                                                className='txtCustName txtBox'
                                                                value={txtAccountCity}
                                                                placeholder={'City'}
                                                                onChange={(e) => {
                                                                    setTxtAccountCity(e.target.value)
                                                                    vaildateFranAccForm()
                                                                }}
                                                                ref={valFranAccCityRef}
                                                                title='Business City'
                                                                pattern={RegExPattern.Text}
                                                                required
                                                            />
                                                            <br />
                                                            <input
                                                                className='txtCustName txtBox'
                                                                value={txtAccountPostalCode}
                                                                placeholder={'Postal Code'}
                                                                onChange={(e) => {
                                                                    setTxtAccountPostalCode(e.target.value)
                                                                    vaildateFranAccForm()
                                                                }}
                                                                ref={valFranAccPostalCodeRef}
                                                                title='Business Postal Code'
                                                                pattern={RegExPattern.Text}
                                                                required
                                                            />
                                                            <br />
                                                            <input
                                                                className='txtCustName txtBox'
                                                                value={txtAccountCountry}
                                                                placeholder={'Business Country'}
                                                                onChange={(e) => {
                                                                    setTxtAccountCountry(e.target.value)
                                                                    vaildateFranAccForm()
                                                                }}
                                                                ref={valFranAccCountryRef}
                                                                title='Business Country'
                                                                pattern={RegExPattern.Text}
                                                                required
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className='row pt-4' /*CustInfo*/>
                                    <div className='text-primary col-auto'>
                                        <h5>Business Settings</h5>
                                    </div>
                                    {/*Interface Buttons*/}
                                    <div className='col-auto me-auto px-0'>
                                        <div>
                                            {editSettingsState ? (
                                                <>
                                                    {boolFranAccSettingsFormValidated ? (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Business'
                                                            authlvl={10}
                                                            onClickCallback={() => editFranAccSettings(true)}
                                                            style={{
                                                                animation: 'icofocus 4s infinite',
                                                            }}
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Business'
                                                            authlvl={10}
                                                            onClickCallback={() => vaildateFranAccSettingsForm(true)}
                                                            usefill={true}
                                                            color1='#ff0000'
                                                            style={{
                                                                animation: 'pulse 4s infinite',
                                                            }}
                                                        />
                                                    )}

                                                    <ROIcon
                                                        icon='ROicoCancel'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Cancel'
                                                        onClickCallback={() => editFranAccSettings(false)}
                                                    />
                                                </>
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoEdit'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Edit Business'
                                                    authlvl={10}
                                                    onClickCallback={() => setEditSettingsState(true)}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!editSettingsState ? (
                                    <>
                                        {/*Settings vat, bar/psi, currency*/}
                                        <div className='container p-0 pt-0 m-0'>
                                            <div className='row'>
                                                {/*Fran Account Name*/}
                                                <div className='custName col-4 pl-2 fs-3'>
                                                    Pressure:&nbsp;{FranAccSettings.Bar === 'PSI' ? 'PSI' : 'BAR'}
                                                </div>
                                                {/*Currency Type*/}
                                                <div className='custCont col-3 fs-5'>
                                                    <span className='fs-5 fw-normal'>
                                                        Currency:&nbsp;{FranAccSettings.Currency ? FranAccSettings.Currency : 'None'}
                                                    </span>
                                                </div>
                                                {/*Currency Symbol*/}
                                                <div className='custEmail col-4 fs-5 fw-normal me-auto '>
                                                    Currency Symbol:&nbsp;{FranAccSettings.CurrencySymb ? FranAccSettings.CurrencySymb : 'None'}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/*Settings vat, bar/psi, currency*/}
                                        <div className='container p-0 pt-0 m-0'>
                                            <div className='row'>
                                                {/*Fran Account Name*/}
                                                <div className='custName col-4 pl-2 fs-3'>
                                                    <ROIcon
                                                        icon='ROicoSettings'
                                                        size={24}
                                                        className='icoSVG text-decoration-underline'
                                                        tooltip='Pressure Type'
                                                        onClickCallback={() =>
                                                            updateSetting({ Bar: objAccountSettings.Bar !== 'PSI' ? 'PSI' : 'BAR' })
                                                        }
                                                        authlvl={10}
                                                        textbefore={objAccountSettings.Bar === 'PSI' ? 'Pressure: PSI' : 'Pressure: BAR'}
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                    />
                                                </div>
                                                {/*Currency Type*/}
                                                <div className='custCont col-3 fs-5'>
                                                    <span className='fs-5 fw-normal'>
                                                        <ROComboBox
                                                            dataArray={GetCurrency()}
                                                            classname='ROdropdownpaypal'
                                                            placeholdertext={'Currency'}
                                                            textVal={GetCurrency(FranAccSettings.Currency).code}
                                                            myValueCallback={updateCurrency}
                                                            ref={valFranAccSettingCurrencyRef}
                                                            required={true}
                                                            validationCallBack={vaildateFranAccSettingsForm}
                                                            allowCustomValue={false}
                                                        />
                                                        {/* <input
                                            className='txtCustName txtBox'
                                            value={objAccountSettings.Currency}
                                            placeholder={(objAccountSettings.Currency ||= 'ZAR')}
                                            onChange={(e) => {
                                                updateSetting({ Currency: (objAccountSettings.Currency ||= 'ZAR') })
                                                vaildateFranAccForm()
                                            }}
                                            ref={valFranAccSettingCurrencyRef}
                                            title='Currency'
                                            pattern={RegExPattern.Text}
                                            required
                                        /> */}
                                                    </span>
                                                </div>
                                                {/*Currency Symbol*/}
                                                <div className='custEmail col-4 fs-5 fw-normal me-auto '>
                                                    Currency Symbol:&nbsp;{FranAccSettings.CurrencySymb}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            ''
                        )}

                        {
                            //#endregion Business Settings
                        }

                        {
                            //#region Email Settings
                        }

                        {settingsCategory === 'Email' ? (
                            <>
                                <div className='row'>
                                    <div className='col-auto pt-2'>
                                        <h5 className='text-primary'>Email Settings</h5>
                                    </div>
                                    {/*Interface Buttons*/}
                                    <div className='col-auto me-auto px-0'>
                                        <div>
                                            {editEmailState ? (
                                                <>
                                                    {boolFranAccEmailFormValidated ? (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Email'
                                                            authlvl={10}
                                                            onClickCallback={() => editFranAccSettings(true)}
                                                            //onClickCallback={() => editFranAccSettings(true)}
                                                            style={{
                                                                animation: 'icofocus 4s infinite',
                                                            }}
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Email'
                                                            authlvl={10}
                                                            onClickCallback={() => vaildateFranAccEmailForm(true)}
                                                            usefill={true}
                                                            color1='#ff0000'
                                                            style={{
                                                                animation: 'pulse 4s infinite',
                                                            }}
                                                        />
                                                    )}

                                                    <ROIcon
                                                        icon='ROicoCancel'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Cancel'
                                                        onClickCallback={() => editFranAccSettings(false)}
                                                        //onClickCallback={() => editFranAccSettings(false)}
                                                    />
                                                </>
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoEdit'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Edit Email'
                                                    authlvl={10}
                                                    onClickCallback={() => setEditEmailState(true)}
                                                    //onClickCallback={() => editFranAccSettings()}
                                                    style={{
                                                        animation: 'pulse 4s infinite',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <span>Customize emails send to customers</span>
                                    </div>
                                </div>
                                {/* Reply-To Email */}
                                <div className='row pt-3'>
                                    <div className='col-4  p-0 m-0 ms-3 fw-bold'>Reply-To Email</div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 p-0 m-0 ms-3'>
                                        {editEmailState ? (
                                            <input
                                                className='txtBox m-0'
                                                value={objAccountSettings.ReplyTo}
                                                placeholder={objAccountSettings.ReplyTo}
                                                onChange={(e) => {
                                                    updateSetting({ ReplyTo: e.target.value })
                                                    vaildateFranAccEmailForm()
                                                }}
                                                ref={valFranAccEmailReplyTo}
                                                title='Reply to Email'
                                                //pattern={RegExPattern.Text}
                                                type='email'
                                                required
                                            />
                                        ) : (
                                            objAccountSettings.ReplyTo
                                        )}
                                    </div>
                                </div>

                                {/* Reply-To Email */}
                                <div className='row pt-3'>
                                    <div className='col-4  p-0 m-0 ms-3 fw-bold'>Email from Name</div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 p-0 m-0 ms-3'>
                                        {editEmailState ? (
                                            <input
                                                className='txtBox m-0'
                                                value={objAccountSettings.FromName ? objAccountSettings.FromName : FranAccName}
                                                placeholder={objAccountSettings.FromName ? objAccountSettings.FromName : 'Email from Name'}
                                                onChange={(e) => {
                                                    updateSetting({ FromName: e.target.value })
                                                    vaildateFranAccEmailForm()
                                                }}
                                                ref={valEmailFromName}
                                                title='From Name'
                                                pattern={RegExPattern.Text}
                                            />
                                        ) : objAccountSettings.FromName ? (
                                            objAccountSettings.FromName
                                        ) : (
                                            FranAccName
                                        )}
                                    </div>
                                </div>

                                {/* Reply-To Email */}
                                <div className='row pt-3'>
                                    <div className='col-4  p-0 m-0 ms-3 fw-bold'>CC (Carbon Copy) Email</div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 p-0 m-0 ms-3'>
                                        {editEmailState ? (
                                            <input
                                                className='txtBox m-0'
                                                value={objAccountSettings.cc ? objAccountSettings.cc : ''}
                                                placeholder={objAccountSettings.cc ? objAccountSettings.cc : 'Send Copy'}
                                                onChange={(e) => {
                                                    updateSetting({ cc: e.target.value })
                                                    vaildateFranAccEmailForm()
                                                }}
                                                ref={valFranAccEmailCC}
                                                title='Carbon Copy Email'
                                                type='email'
                                            />
                                        ) : objAccountSettings.cc ? (
                                            objAccountSettings.cc
                                        ) : (
                                            'None'
                                        )}
                                    </div>
                                </div>

                                {/* Email Message*/}
                                <div className='row pt-3'>
                                    <div className='col-8  p-0 m-0 ms-3 fw-bold'>Email Message</div>
                                </div>
                                <div className='row'>
                                    {editEmailState ? (
                                        <>
                                            <div className='col-8 grow-wrap p-0 m-0 ms-3'>
                                                <textarea
                                                    className='m-0 rounded borderChat w-100'
                                                    value={txtEmailMsg.substring(0, 1000)}
                                                    placeholder={txtEmailMsg}
                                                    onChange={(e) => {
                                                        setTxtEmailMsg(e.target.value)
                                                        valEmailMsgRef.current.parentNode.dataset.replicatedvalue = valEmailMsgRef.current.value
                                                        vaildateFranAccEmailForm()
                                                        UpdateEmailLength()
                                                    }}
                                                    ref={valEmailMsgRef}
                                                    title='Email Message'
                                                    pattern={RegExPattern.Text}
                                                    maxLength='1000'
                                                    required
                                                />
                                            </div>
                                            <div className='col-3 p-0 m-0 ms-2 fw-bold'>
                                                {/* selectStart.current = inputRef.current.selectionStart */}
                                                <small className='fw-bold'>Placeholders you can use in the email body:</small> <br />
                                                <small className='fw-normal'>
                                                    <i>Click [Tag] to insert at current typing position.</i>
                                                </small>
                                                <br />
                                                <br />
                                                {emailTokens.map((e) => {
                                                    return (
                                                        <div className='showClickable' onClick={() => AddTokenToEmail(e[0])}>
                                                            {e[0]}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    ) : (
                                        <div className='col-11 p-0 m-0 ms-3'>
                                            {/* {console.log('txtEmailMsg: ', txtEmailMsg)} */}
                                            {txtEmailMsg
                                                ? txtEmailMsg.split('\n').map((line) => {
                                                      return (
                                                          <>
                                                              {PreviewEmail(line)}
                                                              <br />
                                                          </>
                                                      )
                                                  })
                                                : ''}
                                        </div>
                                    )}
                                </div>
                                {/* Email Message*/}
                                <div className='row pt-4'>
                                    <div className='col-8  p-0 m-0 ms-3 fw-bold'>Client data access duration (days)</div>
                                </div>
                                <div className='row'>
                                    <div className='col-1 p-0 m-0 ms-3'>
                                        {editEmailState ? (
                                            <input
                                                className='txtBox m-0'
                                                value={parseInt(objAccountSettings.EmailToken) ? parseInt(objAccountSettings.EmailToken) : 0}
                                                placeholder={parseInt(objAccountSettings.EmailToken) ? parseInt(objAccountSettings.EmailToken) : 0}
                                                onChange={(e) => {
                                                    updateSetting({ EmailToken: parseInt(e.target.value) })
                                                    //setTxtTokenExpiry(e.target.value)
                                                    vaildateFranAccEmailForm()
                                                }}
                                                ref={valFranAccEmailExpire}
                                                title='Access duration'
                                                pattern={RegExPattern.Int}
                                            />
                                        ) : parseInt(objAccountSettings.EmailToken) ? (
                                            parseInt(objAccountSettings.EmailToken)
                                        ) : (
                                            0
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Account
