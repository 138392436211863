import PropTypes from 'prop-types'
import iconPath from '../features/iconsLib'
import myUUid from '../features/myUUid'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const defaultStyles = { display: 'inline-block', verticalAlign: 'middle' }

const ROIcon = ({
    size = 24,
    width = size,
    height = size,
    usefill = false,
    color1,
    color2,
    color3,
    icon,
    className,
    style,
    viewBox,
    tooltip = '',
    onClickCallback,
    textbefore = '',
    textafter = '',
    authlvl = 10, //Default auth level for all ROIcons
    to = '',
    isLink = true,
}) => {
    const styles = { ...defaultStyles, ...style }

    const { user } = useSelector((state) => state.auth)

    let a_granted = false

    if (user && user.type !== null && user.type < 99) {
        a_granted = user.type <= authlvl
    } else if (authlvl === 99) {
        a_granted = true
    }

    //check rights

    //console.log('ROIcon Size: ', size, icon)

    //If icon not found
    if (!iconPath[icon]) {
        return (
            <>
                <div>
                    <i className='errortxt ROTooltip' data-tooltip={'Icon: ' + icon}>
                        Icon Not Found
                    </i>
                </div>
            </>
        )
    }

    //Get viewBox from iconsLib or get default viewBox
    viewBox = iconPath[icon][0].viewBox || viewBox

    //If useFill = true. Get color from color attribute if set. Else fill color from svg. Else default to black
    const getColor = (fid, fillcolor) => {
        fid = fid || 1

        if (!usefill) return ''

        switch (fid) {
            case 1:
                return color1 || fillcolor || '#000000'

            case 2:
                return color2 || fillcolor || '#000000'

            case 3:
                return color3 || fillcolor || '#000000'

            default:
                return ''
        }
    }

    //Convert/simplify svg data. Type(path/circle/etc), Data(draw data), Color(fill)
    const readSVGData = ({ type = 'nothing', data = '', fillID = 1, fill }) => {
        fill = color1 || fill

        if (type === null || type === 'nothing' || type === '') {
            return ''
        } else if (type === 'path' || type === 'circle' || type === 'rect' || type === 'polygon') {
            const result = {
                svgType: type,
                fill: getColor(fillID, fill),
                data,
            }
            return result
        } else {
            return ''
        }
    }

    let svgData = [{}]
    //Add a array item for each draw item / layer from svg (path/circle/etc)
    for (let i = 0; i < iconPath[icon].length; i++) {
        svgData.push(readSVGData(iconPath[icon][i]))
    }

    //If user doesn't have high enought rights display error message
    const noRights = (e) => {
        e.preventDefault()
        if (!a_granted) {
            toast.error('User not authorized.' /* + user.type.toString() + ' > ' + authlvl */)
        }
    }

    //Return or render svg html.
    return isLink ? (
        <>
            <Link
                to={to !== '' ? to : ''}
                className={to !== '' ? 'nav-link' : ''}
                // style={{
                //     pointerEvents: to !== '' ? '' : a_granted ? '' : 'none',
                // }}
                onClick={(event) => (to !== '' && a_granted ? '' : noRights(event))}
                tabIndex={-1}
            >
                <div
                    className={tooltip !== '' ? className + ' ROTooltip' : className}
                    data-tooltip={tooltip}
                    onClick={a_granted ? onClickCallback : null}
                >
                    {textbefore}
                    <svg
                        style={styles}
                        viewBox={viewBox}
                        width={`${width}px`}
                        height={`${height}px`}
                        data-tooltip={tooltip}
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                    >
                        {svgData.map((data) => {
                            if (data.svgType === 'path') {
                                return <path key={myUUid()} fill={data.fill} d={data.data} />
                            } else if (data.svgType === 'circle') {
                                return <circle key={myUUid()} fill={data.fill} cx={data.data.cx} cy={data.data.cy} r={data.data.r} />
                            } else if (data.svgType === 'rect') {
                                return (
                                    <rect
                                        key={myUUid()}
                                        fill={data.fill}
                                        x={data.data.x}
                                        y={data.data.y}
                                        width={data.data.width}
                                        height={data.data.height}
                                        rx={data.data.rx}
                                        ry={data.data.ry}
                                    />
                                )
                            } else if (data.svgType === 'polygon') {
                                return <polygon key={myUUid()} fill={data.fill} points={data.data} />
                            } else {
                                return ''
                            }
                        })}
                    </svg>
                    <div className='ROiconAfter'>{textafter}</div>
                </div>
            </Link>
        </>
    ) : (
        <>
            <div
                className={tooltip !== '' ? className + ' ROTooltip' : className}
                data-tooltip={tooltip}
                onClick={a_granted ? onClickCallback : null}
            >
                {textbefore}
                <svg
                    style={styles}
                    viewBox={viewBox}
                    width={`${width}px`}
                    height={`${height}px`}
                    data-tooltip={tooltip}
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                >
                    {svgData.map((data) => {
                        if (data.svgType === 'path') {
                            return <path key={myUUid()} fill={data.fill} d={data.data} />
                        } else if (data.svgType === 'circle') {
                            return <circle key={myUUid()} fill={data.fill} cx={data.data.cx} cy={data.data.cy} r={data.data.r} />
                        } else if (data.svgType === 'rect') {
                            return (
                                <rect
                                    key={myUUid()}
                                    fill={data.fill}
                                    x={data.data.x}
                                    y={data.data.y}
                                    width={data.data.width}
                                    height={data.data.height}
                                    rx={data.data.rx}
                                    ry={data.data.ry}
                                />
                            )
                        } else if (data.svgType === 'polygon') {
                            return <polygon key={myUUid()} fill={data.fill} points={data.data} />
                        } else {
                            return ''
                        }
                    })}
                </svg>
                {textafter}
            </div>
        </>
    )
}

ROIcon.defaultProps = {
    size: 24,
    viewBox: '0 0 100 100',
    usefill: false,
    style: {},
    className: '',
}

ROIcon.propTypes = {
    icon: PropTypes.string.isRequired,
}

export default ROIcon
