import axios from 'axios'

const API_URL = '/api/customers/'

//console.log('custService Start User: ', user)

//Add new customer
const createCustomer = async (custData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    custData = { ...custData, user: { ...user } }

    const response = await axios.post(API_URL, custData, headersConfig)
    return response.data
}

const updateCustomer = async (custData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    //console.log('Update Customer: ', custData)
    custData = { ...custData, user: { ...user } }

    const response = await axios.put(API_URL + custData.custID, custData, headersConfig)

    return response.data
}

//Get customers
//Get can't send payload data userCreds Useless
//Need to use params 'headersConfig' config parameter { params: { usertype: 10 } } which sends data as a url paramete. http://www.foo.com/get?usertype=10
const getCustomers = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig)

        return response.data
    } catch (error) {
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        //Need to improve en generalize this error catch through app
        console.log('custService getCustomers response: ', error.response)

        if (error.response.status === 401) {
            if (error.response.data.message) {
                //Check if server sent a message and create error with message
                throw new Error(error.response.data.message)
            } else {
                //Else create error with statusText
                throw new Error(error.response.statusText + ' Please Log In')
            }
        }

        throw new Error('custService(getCustomers): ' + error.response.data.message)
    }
}

//Delete customer
const deleteCustomer = async (custID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    //console.log('Delete Service custid: ', custID)
    const response = await axios.delete(API_URL + custID, headersConfig)

    return response.data
}

const custService = {
    createCustomer,
    getCustomers,
    deleteCustomer,
    updateCustomer,
}

export default custService
