import { useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { getParts, resetparts } from '../features/parts/partsSlice'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROHelpBox from '../components/ROHelpBox.jsx'
import ROComboBox from '../components/ROComboBox'
import { VaildateForm } from '../features/globalFunctions'
import { addROErrorLog } from '../features/globalFunctions'

//Import Customer Redux State/Global Var and Axiox database calls
import { createPart } from '../features/parts/partsSlice.js'
import ContentBox from '../components/ContentBox'
import PartItem from '../components/PartItem'
import { RegExPattern } from '../features/globalVars'

const Parts = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, isError, message } = useSelector((state) => state.auth)

    //console.log('*** Parts Main Draw ***')

    const [displayAllParts, setDisplayAllParts] = useState('')

    //New Part state varibales
    const [showNewPart, setShowNewPart] = useState(false)
    const [txtPartName, setTxtPartName] = useState('')
    const [txtPartCode, setTxtPartCode] = useState('')
    const [txtPartPrice, setTxtPartPrice] = useState('')
    const [txtPartCategory, setTxtPartCategory] = useState('')
    const [arrCategories, setArrCategories] = useState('')

    //Add New Part
    const AddNewPart = () => {
        const newPart = {
            franID: user.franid,
            partcode: txtPartCode,
            partname: txtPartName,
            partserialnr: '',
            partlastprice: txtPartPrice,
            partcategory: txtPartCategory,
            partimageurl: '',
            partnotes: '',
            partflags: '',
        }

        ClearForm()
        setShowNewPart(false)

        dispatch(createPart(newPart))
    }

    const ClearForm = () => {
        setTxtPartCode('')
        setTxtPartName('')
        setTxtPartPrice('')
        setTxtPartCategory('')
    }

    //Get State parts from redux
    const { parts, isPartsError, partsmessage } = useSelector((state) => state.parts)

    useEffect(() => {
        console.log('Parts UseEffect')
        if (isError) {
            console.log('Part USERS useEffect error: ', message)
            toast.error(message)
            addROErrorLog('Part USERS useEffect Error:  ' + message, user ? user.name : '', 'Parts')
            dispatch(reset())
            navigate('/')
            return
        }

        if (isPartsError) {
            console.log('Parts useEffect error: ', partsmessage)
            toast.error(partsmessage)
            addROErrorLog('Parts useEffect Error:  ' + partsmessage, user ? user.name : '', 'Parts')
            dispatch(resetparts())
            navigate('/')
            return
        }

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }

        try {
            console.log('Get All Data from servers')
            dispatch(getParts())
        } catch (error) {
            console.log('Parts.jsx Dispatch(all) Error: ', error)
            addROErrorLog('Parts Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'Parts')
            toast.error(error)
            return () => dispatch(reset())
        }

        SearchParts(searchString)

        return () => dispatch(reset())
    }, [isError, isPartsError]) //[navigate, user, dispatch, isError, isPartsError]

    //Collapse All
    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //Search Parts
    const [searchResults, setSearchResults] = useState([])
    let [searchString, setSearchString] = useState('')
    const [searchFound, setSearchFound] = useState(false)

    const SearchParts = (_searchstr, advSearch = false) => {
        setSearchString(_searchstr)
        _searchstr = _searchstr.toLowerCase()

        let searchWords = _searchstr.trim().split(' ')

        if (!advSearch) {
            searchWords = [_searchstr]
        }
        //console.log('search : ', _searchstr)

        if (_searchstr === '') {
            setSearchResults(parts)
            setSearchFound(true)
        } else {
            let tempResults = []

            if (parts) {
                tempResults = parts.filter((item) => {
                    let found = false
                    searchWords.forEach((word) => {
                        if (String(item.PartCode).toLowerCase().includes(word) || String(item.PartName).toLowerCase().includes(word) || String(item.PartLastPrice).toLowerCase().includes(word) || String(item.PartCategory).toLowerCase().includes(word)) {
                            found = true
                        }
                    })
                    return found
                })
            }

            if (tempResults === null || tempResults.length === 0) {
                if (!advSearch) {
                    SearchParts(_searchstr, true)
                } else {
                    setSearchResults(parts)
                    setSearchFound(false)
                }
            } else {
                setSearchResults(tempResults)
                setSearchFound(true)
            }
        }
    }

    //Only update/redraw if search results changed. Or else it will redraw on each letter type even if results didn't change
    //used to check if customers is populated and to only search once on init
    // if (parts != null && parts.length > 0 && JSON.stringify(partsSearchData) !== JSON.stringify(parts)) {
    //     //console.log('more search')
    //     setPartsSearchData(parts)
    //     SearchParts(searchString)
    // }

    useMemo(() => {
        console.log('Parts useMemo Search Function')
        //setPartsSearchData(parts)
        SearchParts(searchString)

        // if (searchResults && searchResults.length <= 0) {
        //     SearchCustomers(true)
        // }

        let categories = new Set(parts.map((part) => part.PartCategory))
        categories = Array.from(categories)
        categories = categories.map((item, i) => {
            return { Text: item }
        })

        setArrCategories(categories)
    }, [searchString, parts])

    useMemo(() => {
        console.log('searchResults useMemo Search Function')
        const display = searchResults.map((part, i) => {
            return <PartItem key={myUUid()} part={part} arrCategories={arrCategories} collapse={getCollapseAll} containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'} />
        })

        setDisplayAllParts(display)
    }, [searchResults])

    //#region New Part Form Validation
    const [boolPartFormValidated, setPartFormValidated] = useState(false)

    const valPartCodeRef = useRef()
    const valPartNameRef = useRef()
    const valPartPriceRef = useRef()
    const valPartCategoryRef = useRef()

    const vaildatePartNewPartForm = (clicked = false) => {
        if (!showNewPart) return

        if (showNewPart) {
            setPartFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valPartCodeRef,
                            text: 'No Special Characters',
                            form: 'New Part valPartCodeRef',
                        },
                        {
                            ref: valPartNameRef,
                            text: 'No Special Characters',
                            form: 'New Part valPartNameRef',
                        },
                        {
                            ref: valPartPriceRef,
                            text: 'Enter valid price',
                            form: 'New Part valPartPriceRef',
                        },
                        {
                            ref: valPartCategoryRef,
                            text: 'Part Category Required',
                            form: 'New Part valPartCategoryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    return (
        <>
            <div className='bg-welcome'>
                <section className='pageHead container clearfix'>
                    <div className='pageFunctions row bg-welcome pb-2'>
                        <div className='col-md-3 pt-2 text-center'>
                            <h4>
                                Your Parts &nbsp;
                                <ROIcon
                                    icon={showNewPart ? 'ROicoCancel' : 'ROicoPlus'}
                                    size={24}
                                    authlvl={10}
                                    className='icoSVG'
                                    textbefore={showNewPart ? 'Cancel' : ' '}
                                    tooltip={showNewPart ? 'Cancel' : 'Add New Part '}
                                    onClickCallback={() => {
                                        setShowNewPart((prev) => !prev)
                                        ClearForm()
                                    }}
                                    textafter={parts === null || (!showNewPart && parts.length <= 0) ? '<-Click' : ''}
                                    style={{
                                        animation: 'pulse 4s infinite',
                                    }}
                                />
                            </h4>
                        </div>

                        {parts === null || parts.length <= 0 ? (
                            ''
                        ) : (
                            <>
                                {/* SEARCH */}
                                <div className='interfSearch align-middle col-md-5 me-auto pt-2 pb-2'>
                                    {/* <label htmlFor='search'>Search</label> */}
                                    <input id='search' placeholder='Enter your search here' value={searchString} onChange={(e) => SearchParts(e.target.value)} />
                                    {searchFound ? '' : <p className='danger'>Search doesn't match any part</p>}
                                </div>
                                <div className='align-middle col-auto pt-2'>
                                    <ROHelpBox helpTopic={'parts'} />

                                    {/* <ROIcon icon='ROicoUp' size={24} className='icoSVG' tooltip='Collapse All' onClickCallback={() => CollapseAll()} /> */}
                                    {/* <ROIcon icon='ROicoHelp' size={24} className='icoSVG' tooltip='Help' /> */}
                                </div>
                            </>
                        )}

                        {showNewPart ? (
                            <>
                                <div className='container p-2 pt-0 bg-light rounded-2'>
                                    Add New Parts:
                                    <div className='row' /*PartInfo Expanded*/>
                                        <div className='ROmaintxt col-md-3'>
                                            <input
                                                className='txtBox'
                                                value={txtPartCode}
                                                placeholder='Product Code'
                                                onChange={(e) => {
                                                    setTxtPartCode(e.target.value)
                                                    vaildatePartNewPartForm()
                                                }}
                                                ref={valPartCodeRef}
                                                title='Part Code'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='col-auto me-auto px-0'>
                                            <>
                                                {boolPartFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        authlvl={10}
                                                        className='icoSVG'
                                                        tooltip='Add Part'
                                                        onClickCallback={() => AddNewPart()}
                                                        style={{
                                                            animation: 'icofocus 4s infinite',
                                                        }}
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        authlvl={10}
                                                        className='icoSVG'
                                                        tooltip='Add Part'
                                                        onClickCallback={() => vaildatePartNewPartForm(true)}
                                                        usefill={true}
                                                        color1='#ff0000'
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                    />
                                                )}

                                                <ROIcon icon='ROicoCancel' size={24} className='icoSVG' tooltip='Cancel' onClickCallback={() => setShowNewPart((prev) => !prev)} />
                                            </>
                                        </div>
                                    </div>
                                    <div className='row' /*PartInfo Expanded*/>
                                        <div className='ROnormaltxt col-md-6 fs-5'>
                                            {' '}
                                            <input
                                                className='txtBox'
                                                value={txtPartName}
                                                placeholder='Product Name'
                                                onChange={(e) => {
                                                    setTxtPartName(e.target.value)
                                                    vaildatePartNewPartForm()
                                                }}
                                                ref={valPartNameRef}
                                                title='Part Name'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='ROnormaltxt col-md-2 fs-5'>
                                            {' '}
                                            <input
                                                className='txtBox'
                                                value={txtPartPrice}
                                                placeholder='Latest Price'
                                                onChange={(e) => {
                                                    setTxtPartPrice(e.target.value.replace(/[^0-9\.]+/g, ''))
                                                    vaildatePartNewPartForm()
                                                }}
                                                ref={valPartPriceRef}
                                                title='Part Price'
                                                pattern={RegExPattern.Dec}
                                                required
                                            />
                                        </div>
                                        <div className='ROnormaltxt col-md-4 fs-5'>
                                            {' '}
                                            <ROComboBox dataArray={arrCategories} placeholdertext='Category' myValueCallback={setTxtPartCategory} textVal={txtPartCategory} ref={valPartCategoryRef} required={true} validationCallBack={() => vaildatePartNewPartForm()} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </section>
            </div>

            <section className='pageHead container clearfix pt-2'></section>
            <div className='pageContent container'>
                {displayAllParts}
                {/* {searchResults.map((part, i) => {
                    return (
                        <PartItem
                            key={myUUid()}
                            part={part}
                            arrCategories={arrCategories}
                            collapse={getCollapseAll}
                            containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'}
                        />
                    )
                })} */}
            </div>
        </>
    )
}

export default Parts
