import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addROErrorLog } from '../features/globalFunctions'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'

const Terms = () => {
    const navigate = useNavigate()

    return (
        <>
            <section className='pageHead container'>
                <div className='pageFunctions row header sticky-top py-3'>
                    {/* <h6>Welcome {user && user.name}</h6> */}
                    <div className='col-md-4'></div>
                </div>
                <div className='content border border-primary bg-light mt-3 p-2 rounded-2 w-75'>
                    <div className='row p-0 m-0 w-100'>
                        <p>
                            <b>
                                <strong>Terms and Conditions:</strong>
                            </b>
                            <br />
                            By using this website and its services, you agree to follow these terms and conditions. <br />
                            The website and services must only be used for lawful purposes. <br />
                            The company has the right to change or stop the website and services at any time. <br />
                            The company will collect and use your personal information only to provide its services. Your information will not be
                            shared with others, except if required by law. <br /> The company will take reasonable steps to protect the security of
                            your information. The company may use cookies and other technology to improve its website and services. <br />
                            The company is not responsible for any harm that might happen while using the website or services.
                        </p>
                    </div>
                </div>
                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container'></div>
        </>
    )
}

export default Terms
