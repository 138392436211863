import ContentBox from './ContentBox'
import { useEffect, useState, useRef } from 'react'
import ROIcon from './ROIcon'
import { formatTime } from '../features/formatTime'
import myUUid from '../features/myUUid'
import { toast } from 'react-toastify'
import { addROErrorLog } from '../features/globalFunctions'

const CustChatBox = ({
    UserID,
    ChildOjb,
    classTag,
    borderParms,
    backColor,
    containerBackColor,
    expanedState,
    newItem,
    closeChatCallback,
    addnewChatCallback,
    deleteChatCallback,
    contactName,
    isAllChat = false,
    showDeleted = false,
    chatdata = [{}],
}) => {
    //Keep track if chatbox is open/visible or not
    const [toggleState, setToggleState] = useState(true)
    //Chat input box value state
    const [txtChatInput, updateChatInput] = useState('')
    const [confirmDelete, setDeleteState] = useState(false)
    const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(-1)

    //Chat input box reference. Used to refocus input box after redraw of parent component.
    const inputRef = useRef()
    //Chat input box caret location. Used to position text edit caret location after parent component redraw.
    const selectStart = useRef(0)

    if (isAllChat) {
        //console.log('Allchat BEFORE: ', chatdata)
        try {
            chatdata = chatdata.map((chat) => {
                return {
                    data: JSON.parse(chat.Chats),
                    name: chat.Name,
                }
            })

            let alldata = []

            //console.log('chatdata2: ', chatdata)

            chatdata.map((chat) => {
                return {
                    message: chat.data.map((i) =>
                        alldata.push({
                            message: i.message,
                            msgdetails: i.msgdetails,
                            deleted: i.deleted,
                            name: chat.name,
                        })
                    ),
                    //msgdetails: chat.data.msgdetails,
                }
            })

            //console.log('AllData: ', alldata)

            alldata.sort((a, b) => {
                return a.msgdetails === b.msgdetails ? 0 : a.msgdetails < b.msgdetails ? -1 : 1
            })

            chatdata = alldata
        } catch (error) {
            chatdata = []
        }
        //console.log('Allchat: ', chatdata)
    }

    //On each redraw(useEffect)s focus to input box and position caret location which is stored in selectStart ref
    const focus = () => {
        if (!isAllChat && inputRef.current) {
            console.log('!isAllChat inputRef: ', inputRef)
            inputRef.current.focus()
            inputRef.current.setSelectionRange(selectStart.current, selectStart.current)
        }
    }

    useEffect(() => {
        focus()
    })

    //React update of input box value statue and storing of caret position on each change value
    const updateTxt = (obj) => {
        selectStart.current = inputRef.current.selectionStart
        updateChatInput(obj)
    }

    const confirmDeleteToggle = (i) => {
        setConfirmDeleteIndex(i)
        setDeleteState((prev) => !prev)
    }

    //Change the deleted bool to true if message is deleted. We don't permanently delete it for incase and security
    const flagDeleteChat = (pos) => {
        chatdata = chatdata.map((item, i) => {
            if (pos === i) {
                return { ...item, deleted: true }
            }
            return { ...item }
        })

        //Call saveChatMessages in CustContactItem to update deleted message
        deleteChatCallback(chatdata)
        setDeleteState(false)
    }

    console.log('chat chat: ', chatdata)
    try {
        //test if chatdata is a valid array else create blank one on error
        // eslint-disable-next-line
        //const tempChat = chatdata.map((chat) => chat);
        if (!chatdata || chatdata === null) {
            chatdata = [{}]
        }

        console.log('chat chat after: ', chatdata)
    } catch (error) {
        console.log('ERROR chat chat: ', chatdata)

        console.log('Chat chatdata Error: ', error)

        toast.error('Chat chatdata Error: ' + error.toString())
        addROErrorLog('Chat chatdata Error:  ' + error.toString(), '', 'CustChatBox')

        chatdata = [{}]
    }

    //Check for the enter keydown event to store new chat message
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            //Call addChatMessage in CustContactItem to create new chat message
            addnewChatCallback(event.target.value)
        }
    }

    //Checkmark button to create new chat message which in turn:
    //Calls addChatMessage in CustContactItem to create new chat message
    const handleEnterButton = () => {
        //console.log('inputref: ', inputRef.current.value)
        addnewChatCallback(inputRef.current.value)
    }

    return (
        <ContentBox classTag={toggleState ? `custChatBox content` : `custChatBox content`}>
            {' '}
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded
                <>
                    {/*Container Div*/}
                    <div className={classTag + ' ' + borderParms + ' ' + backColor}>
                        {/*1st ROW Header*/}
                        <div className='row align-items-start'>
                            {chatdata.map((chat, i) => {
                                /* If chat doesn't have deleted state show it */
                                if (!chat.deleted) {
                                    /* If chat has message show with date added else return '' */
                                    return chat.message ? (
                                        <div key={myUUid()}>
                                            <div className='custChatMessage col'>
                                                {chat.message}
                                                {!isAllChat ? (
                                                    confirmDelete && i === confirmDeleteIndex ? (
                                                        <>
                                                            <ROIcon
                                                                icon='ROicoDel'
                                                                className='icoSVG danger'
                                                                tooltip='Delete Chat'
                                                                authlvl={5}
                                                                onClickCallback={() => flagDeleteChat(i)}
                                                            />
                                                            <ROIcon
                                                                icon='ROicoClose'
                                                                className='icoSVG'
                                                                tooltip='Cancel'
                                                                onClickCallback={() => confirmDeleteToggle()}
                                                            />
                                                        </>
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoDel'
                                                            className='icoSVG'
                                                            tooltip='Delete Chat'
                                                            authlvl={5}
                                                            onClickCallback={() => confirmDeleteToggle(i)}
                                                        />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {/* Show contact name and datetime of message */}
                                            <div className='custChatDateTime'>
                                                {!isAllChat
                                                    ? contactName + ' - ' + formatTime(chat.msgdetails)
                                                    : chat.name + ' - ' + formatTime(chat.msgdetails)}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )
                                } else {
                                    //Show Deleted Messages
                                    return isAllChat && showDeleted && chat.message ? (
                                        <div key={myUUid()}>
                                            <div key={myUUid()} className='custChatMessage col'>
                                                {chat.message} (Deleted)
                                            </div>
                                            <div key={myUUid()}>
                                                {/* Show contact name and datetime of message */}
                                                <div className='custChatDateTime'>{chat.name + ' - ' + formatTime(chat.msgdetails)} (Deleted)</div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )
                                }
                            })}
                            {!isAllChat ? (
                                <div key={myUUid()} className='row ms-1'>
                                    <input
                                        ref={inputRef}
                                        className='txtBox'
                                        value={txtChatInput}
                                        placeholder={''}
                                        onChange={(e) => updateTxt(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <ROIcon
                                        icon='ROicoAccept'
                                        size={24}
                                        className='icoSVG'
                                        tooltip='Add Chat'
                                        onClickCallback={() => handleEnterButton()}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>

                        {/*Unused*/}
                        <> {ChildOjb}</>
                    </div>
                </>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact

                <>
                    <div>Not Expanded</div>
                    {/* Nothing ChatBox will be invisible if not displayed/compact. For now... */}
                </>
                //#endregion
            )}
        </ContentBox>
    )
}

export default CustChatBox
