import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import CustItem from '../components/CustItem'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'

import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROHelpBox from '../components/ROHelpBox.jsx'
import ROComboBox from '../components/ROComboBox'
import { addROErrorLog } from '../features/globalFunctions'

import { getFranSettings } from '../features/franacc/franaccSlice'

import { getCustomers, resetCust } from '../features/cust/custSlice'

//Import Franchise User Redux State/Global Var and Axiox database calls
import { getUsers, resetusers } from '../features/users/usersSlice'

//Import Customer Locations Redux State/Global Var and Axiox database calls
import { getCustomersLoc, resetLocations } from '../features/custloc/custlocSlice'

//Import Customer Location Contacts Redux State/Global Var and Axiox database calls
import { getCustomerLocContacts, resetLocContacts } from '../features/custloccontacts/custloccontactsSlice'

//Import Customer Location Equipment Contacts Redux State/Global Var and Axiox database calls
import { getEquipment, resetequip } from '../features/equip/equipSlice'

import { getEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice'

const Customers = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let [urlParams, setUrlParams] = useSearchParams()

    let clientFocusID = urlParams.get('custID') && urlParams.get('custID') !== 'all' ? parseInt(urlParams.get('custID')) : -1
    let locationFocusID = urlParams.get('locID') ? parseInt(urlParams.get('locID')) : -1
    let equiplocFocusID = urlParams.get('equiplocID') ? parseInt(urlParams.get('equiplocID')) : -1

    //console.log('equiplocFocusID: ', equiplocFocusID)

    //Get State user from redux
    const { user, message, isError } = useSelector((state) => state.auth)

    //Get State fran acc settings from redux
    const { franacc, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    //Get State users / staff from redux
    // const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } =
    //     useSelector((state) => state.franusers)

    //Get State customers from redux
    const { customers, isCustError, isCustSuccess, custmessage } = useSelector((state) => state.customers)

    //Get State customer locations from redux
    const { loccustomers, isLocError, isLocSuccess, locmessage } = useSelector((state) => state.loccustomers)

    //Get State customer location contacts from redux
    const { custloccontacts, isErrorLocContacts, custloccontmessage } = useSelector((state) => state.loccustcontacts)

    /*Parts*/
    //Get State parts from redux
    // const { parts, isPartsError, isPartsSuccess, partsmessage } = useSelector(
    //     (state) => state.parts
    // )

    // const { isSuccessContacts } = useSelector((state) => state.custcontacts)
    // const { isLocSuccess } = useSelector((state) => state.loccustomers)
    // const { isSuccessLocContacts } = useSelector(
    //     (state) => state.loccustcontacts
    // )

    const [newCustToggle, setNewCustToggle] = useState(false)
    let [searchString, setSearchString] = useState('')
    //const [searchFound, setSearchFound] = useState(false)
    const [newCustID, setNewCustID] = useState(clientFocusID > -1 && clientFocusID !== 'all' ? clientFocusID : -1)

    //console.log('*** Cust Main Draw *** newCust ID: ', newCustID)
    //console.log('*** Cust Main Draw *** clientFocusID: ', clientFocusID)

    let searchResults = [...customers]

    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const AddNewCustToggle = () => {
        console.log('AddNewCustToggle')
        setNewCustToggle((prev) => !prev)
    }

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    const RefreshCustDetails = (queryDB = true, getNewCustID = -1) => {
        setNewCustToggle(false)
        console.log('New Cust Added - RESET FORM getNewCustID: ', getNewCustID)

        setNewCustID(getNewCustID)

        if (queryDB) {
            console.log('QueryDB')
            dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
        }
    }

    //console.log('urlParams.get(custID)', urlParams.get('custID'), urlParams)

    if (urlParams.get('custID') === 'all') {
        clientFocusID = -1
        setNewCustID(-1)
        urlParams.set('custID', -1)
        navigate('/customers')
    }

    // if (
    //     newCustID !== -1 &&
    //     loccustomers &&
    //     loccustomers.some((loccust) => loccust.CustID !== newCustID)
    // ) {
    //     console.log('newCustID: ', newCustID)
    //     RefreshCustDetails(true, newCustID)
    // } else {
    //     if (newCustID !== -1) {
    //         setNewCustID(-1)
    //     }
    // }

    const SearchCustomers = (advSearch = false) => {
        if (searchString !== '') {
            const _searchstr = searchString.toLowerCase()
            let searchWords = _searchstr.trim().split(' ')

            if (!advSearch) {
                searchWords = [_searchstr]
            }

            // console.log('Search String: ', _searchstr)
            // console.log('Search item: ', customers)
            //console.log('Search searchWords: ', searchWords)

            let custContIDArray = []

            custContIDArray = custloccontacts.map((cont) => {
                let found = null
                searchWords.forEach((word) => {
                    //console.log('cont.CustLocContactNumber :', String(cont.CustLocContactNumber.replaceAll(' ', '')).toLowerCase())
                    if (String(cont.CustLocContactName).toLowerCase().includes(word) || String(cont.CustLocContactNumber).toLowerCase().includes(word) || String(cont.CustLocContactNumber.replaceAll(' ', '')).toLowerCase().includes(word) || String(cont.CustLocContactEmail).toLowerCase().includes(word)) {
                        //console.log('cont.CustLocContactNumber :', String(cont.CustLocContactNumber).replace(' ', '').toLowerCase())
                        found = cont.CustLocID
                    }
                })
                return found
            })

            //console.log('custContIDArray: ', custContIDArray)

            let custIDArray = []

            custIDArray = []

            custIDArray = loccustomers.map((loc) => {
                let found = null
                searchWords.forEach((word) => {
                    if (String(loc.CustLocName).toLowerCase().includes(word) || String(loc.CustLocStreetAddress).toLowerCase().includes(word) || String(loc.CustLocSuburb).toLowerCase().includes(word) || String(loc.CustLocCity).toLowerCase().includes(word) || String(loc.CustLocPostalCode).toLowerCase().includes(word) || String(loc.CustLocNotes).toLowerCase().includes(word) || custContIDArray.includes(loc.CustLocID)) {
                        found = loc.CustID
                    }
                })
                return found
            })

            //console.log('custIDArry: ', custIDArray)

            if (customers) {
                searchResults = customers.filter((item) => {
                    let found = false
                    searchWords.forEach((word) => {
                        if (
                            String(item.CustName).toLowerCase().includes(word) ||
                            String(item.CustStreetAddress).toLowerCase().includes(word) ||
                            String(item.CustSuburb).toLowerCase().includes(word) ||
                            String(item.CustCity).toLowerCase().includes(word) ||
                            String(item.CustEmail).toLowerCase().includes(word) ||
                            String(item.CustPostalCode).toLowerCase().includes(word) ||
                            String(item.CustNotes).toLowerCase().includes(word) ||
                            String(item.CustCompanyReg).toLowerCase().includes(word) ||
                            String(item.CustCompanyTax).toLowerCase().includes(word) ||
                            custIDArray.includes(item.CustID)
                        ) {
                            found = true
                        }
                    })
                    return found
                })
            }
        }
    }

    useEffect(() => {
        console.log('*** Customers Use Effect Main ***')

        if (isError) {
            console.log('Customers USERS useEffect error: ', message)
            toast.error(message)
            addROErrorLog('Customer USERS Error:  ' + custmessage, user ? user.name : '', 'Customers')
            dispatch(reset())
            navigate('/')
            return
        }

        if (isCustError) {
            console.log('Customers useEffect error: ', custmessage)
            toast.error('Customer Error:  ' + custmessage)
            addROErrorLog('Customer Error:  ' + custmessage, user ? user.name : '', 'Customers')
            dispatch(resetCust())
            navigate('/')
            return
        }

        if (isLocError) {
            console.log('Customers location useEffect error: ', locmessage)
            toast.error('CustLoc Error:  ' + locmessage)
            addROErrorLog('Customer location Error:  ' + locmessage, user ? user.name : '', 'Customers')
            dispatch(resetLocations())
            navigate('/')
            return
        }

        if (isErrorLocContacts) {
            console.log('Customers location contact useEffect error: ', custloccontmessage)
            toast.error('CustLoc Contact Error:  ' + custloccontmessage)
            addROErrorLog('Customer location contact Error:  ' + custloccontmessage, user ? user.name : '', 'Customers')
            dispatch(resetLocContacts())
            navigate('/')
            return
        }

        try {
            //if user is null do not try to load customers.
            if (!user || !user._id || !user.token) {
                console.log('LOGOUT!!!')
                //toast.error('Logged out')
                dispatch(logout())
                dispatch(reset())
                dispatch(resetCust())
                navigate('/')
                return
            }
        } catch (error) {
            console.log('Customers.jsx Dispatch(reset) Error: ', error)
            toast.error('Customers Error: ' + error.toString())
            addROErrorLog('Customer Catch Error:  ' + error.toString(), user ? user.name : '', 'Customers')
            dispatch(reset())
            navigate('/login')
        }

        try {
            console.log('Get All Data from servers')
            // console.log('Get All Data from servers(getUsers!!!)')
            dispatch(getUsers())
            dispatch(getFranSettings())
            //console.log('Get All Data from servers(getCustomers!!!)')
            dispatch(getCustomers())

            if (customers && customers.length > 0 && !isError && !isCustError) {
                //console.log('Get All Data from servers(getCustomersLoc!!!)')
                dispatch(getCustomersLoc())
                // console.log(
                //     'Get All Data from servers(getCustomerLocContacts!!!)'
                // )
                dispatch(getCustomerLocContacts())
                //console.log('Get All Data from servers(getEquipment!!!)')
                dispatch(getEquipment())
                dispatch(getEquipCustLoc())
            }
        } catch (error) {
            console.log('Customers.jsx Dispatch(all) Error: ', error)
            addROErrorLog('Customer Dispatch(all) Error:  ' + custmessage, user ? user.name : '', 'Customers')
            toast.error(error)
            return () => {
                dispatch(resetCust())
                dispatch(resetLocations())
                dispatch(resetLocContacts())
                dispatch(resetequip())
            }
        }

        return () => {
            dispatch(resetCust())
            dispatch(resetLocations())
            dispatch(resetLocContacts())
            dispatch(resetequip())
        }
    }, [user, isError, isCustError, isLocError, isErrorLocContacts]) //dispatch
    //, isError, navigate, dispatch, message, customersData

    useEffect(() => {
        if (customers && customers.length > 0) {
            console.log('Use Effect Main Cust Customers Changed')
            //dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
            dispatch(getEquipment())
            dispatch(getEquipCustLoc())
        }
    }, [customers])

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //Only update/redraw if search results changed. Or else it will redraw on each letter type even if results didn't change
    //used to check if customers is populated and to only search once on init
    // if (
    //     customers != null &&
    //     customers.length > 0 &&
    //     JSON.stringify(customersData) !== JSON.stringify(customers)
    // ) {
    //     console.log('more search')
    //     setCustomersData(customers)
    //     SearchCustomers(searchString)
    // }

    useMemo(() => {
        console.log('Call Customer Search Function')
        //return () => SearchCustomers()
        SearchCustomers()

        if (searchResults && searchResults.length <= 0) {
            SearchCustomers(true)
        }
    }, [searchString, customers])

    return (
        <>
            {/* <h6>Welcome {user && user.name}</h6> */}
            <div className='bg-welcome donotPrint'>
                <section className='pageHead container clearfix pb-2'>
                    <div className='pageFunctions row'>
                        <div className='col-md-3 text-center pt-2'>
                            <h4>
                                Your Customers &nbsp;
                                {/* New Customer */}
                                <ROIcon
                                    icon={newCustToggle ? 'ROicoCancel' : 'ROicoPlus'}
                                    size={24}
                                    className='icoSVG'
                                    textbefore={newCustToggle ? 'Cancel' : ''}
                                    tooltip={newCustToggle ? 'Cancel' : 'Add New Customer'}
                                    onClickCallback={() => AddNewCustToggle()}
                                    textafter={customers === null || (!newCustToggle && customers.length <= 0) ? '<-Click' : ''}
                                    style={{
                                        animation: 'pulse 4s infinite',
                                    }}
                                />
                                {/* {customers === null || customers.length <= 0 ? (
                                <small>&lt;-Add New</small>
                            ) : (
                                ''
                            )} */}
                            </h4>
                        </div>
                        {customers === null || customers.length <= 0 || newCustID !== -1 ? (
                            <>
                                {/*<div className='row m-0 p-0 ms-3'></div>*/}
                                <div className='text-primary col-auto me-auto '>
                                    <ROIcon
                                        icon='ROicoUnpin'
                                        tooltip='Unpin and Show All Customers'
                                        className='icoSVG text-primary'
                                        style={{
                                            animation: 'pulse 4s infinite',
                                        }}
                                        textbefore='Show All Customers'
                                        onClickCallback={() => RefreshCustDetails(false, -1)}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='interfSearch align-middle col-md-5 col-auto me-auto pt-2 pb-2'>
                                    {/* <label htmlFor='search'>Search</label> */}
                                    <input
                                        id='search'
                                        placeholder='Enter your search here'
                                        value={searchString}
                                        //onChange={(e) => SearchCustomers(e.target.value)}
                                        onChange={(e) => setSearchString(e.target.value)}
                                    />
                                    {searchResults && searchResults.length > 0 ? '' : <p className='danger'>Search doesn't match any customer</p>}
                                </div>

                                <div className='col-auto pt-2 pe-4'>
                                    <ROHelpBox helpTopic={'customer'} />
                                    {/*<ROIcon icon='ROicoHelp' size={24} className='icoSVG' tooltip='Help' />*/}
                                </div>
                            </>
                        )}

                        {newCustToggle ? <CustItem key={myUUid()} newItem={true} UserID={user._id} expanedState={true} refreshCustCallback={RefreshCustDetails} /> : ''}
                    </div>
                </section>
            </div>

            <section className='pageHead container clearfix pt-2'>
                <div className='pageContent container'>
                    {/*searchResults.map((cust, i) => console.log(cust))*/}
                    {/* Display items by search results. (If no search will display all customers) */}
                    {/*console.log('Draw CustItems!!!')*/}
                    {/*console.log('draw search results', searchResults)*/}
                    {!newCustToggle && searchResults && searchResults.length > 0 && searchResults[0].CustID && newCustID === -1
                        ? searchResults.map((cust, i) => (
                              <CustItem
                                  key={'cust' + cust.CustID}
                                  //key={myUUid()}
                                  UserID={user._id}
                                  containerBackColor={(i + 1) % 2 === 0 ? 'bg-light m-0 p-0' : 'bg-secondary m-0 p-0'}
                                  ChildOjb={''}
                                  refreshCustCallback={RefreshCustDetails}
                                  expanedState={false}
                                  focused={false}
                                  collapse={getCollapseAll}
                                  CustItemsList={searchResults}
                                  _CustDetails={{
                                      CustID: cust.CustID,
                                      CustName: cust.CustName,
                                      CustEmail: cust.CustEmail,
                                      CustReg: cust.CustCompanyReg,
                                      CustTax: cust.CustCompanyTax,
                                      CustNotes: cust.CustNotes,
                                      CustSuspended: cust.CustSuspended,
                                      //   CustStreet: cust.CustStreetAddress,
                                      //   CustSub: cust.CustSuburb,
                                      //   CustCity: cust.CustCity,
                                      //   CustPostal: cust.CustPostalCode,
                                      //   CustCountry: cust.CustCountry,
                                      // CustContacts:
                                      //     cust.CustNotes[0] != null
                                      //         ? cust.CustNotes
                                      //         : '',
                                  }}
                              />
                          ))
                        : newCustID !== -1
                        ? searchResults.map((cust, i) =>
                              cust.CustID === newCustID ? (
                                  <CustItem
                                      key={'cust' + cust.CustID}
                                      //key={myUUid()}
                                      UserID={user._id}
                                      containerBackColor={(i + 1) % 2 === 0 ? 'bg-light m-0 p-0' : 'bg-secondary m-0 p-0'}
                                      ChildOjb={''}
                                      refreshCustCallback={RefreshCustDetails}
                                      expanedState={true}
                                      focused={true}
                                      focusLocationID={locationFocusID}
                                      focusEquipLocID={equiplocFocusID}
                                      collapse={getCollapseAll}
                                      CustItemsList={searchResults}
                                      _CustDetails={{
                                          CustID: cust.CustID,
                                          CustName: cust.CustName,
                                          CustEmail: cust.CustEmail,
                                          CustReg: cust.CustCompanyReg,
                                          CustTax: cust.CustCompanyTax,
                                          CustNotes: cust.CustNotes,
                                          CustSuspended: cust.CustSuspended,
                                          //   CustStreet: cust.CustStreetAddress,
                                          //   CustSub: cust.CustSuburb,
                                          //   CustCity: cust.CustCity,
                                          //   CustPostal: cust.CustPostalCode,
                                          //   CustCountry: cust.CustCountry,
                                          // CustContacts:
                                          //     cust.CustNotes[0] != null
                                          //         ? cust.CustNotes
                                          //         : '',
                                      }}
                                  />
                              ) : (
                                  ''
                              )
                          )
                        : ''}
                </div>
            </section>
            <br />
        </>
    )
}

export default Customers
