import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice.js'
import { toast } from 'react-toastify'
import myUUid from '../features/myUUid.js'
import ROIcon from '../components/ROIcon.jsx'
import ROHelpBox from '../components/ROHelpBox.jsx'

import AllReports from '../components/reports/AllReports.jsx'
import { addROErrorLog } from '../features/globalFunctions.js'

import { getFranSettings } from '../features/franacc/franaccSlice.js'

//Import Customers Redux State/Global Var and Axiox database calls
import { getCustomers, resetCust } from '../features/cust/custSlice.js'

//Import Franchise User Redux State/Global Var and Axiox database calls
import { getUsers, resetusers } from '../features/users/usersSlice.js'

//Import Customer Locations Redux State/Global Var and Axiox database calls
import { getCustomersLoc, resetLocations } from '../features/custloc/custlocSlice.js'

//Import Customer Location Contacts Redux State/Global Var and Axiox database calls
import { getCustomerLocContacts, resetLocContacts } from '../features/custloccontacts/custloccontactsSlice.js'

//Import Customer Location Equipment Contacts Redux State/Global Var and Axiox database calls
import { getEquipment, resetequip } from '../features/equip/equipSlice.js'

import { getParts, resetparts } from '../features/parts/partsSlice.js'

//import equipcustlocService from '../features/equipcustloc/equipcustlocService'
import { getEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice.js'

//Import Services Redux State/Global Var and Axiox database calls
import { getServices, resetservices } from '../features/services/servicesSlice.js'
import MelvanReport from '../components/reports/MelvanReport.jsx'

import { useSearchParams } from 'react-router-dom'

const Reports = () => {
    //console.log('!!!!!!!!!!!!!!!!MAIN REPORTS REDRAW!!!!!!!!!!!!!!')
    // const [count, setCount] = useRef (0)
    const count = useRef(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, isError, message } = useSelector((state) => state.auth)

    const { isCustError, custmessage, customers } = useSelector((state) => state.customers)

    const { isLocError, locmessage } = useSelector((state) => state.loccustomers)

    const { isErrorLocContacts, custloccontmessage } = useSelector((state) => state.loccustcontacts)

    const { isPartsError, partsmessage } = useSelector((state) => state.parts)

    const { isEquipError, equipmessage } = useSelector((state) => state.equipment)

    const { isEquipCustLocError, equipcustlocmessage } = useSelector((state) => state.equipcustloc)

    const { isServicesError, servicesmessage } = useSelector((state) => state.service)

    const { isFUsersError, franusersmessage } = useSelector((state) => state.franusers)
    let [urlParams, setUrlParams] = useSearchParams()

    let reportType = urlParams.get('reportType') ? urlParams.get('reportType') : ''

    useEffect(() => {
        if (isError) {
            console.log('Reports useEffect error: ', message)
            toast.error(message)
            addROErrorLog('Reports useEffect Error:  ' + message, user ? user.name : '', 'Reports')

            return
        }

        if (isCustError) {
            console.log('Reports Customer useEffect error: ', custmessage)
            toast.error(custmessage)
            addROErrorLog('Reports Customer useEffect Error:  ' + custmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isLocError) {
            console.log('Reports Location useEffect error: ', locmessage)
            toast.error(locmessage)
            addROErrorLog('Reports Location useEffect Error:  ' + locmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isErrorLocContacts) {
            console.log('Reports Loc Contact useEffect error: ', custloccontmessage)
            toast.error(custloccontmessage)
            addROErrorLog('Reports Loc Contact useEffect Error:  ' + custloccontmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isPartsError) {
            console.log('Reports Part useEffect error: ', partsmessage)
            toast.error(partsmessage)
            addROErrorLog('Reports Part useEffect Error:  ' + partsmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isEquipError) {
            console.log('Reports equipment useEffect error: ', equipmessage)
            toast.error(equipmessage)
            addROErrorLog('Reports equipment useEffect Error:  ' + equipmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isEquipCustLocError) {
            console.log('Reports equipment cust loc useEffect error: ', equipcustlocmessage)
            toast.error(equipcustlocmessage)
            addROErrorLog('Reports equipment cust loc useEffect Error:  ' + equipcustlocmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isServicesError) {
            console.log('Reports services useEffect error: ', servicesmessage)
            toast.error(servicesmessage)
            addROErrorLog('Reports services useEffect Error:  ' + servicesmessage, user ? user.name : '', 'Reports')

            return
        }

        if (isFUsersError) {
            console.log('Reports fran users useEffect error: ', franusersmessage)
            toast.error(franusersmessage)
            addROErrorLog('Reports fran users useEffect Error:  ' + franusersmessage, user ? user.name : '', 'Reports')

            return
        }

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
        }

        try {
            //console.log('Get All Data from servers')
            dispatch(getUsers())
            dispatch(getFranSettings())
            dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
            dispatch(getEquipment())
            dispatch(getEquipCustLoc())
            dispatch(getParts())
            dispatch(getServices())
        } catch (error) {
            console.log('Reports.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            addROErrorLog('Reports Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'Reports')
            return () => dispatch(resetCust())
        }
    }, [user, isError, isCustError, isLocError, isErrorLocContacts, isPartsError, isEquipError, isEquipCustLocError, isServicesError, isFUsersError])

    const DrawCustomer = () => {
        //  var newHTML = '';
        // for(let i = 0; i < customers.length; i++){
        // //    newHTML += '';
        //    return (<div>test</div>)
        // };
        customers.map((cust) => {
            return <div>cust.CustName</div>
        })
    }

    // count.current += 1;
    // console.log(count.current, " ", customers );
    // useEffect(() => {
    //   console.log("useEfect running")

    //   return () => {
    //     console.log("useEfect destroyed")
    //   }
    // }, [])

    return (
        <>
            <AllReports />
            {/* <MelvanReport /> */}
            {/* <DrawCustomer /> */}
        </>
    )
}

export default Reports
