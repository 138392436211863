import React from 'react'
import { useState, useEffect } from 'react'
import myUUid from '../../features/myUUid'
import { toast } from 'react-toastify'

import { addROErrorLog } from '../../features/globalFunctions'

const PartsUsageItem = ({ Data, containerBackColor }) => {
    let partsPriceTotal = 0
    let qtyTotal = 0
    //console.log('Current Price Total ', partsPriceTotal)
    //console.log('Data of array categories', Data)
    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    return (
        <>
            <section key={myUUid()}>
                <div className='container usage  pb-1'>
                    <div className={'row rounded p-2 usageReport ' + containerBackColor}>
                        <div className='col '>
                            <div className='row text-primary fw-bold ms-2'>{Data.CatName}</div>
                            {Data
                                ? Data.Parts.map((item) => {
                                      partsPriceTotal += item.ServicePartPrice
                                      qtyTotal += item.ServicePartQty
                                      return (
                                          <div className='row align-items-center ms-4 me-4' key={myUUid()}>
                                              <div className='col-md-12 smallPrint'>
                                                  <div className='row'>
                                                      <div className='col-sm-2 fw-bold'>{item.ServicePartCode}</div>
                                                      <div className='col-sm-4'>{item.ServicePartName}</div>
                                                      <div className='col-sm-2 text-center pe-0 '>
                                                          <sup>Qty</sup> <span className='fw-bold'>{item.ServicePartQty}</span>
                                                      </div>
                                                      <div className='col-sm-2'></div>
                                                      <div className='col-sm-2 text-end fw-bold'>
                                                          <sup>R</sup>
                                                          {item.ServicePartPrice.toFixed(2)}
                                                      </div>
                                                  </div>
                                                  <div className='row me-2'>
                                                      <hr className='mb-1 ms-2' />
                                                  </div>
                                              </div>
                                          </div>
                                      )
                                  })
                                : ''}
                            <div className='flex-row align-items-center ms-4 me-4'>
                                <div className='col-md-12 smallPrint'>
                                    <div className='row '>
                                        <div className='col-sm-2'>SubTotal</div>
                                        <div className='col-sm-4 '></div>
                                        <div className='col-sm-2 text-center  pe-0'>
                                            <sup>Qty</sup> {qtyTotal}
                                        </div>
                                        <div className='col-sm-2 '></div>
                                        <div className='col-sm-2 text-end pe-4'>
                                            <sup>R</sup>
                                            {partsPriceTotal.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='row'>
                        <div className='col'>{grandTotalPrice}</div>
                    </div> */}
                </div>
            </section>
            {/* <div className='row' key={myUUid()}>
                {Data.Parts.map((price) => {
                    //   grandTotalPrice += price.ServicePartPrice
                    return (
                        <>
                            <div className='row float-end'>
                                <div className='col text-end'>
                                    <small>R</small>
                                    {partsPrice.toFixed(2)}
                                </div>
                            </div>
                        </>
                    )
                })}
            </div> */}
            {/* <section>
                <div className='container'>
                    <div className='row' key={myUUid()}>
                        {Data.Parts.map((price) => {
                              grandTotalPrice += price.ServicePartPrice
                            return (
                                <>
                                    <div className='row float-end'>
                                        <div className='col text-end'>
                                            <small>R</small>
                                            {grandTotalPrice.toFixed(2)}

                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section> */}
        </>
    )
}
export default PartsUsageItem
