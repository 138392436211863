import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import AllServicesItem from './AllServicesItem'
import { VaildateForm, getAnySettingsJSON } from '../features/globalFunctions'
import { formatInputDate, formatLongDate } from '../features/formatTime'

//Import Redux State/Global Var and Axiox database calls
import { updateEquipCustLoc, deleteEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice.js'

//Import Redux State/Global Var and Axiox database calls
import { sendClientService } from '../features/custloc/custlocSlice.js'

import ROComboBox from './ROComboBox'
import { formatShortDate } from '../features/formatTime'

import {
    addROErrorLog,
    getServiceInterval,
    daysDiff,
    getFranSettingsJSON,
    EmailTokensReplace,
    GetEmailTokens,
    FormatHtmlEmail,
} from '../features/globalFunctions'

import { toast } from 'react-toastify'
import { RegExPattern, ServiceEmailMsg } from '../features/globalVars'

const EquipCustLocItem = ({
    equipcustlocitem,
    equipitem,
    arrCategories,
    containerBackColor,
    collapse,
    _expanedState,
    _editState,
    equipfocusCallback,
    CustPriContactName = 'Client',
    CustPriContactEmail = '',
    tokenExpiryDate = '',
}) => {
    const dispatch = useDispatch()

    if (equipitem) {
        console.log(`*** Equip  ${equipitem.EquipName}  Draw ***`)
    }

    console.log('tokenExpiryDate: ', tokenExpiryDate)

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //Get State user from redux
    const { user, message, isError } = useSelector((state) => state.auth)

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    const curSymb = getFranSettingsJSON(franacc, 'CurrencySymb') ? getFranSettingsJSON(franacc, 'CurrencySymb') : 'R'
    const pType = getFranSettingsJSON(franacc, 'Bar') ? getFranSettingsJSON(franacc, 'Bar') : 'Bar'

    let msg = franacc && franacc.FranAccNotes ? franacc.FranAccNotes : ServiceEmailMsg

    //Get State location equipment from redux
    const { equipcustloc } = useSelector((state) => state.equipcustloc)

    //Default States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)
    const [txtEquipRepID, setTxtEquipRepID] = useState(equipcustlocitem ? equipcustlocitem.EquipCustLocSalesID : -1)
    const [servicesChanged, setServicesChanged] = useState(false)

    console.log('togglestate: ', toggleState, '  servicesChanged: ', servicesChanged, '  _expanedState: ', _expanedState)

    if (servicesChanged) {
        console.log('Services has changed show equipment expanded')
        //setServicesChanged((prev) => !prev)
        setToggleState(true)
    }

    const CallServicesChanged = (_id = -1) => {
        console.log('CallServicesChanged: Services has changed!!!!')
        setServicesChanged(true)
        setToggleState(true)
        if (equipfocusCallback) {
            equipfocusCallback(_id)
        }
    }

    const txtEquipIntervalTypeRef = useRef()

    //Edit Equipment on Loction
    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtEquipCustLocSerialNR, setTxtEquipCustSerialNR] = useState(equipcustlocitem ? equipcustlocitem.EquipCustLocSerialNR : '')
    const [txtEquipCustLocNotes, setTxtEquipCustNotes] = useState(equipcustlocitem ? equipcustlocitem.EquipCustLocNotes : '')

    const [txtEquipCustLocRoomPlace, setTxtEquipCustRoomPlace] = useState(equipcustlocitem ? equipcustlocitem.EquipCustLocRoomPlace : '')

    const [txtEquipCustLocLastServiceDate, setTxtEquipCustLocLastServiceDate] = useState(
        equipcustlocitem ? formatInputDate(equipcustlocitem.EquipCustLocLastServiceDate) : formatInputDate(Date())
    )

    const [txtEquipCustLocDaysTillNextService, setTxtEquipCustDaysTillNextService] = useState(
        equipcustlocitem ? equipcustlocitem.EquipCustLocDaysTillNextService / equipcustlocitem.EquipCustLocServiceIntervalType : ''
    )

    const [txtEquipCustLocServiceIntervalType, setTxtEquipCustServiceIntervalType] = useState(
        equipcustlocitem ? equipcustlocitem.EquipCustLocServiceIntervalType : ''
    )
    const editEquipCustLoc = (edit = true) => {
        if (!edit) {
            setEditState((prev) => !prev)
            setTxtEquipCustDaysTillNextService(
                equipcustlocitem.EquipCustLocDaysTillNextService
                    ? equipcustlocitem.EquipCustLocDaysTillNextService / equipcustlocitem.EquipCustLocServiceIntervalType
                    : ''
            )
            setTxtEquipCustRoomPlace(equipcustlocitem.EquipCustLocRoomPlace ? equipcustlocitem.EquipCustLocRoomPlace : '')
            setTxtEquipCustSerialNR(equipcustlocitem.EquipCustLocSerialNR ? equipcustlocitem.EquipCustLocSerialNR : '')
            setTxtEquipCustNotes(equipcustlocitem.EquipCustLocNotes ? equipcustlocitem.EquipCustLocNotes : '')
            setTxtEquipCustServiceIntervalType(
                equipcustlocitem.EquipCustLocServiceIntervalType ? equipcustlocitem.EquipCustLocServiceIntervalType : ''
            )
            setTxtEquipCustLocLastServiceDate(
                equipcustlocitem.EquipCustLocLastServiceDate ? formatInputDate(equipcustlocitem.EquipCustLocLastServiceDate) : formatInputDate(Date())
            )
            return
        }

        if (editState) {
            setTxtEquipCustDaysTillNextService(
                equipcustlocitem.EquipCustLocDaysTillNextService
                    ? equipcustlocitem.EquipCustLocDaysTillNextService / equipcustlocitem.EquipCustLocServiceIntervalType
                    : ''
            )
            setTxtEquipCustRoomPlace(equipcustlocitem.EquipCustLocRoomPlace ? equipcustlocitem.EquipCustLocRoomPlace : '')
            setTxtEquipCustSerialNR(equipcustlocitem.EquipCustLocSerialNR ? equipcustlocitem.EquipCustLocSerialNR : '')
            setTxtEquipCustNotes(equipcustlocitem.EquipCustLocNotes ? equipcustlocitem.EquipCustLocNotes : '')
            setTxtEquipCustServiceIntervalType(
                equipcustlocitem.EquipCustLocServiceIntervalType ? equipcustlocitem.EquipCustLocServiceIntervalType : ''
            )
            setTxtEquipCustLocLastServiceDate(
                equipcustlocitem.EquipCustLocLastServiceDate ? formatInputDate(equipcustlocitem.EquipCustLocLastServiceDate) : formatInputDate(Date())
            )

            const updEquipCustLoc = {
                equipcustlocID: equipcustlocitem.EquipCustLocID,
                custlocID: equipcustlocitem.CustLocID,
                equipID: equipcustlocitem.EquipID,
                equipcustlocRepID: txtEquipRepID,
                equipcustlocserialnr: txtEquipCustLocSerialNR,
                equipcustlastservicedate: txtEquipCustLocLastServiceDate,
                //equipcustlocdays: txtEquipCustLocDaysTillNextService,
                //equipcustlocintervaltype: txtEquipCustLocServiceIntervalType,
                equipcustlocdays: txtEquipCustLocDaysTillNextService * txtEquipIntervalTypeRef.current.value,
                equipcustlocintervaltype: parseInt(txtEquipIntervalTypeRef.current.value),
                equipcustlocroomplace: txtEquipCustLocRoomPlace,
                equipcustlocnotes: txtEquipCustLocNotes,
                equipfcustloclags: '',
            }

            //console.log('updEquipCustLoc: ', updEquipCustLoc)

            dispatch(updateEquipCustLoc(updEquipCustLoc))
            equipfocusCallback(equipcustlocitem.EquipCustLocID)
            //updateCustomerDetails(CustID)
            //refreshCustCallback()
        }

        setEditState((prev) => !prev)
    }

    //Delete Equipment
    //#region All Services
    const { services } = useSelector((state) => state.service)

    const confirmDeleteEquipCustLoc = (setState) => {
        //Validate delete (Check if equipment have services)
        if (services && services.length > 0 && services.some((e) => e.EquipCustLocID === equipcustlocitem.EquipCustLocID)) {
            setDeleteState(false)
            toast('Equipment Delete Error: Contains service')
            return
        }

        if (confirmDelete && setState) {
            // console.log(
            //     'Delete!!!! EquipCustLocID: ',
            //     equipcustlocitem.EquipCustLocID
            // )
            deleteEquipCustLocById(equipcustlocitem.EquipCustLocID)
        }
        setDeleteState(setState)
    }

    const deleteEquipCustLocById = (_id) => {
        if (services && services.length > 0 && services.some((e) => e.EquipCustLocID === equipcustlocitem.EquipCustLocID) === true) {
            setDeleteState(false)
            toast('Equipment Delete Error: ' + 'Cannot delete equipment have services recorded')
            return
        }

        console.log('Delete!!!! Equip_ID: ', _id)
        dispatch(deleteEquipCustLoc(_id))
        confirmDeleteEquipCustLoc(false)
        //refreshCustCallback()
    }

    // const getServiceInterval = (data) => {
    //     let txtInterval = ''
    //     if (data.EquipCustLocServiceIntervalType > 30) {
    //         txtInterval = 'Year'
    //     } else if (data.EquipCustLocServiceIntervalType > 7) {
    //         txtInterval = 'Months'
    //     } else if (data.EquipCustLocServiceIntervalType > 1) {
    //         txtInterval = 'Weeks'
    //     } else {
    //         txtInterval = 'Days'
    //     }

    //     return (
    //         equipcustlocitem.EquipCustLocDaysTillNextService /
    //             equipcustlocitem.EquipCustLocServiceIntervalType +
    //         ' ' +
    //         txtInterval +
    //         ' Interval'
    //     )
    // }

    let arrRoomPlace = []
    if (equipcustloc) {
        try {
            arrCategories = new Set(equipcustloc.map((equip) => equip.EquipCustLocRoomPlace))
            //console.log('equipcustlocitem equipcustloc: ', equipcustloc)
            arrRoomPlace = Array.from(arrCategories)
            arrRoomPlace = arrRoomPlace.map((equipcustloc) => {
                return {
                    Text: equipcustloc,
                }
            })
        } catch (error) {}

        //console.log('arrRoomPlace: ', arrRoomPlace)
    }

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } = useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        try {
            arrSalesReps = new Set(
                franusers.map((reps) => {
                    return { name: reps.name, ID: reps._id }
                })
            )
            arrSalesReps = Array.from(arrSalesReps)
            arrSalesReps = arrSalesReps.map((reps) => {
                return {
                    Text: reps.name,
                    ID: reps.ID,
                }
            })
        } catch (error) {
            console.log('EquipCustLocItem arrSalesReps Error: ', error)

            toast.error('EquipCustLocItem arrSalesReps Error: ' + error.toString())
            addROErrorLog('EquipCustLocItem arrSalesReps Error:  ' + error.toString(), '', 'EquipCustLocItem')
        }
    }

    const getRepName = (id) => {
        let retVal = arrSalesReps.find((rep) => {
            //console.log('Rep id: ', rep, id)
            if (rep.ID === id) {
                //console.log('Choosen Rep id: ', rep)
                return rep.Text
            }
        })

        //console.log('getRepName: ', retVal)

        if (retVal) {
            return retVal.Text
        } else {
            return ''
        }
    }

    //Email
    const emailClientService = (id) => {
        try {
            let msg = franacc && franacc.FranAccNotes ? franacc.FranAccNotes : ServiceEmailMsg

            //console.log('_LocDetails.Equipment: ', _LocDetails.Equipment)

            // const equipInfo = _LocDetails.Equipment.find((item) => {
            //     console.log('item.EquipInfo.EquipID: ', item.EquipInfo.EquipID, ' = ', id)
            //     if (item.EquipInfo.EquipID === id) {
            //         console.log('return item.EquipInfo: ', item.EquipInfo)
            //         return item.EquipInfo
            //     }
            // })

            console.log('equipitem: ', equipitem)

            let emailTokens = GetEmailTokens({
                ReplyToEmail: getFranSettingsJSON(franacc, 'ReplyTo'),
                FranCompName: franacc.FranAccName,
                YourName: user && user.name ? user.name : 'YourName',
                FranAccTel: franacc.FranAccTel,
                FranAccEmail: franacc.FranAccEmail,
                FranAccStreet: franacc.FranAccStreetAddress,
                FranAccSuburb: franacc.FranAccSuburb,
                FranAccCity: franacc.FranAccCity,
                FranAccPostal: franacc.FranAccPostalCode,
                FranAccCountry: franacc.FranAccCountry,
                CustomerName: CustPriContactName ? CustPriContactName : 'Client',
                Equipment: equipitem.EquipName ? equipitem.EquipName : 'Your Equipment',
                ServiceDue: formatLongDate(equipcustlocitem.EquipCustLocLastServiceDate ? equipcustlocitem.EquipCustLocLastServiceDate : Date.now()),
            })

            ////const { custLocID, emailTo, emailReply, emailMsg, emailHtml, mailFrom = 'noreply@rovantage.com' } = req.body

            //console.log('Email Msg: ', EmailTokensReplace(msg, emailTokens))

            const email = EmailTokensReplace(msg, emailTokens)
            const replyTo = getFranSettingsJSON(franacc, 'ReplyTo')
            const cc = getFranSettingsJSON(franacc, 'cc')
            const fromName = getFranSettingsJSON(franacc, 'FromName')
            const emaildays = getFranSettingsJSON(franacc, 'EmailToken')

            //Validation Checks
            if (!CustPriContactEmail || !CustPriContactEmail.match(RegExPattern.Email)) {
                toast.error('Not valid email(To): ' + CustPriContactEmail.toString())
            } else if (!replyTo || !replyTo.match(RegExPattern.Email)) {
                toast.error('Not valid email(ReplyTo): ' + replyTo.toString())
            } else {
                let sendEmailTo = CustPriContactEmail

                //Testing EMAIL
                //Testing EMAIL
                //Testing EMAIL
                //sendEmailTo = replyTo

                const reportDetails = {
                    id: id,
                    type: 'CustLocID',
                    curSymb: curSymb,
                    pType: pType,
                    days: emaildays,
                    cc: cc,
                    emailName: fromName ? fromName : franacc.FranAccName,
                    emailTo: sendEmailTo,
                    emailReply: replyTo,
                    emailMsg: email,
                    emailHtml: FormatHtmlEmail(email),
                }

                toast.info('Service email sent to: ' + sendEmailTo)

                //console.log('reportDetails: ', reportDetails)

                dispatch(sendClientService(reportDetails))
            }
        } catch (error) {
            toast.error('ERROR!!!! in RepServiceOverdueItem(emailClientService): ' + error)
            addROErrorLog(
                'RepServiceOverdueItem(emailClientService) Error:  ' + error.toString(),
                user && user.name ? user.name : '',
                'emailClientService'
            )
            console.log('ERROR!!!! in RepServiceOverdueItem(emailClientService): ', error)
        }
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    //#region Edit Location Equipment Form Validation
    const [boolCustLocEditEquipFormValidated, setCustLocEditEquipFormValidated] = useState(false)

    const valEquipSalesRef = useRef()
    const valEquipSerialNrRef = useRef()
    const valEquipNotesRef = useRef()
    const valEquipRoomPlaceRef = useRef()
    const valEquipServiceIntervalValRef = useRef()
    const valEquipServiceDate = useRef()

    const vaildateCustLocEditEquipForm = (clicked = false) => {
        if (!editState) return

        if (editState) {
            setCustLocEditEquipFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valEquipServiceDate,
                            text: 'Invalid Date',
                            form: 'Edit Customer Location Equipment valEquipServiceDate',
                        },
                        {
                            ref: valEquipSalesRef,
                            text: 'Sales Rep Required',
                            form: 'Edit Customer Location Equipment valEquipSalesRef',
                        },
                        {
                            ref: valEquipSerialNrRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location Equipment valEquipSerialNrRef',
                        },
                        {
                            ref: valEquipNotesRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location Equipment valEquipNotesRef',
                        },
                        {
                            ref: valEquipRoomPlaceRef,
                            text: 'Room/Place Required',
                            form: 'Edit Customer Location Equipment valEquipRoomPlaceRef',
                        },
                        {
                            ref: valEquipServiceIntervalValRef,
                            text: 'Only Integer Values',
                            form: 'Edit Customer Location Equipment valEquipServiceIntervalValRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded

                <div className='container p-2 pt-0 bg-primary text-white rounded-2'>
                    <div className='row fs-5' /*EquipInfo Expanded*/>
                        {/*Big Equipment Icon */}
                        <div className='col-auto px-1'>
                            <ROIcon icon='ROicoEquip' className='icoSVG' usefill='true' color1='#ffffff' size={60} />
                        </div>
                        <div className='col-md-8 me-auto'>
                            {/*Equipment Code and Discription*/}
                            <div className='fw-bold col-md-8 me-auto'>
                                <div className='col-auto fw-bold'>{equipitem ? equipitem.EquipCode : ''}</div>
                                <div className='col-auto fw-normal fs-6'>{equipitem ? equipitem.EquipName : ''}</div>
                            </div>
                        </div>

                        {/*Interface Buttons*/}
                        <div className='col-auto px-1'>
                            {editState ? (
                                <>
                                    {boolCustLocEditEquipFormValidated ? (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderls'
                                            usefill='true'
                                            color1='#ffffff'
                                            tooltip='Update Equipment'
                                            onClickCallback={() => editEquipCustLoc()}
                                            style={{
                                                animation: 'pulse 4s infinite',
                                            }}
                                        />
                                    ) : (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderls'
                                            usefill='true'
                                            color1='#ff0000'
                                            tooltip='Update Equipment'
                                            onClickCallback={() => vaildateCustLocEditEquipForm(true)}
                                            style={{
                                                animation: 'pulse 4s infinite',
                                            }}
                                        />
                                    )}

                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG borderls'
                                        usefill='true'
                                        color1='#ffffff'
                                        tooltip='Cancel'
                                        onClickCallback={() => {
                                            editEquipCustLoc(false)
                                        }}
                                    />
                                </>
                            ) : (
                                <ROIcon
                                    icon='ROicoEdit'
                                    size={24}
                                    className='icoSVG borderls'
                                    usefill='true'
                                    color1='#ffffff'
                                    tooltip='Edit Equipment'
                                    onClickCallback={() => {
                                        setCustLocEditEquipFormValidated(true)
                                        editEquipCustLoc(false)
                                    }}
                                />
                            )}

                            {!editState ? (
                                <>
                                    {confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG danger borderls'
                                            usefill='true'
                                            color1='#ff0000'
                                            tooltip='Delete Equipment'
                                            authlvl={5}
                                            onClickCallback={() => confirmDeleteEquipCustLoc(true)}
                                        />
                                    ) : (
                                        ' '
                                    )}
                                    {!confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG borderls'
                                            usefill='true'
                                            color1='#ffffff'
                                            tooltip='Delete Equipment'
                                            authlvl={5}
                                            onClickCallback={() => confirmDeleteEquipCustLoc(true)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            {!editState ? (
                                confirmDelete ? (
                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG borderls'
                                        usefill='true'
                                        color1='#ffffff'
                                        tooltip='Cancel Delete'
                                        onClickCallback={() => confirmDeleteEquipCustLoc(false)}
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}
                            {
                                <>
                                    {getAnySettingsJSON(franacc.FranAccFlags, 'Email') === null ||
                                    getAnySettingsJSON(franacc.FranAccFlags, 'Email') === '' ? (
                                        <ROIcon
                                            icon={daysDiff(new Date(), new Date(tokenExpiryDate)) > 0 ? 'ROicoEmail' : 'ROicoSend'}
                                            size={24}
                                            className='icoSVG borderls'
                                            tooltip={'Email Service: ' + CustPriContactEmail}
                                            onClickCallback={() => emailClientService(equipcustlocitem.CustLocID)}
                                            usefill={true}
                                            color1='#ffffff'
                                            //color1={daysDiff(new Date(), new Date(tokenExpiryDate)) < 0 ? '#32bebe' : '#ff0000'}
                                            //daysDiff(new Date(), item.LastServiceRecorded)
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <ROIcon
                                        icon='ROicoXUp'
                                        className='icoSVG borderls'
                                        usefill='true'
                                        color1='#ffffff'
                                        onClickCallback={() => setToggleState((prev) => !prev)}
                                    />
                                </>
                            }
                        </div>
                    </div>

                    {/*Equipment Details*/}
                    <div className='row fs-6 pt-1'>
                        {/*Serial Nr*/}
                        <div className='col-lg-6'>
                            <div className='row'>
                                {editState ? (
                                    <>
                                        <div className='col-auto'>
                                            <span className='ROTooltip text-white fs-5 ms-1' data-tooltip={'Serial Nr'}>
                                                #&nbsp;
                                            </span>
                                        </div>
                                        <div className='col-lg-10 '>
                                            <input
                                                className='txtBox'
                                                value={txtEquipCustLocSerialNR}
                                                placeholder={
                                                    equipcustlocitem && equipcustlocitem.EquipCustLocSerialNR !== ''
                                                        ? equipcustlocitem.EquipCustLocSerialNR
                                                        : 'Serial Number'
                                                }
                                                onChange={(e) => {
                                                    setTxtEquipCustSerialNR(e.target.value)
                                                    vaildateCustLocEditEquipForm()
                                                }}
                                                ref={valEquipSerialNrRef}
                                                title='Equipment Serial Number'
                                                pattern={RegExPattern.Text}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* <ROIcon
                                                icon='ROicoLocation'
                                                className='icoSVG'
                                                usefill='true'
                                                color1='#ffffff'
                                                tooltip='Last Service Date'
                                            /> */}
                                        <div className='col-auto'>
                                            <span className='ROTooltip text-white fs-5 ms-1' data-tooltip={'Serial Nr'}>
                                                #&nbsp;
                                            </span>
                                        </div>
                                        <div className='col-auto'>{equipcustlocitem ? equipcustlocitem.EquipCustLocSerialNR : ''}</div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/*Room Location*/}
                        <div className='col-lg-6'>
                            <div className='row'>
                                {editState ? (
                                    <>
                                        <div className='col-auto'>
                                            <ROIcon icon='ROicoLocation' className='icoSVG' tooltip='Room / Place' usefill='true' color1='#ffffff' />
                                        </div>
                                        <div className='col-lg-10'>
                                            <ROComboBox
                                                dataArray={arrRoomPlace}
                                                placeholdertext={'Room / Place'}
                                                classname='ROdropdownFull'
                                                textVal={txtEquipCustLocRoomPlace}
                                                myValueCallback={setTxtEquipCustRoomPlace}
                                                ref={valEquipRoomPlaceRef}
                                                required={true}
                                                validationCallBack={() => vaildateCustLocEditEquipForm()}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    // <input
                                    //     className='txtBox'
                                    //     value={txtEquipCustLocRoomPlace}
                                    //     placeholder={
                                    //         equipcustloc
                                    //             ? equipcustloc.EquipCustLocRoomPlace
                                    //             : ''
                                    //     }
                                    //     onChange={(e) =>
                                    //         setTxtEquipCustRoomPlace(e.target.value)
                                    //     }
                                    // />
                                    <>
                                        <div className='col-auto pe-0 me-0'>
                                            <ROIcon icon='ROicoLocation' className='icoSVG' usefill='true' color1='#ffffff' tooltip='Room / Place' />
                                        </div>
                                        <div className='col-auto p-0 m-0'>{equipcustlocitem ? equipcustlocitem.EquipCustLocRoomPlace : ''}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Last Service Date and Service Interval */}
                    <div className='row fs-6'>
                        {/*Interval*/}
                        <div className='col-lg-6 fs-6'>
                            <div className='row'>
                                {editState ? (
                                    <>
                                        <div className='col-auto'>
                                            <ROIcon
                                                icon='ROicoDate'
                                                className='icoSVG'
                                                usefill='true'
                                                color1='#ffffff'
                                                tooltip='Service Interval'
                                            />
                                        </div>
                                        <div className='col-lg-2'>
                                            <input
                                                className='txtBox'
                                                value={txtEquipCustLocDaysTillNextService}
                                                placeholder='Service'
                                                onChange={(e) => {
                                                    setTxtEquipCustDaysTillNextService(e.target.value.replace(/\D/g, '').slice(0, 3))
                                                    vaildateCustLocEditEquipForm()
                                                }}
                                                ref={valEquipServiceIntervalValRef}
                                                title='Service Interval'
                                                pattern={RegExPattern.Int}
                                                required
                                            />
                                        </div>
                                        <div className='col-auto'>
                                            <select
                                                id='IntervalType'
                                                className='txtBox'
                                                name='Interval Type'
                                                value={txtEquipCustLocServiceIntervalType}
                                                ref={txtEquipIntervalTypeRef}
                                                onChange={(e) => setTxtEquipCustServiceIntervalType(e.target.value)}
                                            >
                                                <option value='365'>Years</option>
                                                <option value='30'>Months</option>
                                                <option value='7'>Weeks</option>
                                                <option value='1'>Days</option>
                                            </select>
                                        </div>
                                    </>
                                ) : equipcustlocitem ? (
                                    <>
                                        <div className='col-auto'>
                                            <ROIcon icon='ROicoDate' className='icoSVG' usefill='true' color1='#ffffff' tooltip='Service Interval' />
                                        </div>
                                        <div className='col-lg-10'>{getServiceInterval(equipcustlocitem)}</div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        {editState ? <></> : ''}

                        {editState ? (
                            <>
                                <div className='col-auto'>
                                    <ROIcon icon='ROicoService' className='icoSVG' usefill='true' color1='#ffffff' tooltip='Last Service Date' />
                                </div>
                                <div className='col-auto'>
                                    <input
                                        className='txtBox'
                                        value={txtEquipCustLocLastServiceDate}
                                        type='date'
                                        onChange={(e) => {
                                            setTxtEquipCustLocLastServiceDate(e.target.value)
                                            vaildateCustLocEditEquipForm()
                                        }}
                                        ref={valEquipServiceDate}
                                        title='Last Service Date'
                                        //pattern={datePattern}
                                        required
                                    />
                                </div>
                            </>
                        ) : equipcustlocitem ? (
                            <>
                                <div className='col-auto'>
                                    <ROIcon
                                        icon='ROicoService'
                                        className='icoSVG'
                                        usefill='true'
                                        //color1='#ffffff'
                                        color1={
                                            daysDiff(new Date(), equipcustlocitem.EquipCustLocLastServiceDate) >
                                            equipcustlocitem.EquipCustLocDaysTillNextService
                                                ? '#ff0000'
                                                : '#ffffff'
                                        }
                                        tooltip='Last Service Date'
                                    />
                                </div>
                                <div className='col-auto p-0 m-0'>{formatLongDate(equipcustlocitem.EquipCustLocLastServiceDate)}</div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    {/* Sales Rep ID */}
                    <div className='row fs-6'>
                        <div className='col-lg-6 fs-6'>
                            <div className='row'>
                                <div className='col-auto'>
                                    <ROIcon icon='ROicoUser' className='icoSVG' tooltip='Sales Person' usefill='true' color1='#ffffff' />
                                </div>
                                <div className='col-lg-10'>
                                    {editState ? (
                                        <ROComboBox
                                            dataArray={arrSalesReps}
                                            placeholdertext={'Search Sales Rep'}
                                            classname='ROdropdownFull'
                                            textVal={getRepName(txtEquipRepID)}
                                            myIDCallback={setTxtEquipRepID}
                                            ref={valEquipSalesRef}
                                            required={true}
                                            validationCallBack={() => vaildateCustLocEditEquipForm()}
                                            allowCustomValue={false}
                                        />
                                    ) : equipcustlocitem ? (
                                        <div className='pt-1'>
                                            {franusers.map((userItem) => {
                                                if (userItem._id === equipcustlocitem.EquipCustLocSalesID) {
                                                    return userItem.name + ' (' + userItem.email + ')'
                                                }
                                            })}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        {/*Notes*/}

                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-auto'>
                                    <div className='col-auto'>
                                        <ROIcon icon='ROicoNote' className='icoSVG' tooltip='Equipment Notes' usefill='true' color1='#ffffff' />
                                    </div>
                                </div>
                                {editState ? (
                                    <>
                                        <div className='col-lg-10'>
                                            <input
                                                className='txtBox'
                                                value={txtEquipCustLocNotes}
                                                placeholder={
                                                    equipcustlocitem && equipcustlocitem.EquipCustLocNotes !== ''
                                                        ? equipcustlocitem.EquipCustLocNotes
                                                        : 'Notes'
                                                }
                                                onChange={(e) => {
                                                    setTxtEquipCustNotes(e.target.value)
                                                    vaildateCustLocEditEquipForm()
                                                }}
                                                ref={valEquipNotesRef}
                                                title='Equipment Notes'
                                                pattern={RegExPattern.Text}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* <ROIcon
                                                icon='ROicoLocation'
                                                className='icoSVG'
                                                usefill='true'
                                                color1='#ffffff'
                                                tooltip='Last Service Date'
                                            /> */}
                                        <div className='col-auto p-0 m-0 pt-1'>{equipcustlocitem ? equipcustlocitem.EquipCustLocNotes : ''}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/*Toggle Icon*/}
                    {/* <div className='col-auto px-2'> */}
                    {/*Expande/Collapse Icon*/}
                    {/* {editState ? (
                                ''
                            ) : (
                                <ROIcon
                                    icon='ROicoTup'
                                    className='icoSVG'
                                    usefill='true'
                                    color1='#ffffff'
                                    onClickCallback={() =>
                                        setToggleState((prev) => !prev)
                                    }
                                />
                            )} */}

                    {/* </div> */}

                    <div className='row p-0 m-0' /*EquipInfo Expanded*/>
                        <AllServicesItem containerBackColor='' equipcustlocitem={equipcustlocitem} expandEquipBoxCallBack={CallServicesChanged} />
                    </div>
                </div>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <div className='container bg-primary text-white p-1 rounded-2' onClick={() => setToggleState((prev) => !prev)}>
                    <div className='row fs-5 align-items-center px-2' /*EquipInfo Compact*/>
                        <div className='fw-bold col-md-3 px-2'>
                            <ROIcon icon='ROicoEquip' usefill='true' color1='#ffffff' size={24} className='icoSVG' />
                            {equipitem ? equipitem.EquipCode : ''}
                        </div>
                        <div className='col-md-2 pl-2 fs-6 '>
                            {equipcustlocitem.EquipCustLocSerialNR !== '' ? ' #' + equipcustlocitem.EquipCustLocSerialNR : ''}
                        </div>
                        <div className='col-md-2 pl-2 fs-6 '>{equipcustlocitem.EquipCustLocRoomPlace ? equipcustlocitem.EquipCustLocRoomPlace : ''}</div>
                        <div className='col-md-2 pl-2 fs-6'>{getServiceInterval(equipcustlocitem)}</div>
                        <div
                            // className={
                            //     daysDiff(
                            //         new Date(),
                            //         equipcustlocitem.EquipCustLocLastServiceDate
                            //     ) >
                            //     equipcustlocitem.EquipCustLocDaysTillNextService
                            //         ? 'col-2 pl-2 fs-6 fw-bolder danger'
                            //         : 'col-2 pl-2 fs-6 fw-bolder '
                            // }
                            className='col-auto pl-2 fs-6 fw-bolder me-auto'
                        >
                            <ROIcon
                                icon='ROicoService'
                                className='icoSVG'
                                usefill='true'
                                //color1='#ffffff'
                                color1={
                                    daysDiff(new Date(), equipcustlocitem.EquipCustLocLastServiceDate) >
                                    equipcustlocitem.EquipCustLocDaysTillNextService
                                        ? '#ff0000'
                                        : '#ffffff'
                                }
                                tooltip='Last Service Date'
                            />
                            {equipcustlocitem.EquipCustLocLastServiceDate ? formatShortDate(equipcustlocitem.EquipCustLocLastServiceDate) : ''}
                        </div>
                        <div className='col-auto px-1'>
                            <ROIcon
                                icon='ROicoXDown'
                                className='icoSVG'
                                usefill='true'
                                color1='#ffffff'
                                // onClickCallback={changeToggleState}
                            />
                        </div>
                    </div>
                </div>
            )}
        </ContentBox>
    )
}

export default EquipCustLocItem
