import ROIcon from '../ROIcon'
const ROHelpUser = () => {

    return (
        <>
            <div className="row p-0 m-0">

                <div class="contianer ps-2 ">

                    <a href="#Add-user" class="bs-linebreak fw-bold">Add User|</a>
                    &emsp;
                    <a href="#Search" class="bs-linebreak fw-bold">Search|</a>
                    &emsp;
                    <a href="#Customer-Information" class="bs-linebreak fw-bold">Customer Information</a>
                </div>
                <div class="container" id="Add-user">
                <br />
                    <h2>Add Users [<ROIcon icon='ROicoAdd' size={26} className='icoSVG ' />]</h2>
                    <img src="./images/help-imges/users-img.jpg" height={80} alt="img" />
                    <p>The plus icon next to the "Your Users" heading.
                        This icon is used when we want to add a equipment, when click
                        under the heading is where they will
                        be able to fill in needed information.</p>
                </div>
                <div class="container" id="Search">
                    <h2>User Details:</h2>
                </div>
                <div class="container" id="Customer-Information">
                    <p>
                        <strong class="ps-3">1. Username:</strong><br />
                        The first textbox is where user enter their user names.
                        <div class="ps-5">
                            [ <ROIcon icon='ROicoUser' size={26} className='icoSVG ' /> ] Username:<br />
                        </div>

                    </p>
                    <p>
                        <strong class="ps-3">2. Email:</strong>
                        The second textbox is where user enter their email adress.
                        <div class="ps-5">
                            [ <ROIcon icon='ROicoEmail' size={26} className='icoSVG ' /> ] Email:
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">3. Contact Number:</strong><br />
                        The third textbox is where user enter their contact number.
                        <div class="ps-5">
                            [<ROIcon icon='ROicoPhone' size={26} className='icoSVG ' /> ] Contact Number:
                        </div>

                    </p>
                    <strong class="ps-3">4. Password:</strong><br/>
                    The fourth textbox is where user enter their password. The password
                    also has a feature to let the user know if their password is strong or not.
                    <br />
                    <div class="ps-5">
                        [ ] Password:<br />
                    </div>
                    <p>
                        <strong class="ps-3">5. User Role:</strong>
                        The fifth dropdownbox is where user select a user role.
                        <div class="ps-5">
                            [ ] User Role: [Dropdown Box with Plus Icon]<br />
                        </div>

                    </p>
                    <h4>Actions:</h4>
                    
                    <p>
                        <strong class="ps-3">7. Submit Information:</strong>
                        <div class="ps-5">
                            [ ] Submit: (Click on button submit information)<br />
                        </div>

                    </p>
                    <p>
                        <strong class="ps-3">8. Cancel:</strong>
                        <div class="ps-5">
                            [] Cancel: (Click the cancel button discard changes)<br />
                        </div>
                    </p>
                    <h4>User List:</h4>
                    <p>The user all contain a settings icon that contains all the information of user once it is click,
                    and where u can edit the information and change setting according to the user need.</p>
                    <div class="ps-5">
                        - [<ROIcon icon='ROicoTdown' size={26} className='icoSVG ' />] Expand User Details<br />
                        - [<ROIcon icon='ROicoUser' size={26} className='icoSVG ' /> ] Username:<br />
                        - [<ROIcon icon='ROicoEmail' size={26} className='icoSVG ' /> ] Email:<br />
                        - [<ROIcon icon='ROicoPhone' size={26} className='icoSVG ' /> ] Contact Number: <br />
                        - [<ROIcon icon='ROicoAdd' size={26} className='icoSVG ' /> ] Password:<br />
                        - [<ROIcon icon='ROicoAdd' size={26} className='icoSVG ' /> ] User Role:<br />
                        - [<ROIcon icon='ROicoSettings' size={26} className='icoSVG ' />] Settings:  (Settings icon for editing)<br />
                    </div>
                </div>

            </div>
        

                        
                    </>

                    )
}

export default ROHelpUser
