import axios from 'axios'

const API_URL = '/api/customers/locations/contacts/'

//Add new customer location contact
const createCustomerLocContact = async (custloccontData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    custloccontData = { ...custloccontData, user: { ...user } }

    const response = await axios.post(API_URL, custloccontData, headersConfig)
    return response.data
}

const updateCustomerLocContact = async (custloccontData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    //console.log('updateCustomerLocationContact before: ', custloccontData)
    custloccontData = { ...custloccontData, user: { ...user } }

    const response = await axios.put(API_URL + custloccontData._id, custloccontData, headersConfig)

    //console.log('updateCustomerLocationContact reponse: ', response)
    return response.data
}

//Get customer contacts
const getCustomerLocContacts = async (token) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig)

        //console.log('getCustomerLocContacts reponse: ', response)

        return response.data
    } catch (error) {
        console.log('custloccontactService getCustomerLocContacts response: ', error.response)

        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }

        throw new Error('custlocSercustloccontactServicevice(getCustomerLocContacts): ' + error.response.data.message)
    }
}

//Delete customer contact
const deleteCustomerLocContact = async (custloccontID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    //console.log('Delete Service custcontid: ', custloccontID)
    const response = await axios.delete(API_URL + custloccontID, headersConfig)

    return response.data
}

const custLocService = {
    createCustomerLocContact,
    getCustomerLocContacts,
    deleteCustomerLocContact,
    updateCustomerLocContact,
}

export default custLocService
