import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { getEquipment, resetequip } from '../features/equip/equipSlice'
import { getParts, resetparts } from '../features/parts/partsSlice'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROHelpBox from '../components/ROHelpBox.jsx'
import ROComboBox from '../components/ROComboBox'
import { addROErrorLog, VaildateForm } from '../features/globalFunctions'
import { getFranSettings } from '../features/franacc/franaccSlice'

//Import Customer Redux State/Global Var and Axiox database calls
import { createEquipment } from '../features/equip/equipSlice.js'
import EquipItem from '../components/EquipItem'
import { RegExPattern } from '../features/globalVars'

const Equipment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, message, isError } = useSelector((state) => state.auth)

    //Get State fran acc settings from redux
    const { franacc, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    //console.log('*** Equip Main Draw ***')

    //New Equipment
    const [showNewEquip, setShowNewEquip] = useState(false)

    const [txtEquipName, setTxtEquipName] = useState('')
    const [txtEquipCode, setTxtEquipCode] = useState('')
    const [txtEquipSerialNr, setTxtEquipSerialNr] = useState('')
    const [txtEquipPrice, setTxtEquipPrice] = useState('')
    const [txtEquipCategory, setTxtEquipCategory] = useState('')

    //Add New Equipment
    const AddNewEquipment = () => {
        const newEquip = {
            franID: user.franid,
            equipcode: txtEquipCode,
            equipname: txtEquipName,
            equipserialnr: txtEquipSerialNr,
            equiplastprice: txtEquipPrice,
            equipcategory: txtEquipCategory,
            equipimageurl: '',
            equipnotes: '',
            equipflags: '',
        }

        ClearForm()
        setShowNewEquip(false)

        dispatch(createEquipment(newEquip))
    }

    const ClearForm = () => {
        setTxtEquipCode('')
        setTxtEquipName('')
        setTxtEquipSerialNr('')
        setTxtEquipPrice('')
        setTxtEquipCategory('')
    }

    //Get State equipment from redux
    const { equipment, isEquipError, isEquipSuccess, equipmessage } = useSelector((state) => state.equipment)

    //Categories
    let arrCategories = []
    if (equipment) {
        arrCategories = new Set(equipment.map((equip) => equip.EquipCategory))
        //console.log('setCategories: ', arrCategories)
        arrCategories = Array.from(arrCategories)
        arrCategories = arrCategories.map((item, i) => {
            return { Text: item }
        })
        //console.log('arrCategories: ', arrCategories)
    }

    //console.log('Equipment before useEffect Check')

    useEffect(() => {
        //console.log('Equipment useEffect Check1')

        if (isError) {
            console.log('Equipment USERS useEffect error: ', message)
            toast.error(message)
            addROErrorLog('Equiment user Error:  ' + message, user ? user.name : '', 'Equipment')
            navigate('/')
            return
        }

        if (isEquipError) {
            console.log('Equipment useEffect error: ', equipmessage)
            toast.error(equipmessage)
            addROErrorLog('Equiment useEffect Error:  ' + message, user ? user.name : '', 'Equipment')
            navigate('/')
            return
        }
        //console.log('Equipment useEffect Check2')

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }
        //console.log('Equipment useEffect Check3')

        try {
            console.log('Get All Data from servers')
            dispatch(getFranSettings())
            dispatch(getEquipment())
        } catch (error) {
            console.log('Equipment.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            addROErrorLog('Equiment Dispatch(all) Error:  ' + message, user ? user.name : '', 'Equipment')
            return () => dispatch(reset())
        }

        //console.log('Equipment useEffect Check4')

        return () => dispatch(reset())
    }, [navigate, user, dispatch, isError, isEquipError])

    //console.log('Equipment after useEffect Check')

    //Parts
    const { parts, isPartsError, partsmessage } = useSelector((state) => state.parts)

    useEffect(() => {
        //console.log('Parts UseEffect')

        if (isPartsError) {
            console.log('Parts useEffect error: ', partsmessage)
            toast.error(partsmessage)
            addROErrorLog('Parts useEffect Error:  ' + partsmessage, user ? user.name : '', 'Parts')
            dispatch(resetparts())
            navigate('/')
            return
        }

        try {
            console.log('Get All Parts from servers')
            dispatch(getParts())
        } catch (error) {
            console.log('Parts.jsx Dispatch(all) Error: ', error)
            addROErrorLog('Parts Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'Parts')
            toast.error(error)
            return () => dispatch(reset())
        }

        return () => dispatch(reset())
    }, [isPartsError]) //[navigate, user, dispatch, isError, isPartsError]

    //Collapse All
    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //Search Equipment
    const [equipmentSearchData, setEquipmentSearchData] = useState([])
    const [searchResults, setSearchResults] = useState([])
    let [searchString, setSearchString] = useState('')
    const [searchFound, setSearchFound] = useState(false)

    const SearchEquipment = (_searchstr, advSearch = false) => {
        setSearchString(_searchstr)
        _searchstr = _searchstr.toLowerCase()

        let searchWords = _searchstr.trim().split(' ')

        if (!advSearch) {
            searchWords = [_searchstr]
        }

        //console.log('search : ', _searchstr)

        if (_searchstr === '') {
            setSearchResults(equipment)
            setSearchFound(true)
        } else {
            let tempResults = []

            if (equipment) {
                tempResults = equipment.filter((item) => {
                    let found = false
                    searchWords.forEach((word) => {
                        if (String(item.EquipCode).toLowerCase().includes(word) || String(item.EquipName).toLowerCase().includes(word) || String(item.EquipLastPrice).toLowerCase().includes(word) || String(item.EquipCategory).toLowerCase().includes(word)) {
                            found = true
                        }
                    })
                    return found
                })
            }

            if (tempResults === null || tempResults.length === 0) {
                if (!advSearch) {
                    SearchEquipment(_searchstr, true)
                } else {
                    setSearchResults(equipment)
                    setSearchFound(false)
                }
            } else {
                setSearchResults(tempResults)
                setSearchFound(true)
            }
        }
    }

    //Only update/redraw if search results changed. Or else it will redraw on each letter type even if results didn't change
    //used to check if customers is populated and to only search once on init
    if (equipment != null && equipment.length > 0 && JSON.stringify(equipmentSearchData) !== JSON.stringify(equipment)) {
        //console.log('more search')
        setEquipmentSearchData(equipment)
        SearchEquipment(searchString)

        if (!searchFound) {
            SearchEquipment(searchString, true)
        }
    }

    //#region New Equipment Form Validation

    const [boolEquipmentFormValidated, setEquipmentFormValidated] = useState(false)

    const valEquipCodeRef = useRef()
    const valEquipNameRef = useRef()
    const valEquipPriceRef = useRef()
    const valEquipCategoryRef = useRef()

    const vaildateEquipmentNewEquipForm = (clicked = false) => {
        if (!showNewEquip) return

        if (showNewEquip) {
            setEquipmentFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valEquipCodeRef,
                            text: 'No Special Characters',
                            form: 'New Equipment valEquipCodeRef',
                        },
                        {
                            ref: valEquipNameRef,
                            text: 'No Special Characters',
                            form: 'New Equipment valEquipNameRef',
                        },
                        {
                            ref: valEquipPriceRef,
                            text: 'Enter valid price',
                            form: 'New Equipment valEquipPriceRef',
                        },
                        {
                            ref: valEquipCategoryRef,
                            text: 'Equiment Category Required',
                            form: 'New Equipment valEquipCategoryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <>
            <div className='bg-welcome'>
                <section className='pageHead container clearfix'>
                    <div className='pageFunctions row pb-2'>
                        {/* <h6>Welcome {user && user.name}</h6> */}
                        <div className='col-md-3 pt-2 text-center'>
                            <h4>
                                Your Equipment &nbsp;
                                {/* New Equipment */}
                                <ROIcon
                                    icon={showNewEquip ? 'ROicoCancel' : 'ROicoPlus'}
                                    size={24}
                                    authlvl={10}
                                    className='icoSVG'
                                    textbefore={showNewEquip ? 'Cancel' : ' '}
                                    tooltip={showNewEquip ? 'Cancel' : 'Add New Equipment'}
                                    onClickCallback={() => {
                                        setShowNewEquip((prev) => !prev)
                                        ClearForm()
                                    }}
                                    textafter={equipment === null || (!showNewEquip && equipment.length <= 0) ? '<-Click' : ''}
                                    style={{
                                        animation: 'pulse 4s infinite',
                                    }}
                                />
                            </h4>
                        </div>

                        {/*equipment === null || equipment.length <= 0 ? '' : <></> */}
                        {equipment === null || equipment.length <= 0 ? (
                            ''
                        ) : (
                            <>
                                <div className='interfSearch align-middle col-md-5 me-auto pt-2 pb-2'>
                                    <input id='search' placeholder='Enter your search here' value={searchString} onChange={(e) => SearchEquipment(e.target.value)} />
                                    {searchFound ? '' : <p className='danger'>Search doesn't match any equipment</p>}
                                </div>
                                <div className='align-middle col-auto pt-2 pe-4'>
                                    <ROHelpBox helpTopic={'equipment'} />
                                    {/* <ROIcon icon='ROicoUp' size={24} className='icoSVG' tooltip='Collapse All' onClickCallback={() => CollapseAll()} /> */}
                                    {/* <ROIcon icon='ROicoHelp' size={24} className='icoSVG' tooltip='Help' /> */}
                                </div>
                            </>
                        )}

                        {showNewEquip ? (
                            <>
                                <div className='container p-2 pt-0 bg-light rounded-2'>
                                    Add New Equipment:
                                    <div className='row' /*EquipInfo Expanded*/>
                                        <div className='ROmaintxt col-md-3'>
                                            <input
                                                className='txtBox'
                                                value={txtEquipCode}
                                                placeholder='Product Code'
                                                onChange={(e) => {
                                                    setTxtEquipCode(e.target.value)
                                                    vaildateEquipmentNewEquipForm()
                                                }}
                                                ref={valEquipCodeRef}
                                                title='Equipment Code'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='col-auto me-auto px-0'>
                                            {' '}
                                            <>
                                                {boolEquipmentFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Add Equipment'
                                                        authlvl={10}
                                                        onClickCallback={() => AddNewEquipment()}
                                                        style={{
                                                            animation: 'icofocus 4s infinite',
                                                        }}
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Add Equipment'
                                                        authlvl={10}
                                                        onClickCallback={() => vaildateEquipmentNewEquipForm(true)}
                                                        usefill={true}
                                                        color1='#ff0000'
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                    />
                                                )}

                                                <ROIcon icon='ROicoCancel' size={24} className='icoSVG' tooltip='Cancel' onClickCallback={() => setShowNewEquip((prev) => !prev)} />
                                            </>
                                        </div>
                                    </div>
                                    <div className='row' /*EquipInfo Expanded*/>
                                        <div className='ROnormaltxt col-md-6 fs-5'>
                                            {' '}
                                            <input
                                                className='txtBox'
                                                value={txtEquipName}
                                                placeholder='Product Name'
                                                onChange={(e) => {
                                                    setTxtEquipName(e.target.value)
                                                    vaildateEquipmentNewEquipForm()
                                                }}
                                                ref={valEquipNameRef}
                                                title='Equipment Name'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='ROnormaltxt col-md-2 fs-5'>
                                            {' '}
                                            <input
                                                className='txtBox'
                                                value={txtEquipPrice}
                                                placeholder='Latest Price'
                                                onChange={(e) => {
                                                    setTxtEquipPrice(e.target.value.replace(/[^0-9\.]+/g, ''))
                                                    vaildateEquipmentNewEquipForm()
                                                }}
                                                ref={valEquipPriceRef}
                                                title='Equipment Price'
                                                pattern={RegExPattern.Dec}
                                                required
                                            />
                                        </div>
                                        <div className='ROnormaltxt col-md-4 fs-5'>
                                            {' '}
                                            <ROComboBox dataArray={arrCategories} placeholdertext='Category' myValueCallback={setTxtEquipCategory} ref={valEquipCategoryRef} required={true} validationCallBack={() => vaildateEquipmentNewEquipForm()} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </section>
            </div>

            <section className='pageHead container clearfix pt-2'></section>
            <div className='pageContent container'>
                {searchResults.map((equip, i) => {
                    return <EquipItem key={myUUid()} equip={equip} arrCategories={arrCategories} collapse={getCollapseAll} containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'} />
                })}
            </div>
        </>
    )
}

export default Equipment
