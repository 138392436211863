import { useEffect, useState, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import { toast } from 'react-toastify'
import ContentBox from './ContentBox'
import ServiceItem from './ServiceItem'
import ROIcon from './ROIcon'
import { formatInputDate } from '../features/formatTime'
import { getParts, resetparts } from '../features/parts/partsSlice'
import { addROErrorLog, VaildateForm, getFranSettingsJSON } from '../features/globalFunctions'
import { MaintenanceTypes, RegExPattern } from '../features/globalVars'

//Import Redux State/Global Var and Axiox database calls
import { createService, getServices, resetservices } from '../features/services/servicesSlice.js'

import ROComboBox from './ROComboBox'
import { formatLongDate } from '../features/formatTime'

//Used to refresh equipment if services was changed. Mainly to update last service date.
import { getEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice'

const AllServicesItem = ({
    equipcustlocitem,
    editOnly = false,
    containerBackColor,
    initDisplayAmount = 2,
    collapse,
    _expanedState,
    _editState,
    expandEquipBoxCallBack,
}) => {
    const dispatch = useDispatch()

    //Get State user from redux
    const { user } = useSelector((state) => state.auth)

    //console.log('equipcustlocitem: ', equipcustlocitem)
    console.log('All Services DRAW')

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    //Get State equipment from redux
    const { equipment } = useSelector((state) => state.equipment)
    //Get Equipment Defaults

    const [equip, setEquip] = useState(equipment ? equipment.find((e) => e.EquipID === equipcustlocitem.EquipID) : undefined)

    const [defs, setDefs] = useState({ DefS: [1, 1, 1, 1, 1], DefP: {} })

    console.log('All Services DRAW Defs: ', defs)

    const curSymb = getFranSettingsJSON(franacc, 'CurrencySymb') ? getFranSettingsJSON(franacc, 'CurrencySymb') : 'R'
    const pType = getFranSettingsJSON(franacc, 'Bar') ? getFranSettingsJSON(franacc, 'Bar') : 'Bar'

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)

    //#region All Services
    const { services, isServicesError, servicesmessage } = useSelector((state) => state.service)

    // New Service
    const [showNewService, setShowNewService] = useState(false)

    const [txtServiceDate, setTxtServiceDate] = useState(formatInputDate(Date()))

    const [txtServiceRepID, setTxtServiceRepID] = useState(-1)
    const [txtMaintenanceObj, setTxtMaintenanceObj] = useState({ ID: 0, Success: true })
    const [txtServiceRef, setTxtServiceRef] = useState('')
    const [txtTDSSystem, setTxtTDSSystem] = useState(0)
    const [txtTDSFeed, setTxtTDSFeed] = useState(0)
    const [txtFeedPressure, setTxtFeedPressure] = useState(0)
    const [txtTankPressure, setTxtTankPressure] = useState(0)
    const [boolControls, setBoolControls] = useState(false)
    //const [boolSuccessful, setBoolSuccessful] = useState(true)
    const [txtComments, setTxtComments] = useState('')

    //Max Amount of service history to show
    const [displayAmount, setDisplayAmount] = useState(initDisplayAmount)

    const [arrServiceParts, setArrServiceParts] = useState([])

    //const boolSuccessful = useRef(false)

    //console.log('draw boolSuccessful.current: ', boolSuccessful);

    //#region New Service Form Validation
    const [boolServiceFormValidated, setServiceFormValidated] = useState(false)

    const valServiceRef = useRef()
    const valServiceDate = useRef()
    const valServiceTechnician = useRef()
    const valMaintenanceType = useRef()
    const valServiceTDSFeed = useRef()
    const valServiceTDSSystem = useRef()
    const valServiceFeedPressure = useRef()
    const valServiceTankPressure = useRef()

    const vaildateServiceForm = (clicked = false) => {
        if (!showNewService) return

        //Validate service ref or invoice num for doubles
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        //************** Disabled due to multiple equipment using the same Service Ref (Equipment all serviced on the same day) **********
        // if (
        //     services &&
        //     services.length > 0 &&
        //     services.some(
        //         (e) => e.ServiceWorkRef === valServiceRef.current.value
        //     )
        // ) {
        //     const existService = services.find(
        //         (e) => e.ServiceWorkRef === valServiceRef.current.value
        //     )

        //     setServiceFormValidated(false)

        //     if (clicked) {
        //         toast.error('Service Ref already exist!')
        //         console.log('Service Ref already exist: ', existService)
        //     }
        //     return
        // }

        valMaintenanceType.current.readonly = false
        //console.log('valServiceTechnician: ', valServiceTechnician.current);
        //console.log('valMaintenanceType: ', valMaintenanceType.current);

        setServiceFormValidated(
            VaildateForm(
                [
                    {
                        ref: valServiceRef,
                        text: 'No Special Characters',
                        form: 'New Service valServiceRef',
                    },
                    {
                        ref: valServiceDate,
                        text: 'Invalid Date',
                        form: 'New Service valServiceRef',
                    },
                    {
                        ref: valServiceTechnician,
                        text: 'Invalid Technician',
                        form: 'New Service valServiceTechnician',
                    },
                    {
                        ref: valMaintenanceType,
                        text: 'Invalid Maintenance Type',
                        form: 'New Service valMaintenanceType',
                    },

                    {
                        ref: valServiceTDSFeed,
                        text: 'Invalid Value',
                        form: 'New Service valServiceTDSFeed',
                    },
                    {
                        ref: valServiceTDSSystem,
                        text: 'Invalid Value',
                        form: 'New Service valServiceTDSSystem',
                    },
                    {
                        ref: valServiceFeedPressure,
                        text: 'Invalid Value',
                        form: 'New Service valServiceFeedPressure',
                    },
                    {
                        ref: valServiceTankPressure,
                        text: 'Invalid Value',
                        form: 'New Service valServiceTankPressure',
                    },
                ],
                clicked
            )
        )
    }

    useEffect(() => {
        //REdraw on validation change
        vaildateServiceForm()
        //Do validation on ROCombobox changes Service Rep ID
    }, [txtServiceRepID])
    //#endregion

    //Services
    const ShowNewService = (show = false) => {
        setShowNewService(show)
        setServiceFormValidated(false)
        if (show) {
            //console.log('getParts')
        } else {
            resetForm()
        }
    }

    const resetForm = () => {
        setTxtServiceDate(formatInputDate(Date()))
        setTxtServiceRef('')
        setTxtMaintenanceObj({ ID: 0, Success: true })
        setTxtTDSSystem(0)
        setTxtTDSFeed(0)
        setTxtFeedPressure(0)
        setTxtTankPressure(0)
        setBoolControls(false)
        //setBoolSuccessful(true)
        setTxtComments('')
        //setArrServiceParts([])
        GetDefaultParts()
        GetDefaultSettings()
    }

    const [serviceTotal, setServiceTotal] = useState(0)

    let serviceParts = arrServiceParts.map((item) => {
        return { ...item, ServicePartPrice: parseFloat(item.ServicePartPrice) }
    })

    const AddNewService = async () => {
        //console.log('user._id: ', user._id)

        //(txtMaintenanceTypeID + 1) % 2 === 0
        const newService = {
            p_EquipCustLocID: equipcustlocitem.EquipCustLocID,
            p_UserID: txtServiceRepID,
            p_ServiceWorkRef: txtServiceRef,
            p_ServiceDate: txtServiceDate,
            p_ServiceTDSSystem: txtTDSSystem,
            p_ServiceTDSFeed: txtTDSFeed,
            p_ServiceFeedPressure: pType === 'PSI' ? txtFeedPressure / 14.5 : txtFeedPressure,
            p_ServiceTankPressure: pType === 'PSI' ? txtTankPressure / 14.5 : txtTankPressure,
            p_ServiceControls: boolControls,
            p_ServiceNotes: txtComments,
            //p_ServiceSuccessful: !((txtMaintenanceTypeID + 1) % 2 === 0),
            p_ServiceSuccessful: txtMaintenanceObj.Success,
            //p_ServiceType: txtMaintenanceTypeID,
            p_ServiceType: txtMaintenanceObj.ID,
            p_ServiceFlags: '',
            p_ServiceCustom1: '',
            p_ServiceCustom2: '',
            p_ServiceCustom3: '',
            p_json: JSON.stringify(serviceParts),
        }

        console.log('AddNewService:', newService)

        ShowNewService(false)

        await dispatch(createService(newService))
        dispatch(getEquipCustLoc())

        focusAndExpandEquipmentBox()
    }

    const focusAndExpandEquipmentBox = () => {
        if (expandEquipBoxCallBack) {
            console.log('focusAndExpandEquipmentBox focusAndExpandEquipmentBox')
            if (equipcustlocitem && equipcustlocitem.EquipCustLocID !== null) {
                expandEquipBoxCallBack(equipcustlocitem.EquipCustLocID)
            }
        }
    }

    /*Parts*/
    //Get State parts from redux
    const { parts, isPartsError, isPartsSuccess, partsmessage } = useSelector((state) => state.parts)

    const addPart = (partID) => {
        let serviceParts = [...arrServiceParts]

        let newPart = parts.find((part) => part.PartID === partID)

        let newServiceItem = {
            ServicePartID: newPart.partID,
            ServicePartCode: newPart.PartCode.replace(/"/g, '\\"'),
            //ServicePartName: newPart.PartName.replace('"', '\\"'),
            ServicePartName: newPart.PartName.replace(/"/g, '\\"'),
            ServicePartSerialNR: '',
            ServicePartQty: 1,
            ServicePartPrice: newPart.PartLastPrice.toFixed(2),
            //ServicePartCategory: newPart.PartCategory.replace('"', '\\"'),
            ServicePartCategory: newPart.PartCategory.replace(/"/g, '\\"'),
            ServicePartNotes: '',
            ServicePartFlags: '',
        }

        // console.log(
        //     'newPart.PartCategory: ',
        //     newPart.PartCategory.replace('"', '\\"')
        // )

        serviceParts.push(newServiceItem)
        setArrServiceParts(serviceParts)

        console.log('arrServiceParts: ', arrServiceParts)

        console.log('call total')
        calcTotalService(serviceParts)
    }

    const updateServicePart = (i, newpriceobj) => {
        let serviceParts = [...arrServiceParts]

        serviceParts[i] = { ...serviceParts[i], ...newpriceobj }
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const updateServicePartPrice = (i, newprice, forceValidation = false) => {
        if (!newprice || newprice === null || newprice === '') {
            newprice = ''
        }

        if (forceValidation) {
            if (newprice === '') {
                newprice = 0
            }

            newprice = parseFloat(newprice).toFixed(2)
        }

        let newpriceobj = { ServicePartPrice: newprice.toString().replace(/[^0-9\.]+/g, '') }

        let serviceParts = [...arrServiceParts]

        serviceParts[i] = { ...serviceParts[i], ...newpriceobj }
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const removeServicePart = (i) => {
        let serviceParts = [...arrServiceParts]
        serviceParts.splice(i, 1)
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const calcTotalService = (partslist) => {
        let total = 0

        console.log('partslist: ', partslist)

        partslist.map((part) => (total += part.ServicePartPrice * part.ServicePartQty))

        console.log('total: ', total)
        setServiceTotal(total)
    }

    let arrParts = []
    if (parts) {
        arrParts = parts.map((part) => {
            return {
                Text: '(' + part.PartCode + ') ' + part.PartName + ' - ' + part.PartCategory,
                ID: part.PartID,
            }
        })

        //console.log('arrParts: ', arrParts)
    }

    //Maintenance Types ROCombobox Array
    let arrMaintTypes = []
    if (MaintenanceTypes) {
        try {
            const MaintTypes = Array.from(MaintenanceTypes)
            console.log('arrMaintTypes: ', arrMaintTypes)
            // arrMaintTypes = arrMaintTypes.map((item, i) => {
            //     return { Text: item.title, ID: item.id }, { Text: item.titleFail, ID: item.id }
            // })
            MaintTypes.forEach((item, i) => {
                arrMaintTypes.push({ Text: item.title, ID: item.id + i })
                arrMaintTypes.push({ Text: item.titleFail, ID: item.id + 1 + i })
            })

            console.log('arrMaintTypes: ', arrMaintTypes)
        } catch (error) {
            console.log('AllServicesItem arrMaintTypes Error: ', error)

            toast.error('AllServicesItem arrMaintTypes  Error: ' + error.toString())
            addROErrorLog('AllServicesItem arrMaintTypes  Error:  ' + error.toString(), user ? user.name : '', 'AllServicesItem')
        }
    }

    const setMaintenaceIDCallback = (id) => {
        const successBool = !((id + 1) % 2 === 0)

        //setBoolSuccessful(successBool)

        //console.log('successBool: ', successBool, ' txtMaintenanceTypeID : ', id)

        //console.log('before boolSuccessful.current: ', successBool);
        if (successBool) {
            //setTxtMaintenanceTypeID((prev) => id / 2 )
            setTxtMaintenanceObj({ ID: id / 2, Success: true })

            //console.log('(id / 2 - id / 2): ' , id / 2 )
        } else {
            //setTxtMaintenanceTypeID((prev) => (id - 1) / 2)
            setTxtMaintenanceObj({ ID: (id - 1) / 2, Success: false })
            //console.log('(id + 1) / 2 - (id + 1) / 2 :', (id - 1) / 2 )
        }

        //console.log('after boolSuccessful.current: ', successBool);
    }

    useEffect(() => {
        try {
            console.log('*** All Services Use Effect Main ***')
            if (isServicesError) {
                console.log('All Services useEffect error: ', servicesmessage)
                toast.error(servicesmessage)
                addROErrorLog('All Services useEffect Error:  ' + servicesmessage, user ? user.name : '', 'Services')
                return
            }

            if (isPartsError) {
                console.log('All Services Parts useEffect error: ', partsmessage)
                toast.error(partsmessage)
                addROErrorLog('All Services Parts useEffect Error:  ' + partsmessage, user ? user.name : '', 'Services')
                return
            }

            dispatch(getServices())
            dispatch(getParts())

            return () => dispatch(resetservices())
        } catch (error) {
            console.log('AllServices.jsx Dispatch(all) Error: ', error)
            addROErrorLog('All Services Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'Services')
            toast.error(error)
        }
    }, [isServicesError, isPartsError])

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } = useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        try {
            arrSalesReps = new Set(
                franusers.map((reps) => {
                    return { name: reps.name, ID: reps._id }
                })
            )
            arrSalesReps = Array.from(arrSalesReps)
            arrSalesReps = arrSalesReps.map((reps) => {
                return {
                    Text: reps.name,
                    ID: reps.ID,
                }
            })
        } catch (error) {
            console.log('All Services arrSalesRepos Error: ', error)

            toast.error('All Services arrSalesRepos Error: ' + error.toString())
            addROErrorLog('All Services arrSalesRepos Error:  ' + error.toString(), user ? user.name : '', 'Services')
        }
    }

    const getRepName = (id) => {
        let retVal = arrSalesReps.find((rep) => {
            console.log('Rep id: ', rep, id)
            if (rep.ID === id) {
                console.log('Choosen Rep id: ', rep)
                return rep.Text
            }
        })

        console.log('getRepName: ', retVal)

        if (retVal) {
            return retVal.Text
        } else {
            return ''
        }
    }

    //Build Array with display amount of services to show.
    //WIP
    const showServices = []

    services.map((serviceitem) => {
        if (serviceitem.EquipCustLocID === equipcustlocitem.EquipCustLocID) {
            showServices.push(serviceitem)

            if (showServices.length > displayAmount) {
                showServices.shift()
            }
        }
    })

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    const GetDefaultParts = () => {
        if (equip && equip.EquipFlags && equip.EquipFlags.length > 0) {
            let defaultParts = []
            let flags = JSON.parse(equip.EquipFlags)

            serviceParts = []

            flags.DefP.map((part, i) => {
                let valPair = Object.entries(part)
                let newPart = parts.find((part) => part.PartID === parseInt(valPair[0][0]))

                if (newPart) {
                    let newServiceItem = {
                        ServicePartID: newPart.partID,
                        ServicePartCode: newPart.PartCode.replace(/"/g, '\\"'),
                        //ServicePartName: newPart.PartName.replace('"', '\\"'),
                        ServicePartName: newPart.PartName.replace(/"/g, '\\"'),
                        ServicePartSerialNR: '',
                        ServicePartQty: parseInt(valPair[0][1]) ? parseInt(valPair[0][1]) : 1,
                        ServicePartPrice: newPart.PartLastPrice.toFixed(2),
                        //ServicePartCategory: newPart.PartCategory.replace('"', '\\"'),
                        ServicePartCategory: newPart.PartCategory.replace(/"/g, '\\"'),
                        //(/"/g, '\\"')
                        ServicePartNotes: '',
                        ServicePartFlags: '',
                    }

                    //console.log('newServiceItem: ', newServiceItem)

                    serviceParts.push(newServiceItem)
                }
            })

            //setArrDefaultParts(defaultParts)
            setArrServiceParts(serviceParts)
        }
    }

    const GetDefaultSettings = () => {
        if (equip && equip.EquipFlags && equip.EquipFlags.length > 0) {
            let defaultParts = []
            let flags = JSON.parse(equip.EquipFlags)

            let settings = flags.DefS
            setDefs({ ...defs, DefS: { ...settings } })
            console.log('flags.DefS: ', flags.DefS)
        }
    }

    useEffect(() => {
        GetDefaultParts()
        GetDefaultSettings()
    }, [equip])

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-primary p-0 m-0 mb-1 rounded-2 bg-primary`
                    : `custBox content border border-primary p-0 m-0 mb-1 rounded-2 bg-primary`
            }
        >
            {/*Create or Add New Service */}
            <div className='container p-0 m-0 rounded-2s'>
                <div className='row m-0 p-0' /*Service Create or Add*/>
                    {showNewService ? (
                        <>
                            <div className='serviceBox col-md-12 m-0 p-0 fs-5 bg-servicewhite rounded-2'>
                                <div className=' container p-0 m-0 bg-servicewhite rounded-2'>
                                    <div className='row m-0 p-0'>
                                        <div className='col-md-6 p-0 m-0'>
                                            <ROIcon
                                                icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'}
                                                size={48}
                                                className='icoSVG'
                                                tooltip='Add New Maintenance'
                                                //onClickCallback={() => AddNewService()}
                                                // style={{
                                                //     animation: 'icofocus 4s infinite',
                                                // }}
                                                usefill='true'
                                                //color1={(txtMaintenanceTypeID + 1) % 2 === 0 ? '#ff0000' : '#32bebe'}
                                                color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'}
                                            />
                                            <span className='text-primary fw-bold fs-6'>Add New Maintenance</span>
                                        </div>
                                        <div className='col-auto ms-auto m-0 p-0'>
                                            <>
                                                {boolServiceFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Add Maintenance'
                                                        onClickCallback={() => AddNewService()}
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                        usefill='true'
                                                        color1='#32bebe'
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoAccept'
                                                        size={24}
                                                        className='icoSVG borderlp'
                                                        tooltip='Add Maintenance'
                                                        onClickCallback={() => vaildateServiceForm(true)}
                                                        style={{
                                                            animation: 'pulse 4s infinite',
                                                        }}
                                                        usefill='true'
                                                        color1='#ff0000'
                                                    />
                                                )}
                                                <ROIcon
                                                    icon='ROicoCancel'
                                                    size={24}
                                                    className='icoSVG borderlp'
                                                    tooltip='Cancel'
                                                    onClickCallback={() => ShowNewService(false)}
                                                    //usefill='true'
                                                    //color1='#ffffff'
                                                />
                                            </>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0 my-0 py-0' /*Service Date:*/>
                                        <div className='small fw-bold col-lg-2'>
                                            Service Date:
                                            <input
                                                className='txtBox px-0 mx-0'
                                                value={txtServiceDate}
                                                type='date'
                                                onChange={(e) => {
                                                    setTxtServiceDate(e.target.value)
                                                    vaildateServiceForm()
                                                }}
                                                ref={valServiceDate}
                                                title='Service Date'
                                                //pattern={datePattern}
                                                required
                                            />
                                        </div>
                                        <div className='small fw-bold col-lg-3'>
                                            Service Ref:
                                            <input
                                                className='txtBox'
                                                value={txtServiceRef}
                                                placeholder='Service Reference'
                                                onChange={(e) => {
                                                    setTxtServiceRef(e.target.value)
                                                    vaildateServiceForm()
                                                }}
                                                ref={valServiceRef}
                                                title='Service Reference'
                                                pattern={RegExPattern.Text}
                                                required
                                            />
                                        </div>
                                        <div className='small fw-bold col-lg-3'>
                                            Technician:
                                            <div className='ROnormaltxt fs-5'>
                                                <ROComboBox
                                                    dataArray={arrSalesReps}
                                                    placeholdertext={'Search Technician'}
                                                    classname='ROdropdownFull small fw-bold '
                                                    textVal={getRepName(txtServiceRepID)}
                                                    myIDCallback={setTxtServiceRepID}
                                                    ref={valServiceTechnician}
                                                    required={true}
                                                    validationCallBack={() => vaildateServiceForm()}
                                                    allowCustomValue={false}
                                                />
                                            </div>
                                        </div>
                                        <div className='small fw-bold col-lg-3'>
                                            Type:
                                            <div className='ROnormaltxt fs-5'>
                                                <div className='row p-0 m-0'>
                                                    <div className='col-md-1 p-0 m-0'>
                                                        {' '}
                                                        <ROIcon
                                                            icon={MaintenanceTypes ? MaintenanceTypes[txtMaintenanceObj.ID].icon : 'ROicoService'}
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Add New Maintenance'
                                                            //onClickCallback={() => AddNewService()}
                                                            // style={{
                                                            //     animation: 'icofocus 4s infinite',
                                                            // }}
                                                            usefill='true'
                                                            //color1={(txtMaintenanceTypeID + 1) % 2 === 0 ? '#ff0000' : '#32bebe'}
                                                            color1={txtMaintenanceObj.Success ? '#32bebe' : '#ff0000'}
                                                        />
                                                    </div>
                                                    <div className='col-md-11 p-0 m-0'>
                                                        <ROComboBox
                                                            dataArray={arrMaintTypes}
                                                            placeholdertext={'Maintenance Type'}
                                                            classname='ROdropdown small fw-bold '
                                                            textVal={
                                                                MaintenanceTypes && txtMaintenanceObj.ID ? MaintenanceTypes[txtMaintenanceObj.ID] : ''
                                                            }
                                                            myIDCallback={setMaintenaceIDCallback}
                                                            ref={valMaintenanceType}
                                                            required={true}
                                                            validationCallBack={() => vaildateServiceForm()}
                                                            allowCustomValue={false}
                                                            allowFilter={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Technician ID */}
                                    <div className='row m-0 p-0 my-0 py-0' /*Service Equip Info:   small fw-bold col-2 ms-4 p-0*/>
                                        <div className={defs.DefS[0] === 1 ? 'small fw-bold col-lg-2' :'col-lg-2 p-0 m-0 fs-6 invisible'}>
                                            TDS Feed:
                                            <input
                                                className='txtBox'
                                                value={txtTDSFeed}
                                                placeholder='TDS Feed'
                                                ref={valServiceTDSFeed}
                                                title='TDS Feed'
                                                pattern={RegExPattern.Int}
                                                onChange={(e) => setTxtTDSFeed(e.target.value.replace(/[^0-9]+/g, ''))}
                                                onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={defs.DefS[1] === 1 ? 'small fw-bold col-lg-2' : 'col-lg-2 p-0 ps-1 m-0 fs-6 invisible'}
                                        >
                                            TDS System:
                                            <input
                                                className='txtBox'
                                                value={txtTDSSystem}
                                                placeholder='TDS System'
                                                ref={valServiceTDSSystem}
                                                title='TDS System'
                                                pattern={RegExPattern.Int}
                                                onChange={(e) => setTxtTDSSystem(e.target.value.replace(/[^0-9]+/g, ''))}
                                                onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={defs.DefS[2] === 1 ? 'small fw-bold col-lg-2' :'col-lg-2 p-0 ps-1 m-0 fs-6 invisible'}
                                        >
                                            Feed Pressure:
                                            <input
                                                className='txtBox'
                                                value={txtFeedPressure}
                                                placeholder='Feed Pressure'
                                                ref={valServiceFeedPressure}
                                                title='Feed Pressure'
                                                pattern={RegExPattern.Dec}
                                                onChange={(e) => setTxtFeedPressure(e.target.value.replace(/[^0-9\.]+/g, ''))}
                                                onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={defs.DefS[3] === 1 ?'small fw-bold col-lg-2' :'col-lg-2 p-0 ps-1 m-0 fs-6 invisible'}
                                        >
                                            Tank Pressure:
                                            <input
                                                className='txtBox'
                                                value={txtTankPressure}
                                                ref={valServiceTankPressure}
                                                title='Tank Pressure'
                                                pattern={RegExPattern.Dec}
                                                placeholder='Tank Pressure'
                                                onChange={(e) => setTxtTankPressure(e.target.value.replace(/[^0-9\.]+/g, ''))}
                                                onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                                required
                                            />
                                        </div>

                                        {/* <ROIcon CheckBox /> */}
                                        <div className={defs.DefS[4] === 1 ? 'small fw-bold col-lg-2' : 'col-md-2 fs-5 invisible'}>
                                            <ROIcon
                                                icon={boolControls ? 'ROicoCheckbox' : 'ROicoBox'}
                                                size={24}
                                                className='icoSVG fw-bold'
                                                tooltip='Controls Tested'
                                                //usefill='true'
                                                //color1='#ffffff'
                                                textbefore='Controls '
                                                onClickCallback={() => setBoolControls((prev) => !prev)}
                                            />
                                        </div>
                                        {/* <div className='col-2 fs-5'>
                                            <ROIcon
                                                icon={boolSuccessful ? 'ROicoCheckbox' : 'ROicoBox'}
                                                size={24}
                                                className='icoSVG text-white fw-bold'
                                                tooltip='Service Successful'
                                                usefill='true'
                                                color1={boolSuccessful ? '#ffffff' : '#ff0000'}
                                                textbefore='Successful'
                                                onClickCallback={() => setBoolSuccessful((prev) => !prev)}
                                                style={{
                                                    animation: 'pulse 4s infinite',
                                                }}
                                            />
                                        </div> */}
                                    </div>
                                    <div className='row mt-2' /*Service User + Comments*/>
                                        <div className='col-lg-12 p-0 ps-4 pe-4 m-0 fs-6'>
                                            <input
                                                className='txtBox'
                                                value={txtComments}
                                                placeholder='Service Comments'
                                                onChange={(e) => setTxtComments(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr className='servicesThick px-4 mx-4' />
                                    <div className='row mx-4 p-0 m-0 ' /*Service Parts / Items*/>
                                        {/* New Service Parts / Items List */}
                                        {/* <div className={(i + 1) % 2 === 0 ? 'row mx-2 bg-serviceswhite' : 'row mx-2 bg-secondary'}> */}
                                        {arrServiceParts.map((part, i) => (
                                            <>
                                                <div className={(i + 1) % 2 === 0 ? 'row bg-serviceswhite p-0 m-0' : 'row bg-secondary p-0 m-0'}>
                                                    <div className='col-md-2 small text-end fw-bold p-0 pe-2 m-0  pt-1'>
                                                        {part.ServicePartCode.replace(/\\/g, '')}
                                                    </div>
                                                    <div className='col-md-4 small p-0 m-0 pt-1'>{part.ServicePartName.replace(/\\/g, '')}</div>
                                                    <div className='col-md-2 p-0 m-0'>
                                                        {' '}
                                                        {/* SERIAL NR */}
                                                        <input
                                                            className='txtBox small'
                                                            value={part.ServicePartSerialNR || ''}
                                                            placeholder='Serial Nr'
                                                            onChange={(e) =>
                                                                updateServicePart(i, {
                                                                    ServicePartSerialNR: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-md-2 p-0 m-0'>
                                                        {/* QTY */}
                                                        <div className='row p-0 m-0'>
                                                            <div className='col-md-3 ps-2 p-0 m-0'>
                                                                <input
                                                                    className='inputBox small text-center'
                                                                    value={part.ServicePartQty || ''}
                                                                    placeholder='Qty'
                                                                    onChange={(e) =>
                                                                        updateServicePart(i, {
                                                                            ServicePartQty: parseInt(e.target.value),
                                                                        })
                                                                    }
                                                                    onBlur={(e) =>
                                                                        e.target.value === null || e.target.value === ''
                                                                            ? updateServicePart(i, {
                                                                                  ServicePartQty: 1,
                                                                              })
                                                                            : true
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-md-9  px-2 m-0'>
                                                                {' '}
                                                                {/* Price */}
                                                                <input
                                                                    className='inputBox small'
                                                                    value={part.ServicePartPrice}
                                                                    placeholder='Price'
                                                                    onChange={(e) => updateServicePartPrice(i, e.target.value)}
                                                                    onBlur={(e) => updateServicePartPrice(i, e.target.value, true)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-1 small fw-bold p-0 m-0 ms-2 pt-1'>
                                                        {curSymb}
                                                        {(part.ServicePartPrice * part.ServicePartQty).toFixed(2) || 0}
                                                    </div>
                                                    <div className='col-auto p-0 m-0 ms-auto pt-1'>
                                                        <ROIcon
                                                            icon='ROicoDel'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Remove'
                                                            onClickCallback={() => removeServicePart(i)}
                                                            usefill='true'
                                                            color1='#f00'
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <div className='row'>
                                        {parts ? (
                                            <div className='col-md-12 p-0 px-5 m-0 fs-6'>
                                                <ROComboBox
                                                    dataArray={arrParts}
                                                    classname='ROdropdownFull'
                                                    placeholdertext='Search Parts to Add'
                                                    myIDCallback={addPart}
                                                    clearTextOnSelect={true}
                                                    allowCustomValue={false}
                                                />
                                            </div>
                                        ) : (
                                            'Loading Parts'
                                        )}
                                        {arrServiceParts && arrServiceParts.length > 0 ? (
                                            <>
                                                <div className='col-md-10 p-0 m-0'>&nbsp;</div>
                                                <div className='col-md-2 p-0 m-0'>
                                                    Total: {curSymb}
                                                    {serviceTotal.toFixed(2)}
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className='col-md-10 p-0 m-0'>&nbsp;</div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='col-md-12 ROmaintxt fs-5 ps-4'>
                                Maintenance
                                {!editOnly ? (
                                    <ROIcon
                                        icon='ROicoPlus'
                                        className='icoSVG ps-2'
                                        tooltip='Add Maintenance'
                                        usefill='true'
                                        color1='#ffffff'
                                        onClickCallback={() => ShowNewService(true)}
                                        style={{
                                            animation: 'pulse 4s infinite',
                                        }}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Show Location Equipment Services */}
            <div className='row p-0 m-0'>
                {services.map((serviceitem) => {
                    if (serviceitem.EquipCustLocID === equipcustlocitem.EquipCustLocID) {
                        let item = { ...serviceitem }
                        if (pType === 'PSI') {
                            item.ServiceFeedPressure = (item.ServiceFeedPressure * 14.5).toFixed(1)
                            item.ServiceTankPressure = (item.ServiceTankPressure * 14.5).toFixed(1)
                        }

                        return (
                            <ServiceItem
                                containerBackColor={containerBackColor}
                                //containerBackColor={'bg-primary'}
                                service={item}
                                serviceChangeCallback={focusAndExpandEquipmentBox}
                                equipSettings={defs.DefS}
                            />
                        )
                    }
                })}
            </div>
        </ContentBox>
    )
}

export default AllServicesItem
