import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import franaccService from './franaccService'

const initialState = {
    franacc: [],
    fransub: [],
    isFranAccError: false,
    isFranAccSuccess: false,
    isFranAccLoading: false,
    franaccmessage: '',
}

// // Create New Part
// export const createPart = createAsyncThunk(
//     'part/create',
//     async (partData, thunkAPI) => {
//         try {
//             //Send payload by thunkAPI->extraReducers(builder->register(user))
//             const token = thunkAPI.getState().auth.user.token

//             return await partsService.createPart(partData, token)
//         } catch (error) {
//             const message =
//                 (error.response &&
//                     error.response.data &&
//                     error.response.data.message) ||
//                 error.message ||
//                 error.toString()
//             //Send reject payload by thunkAPI->extraReducers(builder->register(message))
//             return thunkAPI.rejectWithValue(message)
//         }
//     }
// )

// Update Pay Pal Subscription
export const updatePayPalSub = createAsyncThunk('franacc/updatePayPalSub', async (franAccSub, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await franaccService.updatePayPalSub(franAccSub, token)
        //console.log('return newSub: ', newSub.re)
        // newSubPromise.then((retVal) => {
        //     console.log('newSubPromise retVal: ', retVal)
        // })
        // console.log('newSubPromise: ', newSubPromise)
        // //getFranPayPalSubDetails()
        // return newSubPromise
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

//Get fran paypal subscription details
export const getFranPayPalSubDetails = createAsyncThunk('paypal/getSubDetails', async (subID, thunkAPI) => {
    try {
        let userCred = ''

        //console.log('getFranPayPalSubDetails(franaccSlice) SubId:', subID)

        if (thunkAPI.getState() != null) {
            userCred = thunkAPI.getState().auth.user
        }

        if (userCred.token) {
            const returnData = await franaccService.getFranPayPalSubDetails(subID, userCred.token)
            return returnData
        }

        return thunkAPI.rejectWithValue('No user token found')
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        console.log('thunkAPI getFranSettings error: ', message, error)
        return thunkAPI.rejectWithValue(message)
    }
})

//Get fran part
export const getFranSettings = createAsyncThunk('franacc/getFranSettings', async (_, thunkAPI) => {
    try {
        let userCred = ''

        if (thunkAPI.getState() != null) {
            userCred = thunkAPI.getState().auth.user
        }

        if (userCred.token) {
            const returnData = await franaccService.getFranSettings(userCred)
            return returnData
        }

        return thunkAPI.rejectWithValue('No user token found')
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        console.log('thunkAPI getFranSettings error: ', message, error)
        return thunkAPI.rejectWithValue(message)
    }
})

// Update Pay Pal Subscription
export const updateFranSettings = createAsyncThunk('franacc/updateFranSettings', async (franAccData, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await franaccService.updateFranSettings(franAccData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Cancel sub
export const cancelSub = createAsyncThunk('franacc/cancelSub', async (id, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await franaccService.cancelSub(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

export const franaccSlice = createSlice({
    name: 'franacc',
    initialState,
    reducers: {
        resetfranacc: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            // .addCase(createPart.pending, (state) => {
            //     state.isPartsSuccess = false
            //     state.isPartsError = false
            //     state.isPartsLoading = true
            // })
            // .addCase(createPart.fulfilled, (state, action) => {
            //     state.isPartsLoading = false
            //     state.isPartsError = false
            //     state.isPartsSuccess = true
            //     if (!state.parts) {
            //         state.parts = []
            //     }
            //     state.parts.push(action.payload)
            // })
            // .addCase(createPart.rejected, (state, action) => {
            //     state.isPartsLoading = false
            //     state.isPartsError = true
            //     state.partsmessage = action.payload
            // })
            .addCase(updatePayPalSub.pending, (state) => {
                state.isFranAccSuccess = false
                state.isFranAccLoading = true
                state.isFranAccError = false
            })
            .addCase(updatePayPalSub.fulfilled, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccSuccess = true
                state.isFranAccError = false
                console.log('franaccSlice updatePayPalSub returned payload: ', action.payload)
                state.franacc = action.payload
            })
            .addCase(updatePayPalSub.rejected, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = true
                //This is what redux helps to do. Change immutable values
                state.franaccmessage = action.payload
            })
            .addCase(getFranSettings.pending, (state) => {
                state.isFranAccLoading = true
                state.isFranAccError = false
            })
            .addCase(getFranSettings.fulfilled, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = false
                state.isFranAccSuccess = true
                //This is what redux helps to do. Change immutable values
                state.franacc = action.payload
            })
            .addCase(getFranSettings.rejected, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = true
                console.log('franaccSlice getFranSettings.rejected: ', state.isFranAccError, action.payload)
                //This is what redux helps to do. Change immutable values
                state.franaccmessage = action.payload
            })

            //Update Fran Account
            .addCase(updateFranSettings.pending, (state) => {
                state.isFranAccSuccess = false
                state.isFranAccLoading = true
                state.isFranAccError = false
            })
            .addCase(updateFranSettings.fulfilled, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccSuccess = true
                state.isFranAccError = false
                console.log('franaccSlice updateFranSettings returned payload: ', action.payload)
                state.franacc = action.payload
            })
            .addCase(updateFranSettings.rejected, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = true
                //This is what redux helps to do. Change immutable values
                state.franaccmessage = action.payload
            })

            //Pay Pal Sub Settings
            //Pay Pal Sub Settings
            //Pay Pal Sub Settings
            .addCase(getFranPayPalSubDetails.pending, (state) => {
                state.isFranAccLoading = true
                state.isFranAccError = false
            })
            .addCase(getFranPayPalSubDetails.fulfilled, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = false
                state.isFranAccSuccess = true
                //This is what redux helps to do. Change immutable values
                state.fransub = action.payload ? action.payload : []
            })
            .addCase(getFranPayPalSubDetails.rejected, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = true
                console.log('franaccSlice getFranSettings.rejected: ', state.isFranAccError, action.payload)
                //This is what redux helps to do. Change immutable values
                state.franaccmessage = action.payload
            })

            .addCase(cancelSub.pending, (state) => {
                state.isFranAccSuccess = false
                state.isFranAccLoading = true
                state.isFranAccError = false
            })
            .addCase(cancelSub.fulfilled, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccSuccess = true
                state.isFranAccError = false
                state.fransub = []
                // state.parts = state.parts.filter(
                //     (part) => part.PartID !== action.payload.id
                //)
            })
            .addCase(cancelSub.rejected, (state, action) => {
                state.isFranAccLoading = false
                state.isFranAccError = true
                //This is what redux helps to do. Change immutable values
                state.franaccmessage = action.payload
            })
    },
})

export const { resetfranacc } = franaccSlice.actions
export default franaccSlice.reducer
