import React from 'react'
import { useState } from 'react'
import myUUid from '../../features/myUUid'
import { toast } from 'react-toastify'
import ROIcon from '../../components/ROIcon.jsx'
import { addROErrorLog } from '../../features/globalFunctions'
import { Link } from 'react-router-dom'
const PartsUsageItem = ({ Data, containerBackColor }) => {
    let partsPriceTotal = 0
    let qtyTotal = 0
    //console.log('Current Price Total ', partsPriceTotal)
    //console.log('Data of array customers', Data)
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    return (
        <>
            <section key={myUUid()}>
                <div className='container pb-1 '>
                    <div className={'row rounded pb-2 pt-2 ' + containerBackColor}>
                        <div className='col'>
                            {Data
                                ? Data.Equipment.map((equip) => {
                                      if (equip.PartsUsage && equip.PartsUsage.length > 0) {
                                          return (
                                              <>
                                                  <div className='row'>
                                                      <div className='col-sm-3 fw-bold'>{Data.CustName}</div>
                                                      <div className='col-sm-3 fw-bold'>
                                                          <ROIcon icon='ROicoEquip' className='icoSVG p-0 m-0' size={24} />
                                                          {equip.EquipInfo.EquipCode}
                                                      </div>
                                                      <div className='col-sm-5 fw-bold'>
                                                          <ROIcon icon='ROicoSign' className='icoSVG p-0 m-0' size={24} />
                                                          {Data.CustLocStreetAddress},{Data.CustLocSuburb},{Data.CustLocCity}
                                                          <div className='row p-0 m-0'>
                                                              <div className='col p-0 m-0'>
                                                                  <small>{equip.EquipCustLoc.EquipCustLocRoomPlace}</small>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className='col-sm-1 text-end'>
                                                          <Link to={'/customers?custID=' + equip.CustID + '&locID=' + equip.CustLocID + '&equiplocID=' + equip.EquipCustLocID}>
                                                              <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2 ' tooltip='Go To' isLink={false} />
                                                          </Link>
                                                      </div>
                                                      <div className='row'>
                                                          <div className='col'>
                                                              <hr className='services' />
                                                          </div>
                                                      </div>
                                                  </div>

                                                  {equip.PartsUsage.map((categories) => {
                                                      return categories.Parts.map((part) => {
                                                          partsPriceTotal += part.ServicePartPrice
                                                          qtyTotal += part.ServicePartQty
                                                          return (
                                                              <div className='row align-items-center ms-4 me-4' key={myUUid()}>
                                                                  <div className='col-md-12 smallPrint'>
                                                                      <div className='row'>
                                                                          <div className='col-sm-2 fw-bold'>{part.ServicePartCode}</div>
                                                                          <div className='col-sm-4'>{part.ServicePartName}</div>
                                                                          <div className='col-sm-2 text-center pe-0 '>
                                                                              <sup>Qty</sup> <span className='fw-bold'>{part.ServicePartQty}</span>
                                                                          </div>
                                                                          <div className='col-sm-2'></div>
                                                                          <div className='col-sm-2 text-end fw-bold'>
                                                                              <sup>R</sup>
                                                                              {part.ServicePartPrice.toFixed(2)}
                                                                          </div>
                                                                      </div>
                                                                      <div className='row me-2'>
                                                                          <hr className='mb-1 ms-2 ' />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      })
                                                  })}
                                              </>
                                          )
                                      }
                                  })
                                : ''}
                            <div className='flex-row align-items-center ms-4 me-4 Usage'>
                                <div className='col-md-12 smallPrint'>
                                    <div className='row '>
                                        <div className='col-sm-2'>SubTotal</div>
                                        <div className='col-sm-4 '></div>
                                        <div className='col-sm-2 text-center  pe-0'>
                                            <sup>Qty</sup> {qtyTotal}
                                        </div>
                                        <div className='col-sm-2 '></div>
                                        <div className='col-sm-2 text-end pe-4'>
                                            <sup>R</sup>
                                            {partsPriceTotal.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PartsUsageItem
