import axios from 'axios'

const API_URL = '/api/equipment/'

//Add new customer
const createEquipment = async (equipData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    equipData = { ...equipData, user: { ...user } }

    const response = await axios.post(API_URL, equipData, headersConfig)
    return response.data
}

const updateEquipment = async (equipData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    equipData = { ...equipData, user: { ...user } }

    const response = await axios.put(API_URL + equipData.equipID, equipData, headersConfig)

    return response.data
}

//Get Equipment
const getEquipment = async (userCreds) => {
    try {
        //console.log('equipService getEquipment Check1')

        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }
        //console.log('equipService getEquipment Check2')

        const response = await axios.get(API_URL, headersConfig, userCreds)

        //console.log('equipService getEquipment Check3 response: ', response)

        //console.log('equipService getEquipment res: ', response)

        return response.data
    } catch (error) {
        console.log('equipService getEquipment ERROR!!!!')
        console.log('equipService getEquipment response: ', error.response)
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback

        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }

        throw new Error('equipService(getEquipment): ' + error.response.data.message)
    }
}

//Delete Equipment
const deleteEquipment = async (equipID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + equipID, headersConfig)

    console.log('Equipment deleted ID: ', equipID)
    console.log('Equipment deleted response: ', response)

    return response.data
}

const equipService = {
    createEquipment,
    getEquipment,
    deleteEquipment,
    updateEquipment,
}

export default equipService
