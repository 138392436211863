import ROIcon from '../ROIcon'
const ROHelpParts = () => {

    return (
        <>
            <div className="row p-0 m-0">
                <div class="contianer ps-2 ">

                    <a href="#Overview" class="bs-linebreak fw-bold">Equipment Overview|</a>
                    &emsp;
                    <a href="#Add-Customer" class="bs-linebreak fw-bold">Add Equipment|</a>
                    &emsp;
                    <a href="#Search" class="bs-linebreak fw-bold">Search|</a>
                    &emsp;
                    <a href="#Product-Details" class="bs-linebreak fw-bold">Equipment Details|</a>
                    &emsp;
                    <a href="#Services" class="bs-linebreak fw-bold">Equipment List</a>
                    

                </div>

                <div class="container" id="Overview">
                <br />
                    <h2>Equipment Page Overview</h2>
                    <img src="./images/help-imges/equip.jpg" height={80} alt="img" />
                    <p> The equipment page consists of various equipment and
                        their information from Rovantage. Users are able to edit
                        and delete the information and also add nuwe equipment.


                    </p>
                </div>
                <div class="container" id="Add-Customer">
                    <h3>Add New Equipment [<ROIcon icon='ROicoAdd' size={26} className='icoSVG ' />]</h3>
                    <p>The plus icon next to the "Your Equipment" heading.
                        This icon is used when we want to add a equipment, when click
                        under the heading is where they will
                        be able to fill in needed information.</p>
                </div>
                <div class="container" id="Search">
                    <h2>Search[]</h2>
                    <p>The search box next to the plus icon. The search icon is used when we
                        want to search for specific a equipment in the list of existing equipment, you
                        can use the abbreviation or name to search for equipment</p>
                </div>
                <div class="container" id="Product-Details">
                    <h2> Equipment Details:</h2>
                    <p>
                        This area is where we fill in information about Parts, as following:<br />
                        <strong class="ps-3">1. Product Code</strong><br />
                        Product code is an abbreviation of the equipment name and is a way of uniquely identifying the equipment.
                        <div class="ps-5">
                            - [] Product Code
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">2. Product Name:</strong><br />
                        This is were the name of the equipment will be enter<br />
                        <div class="ps-5">
                            - [] Product Name
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">3. Latest Price:</strong><br />
                        The latest price give user the exact price of a equipment.<br />
                        <div class="ps-5">
                            - [] Price
                        </div>
                    </p>

                    <strong class="ps-3">4. Category:</strong>
                    The category is where user are ale to under which category the equipment fall under.<br />
                    <div class="ps-5">
                        - [] Category<br />
                    </div>

                    <p>
                        <h4>Actions:</h4>
                        <strong class="ps-3">5. Save Information:</strong><br />
                        The check icon, when click the information that was filled in will be saved.<br />
                        <div class="ps-5">
                            - [<ROIcon icon='ROicoAccept' size={20} className='icoSVG ' />] Save
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">6. Delete/Cancel:</strong><br />
                        The times icon, when click the information that was filled in will be Delete/Cancel.<br />
                        <div class="ps-5">
                            - [<ROIcon icon='ROicoAdd' size={20} className='icoSVG ' />] Delete/Cancel
                        </div>
                    </p>
                </div>
                <div class="container" id="Services">
                <h2>Equipment List[]</h2>
                    The equipment list is a collection of equipment that was previously added 
                    through the above plus icon next to the "Your Equipment" heading. When the
                    user clicks anywhere the list will expand and diplay the default service Parts
                    and the Default Setting.<p>
                    <strong class="ps-3">- Default Services Parts [ ] :</strong><br />
                        <div class="ps-5">
                            The Default Services Parts has default set of listed equipment abbreviation
                            , the full name of equipment, and a default amount of equipment starting at one.
                            User can edit or add equipment when clicking the pen icon.<br />
                            <img src="./images/help-imges/DFS&DS.jpg" height={180} alt="img" /><br />
                        </div>
                    </p>
                    <p>
                    <strong class="ps-3">- Default Settings []:</strong><br />
                        <br />
                        <div class="ps-5">
                            The Default Settings has list of services that can be used with the equipment. They
                            are selected by default but cab be change when the user click the pen icon, and also edit 
                            equipment information. The list of services are as follow tds feed, tds system, bar(feed pressure), 
                            bar(tank pressure), and Controls.<br />
                            <img src="./images/help-imges/ds.jpg" height={120} alt="img" /><br />
                        </div>
                    </p>
                    <p>
                        <h4>Actions:</h4>
                        <strong class="ps-3">Edit Information:</strong><br />
                        The pen icon, when click on it they will be able to edit the information.<br />
                        <div class="ps-5">
                            - [<ROIcon icon='ROicoEdit' size={20} className='icoSVG ' />] Edit
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">Delete:</strong><br />
                        The trashbin icon, when click the information that was filled in will be Deleted.<br />
                        <div class="ps-5">
                            - [<ROIcon icon='ROicoDel' size={20} className='icoSVG ' />] Delete
                        </div>
                    </p>
                    <p>
                        <strong class="ps-3">Greater than:</strong><br />
                        The greater icon, when click the information expands.<br />
                        <div class="ps-5">
                            - [<ROIcon icon='ROicoXUp' size={20} className='icoSVG ' />] Greater
                        </div>
                    </p>
                </div>
            </div>
        </>

    )
}

export default ROHelpParts
