import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import ROIcon from '../components/ROIcon'
import { resetCust } from '../features/cust/custSlice'
import { resetequip } from '../features/equip/equipSlice'
import { resetparts } from '../features/parts/partsSlice'
import { resetusers } from '../features/users/usersSlice'
import { resetLocations } from '../features/custloc/custlocSlice'
import { resetLocContacts } from '../features/custloccontacts/custloccontactsSlice'
import { resetequipcustloc } from '../features/equipcustloc/equipcustlocSlice'
import { resetservices } from '../features/services/servicesSlice'
import { resetfranacc } from '../features/franacc/franaccSlice'
import { useState } from 'react'

const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const [showdropdown, setShowDropDown] = useState(false)
    const onLogout = () => {
        console.log('Header LogOut Clicked')
        localStorage.removeItem('user')
        dispatch(logout())
        dispatch(reset())
        dispatch(resetCust())
        dispatch(resetLocations())
        dispatch(resetLocContacts())
        dispatch(resetequipcustloc())
        dispatch(resetservices())
        dispatch(resetequip())
        dispatch(resetparts())
        dispatch(resetusers())
        dispatch(resetfranacc())
        navigate('/')
        return
    }
    return (
        <>
            <div className='header container-fluid'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-auto'>{user && user.type >= 0 ? <ROIcon icon='ROicoLogo' usefill={true} size={256} height={75} className='img' to='/dashboard' authlvl={99} /> : <ROIcon icon='ROicoLogo' usefill={true} size={256} height={75} className='img' to='/' authlvl={99} />}</div>
                        {/* <div className='col me-auto'> */}
                        {/* <div className='row bottommenu'> */}
                        <div className='col-auto donotPrint'>
                            <nav className='navbar navbar-expand-lg py-1'>
                                {/* <button
                                                className='navbar-toggler'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#navbarNav'
                                                aria-controls='navbarNav'
                                                aria-expanded='false'
                                                aria-label='Toggle navigation'
                                            >
                                                <span className='navbar-toggler-icon'></span>
                                            </button> */}
                                {/* <div className='collapse navbar-collapse' id='navbarNav'> */}
                                <ul className='navbar-nav'>
                                    <li className='nav-item'>
                                        <ROIcon icon='ROicoStar' size={24} className='icoSVG' to='/' textafter='Home' authlvl={99} />
                                    </li>
                                    {/* <li className='nav-item'>
                                                        <ROIcon
                                                            icon='ROicoStar'
                                                            size={24}
                                                            className='icoSVG'
                                                            to='/'
                                                            textafter='Pricing'
                                                            tooltip='Coming Soon'
                                                        />
                                                    </li>
                                                    <li className='nav-item'>
                                                        <ROIcon
                                                            icon='ROicoStar'
                                                            size={24}
                                                            className='icoSVG'
                                                            to='/'
                                                            textafter='Add-ons'
                                                            tooltip='Coming Soon'
                                                        />
                                                    </li> */}

                                    <li className='nav-item'>{user ? <ROIcon icon='ROicoSupport' size={24} className='icoSVG' to='/support' textafter='Support' authlvl={99} tooltip='Support' /> : ''}</li>
                                </ul>
                                {/* </div> */}
                            </nav>
                        </div>
                        <div className='col-auto donotPrint ms-auto'>
                            <nav className='navbar navbar-expand-lg py-1'>
                                {/* <div className='collapse navbar-collapse ' id='navbarNav'> */}
                                <ul className='navbar-nav small '>
                                    {user ? (
                                        <>
                                            <li className='nav-item'>Welcome {user && user.name}</li>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoSettings' size={24} className='icoSVG' tooltip='Settings' textafter='Settings' to='/account' authlvl={0} />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoOut' size={24} className='icoSVG' authlvl={99} textafter='Logout' to='/' onClickCallback={onLogout} />
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className='nav-item mt-2 pt-0'>
                                                {/* <Link
                                                                className='nav-link mt-2 pt-0'
                                                                to='/login'
                                                            > */}
                                                <ROIcon icon='ROicoIn' size={24} className='icoSVG' textafter='Login' to='/login' authlvl={99} />
                                                {/* </Link> */}
                                            </li>
                                            <li className='nav-item mt-2 pt-0'>
                                                {/* <Link
                                                                className='nav-link mt-2 pt-0'
                                                                to='/register'
                                                            > */}
                                                <ROIcon icon='ROicoStar' size={24} className='icoSVG' textafter='Register' to='/register' authlvl={99} />
                                                {/* Register */}
                                                {/* </Link> */}
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {/* </div> */}
                            </nav>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>

            {/* <div className='header container-fluid'>
                <div className='container'>
                    <nav className='navbar navbar-expand-lg'>
                        <div className='container-fluid'>
                            <Link to='/test'>test</Link>
                            <Link to='/test1'>test1</Link>
                            <button
                                className='navbar-toggler'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#navbarNav'
                                aria-controls='navbarNav'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                            >
                                <span className='navbar-toggler-icon'></span>
                            </button>
                            <div
                                className='collapse navbar-collapse'
                                id='navbarNav'
                            >
                                <ul className='navbar-nav'>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoStar'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Home
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoStar'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Pricing
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoStar'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Add-ons
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoSupport'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Support
                                        </Link>
                                    </li>

                                    {user ? (
                                        <>
                                            <div className='subheader'>
                                                <li className='nav-item'>
                                                    <Link
                                                        className='nav-link mt-4 pt-0'
                                                        to='/login'
                                                        onClick={onLogout}
                                                    >
                                                        <ROIcon
                                                            icon='ROicoOut'
                                                            size={24}
                                                            className='icoSVG'
                                                        />
                                                        Logout
                                                    </Link>
                                                </li>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <li className='nav-item'>
                                                <Link
                                                    className='nav-link mt-4 pt-0'
                                                    to='/login'
                                                >
                                                    <ROIcon
                                                        icon='ROicoIn'
                                                        size={24}
                                                        className='icoSVG'
                                                    />
                                                    Login
                                                </Link>
                                            </li>
                                            <li className='nav-item mx-2'>
                                                <Link
                                                    className='nav-link mt-4 pt-0'
                                                    to='/register'
                                                >
                                                    <ROIcon
                                                        icon='ROicoStar'
                                                        size={24}
                                                        className='icoSVG'
                                                    />
                                                    Register
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {user ? (
                                    <p className='position-absolute top-0 end-0'>
                                        Welcome {user && user.name}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </nav>
                </div>
            </div> */}

            {/* Bottom logged in bar */}
            {user && user._id ? (
                <>
                    <div className='subHeader sticky-top donotPrint'>
                        <div className='container'>
                            <nav className='navbar navbar-expand-lg py-1 whitetxt'>
                                <div className='container-fluid'>
                                    <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                                        <span className='navbar-toggler-icon'></span>
                                    </button>
                                    <div className='collapse navbar-collapse' id='navbarNav'>
                                        <ul className='navbar-nav'>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoDash' size={24} className='icoSVG' textafter='Dashboard' authlvl={10} to='/dashboard' />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoCust' size={24} className='icoSVG' textafter=' Customers' authlvl={21} to='/customers?custID=all' />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoEquip' size={24} className='icoSVG' authlvl={21} to='/equipment' textafter='Equipment' />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoParts' size={24} className='icoSVG' authlvl={21} to='/parts' textafter='Parts' />
                                            </li>
                                            <li className='nav-item'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='dropdown'>
                                                            <a className=' dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                                                                <ROIcon icon='ROicoReport' size={24} className='icoSVG' authlvl={21} />
                                                                Report
                                                            </a>
                                                            <ul className='dropdown-menu'>
                                                                <li>
                                                                    <Link to='/reports?reportType=upcoming' className='dropdown-item text-primary'>
                                                                        Upcoming Services Report
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='dropdown-item text-primary' to='/reports?reportType=overdue'>
                                                                        Services Overdue Report
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='dropdown-item text-primary' to='/reports?reportType=allcustomers'>
                                                                        All Customers Report
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='/reports?reportType=partsusage' className='dropdown-item text-primary'>
                                                                        Parts Usage Report
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon icon='ROicoUsers' size={24} className='icoSVG' authlvl={5} to='/users' textafter='Users' />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    )
}

export default Header
