import { useState, useRef } from 'react'
import CustChatBox from '../components/CustChatBox'
import ROIcon from './ROIcon'
import { VaildateForm, addROErrorLog } from '../features/globalFunctions'
import { toast } from 'react-toastify'
import { RegExPattern } from '../features/globalVars'

const CustContactItem = ({ id, data, updateCallback, valuesCallback, deleteCallback, resetCallback, showChat = false }) => {
    if (!data) {
        data = {}
    }

    let { contactname = '', tel = '', email = '', primary = false, editing = false, chat = [] } = data

    try {
        chat = JSON.parse(chat)
    } catch (error) {
        chat = []
    }

    //console.log(contactname + 'chat after: ', chat)

    let [txtContactName, setTxtContactName] = useState(contactname)
    let [txtContactTel, setTxtContactTel] = useState(tel)
    let [txtContactEmail, setTxtContactEmail] = useState(email)
    //let [txtContactChat, setTxtContactChat] = useState(chat)

    let [txtContactNameTemp, setTxtContactNameTemp] = useState(contactname)
    let [txtContactTelTemp, setTxtContactTelTemp] = useState(tel)
    let [txtContactEmailTemp, setTxtContactEmailTemp] = useState(email)

    const [getEditState, setEditState] = useState(editing)
    const [getPrimaryState, setPrimaryState] = useState(primary)
    const [getShowChatState, setShowChatState] = useState(showChat)
    const [confirmDelete, setDeleteState] = useState(false)

    const toggleEditState = (cancel = false) => {
        //If not editing start and store temp vars
        //vaildateCustomerLocContactForm()

        if (!cancel) {
            if (!getEditState) {
                setTxtContactNameTemp(txtContactName)
                setTxtContactTelTemp(txtContactTel)
                setTxtContactEmailTemp(txtContactEmail)
                setEditState(true)
            } else {
                //Was editing save changes
                setEditState(false)
                console.log('Contact Update')
                if (updateCallback != null) {
                    doUpdate()
                }
            }
        } else {
            setTxtContactName(txtContactNameTemp)
            setTxtContactEmail(txtContactEmailTemp)
            setTxtContactTel(txtContactTelTemp)
            setEditState(false)
            if (resetCallback) {
                resetCallback()
            }
        }
    }

    const confirmDeleteCustContact = (setState) => {
        if (confirmDelete && setState) {
            console.log('Delete Cust Contact!!!!')
            deleteCallback(id)
        }
        setDeleteState(setState)
    }

    const doUpdate = (addedChat = false) => {
        console.log('Contact Update Sending')
        if (addedChat) {
            console.log('chat: ', chat)
        }
        updateCallback({
            _contid: id,
            _name: txtContactName,
            _number: txtContactTel,
            _email: txtContactEmail,
            _primary: getPrimaryState,
            _chats: chat,
            _flags: '',
            PrimaryID: getPrimaryState ? id : -1,
            AddedChat: addedChat,
        })
        //if (resetCallback != null) resetCallback()
    }

    const togglePrimary = () => {
        if (getEditState) {
            setPrimaryState((prev) => !prev)
        }
    }

    const toggleChat = (close = false) => {
        if (!close) {
            setShowChatState((prev) => !prev)
        } else {
            if (resetCallback) {
                console.log('toggleCHAT --> RESET CALLBACK DONt open Chat')
                resetCallback()
            }
            setShowChatState(false)
        }
    }

    const addChatMessage = (message) => {
        console.log('addChatMessage(CustContactItem): ', chat)
        try {
            // chat.push({
            //     message,
            //     msgdetails: Date().toString(),
            //     deleted: false,
            // })

            if (chat && chat != null) {
                chat.push({
                    message,
                    msgdetails: Date().toString(),
                    deleted: false,
                })
            } else {
                chat = [
                    {
                        message,
                        msgdetails: Date().toString(),
                        deleted: false,
                    },
                ]
            }

            //saveChatMessages(chat)
            doUpdate(true)
            setShowChatState(true)
        } catch (error) {
            console.log('Error addChatMessage(CustContactItem): ', chat, error)
            console.log('CustContactItem addChatMessage Error: ', error)

            toast.error('CustContactItem addChatMessage Error: ' + error.toString())
            addROErrorLog('CustContactItem addChatMessage Error:  ' + error.toString(), '', 'CustContactItem')
        }
    }

    const deleteChatMessage = (_chatdata) => {
        chat = _chatdata
        doUpdate(true)
        setShowChatState(true)
    }

    //#region New Customer Form Validation
    const [boolCustomerLocContactFormValidated, setCustomerLocContactFormValidated] = useState(false)

    const valCustLocContactNameRef = useRef()
    const valCustLocContactTelRef = useRef()
    const valCustLocContactEmailRef = useRef()

    const vaildateCustomerLocContactForm = (clicked = false) => {
        console.log('getEditState: ', getEditState)

        //If not in edit state the compontents doesn't exist to validate YET
        if (!getEditState) return

        if (getEditState) {
            setCustomerLocContactFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valCustLocContactNameRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location Contact valCustLocContactNameRef',
                        },
                        {
                            ref: valCustLocContactTelRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location Contact valCustLocContactTelRef',
                        },
                        {
                            ref: valCustLocContactEmailRef,
                            text: 'Please enter valid email',
                            form: 'Edit Customer Location Contact valCustLocContactEmailRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <div
            className={
                getShowChatState
                    ? getPrimaryState
                        ? 'row border borderChat primaryCont'
                        : 'row border borderChat'
                    : getPrimaryState
                    ? 'row primaryCont'
                    : 'row'
            }
        >
            <div className='col-auto px-0'>
                <span onClick={() => togglePrimary()}>
                    {getPrimaryState ? (
                        <ROIcon icon='ROicoStar' size={24} className='icoSVG' tooltip='Primary Contact' />
                    ) : (
                        <ROIcon icon='ROicoUser' size={24} className='icoSVG' />
                    )}
                </span>
            </div>
            <div className='col-auto p-2 fs-6'>
                {!getEditState ? (
                    txtContactName
                ) : (
                    <input
                        id='txtContactName'
                        value={txtContactName}
                        placeholder='Name'
                        onChange={(e) => {
                            setTxtContactName(e.target.value)
                            vaildateCustomerLocContactForm()
                        }}
                        ref={valCustLocContactNameRef}
                        title='Contact Name'
                        pattern={RegExPattern.Text}
                        required
                    />
                )}
            </div>
            <div className='col-auto  p-2  fs-6'>
                {!getEditState ? (
                    txtContactTel
                ) : (
                    <input
                        id='txtContactTel'
                        value={txtContactTel}
                        placeholder='Number'
                        onChange={(e) => {
                            setTxtContactTel(e.target.value)
                            vaildateCustomerLocContactForm()
                        }}
                        ref={valCustLocContactTelRef}
                        title='Contact Number'
                        pattern={RegExPattern.Phone}
                        required
                    />
                )}
            </div>
            <div className='col-auto me-auto p-2 fs-6'>
                {!getEditState ? (
                    txtContactEmail
                ) : (
                    <input
                        id='txtContactEmail'
                        value={txtContactEmail}
                        placeholder='Email'
                        onChange={(e) => {
                            setTxtContactEmail(e.target.value)
                            vaildateCustomerLocContactForm()
                        }}
                        type='email'
                        ref={valCustLocContactEmailRef}
                        title='Contact Email'
                        style={{
                            width: '100%',
                        }}
                    />
                )}
            </div>
            <div className='col-auto px-0 mx-0 fs-6'>
                <span>
                    {!getEditState ? (
                        <>
                            {!getShowChatState ? (
                                <>
                                    {!confirmDelete ? (
                                        <>
                                            <ROIcon
                                                icon='ROicoEdit'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Edit Contact'
                                                onClickCallback={() => {
                                                    setCustomerLocContactFormValidated(true)
                                                    toggleEditState(false)
                                                }}
                                            />
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Delete Contact'
                                                authlvl={5}
                                                onClickCallback={() => confirmDeleteCustContact(true)}
                                            />
                                            <ROIcon icon='ROicoAddchat' className='icoSVG' tooltip='Add Chat' onClickCallback={() => toggleChat()} />
                                        </>
                                    ) : (
                                        <>
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                className='icoSVG danger'
                                                tooltip='Delete Contact'
                                                authlvl={5}
                                                onClickCallback={() => confirmDeleteCustContact(true)}
                                            />
                                            <ROIcon
                                                icon='ROicoClose'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Cancel Delete'
                                                onClickCallback={() => confirmDeleteCustContact(false)}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <ROIcon
                                    icon='ROicoClose'
                                    toggleIconTrue='ROicoDel'
                                    onClickCallback={() => toggleChat(true)}
                                    className='icoSVG'
                                    tooltip='Close Chat'
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {boolCustomerLocContactFormValidated ? (
                                <ROIcon
                                    icon='ROicoAccept'
                                    size={24}
                                    className='icoSVG'
                                    tooltip='Accept Changes'
                                    onClickCallback={() => toggleEditState(false)}
                                    style={{
                                        animation: 'pulse 4s infinite',
                                    }}
                                />
                            ) : (
                                <ROIcon
                                    icon='ROicoAccept'
                                    size={24}
                                    className='icoSVG'
                                    onClickCallback={() => vaildateCustomerLocContactForm(true)}
                                    usefill='true'
                                    color1='#ff0000'
                                    style={{
                                        animation: 'pulse 4s infinite',
                                    }}
                                />
                            )}

                            <ROIcon icon='ROicoCancel' size={24} className='icoSVG' tooltip='Cancel' onClickCallback={() => toggleEditState(true)} />
                        </>
                    )}
                </span>
            </div>
            {getShowChatState ? (
                <CustChatBox
                    chatdata={chat}
                    contactName={txtContactName}
                    closeChatCallback={toggleChat}
                    deleteChatCallback={deleteChatMessage}
                    addnewChatCallback={addChatMessage}
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default CustContactItem
