import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import custlocService from './custlocService'

const initialState = {
    loccustomers: [],
    isLocError: false,
    isLocSuccess: false,
    isLocLoading: false,
    locmessage: '',
}

// Create New Customer location
export const createCustomerLoc = createAsyncThunk('custloc/create', async (custlocData, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token

        //console.log('New CustLocSlice custlocData: ', custlocData)

        const retVal = await custlocService.createCustomerLoc(custlocData, token)

        //console.log('New CustLocSlice return val: ', retVal)

        return retVal
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        console.log('custlocSlice(createCustomerLoc) Error: ', message)
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Update Customer location
export const updateCustomerLoc = createAsyncThunk('custloc/update', async (custlocData, thunkAPI) => {
    console.log('UpdateCustLoc: ', custlocData)
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await custlocService.updateCustomerLoc(custlocData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        console.log('custLocSlice Error: ', message)
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

//Get customer locations
export const getCustomersLoc = createAsyncThunk('custloc/getAll', async (custID, thunkAPI) => {
    try {
        //console.log('thunkAPI state: ', thunkAPI.getState())
        //console.log('Get Customers from SERVER!!!')
        let token = ''
        if (thunkAPI.getState() != null) {
            //console.log('Assign token from thunkAPI')
            token = thunkAPI.getState().auth.user.token
        }
        if (token) {
            //console.log('Send thunkAPI token to server')

            const retVal = await custlocService.getCustomersLoc(token)

            //console.log('Get CustLocSlice return val: ', retVal)

            return retVal
            //return await custlocService.getCustomersLoc(token)
        }
        return thunkAPI.rejectWithValue('No user token found')
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Delete customer location
export const deleteCustomerLoc = createAsyncThunk('custloc/delete', async (id, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await custlocService.deleteCustomerLoc(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Send Client Service Token
export const sendClientService = createAsyncThunk('custloc/clientservice', async (servicedata, thunkAPI) => {
    try {
        console.log('sendClientService custlocID: ', servicedata)
        const token = thunkAPI.getState().auth.user.token
        return await custlocService.sendClientService(servicedata, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

export const custlocSlice = createSlice({
    name: 'custloc',
    initialState,
    reducers: {
        resetLocations: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCustomerLoc.pending, (state) => {
                state.isLocLoading = true
                state.isLocSuccess = false
                state.isLocError = false
            })
            .addCase(createCustomerLoc.fulfilled, (state, action) => {
                state.isLocLoading = false
                state.isLocSuccess = true
                state.isLocError = false
                //This is what redux helps to do. Change immutable values
                state.loccustomers.push(action.payload)
            })
            .addCase(createCustomerLoc.rejected, (state, action) => {
                state.isLocLoading = false
                state.isLocError = true
                //This is what redux helps to do. Change immutable values
                state.locmessage = action.payload
            })
            .addCase(updateCustomerLoc.pending, (state) => {
                state.isLocLoading = true
                state.isLocSuccess = false
                state.isLocError = false
            })
            .addCase(updateCustomerLoc.fulfilled, (state, action) => {
                state.isLocLoading = false
                state.isLocSuccess = true
                state.isLocError = false
                //This is what redux helps to do. Change immutable values
                //state.loccustomers.push(action.payload)
                state.loccustomers = state.loccustomers.map((custloc) => {
                    if (custloc.CustLocID === action.payload.CustLocID) {
                        return action.payload
                    } else {
                        return custloc
                    }
                })
            })
            .addCase(updateCustomerLoc.rejected, (state, action) => {
                state.isLocLoading = false
                state.isLocError = true
                //This is what redux helps to do. Change immutable values
                state.locmessage = action.payload
            })
            .addCase(getCustomersLoc.pending, (state) => {
                state.isLocLoading = true
                state.isLocError = false
            })
            .addCase(getCustomersLoc.fulfilled, (state, action) => {
                state.isLocLoading = false
                state.isLocSuccess = true
                state.isLocError = false
                //This is what redux helps to do. Change immutable values

                if (!action.payload) {
                    // console.log(
                    //     'getCustomersContacts(custcontactSlice) action payload null!!!'
                    // )
                    //Do nothing stay with zero array
                } else {
                    state.loccustomers = action.payload
                }
            })
            .addCase(getCustomersLoc.rejected, (state, action) => {
                state.isLocLoading = false
                state.isLocError = true
                //This is what redux helps to do. Change immutable values
                state.locmessage = action.payload
            })
            .addCase(deleteCustomerLoc.pending, (state) => {
                state.isLocLoading = true
                state.isLocSuccess = false
                state.isLocError = false
            })
            .addCase(deleteCustomerLoc.fulfilled, (state, action) => {
                state.isLocLoading = false
                state.isLocSuccess = true
                state.isLocError = false
                //Need to alter state and trigger to remove from UI
                state.loccustomers = state.loccustomers.filter((custloc) => custloc.CustLocID !== action.payload.id)
            })
            .addCase(deleteCustomerLoc.rejected, (state, action) => {
                state.isLocLoading = false
                state.isLocError = true
                //This is what redux helps to do. Change immutable values
                state.locmessage = action.payload
            })

            //Send Client Token
            .addCase(sendClientService.pending, (state) => {
                state.isLocLoading = true
                state.isLocError = false
            })
            .addCase(sendClientService.fulfilled, (state, action) => {
                state.isLocLoading = false
                state.isLocSuccess = true
                state.isLocError = false
                //This is what redux helps to do. Change immutable values
                //state.equipcustloc = action.payload
                state.locmessage = action.payload
            })
            .addCase(sendClientService.rejected, (state, action) => {
                state.isLocLoading = false
                state.isLocError = true
                //This is what redux helps to do. Change immutable values
                state.locmessage = action.payload
            })
    },
})

export const { resetLocations } = custlocSlice.actions
export default custlocSlice.reducer
