import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset, forgotUserPassword } from '../features/auth/authSlice'
import { VaildateForm } from '../features/globalFunctions'
import { addROErrorLog } from '../features/globalFunctions'

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {
        //console.log('Logged in user: ', user)

        if (isError) {
            console.log('Error Logged in user: ', message)
            toast.error(message)
            addROErrorLog('Login Error:  ' + message, user ? user.name : '', 'Login')
            dispatch(reset())
            return
        }

        if (isSuccess || user) {
            console.log('Logged in user to dashboard: ', user)
            navigate('/dashboard')
        }

        dispatch(reset())

        return () => {
            dispatch(reset())
        }
    }, [user, isError]) //[user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userLoginData = {
            email,
            password,
        }

        dispatch(login(userLoginData))
    }

    //#region Edit User Form Validation
    const [boolLoginUserFormValidated, setLoginUserFormValidated] = useState(false)

    const valUserEmailRef = useRef()
    const valUserPasswordRef = useRef()

    const vaildateLoginUserForm = (clicked = false) => {
        setLoginUserFormValidated(
            VaildateForm(
                [
                    {
                        ref: valUserEmailRef,
                        text: 'Enter Valid Email',
                        form: 'Register New Fran valUserEmailRef',
                    },
                    {
                        ref: valUserPasswordRef,
                        text: 'Password required',
                        form: 'Register New Fran valUserPasswordRef',
                    },
                ],
                clicked
            )
        )
    }

    const vaildateForgotPasswordForm = (clicked = false) => {
        if (
            VaildateForm(
                [
                    {
                        ref: valUserEmailRef,
                        text: 'Enter Valid Email',
                        form: 'Register New Fran valForgotEmailRef',
                    },
                ],
                clicked
            )
        ) {
            dispatch(forgotUserPassword(email))
            toast.success('Email Sent')
            navigate('/?pagemessage=Please click the email link we emailed.')
        }
    }

    //#endregion

    return (
        <>
            <div className='loginContainer content border border-primary bg-light mt-4 p-2 rounded-2'>
            <section className='form'>
                <section className='heading mb-4'>
                    <h1 className='text-center'>Login</h1>
                </section>
                    <form onSubmit={onSubmit}>
                        <div className='form-group'>
                        <p className='p-0 m-0'>Email:</p>
                            <input
                                type='email'
                                className='form-control'
                                id='email'
                                name='email'
                                value={email}
                                placeholder='Enter your email'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateLoginUserForm()
                                }}
                                ref={valUserEmailRef}
                                title='User Email'
                                required
                            />
                        </div>
                        <div className='form-group mt-2'>
                        <p className='p-0 m-0'>Password:</p>
                            <input
                                type='password'
                                className='form-control'
                                id='password'
                                name='password'
                                value={password}
                                placeholder='Enter your password'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateLoginUserForm()
                                }}
                                ref={valUserPasswordRef}
                                title='User Password'
                                required
                            />
                        </div>
                        <div className='form-group mt-4'>
                        <a  className='forgetpassword' onClick={() => vaildateForgotPasswordForm(true)}>Forgot Password</a>
                            {boolLoginUserFormValidated ? (
                                <button type='submit' className='btn ms-4 btn-primary btn-block'>
                                    Submit
                                </button>
                            ) : (
                                <button type='button' className='btn btn-secondary ms-4 btn-block' onClick={() => vaildateLoginUserForm(true)}>
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </section>
            </div>
        </>
    )
}

export default Login
