import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
let user = []
try {
    user = JSON.parse(localStorage.getItem('user'))
} catch (error) {
    console.log('authSlice/localStorage.getItem(user) error: ', error)
}

//console.log('authSlice Start User: ', user)

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

//Test Paypal Tokens

// Register account
export const registernewaccount = createAsyncThunk('auth/registernewaccount', async (user, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await authService.registernewaccount(user, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Register user
export const newAccountEmail = createAsyncThunk('auth/newaccountemail', async (newAccData, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        return await authService.registernewaccountemail(newAccData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Send New Account Token
export const sendNewAccToken = createAsyncThunk('auth/sendnewacctoken', async (token, thunkAPI) => {
    try {
        return await authService.sendregisternewaccounttoken(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Login user
export const login = createAsyncThunk('auth/login', async (userlogindata, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->login(user))
        return await authService.login(userlogindata)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        console.log('auth/login Error: ', message)
        //Send reject payload by thunkAPI->extraReducers(builder->login(message))
        return thunkAPI.rejectWithValue(message)
    }
})

export const logout = createAsyncThunk('auth/logout', async () => {
    console.log('authSliced logout Called')
    await authService.logout()
    return true
})

// Update User FORGOT Password
export const forgotUserPassword = createAsyncThunk('auth/forgotpassword', async (email, thunkAPI) => {
    try {
        return await authService.forgotpassword(email)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Send FORGOT Password Token
export const sendForgotPasswordToken = createAsyncThunk('auth/sendforgotpasswordtoken', async (token, thunkAPI) => {
    try {
        return await authService.sendforgotpasswordtoken(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Send FORGOT Password Token
export const sendServiceToken = createAsyncThunk('auth/sendservicetoken', async (token, thunkAPI) => {
    try {
        return await authService.sendservicetoken(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registernewaccount.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(registernewaccount.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                console.log('registernewaccount authSlice action.payload: ', action.payload)
                state.user = action.payload
            })
            .addCase(registernewaccount.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(logout.fulfilled, (state) => {
                console.log('authSlice Logged OUT!!!')
                state.user = null
            })

            //Forgot password
            .addCase(forgotUserPassword.pending, (state) => {
                state.isError = false
                state.isSuccess = false
                state.isLoading = true
            })
            .addCase(forgotUserPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
            })
            .addCase(forgotUserPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                //This is what redux helps to do. Change immutable values
                console.log('Forgot password(authSlice) error!!', action.payload)
                state.message = action.payload
            })

            //Send forgot password token
            .addCase(sendForgotPasswordToken.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(sendForgotPasswordToken.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(sendForgotPasswordToken.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })

            //Send service token
            .addCase(sendServiceToken.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(sendServiceToken.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(sendServiceToken.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })

            //New Account Email
            .addCase(newAccountEmail.pending, (state) => {
                state.isError = false
                state.isSuccess = false
                state.isLoading = true
            })
            .addCase(newAccountEmail.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.message = action.payload
            })
            .addCase(newAccountEmail.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                //This is what redux helps to do. Change immutable values
                console.log('New account email(authSlice) error!!', action.payload)
                state.message = action.payload
            })

            //Send New Account token
            .addCase(sendNewAccToken.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(sendNewAccToken.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(sendNewAccToken.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
