import { useEffect, useRef, useState, useMemo } from 'react'
import ROIcon from '../../components/ROIcon.jsx'
import ROHelpBox from '../../components/ROHelpBox.jsx'
import { formatInputDate } from '../../features/formatTime.js'
import { useSearchParams, useNavigate } from 'react-router-dom'

const RepSearchBar = ({ searchCallback, searchFiltersCallback, searchFilters }) => {
    let [urlParams, setUrlParams] = useSearchParams()
    const navigate = useNavigate()

    let reportType = urlParams.get('reportType') ? urlParams.get('reportType') : ''
    //console.log('RepSearchBar searchFilters: ', searchFilters)

    //Search Parts
    let [searchString, setSearchString] = useState('')
    const [advSearch, setAdvSearch] = useState(false)

    //useState for dates
    const [fromDate, setFromDate] = useState(formatInputDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)))
    const [toDate, setToDate] = useState(formatInputDate(new Date()))

    // useRef For date time objects
    const refFromDate = useRef()
    const refToDate = useRef()

    //function to set ref from date to date values
    const todayDate = new Date()

    if (refFromDate.current && refToDate.current) {
        refFromDate.current.value = formatInputDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7))
        refToDate.current.value = formatInputDate(todayDate)
    }

    const setDates = (DateRange) => {
        if (DateRange === 'week') {
            refFromDate.current.value = formatInputDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7))
            refToDate.current.value = formatInputDate(todayDate)
        } else if (DateRange === 'month') {
            refToDate.current.value = formatInputDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0))
            refFromDate.current.value = formatInputDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1))
        } else if (DateRange === 'lastYear') {
            refToDate.current.value = formatInputDate(new Date(todayDate.getFullYear() - 1, 11, 31))
            refFromDate.current.value = formatInputDate(new Date(todayDate.getFullYear() - 1, 0))
        }
        setFromDate(refFromDate.current.value)
        setToDate(refToDate.current.value)
        handleSearchFilter('UpdateDates')
    }

    // const [toggleAllRecentServices, setAllRecentServices] = useState(false)
    // const [toggleShowAllCust, setShowAllCust] = useState(false)
    // const [toggleShowSuspended, setShowSuspended] = useState(false)
    // const [toggleShowServices, setShowServices] = useState(true)
    // const [toggleShowRepairs, setShowRepairs] = useState(false)
    // const [toggleMoreFilters, setMoreFilters] = useState(false)
    // const [toggleShowUnsucessfull, setShowUnsucessfull] = useState(false)
    // const [toggleShowUpcomingServices, setShowUpcomingServices] = useState(reportType === 'upcoming' ? true : false)

    //Deconstruct searchFilters
    let toggleAllRecentServices = searchFilters.toggleAllRecentServices,
        toggleShowAllCust = searchFilters.toggleShowAllCust,
        toggleShowSuspended = searchFilters.toggleShowSuspended,
        toggleShowServices = searchFilters.toggleShowServices,
        toggleShowRepairs = searchFilters.toggleShowRepairs,
        toggleMoreFilters = searchFilters.toggleMoreFilters,
        toggleShowUnsucessfull = searchFilters.toggleShowUnsucessfull,
        toggleShowUpcomingServices = searchFilters.toggleShowUpcomingServices,
        togglePartsUsageByCategory = searchFilters.togglePartsUsageByCategory,
        togglePartsUsageByCustomer = searchFilters.togglePartsUsageByCustomer

    useMemo(() => {
        let filters = {
            toggleAllRecentServices: toggleAllRecentServices,
            toggleShowAllCust: toggleShowAllCust,
            toggleShowSuspended: toggleShowSuspended,
            toggleShowServices: toggleShowServices,
            toggleShowRepairs: toggleShowRepairs,
            toggleMoreFilters: toggleMoreFilters,
            toggleShowUnsucessfull: toggleShowUnsucessfull,
            toggleShowUpcomingServices: toggleShowUpcomingServices,
            togglePartsUsageByCategory: togglePartsUsageByCategory,
            togglePartsUsageByCustomer: togglePartsUsageByCustomer,
            advSearch: advSearch,
            fromDate: fromDate,
            tillDate: toDate,
        }

        //console.log('Update Filters RepSearchBar: ', filters)

        searchFiltersCallback(filters)
    }, [toggleAllRecentServices, toggleShowAllCust, toggleShowSuspended, toggleShowServices, toggleShowRepairs, toggleMoreFilters, toggleShowUnsucessfull, toggleShowUpcomingServices, advSearch, togglePartsUsageByCategory, togglePartsUsageByCustomer]) //navigate

    const handleSearchFilter = (filtername) => {
        console.log('Search String: ', filtername, ' Filters: ', searchFilters)
        switch (filtername) {
            case 'advSearch':
                setAdvSearch((prev) => !prev)
                break
            case 'toggleMoreFilters':
                toggleMoreFilters = !toggleMoreFilters
                break
            case 'toggleShowServices':
                toggleShowServices = !toggleShowServices
                break
            case 'toggleShowRepairs':
                toggleShowRepairs = !toggleShowRepairs
                break
            case 'toggleShowUpcomingServices':
                toggleShowUpcomingServices = !toggleShowUpcomingServices
                break
            case 'toggleShowAllCust':
                toggleShowAllCust = !toggleShowAllCust
                break
            case 'toggleAllRecentServices':
                toggleAllRecentServices = !toggleAllRecentServices
                break
            case 'toggleShowUnsucessfull':
                toggleShowUnsucessfull = !toggleShowUnsucessfull
                break
            case 'toggleShowSuspended':
                toggleShowSuspended = !toggleShowSuspended
                break
            case 'togglePartsUsageByCategory':
                togglePartsUsageByCategory = !togglePartsUsageByCategory
                togglePartsUsageByCustomer = !togglePartsUsageByCategory
                break
            case 'togglePartsUsageByCustomer':
                togglePartsUsageByCustomer = !togglePartsUsageByCustomer
                togglePartsUsageByCategory = !togglePartsUsageByCustomer
                break

            default:
                break
        }

        if (refFromDate.current && refToDate.current) {
            setFromDate(refFromDate.current.value)
            setToDate(refToDate.current.value)
        }

        let filters = {
            toggleAllRecentServices: toggleAllRecentServices,
            toggleShowAllCust: toggleShowAllCust,
            toggleShowSuspended: toggleShowSuspended,
            toggleShowServices: toggleShowServices,
            toggleShowRepairs: toggleShowRepairs,
            toggleMoreFilters: toggleMoreFilters,
            toggleShowUnsucessfull: toggleShowUnsucessfull,
            toggleShowUpcomingServices: toggleShowUpcomingServices,
            togglePartsUsageByCategory: togglePartsUsageByCategory,
            togglePartsUsageByCustomer: togglePartsUsageByCustomer,
            fromDate: refFromDate.current ? refFromDate.current.value : Date.now(),
            tillDate: refToDate.current ? refToDate.current.value : Date.now(),
            advSearch: advSearch,
        }

        //console.log('handleSearchFilter Update Filters RepSearchBar: ', filters)

        searchFiltersCallback(filters)
    }

    // useMemo(() => {
    //     if (refFromDate.current && refToDate.current) {
    //         setDates('week')
    //     }
    // }, [refFromDate, refToDate])

    return (
        <>
            <div className='bg-welcome'>
                <section className='pageHead container clearfix'>
                    <div className='pageFunctions row  py-1'>
                        {/* <h6>Welcome {user && user.name}</h6> */}
                        <div className='row'>
                            <div className='col-md-2 text-center pageHead'>{reportType === 'upcoming' ? <h5>Upcoming Report</h5> : reportType === 'partsusage' ? <h5>Part Usage Report</h5> :reportType === 'allcustomers' ? <h5>All Customer Report</h5>: <h5>Services Overdue Report</h5> }</div>
                            <div className='col-md-8 '>
                                <div className='interfSearch  donotPrint'>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-6 pe-0'>
                                            <input
                                                className='rounded-start'
                                                id='search'
                                                placeholder='Enter your search here'
                                                value={searchString}
                                                //onChange={(e) => SearchCustomers(e.target.value)}
                                                //onChange={(e) => setSearchString(e.target.value)}
                                                //onChange={(e) => searchCallback(e.target.value)}
                                                onChange={(e) => {
                                                    console.log('txtSearch Search String: ', e.target.value)
                                                    searchCallback(e.target.value)
                                                    setSearchString(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='col-auto ps-1 pt-1 rounded-end'>
                                            <ROIcon icon={!advSearch ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} textbefore='Exact Match' onClickCallback={() => handleSearchFilter('advSearch')} />
                                            {/* <ROIcon icon={!advSearch ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} textbefore='Exact Match' onClickCallback={() => (advSearch = !advSearch)} /> */}
                                        </div>
                                        <div className='col-auto p-0'>
                                            <ROIcon icon={toggleMoreFilters ? 'ROicoTup' : 'ROicoTdown'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleMoreFilters')} tooltip='More Filters' />
                                        </div>
                                    </div>

                                    {/* <label htmlFor='search'>Search</label> */}
                                    {/*searchResults && searchResults.length > 0 ? '' : <p className='danger'>Search doesn't match any customer</p>*/}
                                </div>
                            </div>
                            <div className='col-md-2 donotPrint'>
                                <div className='float-end'>
                                    <ROHelpBox helpTopic={'reports'} />
                                </div>
                            </div>
                            {/* // PartUsage Filter  displays when url===partsusage */}
                            {reportType === 'partsusage' ? (
                                <>
                                    <div className='row donotPrint'>
                                        {toggleMoreFilters ? (
                                            <>
                                                <div className='col-md-12'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-auto pe-0'>
                                                            <button type='button' className='Last_Year' onClick={() => setDates('lastYear')}>
                                                                Previous Year
                                                            </button>
                                                        </div>
                                                        <div className='col-auto pe-0'>
                                                            <button type='button' className='Last_Month' onClick={() => setDates('month')}>
                                                                Previous Month
                                                            </button>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <button type='button' className='Last7_Days' onClick={() => setDates('week')}>
                                                                Last 7 Days
                                                            </button>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='row align-items-center'>
                                                                <div className='col-auto pe-0 '>
                                                                    <div className='text-primary '>From:</div>
                                                                </div>
                                                                <div className='col-auto  ps-0'>
                                                                    <input className='txtBox p-0 fw-bold' type='date' value={fromDate} onChange={() => handleSearchFilter('UpdateDates')} ref={refFromDate} title='From' required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='row align-items-center'>
                                                                <div className='col-auto pe-0'>
                                                                    <div className='text-primary'>Till:</div>
                                                                </div>
                                                                <div className='col-auto ps-0'>
                                                                    <input className='txtBox p-0 fw-bold' type='date' value={toDate} onChange={() => handleSearchFilter('UpdateDates')} ref={refToDate} title='From' required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-auto'>
                                                <button onClick={() => handleSearchFilter('UpdateDates')} type='button' className='Update'>
                                                    Update
                                                </button>
                                            </div> */}
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-auto pt-2 pe-0'>
                                                            <h6 className='text-primary '>Report Filters</h6>
                                                        </div>
                                                        <div className='col'>
                                                            <hr className='services' />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-2'>
                                                            Group By Category
                                                            <ROIcon
                                                                icon={togglePartsUsageByCategory ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                className='icoSVG p-0 m-0'
                                                                size={32}
                                                                authlvl={15}
                                                                onClickCallback={() => handleSearchFilter('togglePartsUsageByCategory')}
                                                                // onClickCallback={() => {
                                                                //     setShowServices((prev) => !prev)
                                                                // }}
                                                            />
                                                        </div>
                                                        <div className='col-md-2'>
                                                            {' '}
                                                            Group By Customer
                                                            <ROIcon
                                                                icon={togglePartsUsageByCustomer ? 'ROicoCheckbox' : 'ROicoBox'}
                                                                className='icoSVG p-0 m-0'
                                                                size={32}
                                                                authlvl={15}
                                                                onClickCallback={() => handleSearchFilter('togglePartsUsageByCustomer')}
                                                                // onClickCallback={() => {
                                                                //     setShowRepairs((prev) => !prev)
                                                                // }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </>
                            ) : (
                                //Default Filter  displays
                                <>
                                    <div className='row donotPrint'>
                                        {toggleMoreFilters ? (
                                            <>
                                                <div className='row'>
                                                    <div className='col-auto pt-2'>
                                                        <h6 className='text-primary '>Report Filters </h6>
                                                    </div>
                                                    <div className='col p-0'>
                                                        <hr className='services' />
                                                    </div>
                                                </div>
                                                <div className='row donotPrint'>
                                                    {/*First Row Report CheckBoxes*/}
                                                    <div className='col-md-3'>
                                                        <ROIcon icon={toggleShowServices ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleShowServices')} textafter='Services' />
                                                    </div>
                                                    <div className='col-md-2'>
                                                        {' '}
                                                        <ROIcon icon={toggleShowRepairs ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleShowRepairs')} textafter='Repairs' />
                                                    </div>

                                                    {/* <div className='col-auto'>
                                {' '}
                                <ROIcon icon={toggleMoreFilters ? 'ROicoXUp' : 'ROicoXDown'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => setMoreFilters((prev) => !prev)} tooltip='More Filters' />
                            </div> */}
                                                </div>
                                                <div className='row'>
                                                    {/*Second Row Report CheckBoxes*/}
                                                    {/* <div className='col-md-2'>
                                                        <ROIcon icon={toggleShowAllCust ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleShowAllCust')} textafter='All Customers' />
                                                    </div> */}
                                                    <div className='col-md-3'>
                                                        {' '}
                                                        <ROIcon icon={toggleAllRecentServices ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleAllRecentServices')} textafter='Older Maintenance' />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        {' '}
                                                        <ROIcon icon={toggleShowUnsucessfull ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleShowUnsucessfull')} textafter='Unsuccessful Maintenance' />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        {' '}
                                                        <ROIcon icon={toggleShowSuspended ? 'ROicoCheckbox' : 'ROicoBox'} className='icoSVG p-0 m-0' size={32} authlvl={15} onClickCallback={() => handleSearchFilter('toggleShowSuspended')} textafter='Suspended Customers' />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RepSearchBar
