import React from 'react'
import { useState, useMemo, useEffect } from 'react'
import ROIcon from '../../components/ROIcon'
import RepServiceOverdueItem from './RepServiceOverdueItem'

import { useSelector } from 'react-redux'
import myUUid from '../../features/myUUid'
import { toast } from 'react-toastify'

import { addROErrorLog, getServiceInterval, daysDiff, getFranSettingsJSON, getAnySettingsJSON, getServicesReportArray, EmailTokensReplace, GetEmailTokens, FormatHtmlEmail } from '../../features/globalFunctions'

import { ServiceEmailMsg, RegExPattern } from '../../features/globalVars'
import { formatLongDate, formatShortDate } from '../../features/formatTime'
import { useSearchParams } from 'react-router-dom'
import PartsUsageItemCategory from './PartsUsageItemCategory'
import PartsUsageItemCustomer from './PartsUsageItemCustomer'

const RepPartsUsage = ({ searchString, searchFilters }) => {
    //console.log('*** DRAW RepPartsUsage ***', searchFilters)

    let [urlParams, setUrlParams] = useSearchParams()

    let reportType = urlParams.get('reportType') ? urlParams.get('reportType') : ''
    //Search Parts
    const [searchResults, setSearchResults] = useState([])
    //let [searchString, setSearchString] = useState(_searchString)
    const [searchFound, setSearchFound] = useState(false)

    //Get State user from redux
    const { user } = useSelector((state) => state.auth)
    //Get State users / staff from redux
    const { franusers } = useSelector((state) => state.franusers)
    //Get State customers from redux
    const { customers } = useSelector((state) => state.customers)
    //Get State customer locations from redux
    const { loccustomers } = useSelector((state) => state.loccustomers)
    //Get State customer location contacts from redux
    const { custloccontacts } = useSelector((state) => state.loccustcontacts)
    //Get State equipment from redux
    const { equipment } = useSelector((state) => state.equipment)
    //Get State parts from redux
    const { parts } = useSelector((state) => state.parts)
    //Get State location equipment from redux
    const { equipcustloc } = useSelector((state) => state.equipcustloc)
    //#region All Services
    const { services } = useSelector((state) => state.service)

    //Get State fran acc settings from redux
    const { franacc } = useSelector((state) => state.franacc)

    //Deconstruct Search Filters
    let { toggleAllRecentServices, toggleShowAllCust, toggleShowSuspended, toggleShowServices, toggleShowRepairs, toggleMoreFilters, toggleShowUnsucessfull, toggleShowUpcomingServices, advSearch, togglePartsUsageByCategory, togglePartsUsageByCustomer } = searchFilters

    //console.log('searchFilters:', searchFilters)

    let arrServices //{EquipCustLocID: 0, CustID: 0, CustLocID: 0, equipServices: []}

    //const [updateReportArray, setUpdateReportArray] = useState(false)
    const [emailSendIDs, setEmailSendIDs] = useState([-1])

    try {
        //Sort Services....
        //First Group by EquipCustLocID
        //Then check if there are more than 3 services remove the older services (shift)
        // services &&
        // services.length > 0 &&

        let sortedArray = []
        let arrCategories = []
        let arrCustomers = []

        useEffect(() => {
            //console.log('useEffect RepPartsUsage')
        }, [sortedArray, arrCategories, arrCustomers])

        //console.log('REP SERVICES OVERDUE searchString: ', searchString)

        if (franacc && Object.values(franacc).length > 0 && loccustomers && loccustomers.length > 0 && custloccontacts && equipcustloc && equipcustloc.length > 0 && equipment && equipment.length > 0) {
            arrServices = getServicesReportArray(services, franacc, loccustomers, equipcustloc, custloccontacts, equipment, toggleShowUnsucessfull, toggleShowServices, toggleShowRepairs, toggleAllRecentServices, toggleShowSuspended, toggleShowAllCust, toggleShowUpcomingServices)

            //loop through the array and get all the categories

            //console.log('arrServices: ', arrServices)
            arrServices.map((item, i) => {
                const services = item.equipServices //? JSON.parse(item.equipServices.servicejson) : []

                arrCustomers.push({ ...item, PartsUsage: [] })
                //console.log('arrCustomers item: ', arrCustomers)
                services.map((service, s) => {
                    const serviceParts = service.servicejson ? JSON.parse(service.servicejson) : []

                    serviceParts.map((part, p) => {
                        arrCustomers[i].PartsUsage.push({
                            CatName: part.ServicePartCategory,
                            CatQty: 0,
                            CatTotal: 0,
                            Parts: [{ ...part, ServiceDate: service.ServiceDate, ServicePartPrice: part.ServicePartPrice * part.ServicePartQty }], //* part.ServicePartQty }],
                        })
                        // //Create Parts section for the by Customer Location Report
                        // let tempItem = arrCustomers[i].PartsUsage.find((e) => e.CatName === part.ServicePartCategory)

                        // if (tempItem) {
                        //     //console.log('tempItemNOTNULL: ', tempItem)
                        //     tempItem.CatQty += part.ServicePartQty
                        //     tempItem.CatTotal += part.ServicePartPrice
                        //     //console.log(`[${i}][${s}][${p}tempItem: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice)
                        // } else {
                        //     arrCustomers[i].PartsUsage.push({
                        //         CatName: part.ServicePartCategory,
                        //         CatQty: part.ServicePartQty,
                        //         CatTotal: part.ServicePartPrice,
                        //         Parts: [{ ...part, ServiceDate: service.ServiceDate, ServicePartPrice: part.ServicePartPrice * part.ServicePartQty }],
                        //     })
                        //     console.log(`[${i}][${s}][${p}tempItem: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice, service.ServiceDate)
                        // }

                        //Create arrCategories for the by Categories Report
                        //If the category exists add the qty and total
                        let tempItemCat = arrCategories.find((e) => e.CatName === part.ServicePartCategory)

                        if (tempItemCat) {
                            //console.log(tempItemCat.CatName, ':', tempItemCat.CatQty, 'part: ', part)
                            //tempItemCat.CatQty += part.ServicePartQty
                            //tempItemCat.CatTotal += part.ServicePartPrice
                            //tempItemCat.Parts.push({ Test: 'wtf2' })
                            tempItemCat.Parts.push({ ...part, ServiceDate: service.ServiceDate, ServicePartPrice: part.ServicePartPrice * part.ServicePartQty })
                        } else {
                            arrCategories.push({
                                CatName: part.ServicePartCategory,
                                CatQty: 0,
                                CatTotal: 0,
                                Parts: [{ ...part, ServiceDate: service.ServiceDate, ServicePartPrice: part.ServicePartPrice * part.ServicePartQty }],
                            })
                            //console.log(`[${i}][${s}][${p}] pushItem: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice, ' TOTAL: ', part.ServicePartPrice)
                        }
                    })
                })

                //console.log('services: ', services)
            })

            // console.log('arrCustomers: ', arrCustomers)
            // console.log('arrCategories: ', arrCategories)

            //Filter the array by die searchWords
            //Filter the array by die searchWords
            //Filter the array by die searchWords

            //Categories Search Functionality
            //Categories Search Functionality
            //Categories Search Functionality
            let arrCatSearch = []
            if (arrCategories && togglePartsUsageByCategory) {
                // console.log('MAIN REP PARTS USAGE LOOP togglePartsUsageByCategory: ', togglePartsUsageByCategory)
                arrCategories.map((item) => {
                    let found = false

                    if (searchString !== '') {
                        const _searchstr = searchString.toLowerCase()
                        let searchWords = _searchstr.trim().split(' ')

                        if (!advSearch) {
                            searchWords = [_searchstr]
                        }

                        console.log('Search Words: ', searchWords)
                        //console.log('Item: ', item)

                        searchWords.forEach((word) => {
                            //console.log('cont.CustLocContactNumber :', String(cont.CustLocContactNumber.replaceAll(' ', '')).toLowerCase())
                            if (String(item.CatCode).toLowerCase().includes(word) || String(item.CatName).toLowerCase().includes(word)) {
                                //console.log('Found Item: ', item)
                                found = true
                            }

                            let searchParts = item.Parts.filter((part) => {
                                if (String(part.ServicePartCode).toLowerCase().includes(word) || String(part.ServicePartName).toLowerCase().includes(word)) {
                                    found = true
                                    return part
                                }
                                console.log('part not found: ', part)
                            })

                            //console.log('searchParts: ', searchParts)

                            if (searchParts && searchParts.length > 0) {
                                item.Parts = searchParts
                                console.log('arrCategories item.Parts: ', arrCategories)
                            }
                        })
                    }

                    if (searchString !== '' && !found) {
                        return
                    }

                    let tempItem = arrCatSearch.find((e) => e.CatName === item.CatName)

                    if (!tempItem) {
                        arrCatSearch.push(item)
                    }
                })

                arrCatSearch.map((item) => {
                    item.Parts = item.Parts.filter((part) => {
                        //console.log(part.ServiceDate, ' part.ServiceDate: ', new Date(part.ServiceDate), ' fromDate: ', new Date(searchFilters.fromDate), ' tillDate: ', new Date(searchFilters.tillDate), new Date(part.ServiceDate) > new Date(searchFilters.fromDate) && new Date(part.ServiceDate) < new Date(searchFilters.tillDate), part)
                        if (new Date(part.ServiceDate) >= new Date(searchFilters.fromDate) && new Date(part.ServiceDate) <= new Date(searchFilters.tillDate)) {
                            return part
                        }
                    })
                })

                //Group all sorted and filtered parts by category and name
                //Also Add up total price and qty
                let arrGrouped = []
                arrCatSearch.map((item, i) => {
                    arrGrouped.push({ ...item, Parts: [] })
                    item.Parts.map((part) => {
                        // let tempPart = tempItemCat.Parts.find((e) => e.ServicePartName === part.ServicePartName)
                        // //console.log(`[${i}][${s}][${p}] tempPart: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice, ' TOTAL: ', tempItemCat.CatTotal, tempItem)
                        // if (tempPart) {
                        //     tempPart.ServicePartQty += part.ServicePartQty
                        //     tempPart.ServicePartPrice += part.ServicePartPrice * part.ServicePartQty
                        // } else {
                        //     console.log('part: ', part, 'into: ', tempItemCat)
                        //     tempItemCat.Parts.push({ ...part, ServiceDate: service.ServiceDate, ServicePartPrice: part.ServicePartPrice * part.ServicePartQty })
                        //     // console.log(`[${i}][${s}][${p}] Parts.push: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice, ' TOTAL: ', tempItemCat.CatTotal)
                        //     //tempItemCat.Parts.push({ Test: 'wtf' })
                        // }

                        let tempPart = arrGrouped[i].Parts.find((e) => e.ServicePartName === part.ServicePartName)
                        // //console.log(`[${i}][${s}][${p}] tempPart: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice, ' TOTAL: ', tempItemCat.CatTotal, tempItem)
                        if (tempPart) {
                            arrGrouped[i].CatQty += part.ServicePartQty
                            arrGrouped[i].CatTotal += part.ServicePartPrice
                            tempPart.ServicePartQty += part.ServicePartQty
                            tempPart.ServicePartPrice += part.ServicePartPrice
                        } else {
                            //console.log('part: ', part, 'into: ', item)
                            arrGrouped[i].Parts.push({ ...part, ServicePartPrice: part.ServicePartPrice })
                            arrGrouped[i].CatQty += part.ServicePartQty
                            arrGrouped[i].CatTotal += part.ServicePartPrice
                            // console.log(`[${i}][${s}][${p}] Parts.push: `, part.ServicePartCode, ' ', part.ServicePartQty, ' ', part.ServicePartPrice, ' TOTAL: ', tempItemCat.CatTotal)
                            //item.Parts.push({ Test: 'wtf' })
                        }
                    })
                })

                arrCatSearch = [...arrGrouped]

                //Remove empty categories
                arrCatSearch = arrCatSearch.filter((item) => {
                    if (item.Parts.length > 0) {
                        return item
                    }
                })

                sortedArray = arrCatSearch.sort((a, b) => {
                    return a.CatName === b.CatName ? 0 : a.CatName < b.CatName ? -1 : 1
                })
                //Customers
            } else if (arrCustomers && togglePartsUsageByCustomer) {
                //console.log('MAIN REP PARTS USAGE LOOP togglePartsUsageByCustomer: ', togglePartsUsageByCustomer)
                //sortedArray = [...arrCustomers]

                //console.log('arrCustomers: ', arrCustomers)

                let arrCustomersByCustLocID = []
                arrCustomers.map((item) => {
                    //Add IF for filtering report
                    let found = false

                    if (searchString !== '') {
                        const _searchstr = searchString.toLowerCase()
                        let searchWords = _searchstr.trim().split(' ')

                        if (!advSearch) {
                            searchWords = [_searchstr]
                        }

                        //console.log('Search Words: ', searchWords)
                        //console.log('Item: ', item)

                        searchWords.forEach((word) => {
                            //console.log('cont.CustLocContactNumber :', String(cont.CustLocContactNumber.replaceAll(' ', '')).toLowerCase())
                            if (
                                String(item.CustPriContactName).toLowerCase().includes(word) ||
                                String(item.CustPriContactNumber).toLowerCase().includes(word) ||
                                String(item.CustPriContactNumber.replaceAll(' ', '')).toLowerCase().includes(word) ||
                                String(item.CustPriContactEmail).toLowerCase().includes(word) ||
                                String(item.CustName).toLowerCase().includes(word) ||
                                String(item.CustLocName).toLowerCase().includes(word) ||
                                String(item.CustLocStreetAddress).toLowerCase().includes(word) ||
                                String(item.CustLocSuburb).toLowerCase().includes(word) ||
                                String(item.CustLocCity).toLowerCase().includes(word) ||
                                String(item.CustLocPostalCode).toLowerCase().includes(word) ||
                                String(item.CustLocCountry).toLowerCase().includes(word) ||
                                String(item.CustLocNotes).toLowerCase().includes(word) ||
                                String(item.EquipInfo.EquipCode).toLowerCase().includes(word) ||
                                String(item.EquipInfo.EquipName).toLowerCase().includes(word) ||
                                String(item.EquipCustLoc.EquipCustLocSerialNR).toLowerCase().includes(word) ||
                                String(item.EquipCustLoc.EquipCustLocNotes).toLowerCase().includes(word)
                            ) {
                                //console.log('Found Item: ', item)
                                found = true
                            }
                            // let searchParts = []
                            // item.PartsUsage.forEach((category) => {
                            //     category.Parts.map((part) => {
                            //         if (String(part.ServicePartCode).toLowerCase().includes(word) || String(part.ServicePartName).toLowerCase().includes(word)) {
                            //             found = true
                            //             searchParts.push(part)
                            //         }
                            //     })
                            // })
                            // item.PartsUsage.map((category) => {
                            //     category.Parts.filter((part) => {
                            //         if (String(part.ServicePartCode).toLowerCase().includes(word) || String(part.ServicePartName).toLowerCase().includes(word)) {
                            //             found = true
                            //             return part
                            //         }
                            //     })
                            // })

                            /*
                            let searchParts = item.Parts.filter((part) => {
                                if (String(part.ServicePartCode).toLowerCase().includes(word) || String(part.ServicePartName).toLowerCase().includes(word)) {
                                    found = true
                                    return part
                                }
                                console.log('part not found: ', part)
                            })
*/

                            //filter arrCustomers.PartsUsage(categories).Parts
                            return item.PartsUsage.filter((category) => {
                                category.Parts = category.Parts.filter((part) => {
                                    if (String(part.ServicePartCode).toLowerCase().includes(word) || String(part.ServicePartName).toLowerCase().includes(word)) {
                                        found = true
                                        return part
                                    }
                                })
                            })
                        })
                    }

                    // if (searchString !== '' && !found) {
                    //     return
                    // }

                    let tempItem = arrCustomersByCustLocID.find((e) => e.CustLocID === item.CustLocID)

                    if (tempItem) {
                        tempItem.Equipment.push(item)
                    } else {
                        if (item.PartsUsage && item.PartsUsage.length > 0) {
                            arrCustomersByCustLocID.push({
                                CustLocID: item.CustLocID,
                                EquipCustLocID: item.EquipCustLocID,
                                CustID: item.CustID,
                                EquipID: item.EquipID,
                                ServiceType: item.ServiceType,
                                CustName: item.CustName,
                                CustLocName: item.CustLocName,
                                CustLocStreetAddress: item.CustLocStreetAddress,
                                CustLocSuburb: item.CustLocSuburb,
                                CustLocCity: item.CustLocCity,
                                CustLocPostalCode: item.CustLocPostalCode,
                                CustLocCountry: item.CustLocCountry,
                                CustLocNotes: item.CustLocNotes,
                                CustLocFlags: item.CustLocFlags,
                                CustSuspended: item.CustSuspended,
                                CustPriContactName: item.CustPriContactName,
                                CustPriContactEmail: item.CustPriContactEmail,
                                CustPriContactNumber: item.CustPriContactNumber,
                                LastServiceRecorded: item.LastServiceRecorded,
                                ShowEmail: getAnySettingsJSON(franacc.FranAccFlags, 'Email') === null || getAnySettingsJSON(franacc.FranAccFlags, 'Email') === 'null' || getAnySettingsJSON(franacc.FranAccFlags, 'Email') === '' ? true : false,
                                Contacts: item.Contacts,
                                EquipCustLoc: item.EquipCustLoc,
                                Equipment: [item],
                            })
                        }
                    }
                })

                console.log('arrCustomersByCustLocID: ', arrCustomersByCustLocID)

                arrCustomersByCustLocID.map((item, i) => {
                    item.Equipment.map((equip, e) => {
                        equip.PartsUsage.map((category, c) => {
                            category.Parts = category.Parts.filter((part) => {
                                //console.log('part.ServiceDate: ', new Date(part.ServiceDate), ' fromDate: ', new Date(searchFilters.fromDate), ' tillDate: ', new Date(searchFilters.tillDate), new Date(part.ServiceDate) >= new Date(searchFilters.fromDate) && new Date(part.ServiceDate) <= new Date(searchFilters.tillDate), part)

                                //if (new Date(service.ServiceDate) > new Date(todayDate.getFullYear(), todayDate.getMonth(), 1) && new Date(service.ServiceDate) <= new Date()) {

                                if (new Date(part.ServiceDate) >= new Date(searchFilters.fromDate) && new Date(part.ServiceDate) <= new Date(searchFilters.tillDate)) {
                                    //console.log('arrCustomersByCustLocID: ', arrCustomersByCustLocID[i])
                                    //if (arrCustomersByCustLocID[i].PartUsage && arrCustomersByCustLocID[i].PartUsage.length > 0) {
                                    //console.log('arrCustomersByCustLocID[i].PartUsage: ', arrCustomersByCustLocID[i].PartUsage)
                                    arrCustomersByCustLocID[i].Equipment[e].PartsUsage[c].CatQty += part.ServicePartQty
                                    arrCustomersByCustLocID[i].Equipment[e].PartsUsage[c].CatTotal += part.ServicePartPrice
                                    //}

                                    return part
                                }
                            })

                            // let search = category.Parts.filter((part) => {
                            //     //console.log('part.ServiceDate: ', new Date(part.ServiceDate), ' todayDate: ', new Date(todayDate))
                            //     if (new Date(part.ServiceDate) > new Date(searchFilters.fromDate) && new Date(part.ServiceDate) < new Date(searchFilters.tillDate)) {
                            //         return part
                            //     }
                            // })
                            /*
                            arrCatSearch.map((item) => {
                                item.Parts = item.Parts.filter((part) => {
                                    //console.log('part.ServiceDate: ', new Date(part.ServiceDate), ' todayDate: ', new Date(todayDate))
                                    if (new Date(part.ServiceDate) > new Date(searchFilters.fromDate) && new Date(part.ServiceDate) < new Date(searchFilters.tillDate)) {
                                        return part
                                    }
                                })
                            })
    */
                            // if (search && search.length > 0) {
                            //     return search
                            // }
                        })
                    })
                })

                //console.log('MAIN REP PARTS USAGE LOOP arrServices: ', arrCustomersByCustLocID)
                // arrCustomersByCustLocID.filter((item) => {
                //     if (item.Parts.length > 0) {
                //         return item
                //     }
                // })

                // sortedArray = arrCatSearch.sort((a, b) => {
                //     return a.CatName === b.CatName ? 0 : a.CatName < b.CatName ? -1 : 1
                // })

                //Filter Customers with no parts
                arrCustomersByCustLocID = arrCustomersByCustLocID.filter((item) => {
                    let partsFound = false
                    item.Equipment.forEach((equip) => {
                        equip.PartsUsage.forEach((category) => {
                            if (category.Parts.length > 0) {
                                //return item
                                //console.log('partsFound item: ', item)
                                partsFound = true
                            }
                        })
                    })
                    return partsFound
                })

                //Filter Customers locations with no parts
                arrCustomersByCustLocID.map((item, i) => {
                    let partsFound = false
                    // item.Equipment.forEach((equip) => {
                    //     equip.PartsUsage.forEach((category) => {
                    //         if (category.Parts.length > 0) {
                    //             //return item
                    //             console.log('partsFound item: ', item)
                    //             partsFound = true
                    //         }
                    //     })
                    // })
                    arrCustomersByCustLocID[i].Equipment = item.Equipment.filter((equip) => {
                        let partsFound = false
                        equip.PartsUsage.forEach((category) => {
                            if (category.Parts.length > 0) {
                                //return item
                                //console.log('partsFound item: ', item)
                                partsFound = true
                            }
                        })
                        return partsFound
                    })
                })

                sortedArray = arrCustomersByCustLocID.sort((a, b) => {
                    return a.CustName === b.CustName ? 0 : a.CustName < b.CustName ? -1 : 1
                })
            }

            // sortedArray = arrServOverByCustLocID.sort((a, b) => {
            //     return a.CustID === b.CustID ? 0 : a.CustID < b.CustID ? -1 : 1rr
            // }) sortedArray
        }

        const CollapseAll = () => {}
        let grandTotalPrice = 0
        let grandQTYTotal = 0
        console.log('Final sortedArray: ', sortedArray)
        return (
            <>
                {arrCategories && sortedArray && togglePartsUsageByCategory
                    ? sortedArray.map((item, i) => {
                          grandTotalPrice += item.CatTotal
                          grandQTYTotal += item.CatQty
                          return <PartsUsageItemCategory Data={item} key={myUUid()} containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'} />
                      })
                    : arrCustomers && sortedArray && togglePartsUsageByCustomer
                    ? sortedArray.map((item, i) => {
                          item.Equipment.map((equip) => {
                              equip.PartsUsage.map((part) => {
                                  grandTotalPrice += part.CatTotal
                                  grandQTYTotal += part.CatQty
                              })
                          })

                          return <PartsUsageItemCustomer Data={item} key={myUUid()} containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'} />
                      })
                    : ''}
                <div className='row pb-1 smallPrint'>
                    <div className='col-sm-6 p-0 text-end fs-4'>
                        <sup className=''> Total Quantity:</sup>
                        <span className='fw-bold'> {grandQTYTotal}</span>
                    </div>
                    <div className='col-sm-6  pe-4 ps-0 text-end fs-4'>
                        <sup className=''> Total Value:</sup>
                        <span className='fw-bold'>
                            {' '}
                            <sup>R</sup>
                            {grandTotalPrice}
                        </span>
                    </div>
                </div>
            </>
        )

        // return (
        //     <>
        //         {/*
        //         <div className='pageFunctions row bg-white header sticky-top'>
        //             <div className='col-auto ms-auto'>
        //                 <ROIcon icon='ROicoUp' size={24} className='icoSVG' tooltip='Collapse All' onClickCallback={() => CollapseAll()} />
        //             </div>
        //         </div>
        //         */}
        //         {/* <div className='row text-primary mb-0 mt-3'>
        //             <div className='col-auto me-auto '>
        //                 <h5>Services Report</h5>
        //             </div> */}
        //         {/* <div className='col-auto'>
        //                 {' '}
        //                 <ROIcon
        //                     icon={toggleMoreFilters ? 'ROicoEmail' : 'ROicoEmail'}
        //                     className='icoSVG p-0 m-0'
        //                     size={32}
        //                     authlvl={15}
        //                     onClickCallback={() => setMoreFilters((prev) => !prev)}
        //                     textafter='Send Email to All unsent in Report'
        //                     tooltip='If not already sent'
        //                 />
        //             </div> */}
        //         {/* </div> */}

        //         {sortedArray && sortedArray.length > 0
        //             ? sortedArray.map((line, i) => {
        //                   //   console.log(
        //                   //       'line: ',
        //                   //       line,
        //                   //       ' emailSendIDs: ',
        //                   //       emailSendIDs,
        //                   //       ' line.CustLocID:',
        //                   //       line.CustLocID,
        //                   //       ' emailSendIDs.includes(line.CustLocID): ',
        //                   //       emailSendIDs.includes(line.CustLocID),
        //                   //       'daysDiff: ',
        //                   //       getAnySettingsJSON(line.CustLocFlags, 'EToken') !== '' &&
        //                   //           daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0,
        //                   //       ' getAnySettingsJSON(line.CustLocFlags, EToken): ',
        //                   //       getAnySettingsJSON(line.CustLocFlags, 'EToken')
        //                   //   )

        //                   return (
        //                       <RepServiceOverdueItem
        //                           _LocDetails={line}
        //                           key={() => myUUid()}
        //                           containerBackColor={(i + 1) % 2 === 0 ? 'bg-light' : 'bg-secondary'}
        //                           emailSendIDCallback={updateEmailSend}
        //                           //   emailSent={
        //                           //       emailSendIDs.includes(line.CustLocID) ||
        //                           //       daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0
        //                           //   }
        //                           emailSent={emailSendIDs.includes(line.CustLocID) || (getAnySettingsJSON(line.CustLocFlags, 'EToken') !== '' && daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0)}
        //                           //emailSent={daysDiff(new Date(), new Date(getAnySettingsJSON(line.CustLocFlags, 'EToken') * 1000)) > 0}
        //                           showEmail={line.ShowEmail}
        //                       />
        //                   )
        //               })
        //             : ''}
        //     </>
        // )
    } catch (error) {
        console.log('ERROR!!!! in RepServicesOverdue: ', error)
        console.log('Services: ', services)
        console.log('loccustomers: ', loccustomers)
        console.log('equipcustloc: ', equipcustloc)
        console.log('equipment: ', equipment)
        toast.error('ERROR!!!! in RepServicesOverdue: ' + error)
        addROErrorLog('RepServicesOverdue Error:  ' + error.toString(), user ? user.name : '', 'Reports')
    }
}
export default RepPartsUsage
