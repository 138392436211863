import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import { VaildateForm, getFranSettingsJSON } from '../features/globalFunctions'

//Import Redux State/Global Var and Axiox database calls
import { updatePart, deletePart } from '../features/parts/partsSlice.js'

import ROComboBox from './ROComboBox'
import { RegExPattern } from '../features/globalVars'

const PartItem = ({ part, arrCategories, containerBackColor, collapse, _expanedState, _editState }) => {
    const dispatch = useDispatch()

    //console.log(`*** Part  ${part.PartName}  Draw ***`)

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    const curSymb = getFranSettingsJSON(franacc, 'CurrencySymb') ? getFranSettingsJSON(franacc, 'CurrencySymb') : 'R'

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)

    //Edit Part
    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtPartName, setTxtPartName] = useState(part.PartName ? part.PartName : '')
    const [txtPartCode, setTxtPartCode] = useState(part.PartCode ? part.PartCode : '')
    const [txtPartPrice, setTxtPartPrice] = useState(part.PartLastPrice ? part.PartLastPrice : '')
    const [txtPartCategory, setTxtPartCategory] = useState(part.PartCategory ? part.PartCategory : '')

    const editPart = (edit = true) => {
        if (!edit) {
            setEditState((prev) => !prev)
            setTxtPartCode(part.PartCode ? part.PartCode : '')
            setTxtPartName(part.PartName ? part.PartName : '')
            setTxtPartPrice(part.PartLastPrice ? part.PartLastPrice : '')
            setTxtPartCategory(part.PartCategory ? part.PartCategory : '')
            return
        }

        if (editState) {
            console.log('Save Edit PartName: ', txtPartName)
            setTxtPartCode(part.PartCode ? part.PartCode : '')
            setTxtPartName(part.PartName ? part.PartName : '')
            setTxtPartPrice(part.PartLastPrice ? part.PartLastPrice : '')
            setTxtPartCategory(part.PartCategory ? part.PartCategory : '')

            const updPart = {
                partID: part.PartID,
                partcode: txtPartCode,
                partname: txtPartName,
                partserialnr: '',
                partlastprice: txtPartPrice,
                partcategory: txtPartCategory,
                partimageurl: '',
                partnotes: '',
                partflags: '',
            }

            dispatch(updatePart(updPart))
        } else {
            console.log('Edit PartName: ', txtPartName)
        }
        setEditState((prev) => !prev)
    }

    //Delete Part
    const confirmDeletePart = (setState) => {
        if (confirmDelete && setState) {
            console.log('Delete!!!! PartID: ', part.PartID)
            deletePartById(part.PartID)
        }
        setDeleteState(setState)
    }

    const deletePartById = (_id) => {
        console.log('Delete!!!! Part_ID: ', _id)
        dispatch(deletePart(_id))
        confirmDeletePart(false)
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    //#region New Part Form Validation
    const [boolPartFormValidated, setPartFormValidated] = useState(false)

    const valPartCodeRef = useRef()
    const valPartNameRef = useRef()
    const valPartPriceRef = useRef()
    const valPartCategoryRef = useRef()

    const vaildatePartEditPartForm = (clicked = false) => {
        if (!editState) return

        if (editState) {
            setPartFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valPartCodeRef,
                            text: 'No Special Characters',
                            form: 'Edit Part valPartCodeRef',
                        },
                        {
                            ref: valPartNameRef,
                            text: 'No Special Characters',
                            form: 'Edit Part valPartNameRef',
                        },
                        {
                            ref: valPartPriceRef,
                            text: 'Enter valid price',
                            form: 'Edit Part valPartPriceRef',
                        },
                        {
                            ref: valPartCategoryRef,
                            text: 'Part Category Required',
                            form: 'Edit Part valPartCategoryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `content border border-primary p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded

                <div className='container p-2 pt-0 bg-light rounded-2'>
                    <div className='row' /*PartInfo Expanded*/>
                        {/*Part Code*/}
                        <div className='ROmaintxt col-auto me-auto'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtPartCode}
                                    placeholder={part.PartCode}
                                    onChange={(e) => {
                                        setTxtPartCode(e.target.value)
                                        vaildatePartEditPartForm()
                                    }}
                                    ref={valPartCodeRef}
                                    title='Part Code'
                                    pattern={RegExPattern.Text}
                                    required
                                />
                            ) : (
                                part.PartCode
                            )}
                        </div>
                        {/*Interface Buttons*/}

                        {/*Toggle Icon*/}
                        <div className='col-auto px-2'>
                            {editState ? (
                                <>
                                    {boolPartFormValidated ? (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Update Part'
                                            authlvl={10}
                                            onClickCallback={() => editPart()}
                                        />
                                    ) : (
                                        <ROIcon
                                            icon='ROicoAccept'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Update Part'
                                            authlvl={10}
                                            onClickCallback={() => vaildatePartEditPartForm(true)}
                                            usefill={true}
                                            color1='#ff0000'
                                        />
                                    )}

                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG borderlp'
                                        tooltip='Cancel'
                                        onClickCallback={() => editPart(false)}
                                    />
                                </>
                            ) : (
                                <ROIcon icon='ROicoEdit' size={24} className='icoSVG' tooltip='Edit Part' onClickCallback={() => editPart()} />
                            )}

                            {!editState ? (
                                <>
                                    {confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG danger borderlp'
                                            tooltip='Delete Part'
                                            authlvl={5}
                                            onClickCallback={() => confirmDeletePart(true)}
                                        />
                                    ) : (
                                        ' '
                                    )}
                                    {!confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoDel'
                                            size={24}
                                            className='icoSVG borderlp'
                                            tooltip='Delete Part'
                                            authlvl={5}
                                            onClickCallback={() => confirmDeletePart(true)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            {!editState ? (
                                confirmDelete ? (
                                    <ROIcon
                                        icon='ROicoCancel'
                                        size={24}
                                        className='icoSVG borderlp'
                                        tooltip='Cancel Delete'
                                        onClickCallback={() => confirmDeletePart(false)}
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}

                            {/*Expande/Collapse Icon*/}
                            {editState ? (
                                ''
                            ) : (
                                <ROIcon icon='ROicoXUp' className='icoSVG borderlp' onClickCallback={() => setToggleState((prev) => !prev)} />
                            )}
                        </div>
                    </div>
                    <div className='row' /*PartInfo Expanded*/>
                        <div className='ROnormaltxt col-md-6 fs-5'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtPartName}
                                    placeholder={part.PartName}
                                    onChange={(e) => {
                                        setTxtPartName(e.target.value)
                                        vaildatePartEditPartForm()
                                    }}
                                    ref={valPartNameRef}
                                    title='Part Name'
                                    pattern={RegExPattern.Text}
                                    required
                                />
                            ) : (
                                part.PartName
                            )}
                        </div>
                        <div className='ROnormaltxt col-md-2 fs-5'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtPartPrice}
                                    placeholder={part.PartName}
                                    onChange={(e) => {
                                        setTxtPartPrice(e.target.value.replace(/[^0-9\.]+/g, ''))
                                        vaildatePartEditPartForm()
                                    }}
                                    ref={valPartPriceRef}
                                    title='Part Price'
                                    pattern={RegExPattern.Dec}
                                    required
                                />
                            ) : (
                                <>
                                    <sup>{curSymb}</sup>
                                    {part.PartLastPrice.toFixed(2)}
                                </>
                            )}
                        </div>
                        <div className='ROnormaltxt col-md-4 fs-5 me-auto '>
                            {editState ? (
                                <ROComboBox
                                    dataArray={arrCategories}
                                    placeholdertext={'Category'}
                                    textVal={txtPartCategory}
                                    myValueCallback={setTxtPartCategory}
                                    ref={valPartCategoryRef}
                                    required={true}
                                    validationCallBack={() => vaildatePartEditPartForm()}
                                />
                            ) : (
                                part.PartCategory
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <div className='container p-2 pt-0' onClick={() => setToggleState((prev) => !prev)}>
                    <div className='row' /*EquipInfo Compact*/>
                        <div className='ROmaintxt col-md-2 pl-2 fs-3'>{part.PartCode}</div>
                        <div className='ROnormaltxt col-md-4 fs-5'>{part.PartName}</div>
                        <div className='ROnormaltxt col-md-2 fs-5'>
                            <sup>{curSymb}</sup>
                            {part.PartLastPrice.toFixed(2)}
                        </div>
                        <div className='ROnormaltxt col-md-3 fs-5 me-auto '>{part.PartCategory}</div>
                        {/*Interface Buttons*/}
                        <div className='col-auto me-auto px-0'></div>
                        {/*Toggle Icon*/}
                        <div className='col-auto px-2'>
                            {/*Expande/Collapse Icon*/}
                            {editState ? '' : <ROIcon icon='ROicoXDown' className='icoSVG' />}
                        </div>
                    </div>
                </div>
            )}
        </ContentBox>
    )
}

export default PartItem
