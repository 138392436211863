import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset, isError, message, sendForgotPasswordToken } from '../features/auth/authSlice'
import { updateUserPassword, isFUsersError, isFUsersSuccess, resetusers } from '../features/users/usersSlice'
import { toast } from 'react-toastify'
import { addROErrorLog } from '../features/globalFunctions'
import { useSearchParams } from 'react-router-dom'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROPassword from '../components/ROPassword'

const ResetPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let [searchParams, setSearchParams] = useSearchParams()

    let token = searchParams.get('token') ? searchParams.get('token') : ''

    const { user, isError, message } = useSelector((state) => state.auth)

    //Get State users from redux
    const { isFUsersError, isFUsersSuccess, franusersmessage } = useSelector((state) => state.franusers)

    useEffect(() => {
        if (isError) {
            let msg = message
            console.log('ResetPassword useEffect error: ', msg)
            toast.error(msg)
            addROErrorLog('ResetPassword Error:  ' + msg, user ? user.name : '', 'ResetPassword')
            dispatch(reset())
            dispatch(resetusers())
            navigate('/?pagemessage=' + msg)

            return
        }

        if (isFUsersError) {
            let msg = franusersmessage
            console.log('Password Reset useEffect error: ', franusersmessage)
            toast.error(msg)
            addROErrorLog('Password Reset Error:  ' + msg, user ? user.name : '', 'ResetPassword')
            dispatch(reset())
            dispatch(resetusers())
            navigate('/?pagemessage=' + msg)

            return
        }

        if (isFUsersSuccess) {
            toast.success('Password Changed')
            navigate('/?pagemessage=Password Changed. Please Login')
        }

        // if (!user || !user._id || !user.token) {
        //     console.log('User error!!! log out: ', user)
        //     addROErrorLog('ResetPassword User Error:  ' + message, user ? user.name : '', 'ResetPassword')
        //     dispatch(logout())
        //     dispatch(reset())
        //     navigate('/')
        // }

        try {
            console.log('Send reset token to server')
            if (token && token !== '') {
                dispatch(sendForgotPasswordToken(token))
            }
        } catch (error) {
            console.log('ResetPassword.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }

        return () => dispatch(reset())
    }, [token, isError, isFUsersSuccess])
    //[user, isError, isCustError, isLocError, isErrorLocContacts]) //dispatch

    const [boolUserPassValidated, setUserPassValidated] = useState(false)
    const [txtUserPass, setTxtUserPass] = useState('')

    const getNewUserPassword = (newPass) => {
        if (newPass && newPass !== '') {
            setTxtUserPass(newPass)
            setUserPassValidated(true)
        } else {
            setUserPassValidated(false)
        }
    }

    const updateNewUserPassword = () => {
        if (boolUserPassValidated) {
            //FranAccName = txtAccountName

            const updAccPassword = {
                UserID: 'Reset',
                Password: txtUserPass,
                Token: token,
            }

            console.log('updAccPassword: ', updAccPassword)

            dispatch(updateUserPassword(updAccPassword))

            //dispatch(updateFranSettings(updAcc))
        }
    }
    return (
        <>
            <section className='pageHead container mt-3'>
                <h5>Reset Password</h5>
                {user && user.email ? (
                    <>
                        <h6> {user ? user.email : ''}</h6>
                        <div className='row mt-2'>
                            <div className='col-md-5 ms-2'>
                                <ROPassword validationCallBack={getNewUserPassword} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-5 mt-2'>
                                {boolUserPassValidated ? (
                                    <button type='submit' className='btn btn-success btn-block' onClick={() => updateNewUserPassword()}>
                                        Submit
                                    </button>
                                ) : (
                                    <button type='button' className='btn btn-danger btn-block'>
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}

                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container'></div>
        </>
    )
}

export default ResetPassword
