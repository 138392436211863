import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import ContentBox from '../ContentBox'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import ROIcon from '../../components/ROIcon'
import ServiceItem from '../ServiceItem'
import { toast } from 'react-toastify'

import { addROErrorLog, getServiceInterval, daysDiff, getFranSettingsJSON, getAnySettingsJSON, EmailTokensReplace, GetEmailTokens, FormatHtmlEmail } from '../../features/globalFunctions'
import { ServiceEmailMsg, RegExPattern } from '../../features/globalVars'
import { formatLongDate, formatShortDate } from '../../features/formatTime'

//Import Redux State/Global Var and Axiox database calls
import { sendClientService } from '../../features/custloc/custlocSlice.js'
import myUUid from '../../features/myUUid.js'

const RepServiceOverdueItem = ({ _LocDetails, classTag, containerBackColor, collapse, emailSendIDCallback, emailSent = false, showEmail = true }) => {
    const dispatch = useDispatch()

    //console.log('_LocDetails.CustLocID', _LocDetails.CustLocID, ' emailSent: ', emailSent)

    //Get State fran acc settings from redux
    const { franacc, fransub, franaccmessage, isFranAccError } = useSelector((state) => state.franacc)

    const curSymb = getFranSettingsJSON(franacc, 'CurrencySymb') ? getFranSettingsJSON(franacc, 'CurrencySymb') : 'R'
    const pType = getFranSettingsJSON(franacc, 'Bar') ? getFranSettingsJSON(franacc, 'Bar') : 'Bar'

    const [toggleState, setToggleState] = useState(false)

    const emailClientService = (id, equipObj) => {
        try {
            let msg = franacc && franacc.FranAccNotes ? franacc.FranAccNotes : ServiceEmailMsg

            let serviceDueDate = new Date(_LocDetails.LastServiceRecorded)

            let emailTokens = GetEmailTokens({
                ReplyToEmail: getFranSettingsJSON(franacc, 'ReplyTo'),
                FranCompName: franacc.FranAccName,
                YourName: _LocDetails.User && _LocDetails.User.name ? _LocDetails.User.name : 'YourName',
                FranAccTel: franacc.FranAccTel,
                FranAccEmail: franacc.FranAccEmail,
                FranAccStreet: franacc.FranAccStreetAddress,
                FranAccSuburb: franacc.FranAccSuburb,
                FranAccCity: franacc.FranAccCity,
                FranAccPostal: franacc.FranAccPostalCode,
                FranAccCountry: franacc.FranAccCountry,
                CustomerName: _LocDetails.CustPriContactName ? _LocDetails.CustPriContactName : 'Client',
                Equipment: equipObj.EquipInfo.EquipName ? equipObj.EquipInfo.EquipName : 'Your Equipment',
                ServiceDue: formatLongDate(serviceDueDate.setDate(serviceDueDate.getDate() + equipObj.EquipCustLoc.EquipCustLocDaysTillNextService)),
            })

            const email = EmailTokensReplace(msg, emailTokens)
            const replyTo = getFranSettingsJSON(franacc, 'ReplyTo')
            const cc = getFranSettingsJSON(franacc, 'cc')
            const fromName = getFranSettingsJSON(franacc, 'FromName')
            const emaildays = getFranSettingsJSON(franacc, 'EmailToken')

            //Validation Checks
            if (!_LocDetails.CustPriContactEmail || !_LocDetails.CustPriContactEmail.match(RegExPattern.Email)) {
                if (_LocDetails.CustPriContactEmail) {
                    toast.error('Not valid email(To) address: ' + _LocDetails.CustPriContactEmail)
                } else {
                    toast.error('Not valid email(To) address: None')
                }
            } else if (!replyTo || !replyTo.match(RegExPattern.Email)) {
                toast.error('Not valid email(ReplyTo) address in Main Account Settings')
            } else {
                let sendEmailTo = _LocDetails.CustPriContactEmail

                const reportDetails = {
                    id: id,
                    type: 'CustLocID',
                    curSymb: curSymb,
                    pType: pType,
                    days: emaildays,
                    cc: cc,
                    emailName: fromName ? fromName : franacc.FranAccName,
                    emailTo: sendEmailTo,
                    emailReply: replyTo,
                    emailMsg: email,
                    emailHtml: FormatHtmlEmail(email),
                }

                toast.info('Service email sent to: ' + sendEmailTo)

                dispatch(sendClientService(reportDetails))
                if (emailSendIDCallback) {
                    emailSendIDCallback(id)
                }
            }
        } catch (error) {
            toast.error('ERROR!!!! in RepServiceOverdueItem(emailClientService): ' + error)
            addROErrorLog('RepServiceOverdueItem(emailClientService) Error:  ' + error.toString(), _LocDetails.User && _LocDetails.User.name ? _LocDetails.User.name : '', 'emailClientService')
            console.log('ERROR!!!! in RepServiceOverdueItem(emailClientService): ', error)
        }
    }

    const changeToggleState = () => {
        setToggleState((prev) => !prev)
    }

    //console.log('_LocDetails.CustLocFlags', _LocDetails)
    const tokenExpiryDate = new Date(getAnySettingsJSON(_LocDetails.CustLocFlags, 'EToken') * 1000)

    // console.log('getAnySettingsJSON(_LocDetails.CustLocFlags, EToken):', getAnySettingsJSON(_LocDetails.CustLocFlags, 'EToken'), _LocDetails)
    // console.log('tokenExpiryDate: ', tokenExpiryDate)
    // console.log(
    //     'new Date(): ',
    //     new Date(),
    //     '  new Date(tokenExpiryDate):',
    //     new Date(tokenExpiryDate),
    //     ' === ',
    //     daysDiff(new Date(), new Date(tokenExpiryDate))
    // )

    //console.log('_LocDetails: ', _LocDetails)

    return (
        <ContentBox key={myUUid()} classTag={toggleState ? `custBox content  border-primary p-0 m-0 mb-1 rounded-2 ${containerBackColor}` : `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`}>
            {/*Container Div*/}
            <div className='container p-2 pt-0 rounded-2'>
                {_LocDetails.CustSuspended === 1 ? (
                    <div className='row'>
                        <div className='text-danger'>SUSPENDED</div>{' '}
                    </div>
                ) : (
                    ''
                )}

                <div className='row CustInfoRow ' /*CustInfo*/>
                    {/*Customer Name*/}
                    <div className='custName col-sm-5 pl-2 fs-3'>
                        {_LocDetails.CustName} <span className='fw-normal'>{' (' + _LocDetails.CustLocName + ')'}</span> {' - '}
                        {_LocDetails.CustPriContactNumber} <span className='fw-normal'>{' (' + _LocDetails.CustPriContactName + ')'}</span>
                    </div>
                    {/*Contact Number*/}
                    {/* <div className='custName col-sm-3 pl-0 fs-3'></div>  */}
                    <div className='custName col-sm-6 px-0'>
                        <ROIcon icon='ROicoLocation' size={32} className='icoSVG' />
                        {_LocDetails.CustLocStreetAddress + ', ' + _LocDetails.CustLocSuburb + ', ' + _LocDetails.CustLocCity}
                    </div>
                    {/* Contact Email
                    <div className='custName col-3 pl-2 fs-3'>
                        {_LocDetails.CustPriContactEmail}
                    </div> */}
                    {/*Customer Expande/Collapse Icon*/}
                    <div className='col-sm-1 text-end p-0 pe-1'>
                        <ROIcon icon={toggleState ? 'ROicoTup' : 'ROicoTdown'} className='icoSVG donotPrint' onClickCallback={changeToggleState} />
                    </div>
                </div>

                <div className='row'></div>
                {toggleState ? (
                    _LocDetails.CustLocNotes && _LocDetails.CustLocNotes.length > 0 ? (
                        <>
                            <div className='col-auto px-2'>
                                {/* Blank Spacer Col */}
                                <div className=' col-md-4 fs-5 m-0 p-0'> Note: {_LocDetails.CustLocNotes}</div>
                            </div>
                        </>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}
                {toggleState ? (
                    _LocDetails.Contacts && _LocDetails.Contacts.length > 0 ? (
                        _LocDetails.Contacts.map((contact) => {
                            if (contact.CustLocContactPrimary !== 1) {
                                return (
                                    <>
                                        <div key={myUUid()} className='row smallPrint'>
                                            {/* Blank Spacer Col */}
                                            <div className=' col-md-4 fs-5 m-0 p-0 smallPrint'></div>
                                            <div className=' col-md-3 fs-5 m-0 p-0 smallPrint'>
                                                <ROIcon icon='ROicoUser' size={24} className='icoSVG' />
                                                {contact.CustLocContactName} <br />{' '}
                                            </div>
                                            <div className=' col-md-2 fs-5 m-0 p-0 smallPrint'>{contact.CustLocContactNumber}</div>
                                            <div className=' col-md-3 fs-5 m-0 p-0 smallPrint'>{contact.CustLocContactEmail}</div>
                                        </div>
                                    </>
                                )
                            }
                        })
                    ) : (
                        <>
                            <div key={myUUid()} className='col-auto px-2'>
                                {/* Blank Spacer Col */}
                                <div className=' col-md-4 fs-5 m-0 p-0'>No additional contacts to display</div>
                            </div>
                        </>
                    )
                ) : (
                    ''
                )}

                {_LocDetails.Equipment.map((item) => {
                    let daysFromLastService = daysDiff(new Date(), item.LastServiceRecorded)

                    //console.log('daysFromLastService: ', daysFromLastService)

                    return (
                        <>
                            <div key={myUUid()} className='row px-5'>
                                <hr className='services p-0 m-0' />
                                {/*Big Equipment Icon */}
                                <div className='col-auto px-1'>
                                    <ROIcon icon='ROicoEquip' className='icoSVG p-0 m-0' size={32} />
                                </div>
                                <div className='custName col-3 fs-5 m-0 p-0'>
                                    {item.EquipInfo.EquipCode} <br />
                                </div>
                                <div className='custName col-4 fs-5 m-0 p-0'>
                                    <span className='fs-6 fw-light'>{item.EquipCustLoc.EquipCustLocRoomPlace}</span>
                                </div>
                                <div className='custName col-4 fs-5 ms-auto p-0'>
                                    <ROIcon icon='ROicoService' size={32} className='icoSVG' />
                                    {/*Service Overdue by *?* days*/}
                                    {daysFromLastService > item.EquipCustLoc.EquipCustLocDaysTillNextService ? 'Service Overdue by ' + (daysFromLastService - item.EquipCustLoc.EquipCustLocDaysTillNextService) + ' days' : item.EquipCustLoc.EquipCustLocDaysTillNextService - daysFromLastService + ' days till next service'}
                                    <br />
                                </div>
                            </div>
                            <div key={myUUid()} className='row px-5'>
                                <div className='col-auto px-1'>
                                    <ROIcon icon='ROicoEquip' className='icoSVG p-0 m-0 invisible' size={32} />
                                </div>
                                <div className='custName col-sm-7 fs-5 m-0 p-0 '>
                                    <span className='fs-6 fw-light medPrint'>
                                        {item.EquipInfo.EquipName}
                                        {item && item.EquipCustLoc.EquipCustLocSerialNR !== '' ? ' - #' + item.EquipCustLoc.EquipCustLocSerialNR : ''}
                                    </span>
                                </div>
                                <div className='custName col-sm-4 fs-5 ms-auto p-0'>
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <span className='interval fs-6 fw-light '>{getServiceInterval(item.EquipCustLoc) + ' (' + formatShortDate(item.LastServiceRecorded) + ')'}</span>
                                        </div>
                                        <div className='col-auto p-0 m-0'>
                                            <Link to={'/customers?custID=' + item.CustID + '&locID=' + item.CustLocID + '&equiplocID=' + item.EquipCustLocID}>
                                                <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2' tooltip='Go To' isLink={false} />
                                            </Link>

                                            {showEmail ? (
                                                <ROIcon
                                                    icon={daysDiff(new Date(), new Date(tokenExpiryDate)) > 0 && !emailSent ? 'ROicoEmail' : 'ROicoSend'}
                                                    size={32}
                                                    className='icoSVG interval'
                                                    tooltip={daysDiff(new Date(), new Date(tokenExpiryDate)) > 0 ? 'Email Service: ' + item.CustPriContactEmail : 'Resend Email: ' + item.CustPriContactEmail}
                                                    onClickCallback={() => emailClientService(item.CustLocID, item)}
                                                    //usefill={true}
                                                    //color1={daysDiff(new Date(), new Date(tokenExpiryDate)) < 0 ? '#32bebe' : '#ff0000'}
                                                    //daysDiff(new Date(), item.LastServiceRecorded)
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {item && item.EquipCustLoc.EquipCustLocNotes !== '' ? (
                                <>
                                    <div key={myUUid()} className='row px-5'>
                                        <div className='col-auto px-1'>
                                            <ROIcon icon='ROicoEquip' className='icoSVG p-0 m-0 invisible' size={32} />
                                        </div>
                                        <div className='custName col-sm-8 fs-5 m-0 p-0 '>
                                            <span className='fs-6 fw-light '>Notes: {item.EquipCustLoc.EquipCustLocNotes}</span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ''
                            )}

                            <div key={myUUid()} className='row text-white px-5 p-2 donotPrint' /*EquipInfo Expanded*/>
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* <AllServicesItem
                                    containerBackColor='bg-white'
                                    editOnly={true}
                                    equipcustlocitem={item.EquipCustLoc}
                                /> */}
                                {item.equipServices.map((service) => {
                                    return <ServiceItem key={myUUid()} containerBackColor={containerBackColor} small={true} service={service} equipSettings={item.EquipInfo.EquipFlags.DefS && item.EquipInfo.EquipFlags.DefS !== '' ? item.EquipInfo.EquipFlags.DefS : [1, 1, 1, 1, 1]} displayOnly={true} />
                                })}

                                {/*console.log('item.Equipment: ', item.equipServices)*/}
                            </div>
                        </>
                    )
                })}
            </div>
        </ContentBox>
    )
}

export default RepServiceOverdueItem
