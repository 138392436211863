import React from 'react'
import ContentBox from './ContentBox'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import ROIcon from './ROIcon'
import ServiceItem from './ServiceItem'
import { toast } from 'react-toastify'

import { addROErrorLog, getServiceInterval, daysDiff, getFranSettingsJSON } from '../features/globalFunctions'

import { formatLongDate, formatShortDate } from '../features/formatTime'
import myUUid from '../features/myUUid'

const ClientServiceItem = ({ _LocDetails, classTag, containerBackColor, collapse }) => {
    console.log('ClientServiceItem _LocDetails:', _LocDetails)

    //Get State user from redux
    const { user } = useSelector((state) => state.auth)

    const [toggleState, setToggleState] = useState(false)

    let contactList = _LocDetails.Contact
    //let contactList = '{' + _LocDetails.Contact + '}'
    // console.log('contactList', contactList)
    // console.log('JSON.parse :', JSON.parse(_LocDetails.Contact))
    let contacts = []

    let primaryName = ''
    let primaryTel = ''
    let primaryEmail = ''

    try {
        contactList = JSON.parse(contactList)
        //console.log('contactList', contactList)

        if (contactList && contactList.length > 0) {
            contactList.map((item) => {
                //console.log('contactList item: ', item, '   JSON.parse :', JSON.parse(item))
                contacts.push(JSON.parse(item))
                if (JSON.parse(item).ContactPrimary === '1') {
                    primaryName = JSON.parse(item).ContactName
                    primaryEmail = JSON.parse(item).ContactEmail
                    primaryTel = JSON.parse(item).ContactTel
                }
            })
        }

        //console.log('contact', contacts)
    } catch (error) {
        console.log('ClientServiceItem JSON.parse(contactList) Error: ', error)
    }

    const changeToggleState = () => {
        setToggleState((prev) => !prev)
    }

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-primary p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {/*Container Div*/}
            <div className='container p-2 pt-0 rounded-2' key={myUUid()}>
                <div className='row CustInfoRow' /*CustInfo*/>
                    {/*Customer Name*/}

                    <div className='custName col-md-4 pl-2 fs-3'>{_LocDetails.CustName + ' (' + _LocDetails.CustLocName + ')'}</div>
                    {/*Contact Number*/}
                    <div className='custName col-md-3 pl-0 fs-3'>
                        {primaryName !== '' && primaryTel != '' ? primaryTel + ' (' + primaryName + ')' : ''}
                    </div>
                    <div className='custName col-md-4 px-0'>
                        <ROIcon icon='ROicoLocation' size={32} className='icoSVG' authlvl={99} />
                        {_LocDetails.CustLocStreetAddress + ', ' + _LocDetails.CustLocSuburb + ', ' + _LocDetails.CustLocCity}
                    </div>
                    {/* Contact Email
                    <div className='custName col-3 pl-2 fs-3'>
                        {_LocDetails.CustPriContactEmail}
                    </div> */}
                    {/*Customer Expande/Collapse Icon*/}
                    <div className='col-auto px-2'>
                        <ROIcon
                            icon={toggleState ? 'ROicoTup' : 'ROicoTdown'}
                            className='icoSVG donotPrint'
                            onClickCallback={changeToggleState}
                            authlvl={99}
                        />
                    </div>
                </div>

                {toggleState ? (
                    contacts && contacts.length > 0 ? (
                        contacts.map((contact) => {
                            if (contact.ContactPrimary !== 1) {
                                return (
                                    <div className='row smallPrint' key={myUUid()}>
                                        {/* Blank Spacer Col */}
                                        <div className=' col-md-4 fs-5 m-0 p-0 smallPrint'></div>
                                        <div className=' col-md-3 fs-5 m-0 p-0 smallPrint'>
                                            <ROIcon icon='ROicoUser' size={24} className='icoSVG' authlvl={99} />
                                            {contact.ContactName} <br />{' '}
                                        </div>
                                        <div className=' col-md-2 fs-5 m-0 p-0 smallPrint'>{contact.ContactTel}</div>
                                        <div className=' col-md-3 fs-5 m-0 p-0 smallPrint'>{contact.ContactEmail}</div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <>
                            <div className='col-auto px-2' key={myUUid()}>
                                {/* Blank Spacer Col */}
                                <div className=' col-md-4 fs-5 m-0 p-0'>No additional contacts to display</div>
                            </div>
                        </>
                    )
                ) : (
                    ''
                )}

                {_LocDetails.Equipment.map((item) => {
                    let daysFromLastService = daysDiff(new Date(), item.EquipCustLocLastServiceDate)

                    //console.log('daysFromLastService: ', daysFromLastService)

                    return (
                        <>
                            <div className='row px-5' key={myUUid()}>
                                <hr className='services p-0 m-0' />
                                {/*Big Equipment Icon */}
                                <div className='col-auto px-1'>
                                    <ROIcon icon='ROicoEquip' className='icoSVG p-0 m-0' size={32} authlvl={99} />
                                </div>
                                <div className='custName col-md-3 fs-5 m-0 p-0'>
                                    {item.EquipCode} <br />
                                </div>
                                <div className='custName col-md-4 fs-5 m-0 p-0'>
                                    <span className='fs-6 fw-light'>{item.EquipCustLocRoomPlace}</span>
                                </div>
                                <div className='custName col-md-4 fs-5 ms-auto p-0'>
                                    <ROIcon icon='ROicoService' size={32} className='icoSVG' authlvl={99} />
                                    {/*Service Overdue by *?* days*/}
                                    {daysFromLastService > item.EquipCustLocDaysTillNextService
                                        ? 'Service Overdue by ' + (daysFromLastService - item.EquipCustLocDaysTillNextService) + ' days'
                                        : item.EquipCustLocDaysTillNextService - daysFromLastService + ' days till next service'}
                                    <br />
                                </div>
                            </div>
                            <div className='row px-5' key={myUUid()}>
                                <div className='col-auto px-1'>
                                    <ROIcon icon='ROicoEquip' className='icoSVG p-0 m-0 invisible' size={32} authlvl={99} />
                                </div>
                                <div className='custName col-md-7 fs-5 m-0 p-0 '>
                                    <span className='fs-6 fw-light medPrint'>
                                        {item.EquipName}
                                        {item && item.EquipCustLocSerialNR !== '' ? ' - #' + item.EquipCustLocSerialNR : ''}
                                    </span>
                                </div>
                                <div className='custName col-md-4 fs-5 ms-auto p-0'>
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <span className='interval fs-6 fw-light '>
                                                {getServiceInterval(item) + ' (' + formatShortDate(item.EquipCustLocLastServiceDate) + ')'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row text-white px-5 p-2 donotPrint' key={myUUid()} /*EquipInfo Expanded*/>
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* <AllServicesItem
                                    containerBackColor='bg-white'
                                    editOnly={true}
                                    equipcustlocitem={item.EquipCustLoc}
                                /> */}
                                {item.Services.map((service) => {
                                    return (
                                        <ServiceItem
                                            containerBackColor={containerBackColor}
                                            small={true}
                                            service={service}
                                            equipSettings={
                                                item.EquipFlags.DefS && item.EquipFlags.DefS !== '' ? item.EquipFlags.DefS : [1, 1, 1, 1, 1]
                                            }
                                            displayOnly={true}
                                            hideNotes={true}
                                        />
                                    )
                                })}

                                {/*console.log('item.Equipment: ', item.equipServices)*/}
                            </div>
                        </>
                    )
                })}
            </div>
        </ContentBox>
    )
}

export default ClientServiceItem
