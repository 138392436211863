import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import Header from './components/Header'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Customers from './pages/Customers'
import Login from './pages/Login'
import Register from './pages/Register'
import Equipment from './pages/Equipment'
import Parts from './pages/Parts'
import Users from './pages/Users'
import Reports from './pages/Reports'
import Account from './pages/Account'
import ResetPassword from './pages/ResetPassword'
import NewAccount from './pages/NewAccount'
import Support from './pages/Support'
import Footer from './components/Footer'
import Terms from './pages/Terms'
import ServiceToken from './pages/ServiceToken'

//Demo
// const initialOptions = {
//     'client-id':
//         'AUb7KyqqvERBeZZy_KhfcBAEBNVtvGvRFdFWE5eAkDaEbZ7hThl0oHW3y2GIUj69BSXbeka6oSaxO3Z9',
//     currency: 'USD',
//     intent: 'subscription',
//     vault: true,
// }

//Live
const initialOptions = {
    'client-id': 'Afo24vk7DMvj7yNS3Tnhd3E9BGPke8l0tV6QgMBcALQVItoiHOWQ5i8QbIHlMg2n2mmmLzrFkDsmtPDN',
    currency: 'USD',
    intent: 'subscription',
    vault: true,
}

function App() {
    return (
        <>
            <Router>
                <PayPalScriptProvider options={initialOptions}>
                    <div className='globalContainer container-fluid'>
                        <Header />
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/login' element={<Login />} />
                            <Route path='/dashboard' element={<Dashboard />} />
                            <Route path='/customers' element={<Customers />} />
                            <Route path='/register' element={<Register />} />
                            <Route path='/equipment' element={<Equipment />} />
                            <Route path='/parts' element={<Parts />} />
                            <Route path='/users' element={<Users />} />
                            <Route path='/reports' element={<Reports />} />
                            <Route path='/account' element={<Account />} />
                            <Route path='/reset-password' element={<ResetPassword />} />
                            <Route path='/service-token' element={<ServiceToken />} />
                            <Route path='/register-new' element={<NewAccount />} />
                            <Route path='/support' element={<Support />} />
                            <Route path='/terms' element={<Terms />} />
                        </Routes>
                        <Footer />
                    </div>
                </PayPalScriptProvider>
            </Router>
            <ToastContainer />
        </>
    )
}

export default App
