import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import ROIcon from '../components/ROIcon'
import ROHelpBox from '../components/ROHelpBox.jsx'
import ShowROIcons from '../components/ShowROIcons'
import { toast } from 'react-toastify'
import { addROErrorLog, daysDiff, getServicesReportArray } from '../features/globalFunctions'
import { formatLongDate, formatShortDate, formatGetMonthName } from '../features/formatTime'

//Import Customers Redux State/Global Var and Axiox database calls
import { getCustomers, resetCust } from '../features/cust/custSlice'

//Import Customer Locations Redux State/Global Var and Axiox database calls
import { getCustomersLoc, resetLocations } from '../features/custloc/custlocSlice'

//import equipcustlocService from '../features/equipcustloc/equipcustlocService'
import { getEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice'

import { getFranSettings } from '../features/franacc/franaccSlice'

//Import Franchise User Redux State/Global Var and Axiox database calls
import { getUsers, resetusers } from '../features/users/usersSlice'

//Import Customer Location Contacts Redux State/Global Var and Axiox database calls
import { getCustomerLocContacts, resetLocContacts } from '../features/custloccontacts/custloccontactsSlice'

//Import Customer Location Equipment Contacts Redux State/Global Var and Axiox database calls
import { getEquipment, resetequip } from '../features/equip/equipSlice'

import { getParts, resetparts } from '../features/parts/partsSlice'

//Import Services Redux State/Global Var and Axiox database calls
import { getServices, resetservices } from '../features/services/servicesSlice.js'

//Import Services Redux State/Global Var and Axiox database calls
// import { getServices, resetservices } from '../features/services/servicesSlice.js'

const Dashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const devTesting = false

    //Get State user from redux
    const { user, message, isError } = useSelector((state) => state.auth)
    //Get State users / staff from redux
    const { franusers } = useSelector((state) => state.franusers)
    //Get State customers from redux
    const { customers, isCustError, custmessage } = useSelector((state) => state.customers)
    //Get State customer locations from redux
    const { loccustomers, isLocError, locmessage } = useSelector((state) => state.loccustomers)
    //Get State customer location contacts from redux
    const { isErrorLocContacts, custloccontmessage, custloccontacts } = useSelector((state) => state.loccustcontacts)
    //Get State equipment from redux
    const { equipment, isEquipError, equipmessage } = useSelector((state) => state.equipment)
    //Get State parts from redux
    const { parts, isPartsError, partsmessage } = useSelector((state) => state.parts)
    //Get State location equipment from redux
    const { equipcustloc, isEquipCustLocError, equipcustlocmessage } = useSelector((state) => state.equipcustloc)
    //#region All Services
    const { services, isServicesError, servicesmessage } = useSelector((state) => state.service)

    //Get State fran acc settings from redux
    const { franacc, isFUsersError, franusersmessage } = useSelector((state) => state.franacc)

    //const { services, isServicesError, servicesmessage } = useSelector((state) => state.service)

    const arrServReport = getServicesReportArray(services, franacc, loccustomers, equipcustloc, custloccontacts, equipment, false, true, false, true, false, false, false)

    //Overdue Settings
    const todayDate = new Date()
    const overdueMaxCount = 20
    let curOverdueCount = 0
    let totalPartsUsageQty = 0
    let totalPartsUsagePrice = 0

    useEffect(() => {
        //console.log('DASHBOARD: ', user)

        if (isError) {
            console.log('DASHBOARD error: ', message)
            toast.error(message)
            addROErrorLog('DASHBOARD Error:  ' + message, user ? user.name : '', 'DASHBOARD')
            navigate('/')
            return
        }

        if (isCustError) {
            console.log('DASHBOARD useEffect error: ', custmessage)
            toast.error(custmessage)
            addROErrorLog('DASHBOARD useEffect Error:  ' + custmessage, user ? user.name : '', 'DASHBOARD')
            navigate('/')
            return
        }

        if (isLocError) {
            console.log('DASHBOARD useEffect error: ', locmessage)
            toast.error(locmessage)
            addROErrorLog('DASHBOARD useEffect Error:  ' + locmessage, user ? user.name : '', 'DASHBOARD')
            navigate('/')
            return
        }

        if (isEquipCustLocError) {
            console.log('DASHBOARD cust loc useEffect error: ', equipcustlocmessage)
            toast.error(equipcustlocmessage)
            addROErrorLog('DASHBOARD cust loc useEffect Error:  ' + equipcustlocmessage, user ? user.name : '', 'DASHBOARD')
            navigate('/')
            return
        }

        // if (isServicesError) {
        //     console.log('DASHBOARD services useEffect error: ', servicesmessage)
        //     toast.error(servicesmessage)
        //     addROErrorLog('DASHBOARD services useEffect Error:  ' + servicesmessage, user ? user.name : '', 'DASHBOARD')
        //     navigate('/')
        //     return
        // }

        if (!user || !user._id || !user.token) {
            console.log('User error!!! log out: ', user)
            addROErrorLog('DASHBOARD User Error:  ' + message, user ? user.name : '', 'DASHBOARD')
            dispatch(logout())
            dispatch(reset())

            navigate('/')
            return
        }

        // try {
        //     console.log('Get Data from servers')
        //     dispatch(getCustomers())
        //     dispatch(getCustomersLoc())
        //     dispatch(getEquipCustLoc())
        //     //dispatch(getServices())
        // } catch (error) {
        //     console.log('DASHBOARD.jsx Dispatch(all) Error: ', error)
        //     toast.error(error)
        //     addROErrorLog('DASHBOARD Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'DASHBOARD')
        //     return () => dispatch(resetCust())
        // }
        try {
            console.log('Get All Data from servers')
            dispatch(getUsers())
            dispatch(getFranSettings())
            dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
            dispatch(getEquipment())
            dispatch(getEquipCustLoc())
            dispatch(getParts())
            dispatch(getServices())
        } catch (error) {
            console.log('DASHBOARD.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            addROErrorLog('DASHBOARD Dispatch(all) Error:  ' + error.toString(), user ? user.name : '', 'DASHBOARD')
            return () => dispatch(resetCust())
        }

        return () => {
            dispatch(reset())
        }
    }, [user, isError, isCustError, isLocError, isEquipCustLocError])

    //Dashboard vars
    let custCount = 0
    let custEquipCount = 0
    let custEquipServiced = 0
    let custEquipNeedService = 0
    let custEquipNeedServiceNextMonth = 0

    let boolNoData = true

    //Array vars
    let arrServOver = []

    if (customers && customers.length > 0 && loccustomers && loccustomers.length > 0 && equipcustloc && equipcustloc.length > 0) {
        boolNoData = false
        custCount = customers.length ? customers.length : 0
        custEquipCount = equipcustloc.length ? equipcustloc.length : 0

        //var d = new Date(2008, month + 1, 0);
        //console.log('todayDate: ', todayDate)
        const thisMonthEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)
        const nextMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 2, 0)

        // console.log('todayDate: ', todayDate)
        // console.log('nextMonthDate: ', nextMonthDate)

        equipcustloc.map((item) => {
            //console.log(daysDiff(todayDate, item.EquipCustLocLastServiceDate) + ' < ' + item.EquipCustLocDaysTillNextService)
            if (daysDiff(todayDate, item.EquipCustLocLastServiceDate) < item.EquipCustLocDaysTillNextService) {
                custEquipNeedService++
            } else {
                custEquipServiced++
            }

            if (daysDiff(nextMonthDate, item.EquipCustLocLastServiceDate) > item.EquipCustLocDaysTillNextService && daysDiff(todayDate, item.EquipCustLocLastServiceDate) < item.EquipCustLocDaysTillNextService) {
                // console.log(
                //     'item.EquipCustLocLastServiceDate: ',
                //     item.EquipCustLocLastServiceDate,
                //     ' item.EquipCustLocDaysTillNextService:',
                //     item.EquipCustLocDaysTillNextService
                // )
                // console.log('daysDiff(nextMonthDate, item.EquipCustLocLastServiceDate): ', daysDiff(nextMonthDate, item.EquipCustLocLastServiceDate))
                custEquipNeedServiceNextMonth++
            }
        })
    }

    arrServReport.sort((a, b) => {
        return daysDiff(todayDate, a.LastServiceRecorded) - a.EquipCustLoc.EquipCustLocDaysTillNextService === daysDiff(todayDate, b.LastServiceRecorded) - b.EquipCustLoc.EquipCustLocDaysTillNextService ? 0 : daysDiff(todayDate, a.LastServiceRecorded) - a.EquipCustLoc.EquipCustLocDaysTillNextService > daysDiff(todayDate, b.LastServiceRecorded) - b.EquipCustLoc.EquipCustLocDaysTillNextService ? -1 : 1
    })

    //   {
    //       daysDiff(todayDate, item.LastServiceRecorded) -
    //           item.EquipCustLoc.EquipCustLocDaysTillNextService
    //   }

    console.log('arrServReport: ', arrServReport)

    //const arrCategories = []

    const arrAllServices = getServicesReportArray(services, franacc, loccustomers, equipcustloc, custloccontacts, equipment, false, true, true, true, false, true, false)

    console.log('arrAllServices: ', arrAllServices)

    arrAllServices.map((item, i) => {
        const services = item.equipServices //? JSON.parse(item.equipServices.servicejson) : []
        //console.log('arrCustomers item: ', arrCustomers)
        services.map((service, s) => {
            const serviceParts = service.servicejson ? JSON.parse(service.servicejson) : []
            console.log(' Date: ', new Date(service.ServiceDate), ' ', new Date(service.ServiceDate) > new Date(todayDate.getFullYear(), todayDate.getMonth(), 1) && new Date(service.ServiceDate) <= new Date())

            if (new Date(service.ServiceDate) > new Date(todayDate.getFullYear(), todayDate.getMonth(), 1) && new Date(service.ServiceDate) <= new Date()) {
                serviceParts.map((part, p) => {
                    //console.log(' Date: ', new Date(service.ServiceDate), ' from: ', new Date(todayDate.getFullYear(), todayDate.getMonth(), 1), ' till: ', new Date(), ' ', new Date(service.ServiceDate) > new Date(todayDate.getFullYear(), todayDate.getMonth(), 1) && new Date(service.ServiceDate) <= new Date())

                    totalPartsUsagePrice += part.ServicePartPrice * part.ServicePartQty
                    totalPartsUsageQty += part.ServicePartQty
                    console.log(part.ServicePartName, part.ServicePartPrice, ' * ', part.ServicePartQty, ' = ', part.ServicePartPrice * part.ServicePartQty, ' Total: ', totalPartsUsagePrice, '(Qty: ', totalPartsUsageQty, ')', service)
                })
            }
        })
    })

    console.log('totalPartsUsagePrice: ', totalPartsUsagePrice, ' totalPartsUsageQty: ', totalPartsUsageQty)

    arrServReport.filter((item) => {
        if (daysDiff(todayDate, item.LastServiceRecorded) > item.EquipCustLoc.EquipCustLocDaysTillNextService && item.ServiceType === 0) {
            return item
        }
    })

    return (
        <>
            <div className='bg-welcome pb-2'>
                <section className='pageHead container pb-2 clearfix'>
                    {/* <h4>Welcome {user && user.name}</h4> */}
                    <div className='row'>
                        <div className='col-6'>
                            <h4>ROvantage Dashboard </h4>
                        </div>
                        <div className='col-6 pe-4'>
                            <div class='float-end'>
                                <ROHelpBox helpTopic={'dashboard'} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className='pageHead container p-2 '>
                <div className='py-5 bg-light'>
                    <div className='container'>
                        {boolNoData ? (
                            <>
                                <Link to='/customers'>
                                    <h4 className='danger'>No Data - Please Add Equipment</h4>
                                    <button type='button' className='btn btn-primary bg-primary btn-block rounded-2 ms-1 w-25'>
                                        View Customers
                                    </button>
                                </Link>
                            </>
                        ) : (
                            <div className='row'>
                                <div className='col-md-3 justify-content-center'>
                                    <Link to='/customers'>
                                        <div className='border border-primary m-1 p-1 rounded-2 text-primary text-center bg-white w-100'>
                                            <ROIcon icon='ROicoCust' size={80} className='icoSVG mr-auto ml-auto' isLink={false} />
                                            <div>
                                                <h1>{custCount}</h1>
                                                Total Customers
                                            </div>
                                        </div>
                                        <button type='button' className='btn btn-primary bg-primary btn-block rounded-2 ms-1 w-100'>
                                            View All Customers
                                        </button>
                                    </Link>
                                </div>

                                <div className='col-md-3 justify-content-center'>
                                    <Link to='/reports?reportType=partsusage'>
                                        <div className='border border-primary m-1 p-1 rounded-2 text-primary text-center bg-white w-100'>
                                            <ROIcon icon='ROicoParts' size={80} className='icoSVG mr-auto ml-auto' isLink={false} />
                                            <div>
                                                <h1>
                                                    <sup>R</sup>
                                                    {totalPartsUsagePrice}
                                                </h1>
                                                Total value for {formatGetMonthName(Date.now())}
                                            </div>
                                        </div>
                                        <button type='button' className='btn btn-primary bg-primary btn-block rounded-2 ms-1 w-100'>
                                            Part Usage Report
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-md-3 justify-content-center'>
                                    <Link to='/reports'>
                                        <div className='border border-primary m-1 p-1 rounded-2 text-primary text-center bg-white w-100'>
                                            <ROIcon icon='ROicoService' size={80} className='icoSVG mr-auto ml-auto' isLink={false} />
                                            <div>
                                                <h1>
                                                    {custEquipCount - custEquipNeedService}/{custEquipCount}
                                                </h1>
                                                Services Completed
                                            </div>
                                        </div>
                                        <button type='button' className='btn btn-primary bg-primary btn-block rounded-2 ms-1 w-100'>
                                            Services Due Report
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-md-3 justify-content-center'>
                                    <Link to='/reports?reportType=upcoming'>
                                        <div className='border border-primary m-1 p-1 rounded-2 text-primary text-center bg-white w-100'>
                                            <ROIcon icon='ROicoService' size={80} className='icoSVG mr-auto ml-auto' isLink={false} />
                                            <div>
                                                <h1>{custEquipNeedServiceNextMonth}</h1>
                                                Services Next Month
                                            </div>
                                        </div>
                                        <button type='button' className='btn btn-primary bg-primary btn-block rounded-2 ms-1 w-100'>
                                            Upcoming Services
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className='row text-primary mb-0 mt-4 ms-1'>
                            <div className='col-auto me-auto '>
                                <h5>Longest Overdue</h5>
                            </div>
                        </div>

                        {arrServReport && arrServReport.length
                            ? // ? arrServReport.map((item) => (
                              //       <div className='row w-50 ms-1'>
                              //           <div className='col-6 fw-bold'>{item.CustLocName}</div>
                              //           <div className='col-4 p-0 m-0 text-end'>
                              //               Overdue by <b className='fw-bold'>{formatShortDate(item.LastServiceRecorded)}</b> days
                              //           </div>
                              //           <div className='col-auto p-0 m-0'>
                              //               <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2' tooltip='Go To' isLink={false} />
                              //           </div>
                              //       </div>
                              //   ))
                              arrServReport.map((item) => {
                                  if (daysDiff(todayDate, item.LastServiceRecorded) > item.EquipCustLoc.EquipCustLocDaysTillNextService && item.ServiceType === 0 && curOverdueCount < overdueMaxCount) {
                                      curOverdueCount++
                                      return (
                                          <div className='row w-50 ms-1'>
                                              <div className='col-md-6 fw-bold'>
                                                  {item.CustName} <span className='fw-light'> {' (' + item.CustLocName + ')'}</span>
                                              </div>
                                              <div className='col-md-4 p-0 m-0 text-end'>
                                                  Overdue by <b className='fw-bold'>{daysDiff(todayDate, item.LastServiceRecorded) - item.EquipCustLoc.EquipCustLocDaysTillNextService}</b> days
                                              </div>
                                              <div className='col-auto p-0 m-0'>
                                                  <Link to={'/customers?custID=' + item.CustID + '&locID=' + item.CustLocID + '&equiplocID=' + item.EquipCustLocID}>
                                                      <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2' tooltip='Go To' isLink={false} />
                                                  </Link>
                                              </div>
                                          </div>
                                      )
                                  }
                              })
                            : ''}

                        {/*                         
                        <div className='row w-50 ms-1'>
                            <div className='col-6 fw-bold'>Elani Davids</div>
                            <div className='col-4 p-0 m-0 text-end'>
                                Overdue by <b className='fw-bold'>62</b> days
                            </div>
                            <div className='col-auto p-0 m-0'>
                                <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2' tooltip='Go To' isLink={false} />
                            </div>
                        </div>
                        <div className='row w-50 ms-1'>
                            <div className='col-6 fw-bold'>Johan Hiemstra</div>
                            <div className='col-4 p-0 m-0 text-end'>
                                Overdue by <b className='fw-bold'>1</b> days
                            </div>
                            <div className='col-auto p-0 m-0'>
                                <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2' tooltip='Go To' isLink={false} />
                            </div>
                        </div> 
                        */}
                    </div>
                </div>

                {/* {devTesting ? (
                    <div className='pageContent container p-0 m-0 bg-servicewhite floatingContainer'>
                        <h4 className='danger'>Work in progress (not implemented)</h4>
                        <div className='container bg-servicewhite'>
                            <ShowROIcons />
                        </div>
                    </div>
                ) : (
                    ''
                )} */}
            </section>
        </>
    )
}

export default Dashboard

// <div className='col-2 ms-auto'>
//     {/* <Link to={'/customers?custID=' + item.CustID + '&locID=' + item.CustLocID + '&equiplocID=' + item.EquipCustLocID}>
//             <ROIcon icon='ROicoGoto' size={32} className='icoSVG interval px-2' tooltip='Go To' isLink={false} />
//         </Link> */}
// </div>
