import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import { toast } from 'react-toastify'
import { VaildateForm } from '../features/globalFunctions'
import ROPassword from '../components/ROPassword'

//Import Redux Functions and Axiox database calls
import { deleteUser, updateUser, updateUserPassword } from '../features/users/usersSlice'

import ROComboBox from './ROComboBox'
import { RegExPattern } from '../features/globalVars'

const UserItem = ({ userData, arrRoles, containerBackColor, collapse, _expanedState, _editState }) => {
    const dispatch = useDispatch()

    //console.log(`*** User  ${userData.name}  Draw ***`, userData)

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)

    //Edit User
    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtUserName, setTxtUserName] = useState(userData.name ? userData.name : '')
    const [txtEmail, setTxtEmail] = useState(userData.email ? userData.email : '')
    const [txtUserTel, setTxtUserTel] = useState(userData.usertel ? userData.usertel : '')
    const [txtUserType, setTxtUserType] = useState(userData.usertype ? userData.usertype : '')
    const [boolUserSuspended, setBoolUserSuspended] = useState(userData.usersuspended === 1 ? true : false)

    //Account Password
    const [editUserAccPasswordState, setEditUserAccPasswordState] = useState(false)
    const [boolUserPassValidated, setUserPassValidated] = useState(false)
    const [txtUserPass, setTxtUserPass] = useState('')

    const getNewUserPassword = (newPass) => {
        if (newPass && newPass !== '') {
            setTxtUserPass(newPass)
            setUserPassValidated(true)
        } else {
            setUserPassValidated(false)
        }
    }

    const editUserAccPassword = (edit = false) => {
        //console.log('editFranAcc clicked franacc.settings: ', FranAccSettings)

        if (!edit) {
            setTxtUserPass('')
            setUserPassValidated(false)
            setEditUserAccPasswordState((prev) => !prev)
            return
        }

        if (editUserAccPasswordState) {
            //FranAccName = txtAccountName

            const updAccPassword = {
                UserID: userData._id,
                Password: txtUserPass,
            }

            console.log('updAccPassword: ', updAccPassword)

            dispatch(updateUserPassword(updAccPassword))

            //dispatch(updateFranSettings(updAcc))
        }

        setEditUserAccPasswordState((prev) => !prev)
    }

    //to do!!!!
    //to do!!!!
    //to do!!!!
    //to do!!!!
    //to do!!!!
    //to do!!!!
    const [txtUserRoleTitle, setTxtUserRoleTitle] = useState('')

    const editUser = (edit = true) => {
        console.log('start edit username usetype: ', userData.usertype)
        if (!edit) {
            setEditState((prev) => !prev)
            setTxtUserName(userData.name ? userData.name : '')
            setTxtEmail(userData.email ? userData.email : '')
            setTxtUserTel(userData.usertel ? userData.usertel : '')
            setTxtUserType(userData.usertype ? userData.usertype : '')
            setBoolUserSuspended(userData.usersuspended === 1 ? true : false)
            return
        }

        if (editState) {
            const updUser = {
                userID: userData._id,
                userName: txtUserName,
                userEmail: txtEmail,
                userContactNumber: txtUserTel,
                userFlags: boolUserSuspended ? JSON.stringify({ Suspended: 'Account Suspended' }) : JSON.stringify({ Suspended: 'Account Active' }),
                userSuspended: boolUserSuspended,
                userType: txtUserType,
            }
            dispatch(updateUser(updUser))
        } else {
            console.log('Edit UserName: ', txtUserName)
        }
        setEditState((prev) => !prev)
    }

    //Delete User
    const confirmDeleteUser = (setState) => {
        if (confirmDelete && setState) {
            console.log('Delete!!!! UserID: ', userData._id)
            deleteUserById(userData._id)
        }
        setDeleteState(setState)
    }

    const deleteUserById = (_id) => {
        console.log('Delete!!!! Part_ID: ', _id)
        dispatch(deleteUser(_id))
        confirmDeleteUser(false)
    }

    const getUserTitle = (userTypeLvl) => {
        //console.log('getUserTitle from Arr: ', arrRoles)

        if (userTypeLvl === 0) {
            return 'Owner'
        }

        //console.log('txtUserType: ', txtUserType)
        //console.log('getUserTitle: ', userTypeLvl)

        //let ret = 'N/A'
        let ret = arrRoles.find((item) => {
            if (item.ID === userTypeLvl) {
                //console.log('return item.Text: ', item.Text)
                return item.Text
            }
        })

        return ret ? ret.Text : 'N/A'
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    //#region Edit User Form Validation
    const [boolUserFormValidated, setUserFormValidated] = useState(false)

    const valUserNameRef = useRef()
    const valUserEmailRef = useRef()
    const valUserContactNumRef = useRef()
    const valUserRoleRef = useRef()

    const vaildateEditUserForm = (clicked = false) => {
        if (!editState) return

        if (editState && userData.usertype > 0) {
            setUserFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valUserNameRef,
                            text: 'No Special Characters',
                            form: 'Edit User valUserNameRef',
                        },
                        {
                            ref: valUserEmailRef,
                            text: 'Enter Valid Email',
                            form: 'Edit User valUserEmailRef',
                        },
                        {
                            ref: valUserContactNumRef,
                            text: 'No Special Characters',
                            form: 'Edit User valUserContactNumRef',
                        },
                        {
                            ref: valUserRoleRef,
                            text: 'User Role Required',
                            form: 'Edit User valUserRoleNumRef',
                        },
                    ],
                    clicked
                )
            )
            return
        } else if (editState && userData.usertype === 0 && valUserRoleRef.current.text === 'Owner') {
            setUserFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valUserNameRef,
                            text: 'No Special Characters',
                            form: 'Edit User valUserNameRef',
                        },
                        {
                            ref: valUserEmailRef,
                            text: 'Enter Valid Email',
                            form: 'Edit User valUserEmailRef',
                        },
                        {
                            ref: valUserContactNumRef,
                            text: 'No Special Characters',
                            form: 'Edit User valUserContactNumRef',
                        },
                        {
                            ref: valUserRoleRef,
                            text: 'User Role Required',
                            form: 'Edit User valUserRoleNumRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <ContentBox classTag={toggleState ? `content border border-primary p-0 m-0 mb-1 rounded-2 ${containerBackColor}` : `content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`}>
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded

                <div className='container p-2 pt-0 bg-white rounded-2'>
                    <div className='row' /*PartInfo Expanded*/>
                        {/*Part Code*/}
                        <div className='ROmaintxt col-auto'>
                            {editState ? (
                                <input
                                    className='txtBox'
                                    value={txtUserName}
                                    placeholder={userData.name}
                                    onChange={(e) => {
                                        setTxtUserName(e.target.value)
                                        vaildateEditUserForm()
                                    }}
                                    ref={valUserNameRef}
                                    title='User Name'
                                    pattern={RegExPattern.Text}
                                    required
                                />
                            ) : (
                                userData.name
                            )}
                        </div>
                        {/*Interface Buttons*/}
                        <div className='col-auto me-auto px-0'>
                            <div>
                                {editState ? (
                                    <>
                                        {boolUserFormValidated ? <ROIcon icon='ROicoAccept' size={24} className='icoSVG' tooltip='Update User' authlvl={5} onClickCallback={() => editUser()} /> : <ROIcon icon='ROicoAccept' size={24} className='icoSVG' tooltip='Update User' authlvl={5} onClickCallback={() => vaildateEditUserForm(true)} usefill={true} color1='#ff0000' />}

                                        <ROIcon icon='ROicoCancel' size={24} className='icoSVG' tooltip='Cancel' onClickCallback={() => editUser(false)} />
                                    </>
                                ) : userData.usertype > 0 ? (
                                    <ROIcon icon='ROicoEdit' size={24} className='icoSVG' authlvl={5} tooltip='Edit User' onClickCallback={() => editUser(true)} />
                                ) : (
                                    ''
                                )}

                                {/* {!editState ? (
                                    <>
                                        {confirmDelete ? (
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                className='icoSVG danger'
                                                tooltip='Delete User'
                                                authlvl={0}
                                                onClickCallback={() => confirmDeleteUser(true)}
                                            />
                                        ) : (
                                            ' '
                                        )}
                                        {!confirmDelete ? (
                                            <ROIcon
                                                icon='ROicoDel'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Delete User'
                                                authlvl={0}
                                                onClickCallback={() => confirmDeleteUser(true)}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    ''
                                )} */}

                                {/* {!editState ? (
                                    confirmDelete ? (
                                        <ROIcon
                                            icon='ROicoClose'
                                            size={24}
                                            className='icoSVG'
                                            tooltip='Cancel Delete'
                                            onClickCallback={() => confirmDeleteUser(false)}
                                        />
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    ''
                                )} */}
                            </div>
                        </div>
                        {/*Toggle Icon*/}
                        <div className='col-auto px-2'>
                            {/*Expande/Collapse Icon*/}

                            {editState && userData.usertype > 0 ? (
                                <>
                                    <ROIcon icon={boolUserSuspended ? 'ROicoCheckbox' : 'ROicoBox'} size={24} className='icoSVG' tooltip='Suspended' onClickCallback={() => setBoolUserSuspended((prev) => !prev)} textbefore='Suspend' />
                                </>
                            ) : boolUserSuspended ? (
                                <span className='danger'>Suspended</span>
                            ) : (
                                ''
                            )}

                            {editState ? '' : <ROIcon icon='ROicoTup' className='icoSVG' onClickCallback={() => setToggleState((prev) => !prev)} />}
                        </div>
                    </div>
                    <div className='row' /*UserInfo Expanded*/>
                        <div className='ROnormaltxt col-md-6 fs-5'>
                            {editState && userData.usertype > 0 ? (
                                <input
                                    className='txtBox'
                                    value={txtEmail}
                                    placeholder={userData.email}
                                    onChange={(e) => {
                                        setTxtEmail(e.target.value)
                                        vaildateEditUserForm()
                                    }}
                                    type='email'
                                    ref={valUserEmailRef}
                                    title='User Email'
                                    required
                                />
                            ) : (
                                userData.email
                            )}
                        </div>
                        <div className='ROnormaltxt col-md-2 fs-5'>
                            {editState && userData.usertype > 0 ? (
                                <input
                                    className='txtBox'
                                    value={txtUserTel}
                                    placeholder={userData.usertel}
                                    onChange={(e) => {
                                        setTxtUserTel(e.target.value)
                                        vaildateEditUserForm()
                                    }}
                                    ref={valUserContactNumRef}
                                    title='Contact Number'
                                    pattern={RegExPattern.Phone}
                                    required
                                />
                            ) : (
                                userData.usertel
                            )}
                        </div>
                        <div className='ROnormaltxt col-md-4 fs-5 me-auto '>
                            {editState && userData.usertype > 0 ? (
                                <ROComboBox dataArray={arrRoles} placeholdertext={'User Role'} textVal={getUserTitle(txtUserType)} myIDCallback={setTxtUserType} ref={valUserRoleRef} required={true} validationCallBack={() => vaildateEditUserForm()} allowCustomValue={false} allowFilter={false} />
                            ) : userData.usertype > 0 || !editState ? (
                                getUserTitle(userData.usertype)
                            ) : (
                                <input
                                    className='txtBox w-50 border-0'
                                    value={'Owner'}
                                    onChange={(e) => {
                                        setTxtUserTel(e.target.value)
                                        vaildateEditUserForm()
                                    }}
                                    ref={valUserRoleRef}
                                    title='Owner'
                                    required
                                    readonly
                                />
                            )}
                        </div>
                        {userData.usertype > 0 ? (
                            <>
                                <div className='row'>
                                    <div className='custName col-auto p-1 mx-2 fs-3  fw-normal'>{editUserAccPasswordState ? <ROPassword validationCallBack={getNewUserPassword} classname='ROpassword ps-3' password1={txtUserPass} password2={txtUserPass} /> : 'Change User Password'}</div>
                                    <div className='col-auto me-auto px-0'>
                                        <div>
                                            {editUserAccPasswordState ? (
                                                <>
                                                    {boolUserPassValidated ? (
                                                        <ROIcon icon='ROicoAccept' size={24} className='icoSVG' tooltip='Update Password' authlvl={10} onClickCallback={() => editUserAccPassword(true)} />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoAccept'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Password'
                                                            authlvl={10}
                                                            onClickCallback={() => {
                                                                toast.error('Invalid Password')
                                                            }}
                                                            usefill={true}
                                                            color1='#ff0000'
                                                        />
                                                    )}

                                                    <ROIcon icon='ROicoCancel' size={24} className='icoSVG' tooltip='Cancel' onClickCallback={() => editUserAccPassword(false)} />
                                                </>
                                            ) : (
                                                <ROIcon icon='ROicoEdit' size={24} className='icoSVG' tooltip='Edit Password' authlvl={10} onClickCallback={() => editUserAccPassword()} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='row p-0'>
                                <div className='col-md-6 p-0'>
                                    <ROIcon
                                        icon='ROicoSettings'
                                        size={24}
                                        className='icoSVG'
                                        tooltip='Settings'
                                        textafter='Settings'
                                        to='/account'
                                        authlvl={0}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <div className='container p-2 pt-0' onClick={() => setToggleState((prev) => !prev)}>
                    <div className='row' /*EquipInfo Compact*/>
                        <div className='ROmaintxt col-md-2 pl-2 fs-3'>{userData.name}</div>
                        <div className='ROnormaltxt col-md-4 fs-5'>{userData.email}</div>
                        <div className='ROnormaltxt col-md-2 fs-5'>{userData.usertel}</div>
                        <div className='ROnormaltxt col-md-2 fs-5 me-auto '>{getUserTitle(userData.usertype)}</div>
                        {/*Interface Buttons*/}
                        <div className='col-auto me-auto px-0'></div>
                        {/*Toggle Icon*/}
                        <div className='col-auto px-1'>
                            {/*Expande/Collapse Icon*/}
                            {boolUserSuspended ? <span className='danger'>Suspended</span> : ''}
                            {editState ? '' : <ROIcon icon='ROicoTdown' className='icoSVG' />}
                        </div>
                    </div>
                </div>
            )}
        </ContentBox>
    )
}

export default UserItem
