import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import ROIcon from '../components/ROIcon'
import { addROErrorLog } from '../features/globalFunctions'
import { resetCust } from '../features/cust/custSlice'
import { resetequip } from '../features/equip/equipSlice'
import { resetparts } from '../features/parts/partsSlice'
import { resetusers } from '../features/users/usersSlice'
import { resetLocations } from '../features/custloc/custlocSlice'
import { resetLocContacts } from '../features/custloccontacts/custloccontactsSlice'
import { resetequipcustloc } from '../features/equipcustloc/equipcustlocSlice'
import { resetservices } from '../features/services/servicesSlice'
import { resetfranacc } from '../features/franacc/franaccSlice'
import { useSearchParams } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let [searchParams, setSearchParams] = useSearchParams()

    const [pagemessage, setPageMessage] = useState(searchParams.get('pagemessage') ? searchParams.get('pagemessage') : '')

    //toast.success(pagemessage)

    const { user, isError, message } = useSelector((state) => state.auth)

    const onLogout = () => {
        console.log('Home LogOut Clicked')
        localStorage.removeItem('user')
        dispatch(logout())
        dispatch(reset())
        dispatch(resetCust())
        dispatch(resetLocations())
        dispatch(resetLocContacts())
        dispatch(resetequipcustloc())
        dispatch(resetservices())
        dispatch(resetequip())
        dispatch(resetparts())
        dispatch(resetfranacc())
        dispatch(resetusers())
        navigate('/')
        return
    }

    useEffect(() => {
        console.log('Home called: ', user)

        if (isError) {
            console.log('Customers USERS useEffect error: ', message)
            toast.error(message)
            dispatch(reset())
            navigate('/')
            addROErrorLog('Home users useEffect Error:  ' + message, user ? user.name : '', 'Home')
            return
        }

        if (!user || !user._id || !user.token) {
            console.log('User error!!! log out: ', user)
            addROErrorLog('Home users Error:  ' + message, user ? user.name : '', 'Home')
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }
    }, [user, isError])

    return (
        <>
            <div className='container-fluid bg-primary'>
                <div className='container '>
                    <div className='row align-items-center'>
                        <div className='col-md-7 bg-primary p-3 '>
                            <h1 className='text-white'>Welcome</h1>
                            <p className='text-white'>
                                Welcome to the ROvantage - a comprehensive platform designed to help water purification companies manage their clients
                                and services with ease. This website provides an all-in-one solution for storing and accessing client information,
                                tracking services, and monitoring the performance of your water purification systems.&nbsp;
                                <br />
                                <br />
                                Whether you're a small business just starting out or a large enterprise looking for an efficient way to manage your
                                operations, our system is designed to meet your needs.&nbsp;
                                <br />
                                <br />
                                Start streamlining your business processes today and take control of your water purification services.
                            </p>
                            <div className='btn-group'>
                                {' '}
                                <Link to='/register' className='btn text-primary bg-white btn-outline-secondary'>
                                    Signup Now
                                </Link>
                                {/* <a href='#' className='btn btn-secondary'>
                                    Request Demo
                                </a> */}
                            </div>
                            <div className='row m-auto text-danger h5 '>{pagemessage ? pagemessage : ''}</div>
                        </div>
                        <div className='col-md-5 bg-primar mt-5'>
                            <img className='img-fluid d-block bg-primary w-100' src='./images/RO-Guy.jpg' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='text-primary'>
                                Features
                                <br />
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-3 text-center'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#1</b>{' '}
                            </h4>
                            <p>
                                Access your customer, equipment, and service data from anywhere on any computer with our user-friendly website based
                                system.
                            </p>
                        </div>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#2</b>{' '}
                            </h4>
                            <p>
                                Get a quick overview on your Dashboard or detailed access to customer information, contact info, equipment, and
                                service history with our expandable sections and reports.
                            </p>
                        </div>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#3</b>{' '}
                            </h4>
                            <p>
                                Easily create customer profiles for basic and complex setups, such as a single home address with one system, or a
                                company with multiple branches and systems in different locations.
                            </p>
                        </div>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#4</b>{' '}
                            </h4>
                            <p>
                                Make notes per customer and capture or view conversations by contact persons to easy keep track of conversations with
                                customers.
                            </p>
                        </div>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#5</b>
                            </h4>
                            <p>
                                Record all necessary service history details, including date, service technician, water quality (TDS), water pressure,
                                and all filters and parts used.{' '}
                            </p>
                        </div>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#6</b>
                            </h4>
                            <p>
                                Set up service intervals per customer and individual system, which can be scheduled by year, months, weeks, or days
                                (e.g., every 2 years, every 3 months, every 6 weeks, or every 180 days).
                            </p>
                        </div>

                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#7</b>
                            </h4>
                            <p>Generate reports to show customers due for service, including all equipment with service details.</p>
                        </div>
                        <div className='col-lg-3 col-md-6 p-2'>
                            <h4>
                                {' '}
                                <b>#8</b>
                            </h4>
                            <p>Easily add users and manage their access levels.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
